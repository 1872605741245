import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  profileIconContainer: {},
  profileIcon: {
    alignItems: 'center',
    backgroundColor: theme.palette.grey[100],
    backgroundSize: '100%',
    border: `1px solid ${theme.new.color.line.hairline}`,
    borderRadius: '50%',
    color: theme.palette.text.secondary,
    display: 'flex',
    height: '56px',
    justifyContent: 'center',
    padding: '0',
    width: '56px',
  },
  profileEmail: {
    color: theme.palette.text.secondary,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    width: '200px',
  },
  profileRole: {
    color: theme.palette.text.secondary,
  },
}));

export default useStyles;
