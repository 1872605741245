import { AccountInfo } from '@azure/msal-browser';
import { AuthError } from '@azure/msal-common';
import { DateTime } from 'luxon';

import { AuthState } from '../../../features/session/authSlice';

export const isMSALAuthError = (err: Error): err is AuthError => {
  return err && Object.prototype.hasOwnProperty.call(err, 'errorCode');
};

export const isAuthExpired = (authToken: AuthState['authToken']): boolean => {
  return (
    !authToken?.expiration ||
    (typeof authToken?.expiration === 'number' &&
      DateTime.fromMillis(authToken.expiration)
        .diff(DateTime.now())
        .toMillis() <= 0)
  );
};

export const getCurrentEnvAccount = (accounts: AccountInfo[]) => {
  if (accounts.length) {
    const loginEndpoint = (globalThis.appConfig.auth.authority || '')
      .split('/')
      .pop();
    const endpointExpression = loginEndpoint
      ? new RegExp(loginEndpoint, 'i')
      : undefined;
    return accounts.find(
      (account) =>
        account?.environment &&
        account.homeAccountId &&
        account.environment === globalThis.appConfig.auth.knownAuthority &&
        endpointExpression &&
        endpointExpression.test(account.homeAccountId)
    );
  }
  return undefined;
};
export const getDomainHintFromAccountInfo = (
  account: AccountInfo
): string | undefined => account?.username?.split('@')?.[1];
