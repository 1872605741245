import {
  CameraStatus,
  PeripheralLastCommunicatedStatus,
} from '~/common/models/peripheral.model';

export interface PeripheralFilterCriteria {
  assignmentStatus?: CameraStatus[];
  lastCommunicatedStatus?: PeripheralLastCommunicatedStatus[];
}

export const PERIPHERAL_FILTER_EMPTY_STATE: PeripheralFilterCriteria = {
  assignmentStatus: [],
  lastCommunicatedStatus: [],
};

export type PeripheralFilterKey = keyof PeripheralFilterCriteria;
