import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  fullSize: {
    width: '100%',
    height: '100%',
  },
  image: {
    objectFit: 'contain',
  },
  imageContainer: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    textAlign: 'center',
  },
}));

export default useStyles;
