import { BatteryRange } from '../models/asset.model';
import { BatterySocStatus } from '../models/asset-report.model';

export const MIN_VALID_SOC_VALUE = 0;
export const MAX_VALID_SOC_VALUE = 100;

//TODO: Get thresholds from endpoint
export const BATTERY_SOC_RANGES: {
  [k in BatterySocStatus]: {
    start?: number;
    end?: number;
    inclusiveStart?: boolean;
    inclusiveEnd?: boolean;
  };
} = {
  [BatterySocStatus.CRITICAL_CHARGE]: {
    end: 15,
  },
  [BatterySocStatus.LOW_CHARGE]: {
    start: 15,
    end: 25,
    inclusiveStart: true,
    inclusiveEnd: true,
  },
  [BatterySocStatus.MEDIUM_CHARGE]: {
    start: 25,
    end: 80,
    inclusiveEnd: true,
  },
  [BatterySocStatus.HIGH_CHARGE]: {
    start: 80,
  },
};

/**
 * Rounding rules to ensure rounding does not end up conflicting with SOC diagnostic flag logic applied in the back-end; specifically:
 * * Values under 15 must not round to 15 (critical)
 * * Values over 25 must not round to 25 (good)
 * * Values between 15 and 25 may round to 15 or 25 (impaired)
 */
export const BATTERY_RANGE_VALUE_MAPPERS: {
  [k in BatteryRange]: (value: number) => number;
} = {
  [BatteryRange.LOW]: (value: number) => Math.floor(value),
  [BatteryRange.MEDIUM]: (value: number) => Math.round(value),
  [BatteryRange.HIGH]: (value: number) => Math.ceil(value),
  [BatteryRange.UNKNOWN]: (value: number) => Math.round(value),
};

export const getRangeLabel = (status: BatterySocStatus) => {
  const range = BATTERY_SOC_RANGES[status];
  if (range?.start && range?.end) {
    return `${range?.start}% - ${range?.end}%`;
  } else if (range?.start && !range?.end) {
    return `> ${range.start}%`;
  } else if (!range?.start && range?.end) {
    return `< ${range.end}%`;
  }
  return '';
};
