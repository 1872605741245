import { datadogRum } from '@datadog/browser-rum';
import { useCallback } from 'react';

const useEventAnalytics = <T>(
  eventName: string
): { trackEventData: (data: T) => void } => {
  const trackEventData = useCallback(
    (data: T) => {
      //Log to Data Dog
      datadogRum.addAction(eventName, {
        properties: data,
      });
    },
    [eventName]
  );
  return { trackEventData };
};

export default useEventAnalytics;
