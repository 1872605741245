import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

const useSearchParams = <ParamNames extends string[]>(
  parameterNames?: ParamNames
): Record<ParamNames[number], string | null> => {
  const { search } = useLocation();
  return useMemo(() => {
    const searchParams = new URLSearchParams(search);

    // Currently unused
    if (parameterNames) {
      return parameterNames.reduce(
        (accumulator, parameterName: ParamNames[number]) => {
          accumulator[parameterName] = searchParams.get(parameterName);
          return accumulator;
        },
        {} as Record<ParamNames[number], string | null>
      );
    }

    return Object.fromEntries(searchParams.entries()) as Record<
      ParamNames[number],
      string | null
    >;
  }, [search, parameterNames]);
};

export default useSearchParams;
