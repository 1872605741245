import {
  createEntityAdapter,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';
import merge from 'deepmerge';
import { PURGE } from 'redux-persist';

import {
  API_VERSION_DEFAULTS,
  DEFAULT_API_CONFIG,
} from '~/common/apis/api.constants';
import { RouteParams } from '~/common/configs/route.config';
import {
  ApiResponse,
  BDRequestStatus,
  BDRequestType,
  OperationStatus,
} from '~/common/models/apis/apiResponse.model';
import { AssetType } from '~/common/models/asset.model';
import {
  ConnectivityStatusType,
  NonNullablePick,
} from '~/common/models/common.model';
import {
  ApiDashboardSummary,
  DashboardSummary,
  SummaryType,
} from '~/common/models/dashboard.model';
import { BDAppErrorType, BDError } from '~/common/models/error.model';
import {
  addAcceptLanguageHeader,
  addOrganizationIdHeader,
  hasApiResult,
} from '~/common/utils/apis/api.utils';
import {
  makeGetPayloadCreator,
  makeThunk,
} from '~/common/utils/store/thunk.helper';

import { RootState } from '../../app/rootReducer';
import { mapDashboardSummaryApiResponse } from './mappers/dashboard.mappers';

export type DashboardSummaryParams = NonNullablePick<
  RouteParams,
  'organizationsId'
> &
  Pick<RouteParams, 'hubsId' | 'fleetsId'> & {
    sessionId: string;
  };

export interface EnergySummary {
  highCharge: number;
  mediumCharge: number;
  lowCharge: number;
  criticalCharge: number;
  charging: number;
}

export interface TiresSummary {
  lowTire: number;
  flatTire: number;
}

export interface FluidsSummary {
  lowWasherFluid: number;
  lowBrakeFluid: number;
}

export enum DashboardRequestType {
  GET_DASHBOARD_SUMMARY = 'Get Dashboard Summary',
}

export type SummarySessionConfig = {
  [k in string]?: SummaryType;
};

interface DashboardState {
  summaryConfigs: SummarySessionConfig;
  operations: {
    [key in DashboardRequestType | BDRequestType]?: OperationStatus;
  };
  selectedConnectivityStatus: ConnectivityStatusType;
}

const dashboardAdapter = createEntityAdapter<DashboardSummary>({});

const initialState = dashboardAdapter.getInitialState<DashboardState>({
  summaryConfigs: {},
  operations: {},
  selectedConnectivityStatus: '' as ConnectivityStatusType,
});

const CONFIG = DEFAULT_API_CONFIG;

export const getDashboardSummary = makeThunk(
  'dashboard/fetchDashboardSummary',
  makeGetPayloadCreator<ApiResponse<DashboardSummary>, DashboardSummaryParams>({
    url: `${globalThis.appConfig.apiBaseUrl}/assets/${API_VERSION_DEFAULTS.default}/views/orgDashboard`,
    axiosOptions: ({ organizationsId }, state) =>
      addAcceptLanguageHeader(
        addOrganizationIdHeader(CONFIG, organizationsId),
        state.profile.currentLocale
      ),
    argAdapter: (routeParams) => {
      return {
        requestParams: {
          hubId: routeParams.hubsId || '',
          fleetId: routeParams.fleetsId || '',
        },
      };
    },
    responseAdapter: (response: unknown | ApiResponse<unknown>) => {
      if (!!response && hasApiResult<ApiDashboardSummary>(response)) {
        return {
          ...response,
          result: mapDashboardSummaryApiResponse(response.result),
        };
      }
      throw new BDError('Unexpected dashboard summary response', {
        data: response,
      });
    },
  })
);

export const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    setSummaryConfigs: (state, action: PayloadAction<SummarySessionConfig>) => {
      Object.keys(action.payload).forEach((key) => {
        const scope = key;
        const payload = action.payload[scope] || {};
        Object.keys(payload).forEach((id) => {
          const assetType = id as AssetType;
          state.summaryConfigs = merge(
            state.summaryConfigs,
            {
              [scope]: {
                [assetType]: {
                  ...payload[assetType],
                },
              },
            },
            {
              arrayMerge: (_, sourceArray) => sourceArray,
            }
          );
        });
      });
    },
    setSelectedConnectivityStatus: (
      state,
      action: PayloadAction<ConnectivityStatusType>
    ) => {
      state.selectedConnectivityStatus = action.payload;
    },
  },
  extraReducers: (builder) => {
    // dashboard summary
    builder.addCase(getDashboardSummary.pending, (state) => {
      state.operations[DashboardRequestType.GET_DASHBOARD_SUMMARY] = {
        status: BDRequestStatus.PENDING,
        errors: [],
      };
    });
    builder.addCase(getDashboardSummary.fulfilled, (state, action) => {
      state.operations[DashboardRequestType.GET_DASHBOARD_SUMMARY] = {
        status: BDRequestStatus.SUCCEEDED,
      };
      const { sessionId } = action.meta.arg;
      const summary =
        action.payload.result.summaries &&
        Object.keys(action.payload.result.summaries).length
          ? action.payload.result.summaries
          : undefined;
      state.summaryConfigs = merge(
        state.summaryConfigs,
        {
          [sessionId]: summary,
        },
        {
          arrayMerge: (_, sourceArray) => sourceArray,
        }
      );
    });
    builder.addCase(getDashboardSummary.rejected, (state, action) => {
      state.operations[DashboardRequestType.GET_DASHBOARD_SUMMARY] = {
        status: BDRequestStatus.FAILED,
        errors: [
          {
            type: BDAppErrorType.API,
            ...(action.payload || (action.error as BDError)),
          },
        ],
      };
    });

    // reset state when persist store is purged on logout
    builder.addCase(PURGE, (state) => {
      state = initialState;
    });
  },
});

export const { setSummaryConfigs, setSelectedConnectivityStatus } =
  dashboardSlice.actions;

export const selectDashboardState = (
  state: RootState
): RootState['dashboard'] => state.dashboard;

export const dashboardReducer = dashboardSlice.reducer;
