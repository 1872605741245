import { NavBarConfig } from '@gm-commercial/navbar-model';

import {
  SETTINGS_NOTIFICATIONS_ACCOUNT,
  SETTINGS_NOTIFICATIONS_USERS,
  SETTINGS_NOTIFICATIONS_VEHICLES,
} from '~/common/models/pages/settingsPages.model';

import NotificationIcon from '../../../assets/icons/new/notification.svg?react';
import { NAV_DRAWER_MESSAGES } from '../GlobalNavDrawer.messages';

//TODO: Make this dynamic once rules are defined for S&N
export const getSettingsNavBarConfig = ({
  messages,
}: {
  messages: typeof NAV_DRAWER_MESSAGES.settingsMenu;
}): NavBarConfig => ({
  title: messages['settings:settings'],
  homePath: '/',
  items: [
    {
      label: messages['settings:notifications.title'],
      icon: <NotificationIcon className="noFill" />,
      testId: 'notifications',
      path: SETTINGS_NOTIFICATIONS_VEHICLES,
      children: [
        {
          label: messages['common:vehicles'],
          testId: 'vehicles',
          path: SETTINGS_NOTIFICATIONS_VEHICLES,
        },
        {
          label: messages['common:users'],
          testId: 'users',
          path: SETTINGS_NOTIFICATIONS_USERS,
        },
        {
          label: messages['common:account'],
          testId: 'account',
          path: SETTINGS_NOTIFICATIONS_ACCOUNT,
        },
      ],
    },
  ],
});
