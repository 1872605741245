import { Profile } from '@gm-commercial/profile-model';
import { useContext } from 'react';

import ProfileContext, {
  ProfileContextProps,
} from '../contexts/ProfileContext';

/**
 * Custom hook for accessing the current user's profile information.
 * This hook utilizes the ProfileContext to provide access to the user's profile data.
 *
 * @returns  An object containing two properties:
 * - `profile`: The user profile
 * - `actingProfile`: The user acting profile when a user is impersonating another user
 */
const useProfile = (): {
  profile?: Profile;
  actingProfile?: Profile;
} => {
  const { profile, actingProfile } =
    useContext<ProfileContextProps>(ProfileContext);

  return { profile, actingProfile };
};

export default useProfile;
