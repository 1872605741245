import {
  ApiInspectionDetail,
  AssetInspectionDetail,
} from '~/common/models/assetInspection.model';

export const mapApiInspectionToInspectionDetails = ({
  categories,
  ...inspection
}: Partial<ApiInspectionDetail>): AssetInspectionDetail =>
  ({
    ...inspection,
    categories: categories?.map((category) => {
      return {
        id: category.id,
        label: category.label,
        subcategories: category.subcategories?.map((subcategory) => {
          return {
            id: subcategory.id,
            label: subcategory.label,
            questions: subcategory.questions?.map((question) => {
              return {
                ...question,
              };
            }),
          };
        }),
      };
    }),
  } as AssetInspectionDetail);
