import { createSelector } from '@reduxjs/toolkit';

import {
  DEFAULT_PAGINATION_PAGE,
  DEFAULT_PAGINATION_ROW_PER_PAGE,
} from '~/common/constants/common.constant';
import {
  AlertCardAssetAlerts,
  AlertEvent,
  AssetAlert,
} from '~/common/models/alert.model';
import { OperationStatus } from '~/common/models/apis/apiResponse.model';
import { ApiAssetType } from '~/common/models/asset.model';
import {
  DateRange as AlertDateRange,
  SearchCriteria,
} from '~/common/models/common.model';
import { IDLE_OPERATION_STATUS } from '~/common/utils/store/selector.helper';

import { RootState } from '../../app/rootReducer';
import {
  AlertListFilterSession,
  AlertsEntitiesState,
  AlertSessionViewType,
  AlertsState,
} from './alertsSlice';

export const selectAlertsState = (state: RootState): RootState['alerts'] =>
  state.alerts;

export const selectAlertsForUnreadCount = createSelector(
  selectAlertsState,
  (alertsState) => alertsState.unread
);

export const selectAlertEntitiesSessions = createSelector(
  selectAlertsState,
  (alertsState) => alertsState.entitiesSessions
);

export const selectAlertsSessions = createSelector(
  selectAlertsState,
  (alertsState) => alertsState.alertsSessions
);

export const selectAlertsFiltersSessions = createSelector(
  selectAlertsState,
  (alertsState) => alertsState.filterSessions
);

export const selectAlertsRowsPerPage = createSelector(
  selectAlertsState,
  (state) => state.rowsPerPage || DEFAULT_PAGINATION_ROW_PER_PAGE
);

export const selectEditableSubscriptionId = createSelector(
  selectAlertsState,
  (state) => state.editableSubscriptionId
);

export const selectHighlightedSubscriptionId = createSelector(
  selectAlertsState,
  (state) => state.highlightedSubscriptionId
);

export const makeAlertEntitiesStateBySessionIdSelector = (
  viewType: AlertSessionViewType,
  sessionId: string
): ((state: RootState) => AlertsEntitiesState) =>
  createSelector(
    selectAlertEntitiesSessions,
    (items) => items?.[viewType]?.[sessionId] || {}
  );

export const makeAlertStateBySessionIdSelector = (
  viewType: AlertSessionViewType,
  sessionId: string,
  entityId: string
): ((state: RootState) => AlertsState) =>
  createSelector(
    selectAlertsSessions,
    (items) => items?.[viewType]?.[sessionId]?.[entityId] || {}
  );

export const makeAlertFiltersStateBySessionIdSelector = (
  viewType: AlertSessionViewType,
  sessionId: string
): ((state: RootState) => AlertListFilterSession) =>
  createSelector(
    selectAlertsFiltersSessions,
    (items) => items?.[viewType]?.[sessionId] || {}
  );

export const makeAlertEntitiesOperationsStatusSelector = (
  viewType: AlertSessionViewType,
  sessionId: string
): ((state: RootState) => OperationStatus) =>
  createSelector(
    makeAlertEntitiesStateBySessionIdSelector(viewType, sessionId),
    (sessionData) => sessionData?.operationStatus || IDLE_OPERATION_STATUS
  );

export const makeAlertEntitiesSelector = (
  viewType: AlertSessionViewType,
  sessionId: string
): ((state: RootState) => AlertCardAssetAlerts[] | undefined) =>
  createSelector(
    makeAlertEntitiesStateBySessionIdSelector(viewType, sessionId),
    (sessionData) => sessionData?.entities
  );

export const makeAlertsEntitiesPageNumberSelector = (
  viewType: AlertSessionViewType,
  sessionId: string
): ((state: RootState) => number) =>
  createSelector(
    makeAlertEntitiesStateBySessionIdSelector(viewType, sessionId),
    (sessionData) => sessionData?.page || DEFAULT_PAGINATION_PAGE
  );

export const makeAlertsEntitiesRowsPerPageSelector = (
  viewType: AlertSessionViewType,
  sessionId: string
): ((state: RootState) => number) =>
  createSelector(
    makeAlertEntitiesStateBySessionIdSelector(viewType, sessionId),
    (sessionData) => sessionData?.rowsPerPage || DEFAULT_PAGINATION_ROW_PER_PAGE
  );

export const makeAlertsEntitiesTotalCountSelector = (
  viewType: AlertSessionViewType,
  sessionId: string
): ((state: RootState) => number | undefined) =>
  createSelector(
    makeAlertEntitiesStateBySessionIdSelector(viewType, sessionId),
    (sessionData) => sessionData?.count
  );

export const makeAlertsByEntityIdSelector = (
  viewType: AlertSessionViewType,
  sessionId: string,
  assetsId: string
): ((state: RootState) => AssetAlert[] | undefined) =>
  createSelector(
    makeAlertStateBySessionIdSelector(viewType, sessionId, assetsId),
    (alertsSession) => alertsSession?.alerts
  );

export const makeAlertsCountByEntityIdSelector = (
  viewType: AlertSessionViewType,
  sessionId: string,
  assetsId: string
): ((state: RootState) => number | undefined) =>
  createSelector(
    makeAlertStateBySessionIdSelector(viewType, sessionId, assetsId),
    (alertsSession) => alertsSession?.count
  );

export const makeAlertsOperationStatusByEntityIdSelector = (
  viewType: AlertSessionViewType,
  sessionId: string,
  assetsId: string
): ((state: RootState) => OperationStatus) =>
  createSelector(
    makeAlertStateBySessionIdSelector(viewType, sessionId, assetsId),
    (alertsSession) => alertsSession?.operationStatus || IDLE_OPERATION_STATUS
  );

export const makeExpandedRowsIndicesSelector = (
  viewType: AlertSessionViewType,
  sessionId: string
): ((state: RootState) => number[]) =>
  createSelector(
    makeAlertEntitiesStateBySessionIdSelector(viewType, sessionId),
    (sessionData) => sessionData?.rowsExpanded || []
  );

export const makeSelectedAssetTypesSelector = (
  viewType: AlertSessionViewType,
  sessionId: string
): ((state: RootState) => ApiAssetType[] | undefined) =>
  createSelector(
    makeAlertFiltersStateBySessionIdSelector(viewType, sessionId),
    (sessionData) => sessionData?.selectedAssetTypes
  );

export const makeSelectedAlertTypesSelector = (
  viewType: AlertSessionViewType,
  sessionId: string
): ((state: RootState) => AlertEvent[] | undefined) =>
  createSelector(
    makeAlertFiltersStateBySessionIdSelector(viewType, sessionId),
    (sessionData) => sessionData?.selectedAlertTypes
  );

export const makeSelectedDateRangeSelector = (
  viewType: AlertSessionViewType,
  sessionId: string
): ((state: RootState) => AlertDateRange | undefined) =>
  createSelector(
    makeAlertFiltersStateBySessionIdSelector(viewType, sessionId),
    (sessionData) => sessionData?.selectedDateRange
  );

export const makeSearchCriteriaSelector = (
  viewType: AlertSessionViewType,
  sessionId: string
): ((state: RootState) => SearchCriteria | undefined) =>
  createSelector(
    makeAlertFiltersStateBySessionIdSelector(viewType, sessionId),
    (sessionData) => sessionData?.searchCriteria
  );
