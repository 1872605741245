import { NavBarConfig } from '@gm-commercial/navbar-model';
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline'; // Correct icon that matches

import { SUPPORT_CONTACT_PATH } from '~/common/models/pages/supportPages.model';

import { NAV_DRAWER_MESSAGES } from '../GlobalNavDrawer.messages';

export const getSupportNavBarConfig = ({
  messages,
}: {
  messages: typeof NAV_DRAWER_MESSAGES.supportMenu;
}): NavBarConfig => ({
  title: messages['common:header.support'],
  homePath: '/support',
  items: [
    {
      label: messages['support:contact'],
      testId: 'support-contact',
      icon: <ChatBubbleOutlineIcon fontSize="small" />,
      path: SUPPORT_CONTACT_PATH,
    },
  ],
});
