import MuiAlert, { AlertColor } from '@mui/material/Alert';
import Box from '@mui/material/Box';
import { ReactElement, ReactNode, useEffect, useState } from 'react';

export type BDAlertSeverity = AlertColor;
export enum BDAlertPriority {
  LOW = 0,
  MEDIUM = 1,
  HIGH = 2,
}
export interface BDAlert {
  message: string | ReactElement;
  severity: BDAlertSeverity;
  priority: BDAlertPriority;
  id?: string;
  dismissable?: boolean;
  onDismiss?: () => void;
  overrideIcon?: ReactNode | false;
  variant?: 'standard' | 'filled' | 'outlined';
  pushToTop?: boolean;
}

interface BDAlertState extends BDAlert {
  open: boolean;
}

interface BDAlertsProps {
  alerts?: BDAlert[];
}

const ALERT_SEVERITY_ORDER_ORDER = {
  success: 3,
  info: 2,
  warning: 1,
  error: 0,
};
const BDAlerts = ({ alerts }: BDAlertsProps): JSX.Element => {
  const [alertStates, setAlertStates] = useState<BDAlertState[]>();

  useEffect(() => {
    setAlertStates(
      alerts
        ?.map(
          (alert) =>
            ({
              ...alert,
              open: true,
              dismissable: alert.dismissable ?? alert.severity === 'warning',
            } as BDAlertState)
        )
        .sort((a, b) => {
          if (a.pushToTop || b.pushToTop) {
            return a.pushToTop ? -1 : b.pushToTop ? 1 : -1;
          } else if (
            ALERT_SEVERITY_ORDER_ORDER[a.severity] ===
            ALERT_SEVERITY_ORDER_ORDER[b.severity]
          ) {
            return a.priority > b.priority ? -1 : 1;
          } else {
            return ALERT_SEVERITY_ORDER_ORDER[a.severity] <
              ALERT_SEVERITY_ORDER_ORDER[b.severity]
              ? -1
              : 1;
          }
        })
    );
  }, [alerts]);

  return (
    <>
      {alertStates &&
        alertStates.map((alert, i) => (
          <Box key={i} mb={2}>
            {alert.open && (
              <MuiAlert
                icon={
                  alert.overrideIcon
                    ? alert.overrideIcon
                    : alert.overrideIcon === false
                    ? false
                    : undefined
                }
                variant={alert.variant || 'filled'}
                severity={alert.severity}
                onClose={
                  alert.dismissable
                    ? () => {
                        setAlertStates(
                          alertStates.map((item, j) =>
                            i !== j
                              ? item
                              : {
                                  ...item,
                                  open: false,
                                }
                          )
                        );
                        alert.onDismiss?.();
                      }
                    : undefined
                }
                data-testid="alert-message"
                id={alert.id}
              >
                {alert.message}
              </MuiAlert>
            )}
          </Box>
        ))}
    </>
  );
};
export default BDAlerts;
