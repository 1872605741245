import { useFlags } from 'launchdarkly-react-client-sdk';
import { useContext } from 'react';

import { FeatureFlag } from '../models/featureFlags.model';
import FeatureFlagsProviderContext, {
  FeatureFlagsProviderContextProps,
} from '../providers/FeatureFlagsProviderContext';

const useFeatureFlags = <T>(): {
  ready: boolean;
  featureFlags?: FeatureFlag[];
  getFlag: (name: string) => FeatureFlag | undefined;
  setContext: (name: string, context: T) => void;
} => {
  const flags = useFlags();

  const { ready, setContext } = useContext<FeatureFlagsProviderContextProps<T>>(
    FeatureFlagsProviderContext
  );

  const featureFlags: Array<FeatureFlag> = Object.entries(flags).map(
    ([name, value]) => ({
      name,
      value,
    })
  );

  const getFlag = (name: string) => flags[name] ?? undefined;

  return { featureFlags, getFlag, ready, setContext };
};

export default useFeatureFlags;
