import { TimePeriodUtilizationSummary } from '~/common/models/utilization.model';

export const MILLISECOND = 1000;
export const DEFAULT_TIME_PERIOD = 30;
export const DEFAULT_UNIT_SUMMARY: TimePeriodUtilizationSummary = {
  batteryStart: 0,
  batteryEnd: 0,
  batteryUsed: 0,
  date: '',
  distanceDriven: 0,
  estimatedRangeEnd: 0,
  estimatedRangeStart: 0,
  ignitionEnd: '',
  ignitionStart: '',
  numberOfVehicles: 0,
  odometerEnd: 0,
  odometerStart: 0,
  timeDriven: 0,
  daysDriven: 0,
};
export const UTILIZATION_FLAG_NAME = 'F21997-Utilization';
export const SIXTY_TIME_PERIOD = 60;
export const NINETY_TIME_PERIOD = 90;
export const DEFAULT_UTILIZATION_START_DATE = '2020-01-01';
