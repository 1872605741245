import { VehicleOrderSummary } from '~/common/models/vehicleOrders.model';
import type { VehicleOrderListSession } from '~/common/models/vehicleOrdersSlice.model';

export const VEHICLE_ORDER_LIST = 'vehicle-order-list';
export const DEFAULT_VEHICLE_ORDER_LIST_SORTING = 'updatedAt';
export const DEFAULT_VEHICLE_ORDER_LIST_SORTING_DIRECTION = 'desc';
export const VEHICLE_ORDER_DETAILS = 'vehicle-order-details';
export const VEHICLE_ORDER_LIST_SUMMARY = 'vehicle-order-list-summary';
export const VEHICLE_ORDER_RECENT_ORDERS = 'vehicle-order-recent-orders';
export const VEHICLE_ORDER_LIST_EXPORT = 'vehicle-order-list-export';
export const MAX_RECENT_ORDERS_CARDS = 10;
export const ACCEPTED_EXPORT_FILE_TYPE = 'text/csv';
export const DEFAULT_ORDER_LIST_SORT: VehicleOrderListSession['sortOrder'] = {
  name: DEFAULT_VEHICLE_ORDER_LIST_SORTING,
  direction: DEFAULT_VEHICLE_ORDER_LIST_SORTING_DIRECTION,
};
export const DEFAULT_PAGE_TOKENS = {
  currentToken: '',
  previousToken: '',
  nextToken: '',
  firstPageItemIndex: 0,
  totalItems: 0,
};
export const DEFAULT_ORDER_FILTER = {};

export const CARASOUEL_RESPONSIVE = {
  xxxlarge: {
    breakpoint: { max: 4000, min: 3000 },
    items: 6,
  },
  xxlarge: {
    breakpoint: { max: 3000, min: 2400 },
    items: 5,
  },
  xlarge: {
    breakpoint: { max: 2400, min: 1890 },
    items: 4,
  },
  large: {
    breakpoint: { max: 1890, min: 1300 },
    items: 3,
  },
  desktop: {
    breakpoint: { max: 1300, min: 800 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 800, min: 0 },
    items: 1,
  },
};
export const DEFAULT_SUMMARY: VehicleOrderSummary = {
  ORDERED: 0,
  ACCEPTED: 0,
  PRODUCED: 0,
  SHIPPED: 0,
  AT_DEALERSHIP: 0,
  DELIVERED: 0,
};
