import {
  ActionReducerMapBuilder,
  createAction,
  PayloadAction,
} from '@reduxjs/toolkit';
import merge from 'deepmerge';
import { WritableDraft } from 'immer/dist/types/types-external';

import { SessionConfigType } from '~/common/constants/common.constant';
import { BDRequestStatus } from '~/common/models/apis/apiResponse.model';
import { Operator } from '~/common/models/workplan.model';

import { WorkPlansState } from '../workPlansSlice.model';

export interface UpdateWorkPlanAssignedOperatorOperationState {
  status: BDRequestStatus;
  error: {
    message: string;
    code: string;
  } | null;
}

export type EditWorkPlanAssignedOperatorActivityStatus =
  | 'inactive'
  | 'active'
  | 'updating';

export interface EditWorkPlanAssignedOperatorActivitySessionState {
  status: EditWorkPlanAssignedOperatorActivityStatus;
  workPlanId: string | null;
  selectedOperator: Operator | null;
  updateOperation: UpdateWorkPlanAssignedOperatorOperationState;
}

export interface EditWorkPlanAssignedOperatorActivitySessionsConfigState {
  [id: string]: EditWorkPlanAssignedOperatorActivitySessionState;
}

export interface UpdateEditWorkPlanAssignedOperatorActivitySessionPayload {
  [id: string]: EditWorkPlanAssignedOperatorActivitySessionState;
}

const INIT_UPDATE_OPERATION_STATE: UpdateWorkPlanAssignedOperatorOperationState =
  {
    status: BDRequestStatus.IDLE,
    error: null,
  };

const INIT_EDIT_ASSIGNED_OPERATOR_ACTIVITY_SESSION_STATE: EditWorkPlanAssignedOperatorActivitySessionState =
  {
    status: 'inactive',
    workPlanId: null,
    selectedOperator: null,
    updateOperation: INIT_UPDATE_OPERATION_STATE,
  };

function getEditAssignedOperatorActivitySessionState(
  rootState: WritableDraft<WorkPlansState>,
  sessionId: string
) {
  return rootState?.editAssignedOperatorActivitySessions?.[
    SessionConfigType.LIST_VIEW
  ]?.[sessionId];
}

function updateEditAssignedOperatorActivitySessionState(
  sessionId: string,
  rootState: WritableDraft<WorkPlansState>,
  sessionState: Partial<EditWorkPlanAssignedOperatorActivitySessionState>
) {
  const currentSessionState =
    getEditAssignedOperatorActivitySessionState(rootState, sessionId) ||
    INIT_EDIT_ASSIGNED_OPERATOR_ACTIVITY_SESSION_STATE;

  const nextSessionState = {
    ...currentSessionState,
    ...sessionState,
  };

  rootState.editAssignedOperatorActivitySessions = merge(
    rootState.editAssignedOperatorActivitySessions,
    {
      [SessionConfigType.LIST_VIEW]: {
        [sessionId]: nextSessionState,
      },
    },
    {
      arrayMerge: (_, sourceArray) => sourceArray,
    }
  );
}

/// Reset Session State

type ResetSessionStateActionPayload = {
  sessionId: string;
};

export const resetSessionStateAction =
  createAction<ResetSessionStateActionPayload>(
    'workPlans/editAssignedOperatorActivity/resetSessionState'
  );

export function resetSessionStateReducer(
  state: WritableDraft<WorkPlansState>,
  action: PayloadAction<ResetSessionStateActionPayload>
) {
  updateEditAssignedOperatorActivitySessionState(
    action.payload.sessionId,
    state,
    INIT_EDIT_ASSIGNED_OPERATOR_ACTIVITY_SESSION_STATE
  );
}

/// Reset Update Operation

type ResetUpdateOperationActionPayload = {
  sessionId: string;
};

export const resetUpdateOperationAction =
  createAction<ResetUpdateOperationActionPayload>(
    'workPlans/editAssignedOperatorActivity/resetUpdateOperation'
  );

export function resetUpdateOperationReducer(
  state: WritableDraft<WorkPlansState>,
  action: PayloadAction<ResetUpdateOperationActionPayload>
) {
  updateEditAssignedOperatorActivitySessionState(
    action.payload.sessionId,
    state,
    {
      updateOperation: INIT_UPDATE_OPERATION_STATE,
    }
  );
}

/// Activate Edit Activity

type ActivateEditActivityActionPayload = {
  sessionId: string;
  workPlanId: string;
  operator: Operator | null;
};

export const activateEditActivityAction =
  createAction<ActivateEditActivityActionPayload>(
    'workPlans/editAssignedOperatorActivity/activateEditActivity'
  );

export function activateEditActivityReducer(
  state: WritableDraft<WorkPlansState>,
  action: PayloadAction<ActivateEditActivityActionPayload>
) {
  updateEditAssignedOperatorActivitySessionState(
    action.payload.sessionId,
    state,
    {
      workPlanId: action.payload.workPlanId,
      status: 'active',
      selectedOperator: action.payload.operator,
      updateOperation: INIT_UPDATE_OPERATION_STATE,
    }
  );
}

/// Cancel Edit Activity

type CancelEditActivityActionPayload = {
  sessionId: string;
};

export const cancelEditActivityAction =
  createAction<CancelEditActivityActionPayload>(
    'workPlans/editAssignedOperatorActivity/cancelEditActivity'
  );

export function cancelEditActivityReducer(
  state: WritableDraft<WorkPlansState>,
  action: PayloadAction<CancelEditActivityActionPayload>
) {
  updateEditAssignedOperatorActivitySessionState(
    action.payload.sessionId,
    state,
    INIT_EDIT_ASSIGNED_OPERATOR_ACTIVITY_SESSION_STATE
  );
}

/// Set Edit Activity Updating

type SetEditActivityUpdatingActionPayload = {
  sessionId: string;
  operator: Operator | null;
};

export const setEditActivityUpdatingAction =
  createAction<SetEditActivityUpdatingActionPayload>(
    'workPlans/editAssignedOperatorActivity/setEditActivityUpdating'
  );

export function setEditActivityUpdatingReducer(
  state: WritableDraft<WorkPlansState>,
  action: PayloadAction<SetEditActivityUpdatingActionPayload>
) {
  updateEditAssignedOperatorActivitySessionState(
    action.payload.sessionId,
    state,
    {
      status: 'updating',
      selectedOperator: action.payload.operator,
      updateOperation: {
        status: BDRequestStatus.PENDING,
        error: null,
      },
    }
  );
}

/// Set Edit Activity Update Succeeded

type SetEditActivityUpdateSucceededActionPayload = {
  sessionId: string;
};

export const setEditActivityUpdateSucceededAction =
  createAction<SetEditActivityUpdateSucceededActionPayload>(
    'workPlans/editAssignedOperatorActivity/setEditActivityUpdateSucceeded'
  );

export function setEditActivityUpdateSucceededReducer(
  state: WritableDraft<WorkPlansState>,
  action: PayloadAction<SetEditActivityUpdateSucceededActionPayload>
) {
  updateEditAssignedOperatorActivitySessionState(
    action.payload.sessionId,
    state,
    {
      status: 'inactive',
      updateOperation: {
        status: BDRequestStatus.SUCCEEDED,
        error: null,
      },
    }
  );
}

/// Set Edit Activity Update Failed

type SetEditActivityUpdateFailedActionPayload = {
  sessionId: string;
  error: { code: string; message: string };
};

export const setEditActivityUpdateFailedAction =
  createAction<SetEditActivityUpdateFailedActionPayload>(
    'workPlans/editAssignedOperatorActivity/setEditActivityUpdateFailed'
  );

export function setEditActivityUpdateFailedReducer(
  state: WritableDraft<WorkPlansState>,
  action: PayloadAction<SetEditActivityUpdateFailedActionPayload>
) {
  updateEditAssignedOperatorActivitySessionState(
    action.payload.sessionId,
    state,
    {
      status: 'inactive',
      updateOperation: {
        status: BDRequestStatus.FAILED,
        error: action.payload.error,
      },
    }
  );
}

/// Build Extra Reducers

export function buildEditWorkPlanAssignedOperatorActivityExtraReducers(
  builder: ActionReducerMapBuilder<WorkPlansState>
) {
  builder.addCase(activateEditActivityAction, activateEditActivityReducer);
  builder.addCase(cancelEditActivityAction, cancelEditActivityReducer);
  builder.addCase(resetSessionStateAction, resetSessionStateReducer);
  builder.addCase(resetUpdateOperationAction, resetUpdateOperationReducer);

  builder.addCase(
    setEditActivityUpdatingAction,
    setEditActivityUpdatingReducer
  );

  builder.addCase(
    setEditActivityUpdateFailedAction,
    setEditActivityUpdateFailedReducer
  );

  builder.addCase(
    setEditActivityUpdateSucceededAction,
    setEditActivityUpdateSucceededReducer
  );
}
