import { User } from '@gm-commercial/profile-model';

import { DEFAULT_EMPTY_VALUE } from '~/common/constants/common.constant';
import {
  ApiAddressFields,
  mapAddressToApiResponse,
  mapApiResponseToAddress,
  stripPhoneNumber,
} from '~/common/mappers/common.mappers';
import {
  PartialEntity,
  SummaryCounts,
  UserSupportedLocale,
} from '~/common/models/common.model';
import {
  ApiAssetCountSummary,
  AssetCountSummary,
  Organization,
  OrganizationType,
} from '~/common/models/organization.model';
import { lowercaseLiteral } from '~/common/utils/common.utils';

import { AddOrgFormPayload, UpdateOrgFormPayload } from '../organizationsSlice';

type OrgApiRequestPayload = {
  name: string;
  emailAddress: string;
  address: ApiAddressFields;
  phoneNumber: string;
  primaryContact?: PartialEntity<User>;
  userPreferences?: { locale: UserSupportedLocale };
  type?: OrganizationType;
  orgMasterNumber?: string;
};

//TODO: Add assetCount and userCount in Phase 2 per API contract
type ApiOrgStats = {
  hubCount: number;
  fleetCount: number;
};

export const mapApiResponseToOrg = <
  Result extends Partial<
    Omit<Organization, 'address' | 'status' | 'primaryContact'>
  > & {
    address: ApiAddressFields | null;
    primaryContact: PartialEntity<User> | null;
  } & Record<string, unknown>
>(
  rawResult: Result
): Organization => {
  const mapped = {
    ...rawResult,
    email: rawResult.emailAddress || '',
    phoneNumber:
      rawResult.phoneNumber && rawResult.phoneNumber.length >= 10
        ? stripPhoneNumber(rawResult.phoneNumber)
        : DEFAULT_EMPTY_VALUE,
    address: rawResult.address
      ? mapApiResponseToAddress(rawResult.address)
      : undefined,
    status:
      typeof rawResult.status === 'string'
        ? lowercaseLiteral(rawResult.status)
        : undefined,
    primaryContact: rawResult.primaryContact || undefined,
    orgType: rawResult.type || undefined,
    orgMasterNumber: rawResult.orgMasterNumber || undefined,
  };
  return mapped as Organization;
};

export const mapOrgFormToApiRequest = (
  payload: AddOrgFormPayload | UpdateOrgFormPayload
): OrgApiRequestPayload => {
  const { name, address, email, phoneNumber, orgType, orgMasterNumber } =
    payload.formFields;
  let result = {
    name: name.trim(),
    address: mapAddressToApiResponse(address),
    emailAddress: email,
    phoneNumber,
    type: orgType,
    orgMasterNumber: orgMasterNumber,
  } as OrgApiRequestPayload;
  if (payload.params?.organizationsId && payload.formFields.primaryContact) {
    const { id, name } = payload.formFields.primaryContact;
    result = { ...result, primaryContact: { id, name } };
  }
  const locale = payload.formFields.locale;
  if (locale) {
    result = {
      ...result,
      userPreferences: { locale },
    };
  }
  return result;
};

//TODO: Phase 2 will add assets and users
export const mapStatsApiResponseToSummaryCounts = (
  apiOrgStats: ApiOrgStats
): SummaryCounts =>
  ({
    ...apiOrgStats,
    hubs: apiOrgStats.hubCount,
    fleets: apiOrgStats.fleetCount,
  } as SummaryCounts);

export const mapApiToAssetCountSummary = (
  summary: Partial<ApiAssetCountSummary>
): AssetCountSummary => {
  const { hubs } = summary;
  return {
    organizationId: summary.organizationId,
    numberOfAssets: summary.numberOfAssets,
    hubs,
  } as AssetCountSummary;
};
