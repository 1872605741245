import { DESIGN_SYSTEM_COLORS } from '@brightdrop/mui-theme-override';

import {
  DEFAULT_EMPTY_VALUE,
  SearchType,
} from '~/common/constants/common.constant';
import { JobStatus } from '~/common/models/common.model';
import {
  UploadHistoryJobStatus,
  UploadWorkPlanHistoryFieldType,
} from '~/common/models/dispatch.model';
import { BDQuery, BDSort, BDSortDirection } from '~/common/models/query.model';
import { ScheduledDateRange } from '~/common/models/workplan.model';
import { createScheduledDateBetweenQuery } from '~/common/utils/date-time.query.utils';
import { buildQueryTree } from '~/common/utils/query.utils';

import {
  createContainingQuery,
  createEqualQuery,
  createInQuery,
} from '../../../assets/utils/assets.utils';
import { getWorkPlanScheduledDateDateRangeConfig } from '../../workplans/utils/WorkPlan.utils';
import { JobsSession, UploadWorkPlanHistoryParams } from '../jobsSlice';
import { UPLOAD_HISTORY_WORKSPACE_MESSAGES } from '../UploadHistoryWorkspace';

export const UPLOAD_HISTORY_CUSTOM_RENDER_COLORS = {
  status: {
    [UploadHistoryJobStatus.QUEUED]: DESIGN_SYSTEM_COLORS.grey[200],
    [UploadHistoryJobStatus.DONE]: DESIGN_SYSTEM_COLORS.success[200],
    [UploadHistoryJobStatus.ERROR]: DESIGN_SYSTEM_COLORS.danger[200],
    [UploadHistoryJobStatus.RUNNING]: DESIGN_SYSTEM_COLORS.warning[200],
  },
  text: {
    [UploadHistoryJobStatus.QUEUED]: DESIGN_SYSTEM_COLORS.text.primary,
    [UploadHistoryJobStatus.DONE]: DESIGN_SYSTEM_COLORS.success[800],
    [UploadHistoryJobStatus.ERROR]: DESIGN_SYSTEM_COLORS.danger[800],
    [UploadHistoryJobStatus.RUNNING]: DESIGN_SYSTEM_COLORS.warning[800],
  },
};

export const DEFAULT_JOB_TYPE = 'processWorkPlanFile';
export const DEFAULT_SORT: Array<BDSort> = [
  {
    direction: 'DESC',
    properties: [UploadWorkPlanHistoryFieldType.CREATED_DATE],
  },
];

export const getStatusLabel = (
  status: UploadHistoryJobStatus,
  labels: typeof UPLOAD_HISTORY_WORKSPACE_MESSAGES.labels
): string => {
  let jobStatus = DEFAULT_EMPTY_VALUE;
  switch (status) {
    case UploadHistoryJobStatus.QUEUED:
      jobStatus =
        labels['dispatch:tab.uploadHistory.table.column.jobStatus.queued'];
      break;
    case UploadHistoryJobStatus.ERROR:
      jobStatus =
        labels['dispatch:tab.uploadHistory.table.column.jobStatus.error'];
      break;
    case UploadHistoryJobStatus.DONE:
      jobStatus =
        labels['dispatch:tab.uploadHistory.table.column.jobStatus.done'];
      break;
    case UploadHistoryJobStatus.RUNNING:
      jobStatus =
        labels['dispatch:tab.uploadHistory.table.column.jobStatus.running'];
      break;
  }
  return jobStatus;
};

export const getJobStatus = (status: UploadHistoryJobStatus): Array<string> => {
  switch (status) {
    case UploadHistoryJobStatus.QUEUED:
      return [JobStatus.NOT_STARTED];
    case UploadHistoryJobStatus.ERROR:
      return [
        JobStatus.FAILED,
        JobStatus.TERMINATED_BY_TIMEOUT,
        JobStatus.TERMINATED_BY_USER,
        JobStatus.TERMINATION_REQUESTED,
      ];
    case UploadHistoryJobStatus.DONE:
      return [JobStatus.SUCCEEDED];
    case UploadHistoryJobStatus.RUNNING:
      return [JobStatus.ACTIVE];
    default:
      return [status];
  }
};

export const buildSearchQuery = (
  type: SearchType,
  input: string
): BDQuery | undefined => {
  switch (type) {
    case SearchType.FILE_NAME:
      return createContainingQuery(UploadWorkPlanHistoryFieldType.FILE_NAME, [
        input,
      ]);
    case SearchType.WORK_PLAN_STATUS: {
      const status = getJobStatus(
        input.toLowerCase() as UploadHistoryJobStatus
      );
      return status.length > 1
        ? createInQuery(UploadWorkPlanHistoryFieldType.STATUS, status)
        : createEqualQuery(UploadWorkPlanHistoryFieldType.STATUS, status);
    }
  }
};

export const buildRouteParamQueries = (
  params: Partial<UploadWorkPlanHistoryParams>
): Array<BDQuery> => {
  return [
    ...(params?.hubsId
      ? [
          createEqualQuery(UploadWorkPlanHistoryFieldType.HUB_ID, [
            params.hubsId,
          ]),
        ]
      : []),
    ...(params.fleetsId
      ? [
          createEqualQuery(UploadWorkPlanHistoryFieldType.FLEET_ID, [
            params.fleetsId,
          ]),
        ]
      : []),
  ];
};

export const buildDateRangeQuery = (selectedDateRange?: ScheduledDateRange) => {
  if (!selectedDateRange) {
    return undefined;
  }
  const { start: scheduledStartDate, end: scheduledEndDate } =
    getWorkPlanScheduledDateDateRangeConfig(selectedDateRange);

  return createScheduledDateBetweenQuery(
    UploadWorkPlanHistoryFieldType.CREATED_DATE,
    {
      start: scheduledStartDate,
      end: scheduledEndDate,
    }
  );
};

export const buildUploadWorkPlanHistoryQuery = (
  config: Partial<JobsSession> | undefined,
  params: Partial<UploadWorkPlanHistoryParams>
): BDQuery | undefined => {
  const routeParamQuery = buildRouteParamQueries(params);
  const jobTypeQuery = createEqualQuery(
    UploadWorkPlanHistoryFieldType.JOB_TYPE,
    [DEFAULT_JOB_TYPE]
  );
  const searchCriteriaQuery =
    config?.searchCriteria && config.searchCriteria?.input
      ? buildSearchQuery(
          config.searchCriteria.searchType,
          config.searchCriteria.input
        )
      : undefined;
  const dateRangeQuery = buildDateRangeQuery(
    config?.selectedScheduledDateRange || ScheduledDateRange.TODAY
  );
  const filteredQueries = [
    ...routeParamQuery,
    jobTypeQuery,
    searchCriteriaQuery,
    dateRangeQuery,
  ].filter((query) => query !== undefined) as BDQuery[];

  if (filteredQueries.length === 0) {
    return undefined;
  }
  return buildQueryTree(filteredQueries, 0, filteredQueries.length - 1, true);
};

export const buildSortRequest = (sortOrder?: {
  name: string;
  direction: string;
}): Array<BDSort> => {
  if (!sortOrder) {
    return DEFAULT_SORT;
  }

  return [
    {
      properties: [sortOrder.name],
      direction: sortOrder.direction.toUpperCase() as BDSortDirection,
    },
  ];
};
