import {
  ActionReducerMapBuilder,
  CaseReducer,
  EntityState,
  PayloadAction,
} from '@reduxjs/toolkit';
import merge from 'deepmerge';
import set from 'lodash/set';

import { DEFAULT_SESSION_CONFIG_KEY } from '../../../common/constants/common.constant';
import { BDRequestStatus } from '../../../common/models/apis/apiResponse.model';
import { BDAppErrorType, BDError } from '../../../common/models/error.model';
import { VehicleOrderDetailsApiResponse } from '../../../common/models/vehicleOrders.model';
import {
  SelectedVehicleOrderCodes,
  VehicleOrderSessionConfig,
  VehicleOrderSessionViewType,
  VehicleOrdersState,
} from '../../../common/models/vehicleOrdersSlice.model';
import { DEFAULT_SUMMARY } from '../vehicleOrders.constant';
import { VEHICLE_ORDERS_ACTIONS } from './vehicleOrdersSlice.actions';
import { vehicleOrdersAdapter } from './vehicleOrdersSlice.adapters';
import { INITIAL_VEHICLE_ORDERS_STATE } from './vehicleOrdersSlice.constants';

const reducerSetVehicleOrdersSessionConfig: CaseReducer<
  typeof INITIAL_VEHICLE_ORDERS_STATE,
  PayloadAction<VehicleOrderSessionConfig>
> = (state, action) => {
  Object.keys(action.payload).forEach((key) => {
    const scope = key as VehicleOrderSessionViewType;
    const payload = action.payload[scope] || {};
    Object.keys(payload).forEach((id) => {
      state.sessionConfigs = merge(
        state.sessionConfigs,
        {
          [scope]: {
            [id]: payload[id] && {
              ...payload[id],
            },
          },
        },
        {
          arrayMerge: (_, sourceArray) => sourceArray,
        }
      );
    });
  });
};

const reducerSetSelectedVehicleOrdersSelected: CaseReducer<
  typeof INITIAL_VEHICLE_ORDERS_STATE,
  PayloadAction<number[]>
> = (state, action) => {
  const currentVehicleOrders = vehicleOrdersAdapter
    .getSelectors()
    .selectAll(state);

  const prevSessionConfig =
    state.sessionConfigs?.[VehicleOrderSessionViewType.VEHICLE_ORDER_LIST]?.[
      DEFAULT_SESSION_CONFIG_KEY
    ] || {};

  const newSelectedOrderCodes = {
    ...prevSessionConfig.selectedVehicleOrderCodes,
  };

  const selectedIndices = action.payload;

  //when user selects a vehicle order, add it to the selected vehicle orders
  selectedIndices.forEach((index) => {
    const vehicleOrder = currentVehicleOrders[index];
    newSelectedOrderCodes[vehicleOrder.code] = index;
  });

  //when user unselects a vehicle order, remove it from the selected vehicle orders
  currentVehicleOrders.forEach((vehicleOrder) => {
    if (!selectedIndices.includes(newSelectedOrderCodes[vehicleOrder.code])) {
      delete newSelectedOrderCodes[vehicleOrder.code];
    }
  });

  //update the allSelected flag based on the number of selected vehicle orders
  const allSelected =
    Object.keys(newSelectedOrderCodes).length ===
    prevSessionConfig.pageTokens?.totalItems;
  set(
    state,
    `sessionConfigs.${VehicleOrderSessionViewType.VEHICLE_ORDER_LIST}.${DEFAULT_SESSION_CONFIG_KEY}.allSelected`,
    allSelected
  );

  //update the session config with the selected vehicle orders and indicies
  set(
    state,
    `sessionConfigs.${VehicleOrderSessionViewType.VEHICLE_ORDER_LIST}.${DEFAULT_SESSION_CONFIG_KEY}.selectedVehicleOrderCodes`,
    newSelectedOrderCodes
  );
};

const reduceResetVehicleOrdersSessionConfig: CaseReducer<
  typeof INITIAL_VEHICLE_ORDERS_STATE,
  PayloadAction<{
    viewType: VehicleOrderSessionViewType;
  }>
> = (
  state,
  action: PayloadAction<{
    viewType: VehicleOrderSessionViewType;
  }>
) => {
  state.sessionConfigs = merge(
    state.sessionConfigs,
    {
      [action.payload.viewType]: {
        [DEFAULT_SESSION_CONFIG_KEY]: {
          operationStatus: undefined,
          searchCriteria: undefined,
          selectedIndices: undefined,
          filterModalOpen: false,
          vehicleOrders: undefined,
          filterCriteria: undefined,
          sortOrder: undefined,
          pageTokens: undefined,
          selectedVehicleOrderCodes: undefined,
          allSelected: false,
        },
      },
    },
    {
      arrayMerge: (_, sourceArray) => sourceArray,
    }
  );
};

const reducerClearAllSelectedOrders: CaseReducer<
  typeof INITIAL_VEHICLE_ORDERS_STATE
> = (state) => {
  //clear out selected vehicle objects and set allSelected to false
  set(
    state,
    `sessionConfigs.${VehicleOrderSessionViewType.VEHICLE_ORDER_LIST}.${DEFAULT_SESSION_CONFIG_KEY}.selectedVehicleOrderCodes`,
    {}
  );
  set(
    state,
    `sessionConfigs.${VehicleOrderSessionViewType.VEHICLE_ORDER_LIST}.${DEFAULT_SESSION_CONFIG_KEY}.allSelected`,
    false
  );
};

const buildAddVehicleOrdersReducer = (
  builder: ActionReducerMapBuilder<VehicleOrdersState>
) => {
  builder.addCase(
    VEHICLE_ORDERS_ACTIONS.addVehicleOrders.pending,
    (state, action) => {
      state.sessionConfigs = merge(
        state.sessionConfigs,
        {
          [VehicleOrderSessionViewType.VEHICLE_ORDER_ADD]: {
            [DEFAULT_SESSION_CONFIG_KEY]: {
              order: {},
              operationStatus: {
                status: BDRequestStatus.PENDING,
              },
            },
          },
        },
        {
          arrayMerge: (_, sourceArray) => sourceArray,
        }
      );
    }
  );
  builder.addCase(
    VEHICLE_ORDERS_ACTIONS.addVehicleOrders.fulfilled,
    (state, action) => {
      set(
        state,
        `sessionConfigs.${VehicleOrderSessionViewType.VEHICLE_ORDER_LIST}.${DEFAULT_SESSION_CONFIG_KEY}.selectedVehicleOrderCodes`,
        {}
      );

      state.sessionConfigs = merge(
        state.sessionConfigs,
        {
          [VehicleOrderSessionViewType.VEHICLE_ORDER_ADD]: {
            [DEFAULT_SESSION_CONFIG_KEY]: {
              order: action.payload.result,
              operationStatus: {
                status: BDRequestStatus.SUCCEEDED,
              },
            },
          },
        },
        {
          arrayMerge: (_, sourceArray) => sourceArray,
        }
      );
    }
  );
  builder.addCase(
    VEHICLE_ORDERS_ACTIONS.addVehicleOrders.rejected,
    (state, action) => {
      state.sessionConfigs = merge(
        state.sessionConfigs,
        {
          [VehicleOrderSessionViewType.VEHICLE_ORDER_ADD]: {
            [DEFAULT_SESSION_CONFIG_KEY]: {
              operationStatus: {
                status: BDRequestStatus.FAILED,
                errors: [
                  {
                    type: BDAppErrorType.API,
                    ...(action.payload || (action.error as BDError)),
                  },
                ],
              },
            },
          },
        },
        {
          arrayMerge: (_, sourceArray) => sourceArray,
        }
      );
    }
  );
};

const buildGetVehicleOrdersReducer = (
  builder: ActionReducerMapBuilder<
    EntityState<VehicleOrderDetailsApiResponse> & VehicleOrdersState
  >
) => {
  builder.addCase(VEHICLE_ORDERS_ACTIONS.getVehicleOrders.pending, (state) => {
    state.sessionConfigs = merge(
      state.sessionConfigs,
      {
        [VehicleOrderSessionViewType.VEHICLE_ORDER_LIST]: {
          [DEFAULT_SESSION_CONFIG_KEY]: {
            operationStatus: {
              status: BDRequestStatus.PENDING,
            },
          },
        },
      },
      {
        arrayMerge: (_, sourceArray) => sourceArray,
      }
    );
  });
  builder.addCase(
    VEHICLE_ORDERS_ACTIONS.getVehicleOrders.fulfilled,
    (state, action) => {
      const {
        items,
        token,
        previousToken,
        nextToken,
        firstPageItemIndex,
        totalItems,
      } = action?.payload?.result ?? {};

      //if retrieveAllOrders flag is true then we're only updating the selected orders
      if (action.payload.retrieveAllOrders) {
        const selectedVehicleOrderCodes = items.reduce(
          (
            acc: SelectedVehicleOrderCodes,
            item: VehicleOrderDetailsApiResponse,
            index: number
          ) => {
            acc[item.code] = index;
            return acc;
          },
          {}
        );

        state.sessionConfigs = merge(
          state.sessionConfigs,
          {
            [VehicleOrderSessionViewType.VEHICLE_ORDER_LIST]: {
              [DEFAULT_SESSION_CONFIG_KEY]: {
                operationStatus: {
                  status: BDRequestStatus.SUCCEEDED,
                },
                selectedVehicleOrderCodes,
              },
            },
          },
          {
            arrayMerge: (_, sourceArray) => sourceArray,
          }
        );
      } else {
        vehicleOrdersAdapter.setAll(state, items);

        state.sessionConfigs = merge(
          state.sessionConfigs,
          {
            [VehicleOrderSessionViewType.VEHICLE_ORDER_LIST]: {
              [DEFAULT_SESSION_CONFIG_KEY]: {
                pageTokens: {
                  currentToken: token,
                  previousToken,
                  nextToken,
                  firstPageItemIndex,
                  totalItems,
                },
                operationStatus: {
                  status: BDRequestStatus.SUCCEEDED,
                },
              },
            },
          },
          {
            arrayMerge: (_, sourceArray) => sourceArray,
          }
        );
      }
    }
  );
  builder.addCase(VEHICLE_ORDERS_ACTIONS.getVehicleOrders.rejected, (state) => {
    state.sessionConfigs = merge(
      state.sessionConfigs,
      {
        [VehicleOrderSessionViewType.VEHICLE_ORDER_LIST]: {
          [DEFAULT_SESSION_CONFIG_KEY]: {
            operationStatus: {
              status: BDRequestStatus.FAILED,
            },
          },
        },
      },
      {
        arrayMerge: (_, sourceArray) => sourceArray,
      }
    );
  });
};

const buildGetVehicleOrderDetailsReducer = (
  builder: ActionReducerMapBuilder<
    EntityState<VehicleOrderDetailsApiResponse> & VehicleOrdersState
  >
) => {
  builder.addCase(
    VEHICLE_ORDERS_ACTIONS.getVehicleOrderDetails.pending,
    (state, action) => {
      const { code } = action.meta.arg;
      state.sessionConfigs = merge(
        state.sessionConfigs,
        {
          [VehicleOrderSessionViewType.VEHICLE_ORDER_DETAILS]: {
            [code]: {
              details: {},
              operationStatus: {
                status: BDRequestStatus.PENDING,
              },
            },
          },
        },
        {
          arrayMerge: (_, sourceArray) => sourceArray,
        }
      );
    }
  );
  builder.addCase(
    VEHICLE_ORDERS_ACTIONS.getVehicleOrderDetails.fulfilled,
    (state, action) => {
      const { code } = action.meta.arg;
      state.sessionConfigs = merge(
        state.sessionConfigs,
        {
          [VehicleOrderSessionViewType.VEHICLE_ORDER_DETAILS]: {
            [code]: {
              details: action.payload.result,
              operationStatus: {
                status: BDRequestStatus.SUCCEEDED,
              },
            },
          },
        },
        {
          arrayMerge: (_, sourceArray) => sourceArray,
        }
      );
    }
  );
  builder.addCase(
    VEHICLE_ORDERS_ACTIONS.getVehicleOrderDetails.rejected,
    (state, action) => {
      const { code } = action.meta.arg;
      state.sessionConfigs = merge(
        state.sessionConfigs,
        {
          [VehicleOrderSessionViewType.VEHICLE_ORDER_DETAILS]: {
            [code]: {
              operationStatus: {
                status: BDRequestStatus.FAILED,
                errors: [
                  {
                    type: BDAppErrorType.API,
                    ...(action.payload || (action.error as BDError)),
                  },
                ],
              },
            },
          },
        },
        {
          arrayMerge: (_, sourceArray) => sourceArray,
        }
      );
    }
  );
};

export const buildGetVehicleOrderListExportReducer = (
  builder: ActionReducerMapBuilder<
    EntityState<VehicleOrderDetailsApiResponse> & VehicleOrdersState
  >
) => {
  builder.addCase(
    VEHICLE_ORDERS_ACTIONS.getVehicleOrderListExport.pending,
    (state, action) => {
      state.sessionConfigs = merge(
        state.sessionConfigs,
        {
          [VehicleOrderSessionViewType.VEHICLE_ORDER_LIST_EXPORT]: {
            [DEFAULT_SESSION_CONFIG_KEY]: {
              operationStatus: {
                status: BDRequestStatus.PENDING,
              },
            },
          },
        },
        {
          arrayMerge: (_, sourceArray) => sourceArray,
        }
      );
    }
  );
  builder.addCase(
    VEHICLE_ORDERS_ACTIONS.getVehicleOrderListExport.fulfilled,
    (state, action) => {
      state.sessionConfigs = merge(
        state.sessionConfigs,
        {
          [VehicleOrderSessionViewType.VEHICLE_ORDER_LIST_EXPORT]: {
            [DEFAULT_SESSION_CONFIG_KEY]: {
              operationStatus: {
                status: BDRequestStatus.SUCCEEDED,
              },
            },
          },
        },
        {
          arrayMerge: (_, sourceArray) => sourceArray,
        }
      );
    }
  );
  builder.addCase(
    VEHICLE_ORDERS_ACTIONS.getVehicleOrderListExport.rejected,
    (state, action) => {
      state.sessionConfigs = merge(
        state.sessionConfigs,
        {
          [VehicleOrderSessionViewType.VEHICLE_ORDER_LIST_EXPORT]: {
            [DEFAULT_SESSION_CONFIG_KEY]: {
              operationStatus: {
                status: BDRequestStatus.FAILED,
                errors: [
                  {
                    type: BDAppErrorType.API,
                    ...(action.payload || (action.error as BDError)),
                  },
                ],
              },
            },
          },
        },
        {
          arrayMerge: (_, sourceArray) => sourceArray,
        }
      );
    }
  );
};
const buildGetVehicleOrdersSummaryReducer = (
  builder: ActionReducerMapBuilder<VehicleOrdersState>
) => {
  builder.addCase(
    VEHICLE_ORDERS_ACTIONS.getVehicleOrdersSummary.pending,
    (state, action) => {
      state.sessionConfigs = merge(
        state.sessionConfigs,
        {
          [VehicleOrderSessionViewType.VEHICLE_ORDER_DASHBOARD]: {
            [DEFAULT_SESSION_CONFIG_KEY]: {
              summary: {},
              operationStatus: {
                status: BDRequestStatus.PENDING,
              },
            },
          },
        },
        {
          arrayMerge: (_, sourceArray) => sourceArray,
        }
      );
    }
  );
  builder.addCase(
    VEHICLE_ORDERS_ACTIONS.getVehicleOrdersSummary.fulfilled,
    (state, action) => {
      state.sessionConfigs = merge(
        state.sessionConfigs,
        {
          [VehicleOrderSessionViewType.VEHICLE_ORDER_DASHBOARD]: {
            [DEFAULT_SESSION_CONFIG_KEY]: {
              summary: action.payload.result,
              operationStatus: {
                status: BDRequestStatus.SUCCEEDED,
              },
            },
          },
        },
        {
          arrayMerge: (_, sourceArray) => sourceArray,
        }
      );
    }
  );
  builder.addCase(
    VEHICLE_ORDERS_ACTIONS.getVehicleOrdersSummary.rejected,
    (state, action) => {
      state.sessionConfigs = merge(
        state.sessionConfigs,
        {
          [VehicleOrderSessionViewType.VEHICLE_ORDER_DASHBOARD]: {
            [DEFAULT_SESSION_CONFIG_KEY]: {
              summary: DEFAULT_SUMMARY,
              operationStatus: {
                status: BDRequestStatus.FAILED,
                errors: [
                  {
                    type: BDAppErrorType.API,
                    ...(action.payload || (action.error as BDError)),
                  },
                ],
              },
            },
          },
        },
        {
          arrayMerge: (_, sourceArray) => sourceArray,
        }
      );
    }
  );
};

const buildRemoveVehicleOrdersReducer = (
  builder: ActionReducerMapBuilder<
    EntityState<VehicleOrderDetailsApiResponse> & VehicleOrdersState
  >
) => {
  builder.addCase(
    VEHICLE_ORDERS_ACTIONS.removeVehicleOrders.pending,
    (state) => {
      state.sessionConfigs = merge(
        state.sessionConfigs,
        {
          [VehicleOrderSessionViewType.VEHICLE_ORDER_REMOVE]: {
            [DEFAULT_SESSION_CONFIG_KEY]: {
              operationStatus: {
                status: BDRequestStatus.PENDING,
              },
            },
          },
        },
        {
          arrayMerge: (_, sourceArray) => sourceArray,
        }
      );
    }
  );
  builder.addCase(
    VEHICLE_ORDERS_ACTIONS.removeVehicleOrders.fulfilled,
    (state, action) => {
      set(
        state,
        `sessionConfigs.${VehicleOrderSessionViewType.VEHICLE_ORDER_LIST}.${DEFAULT_SESSION_CONFIG_KEY}.selectedVehicleOrderCodes`,
        {}
      );

      state.sessionConfigs = merge(
        state.sessionConfigs,
        {
          [VehicleOrderSessionViewType.VEHICLE_ORDER_REMOVE]: {
            [DEFAULT_SESSION_CONFIG_KEY]: {
              orders: action.payload.result,
              operationStatus: {
                status: BDRequestStatus.SUCCEEDED,
              },
            },
          },
        },
        {
          arrayMerge: (_, sourceArray) => sourceArray,
        }
      );
    }
  );
  builder.addCase(
    VEHICLE_ORDERS_ACTIONS.removeVehicleOrders.rejected,
    (state, action) => {
      state.sessionConfigs = merge(
        state.sessionConfigs,
        {
          [VehicleOrderSessionViewType.VEHICLE_ORDER_REMOVE]: {
            [DEFAULT_SESSION_CONFIG_KEY]: {
              operationStatus: {
                status: BDRequestStatus.FAILED,
                errors: [
                  {
                    type: BDAppErrorType.API,
                    ...(action.payload || (action.error as BDError)),
                  },
                ],
              },
            },
          },
        },
        {
          arrayMerge: (_, sourceArray) => sourceArray,
        }
      );
    }
  );
};
const buildRecentVehicleOrdersReducer = (
  builder: ActionReducerMapBuilder<
    EntityState<VehicleOrderDetailsApiResponse> & VehicleOrdersState
  >
) => {
  builder.addCase(
    VEHICLE_ORDERS_ACTIONS.getVehicleRecentOrder.pending,
    (state) => {
      state.sessionConfigs = merge(
        state.sessionConfigs,
        {
          [VehicleOrderSessionViewType.VEHICLE_RECENT_ORDER_DASHBOARD]: {
            [DEFAULT_SESSION_CONFIG_KEY]: {
              recentOrders: [],
              operationStatus: {
                status: BDRequestStatus.PENDING,
              },
            },
          },
        },
        {
          arrayMerge: (_, sourceArray) => sourceArray,
        }
      );
    }
  );
  builder.addCase(
    VEHICLE_ORDERS_ACTIONS.getVehicleRecentOrder.fulfilled,
    (state, action) => {
      const {
        items,
        token,
        previousToken,
        nextToken,
        firstPageItemIndex,
        totalItems,
      } = action?.payload?.result ?? {};

      state.sessionConfigs = merge(
        state.sessionConfigs,
        {
          [VehicleOrderSessionViewType.VEHICLE_RECENT_ORDER_DASHBOARD]: {
            [DEFAULT_SESSION_CONFIG_KEY]: {
              recentOrders: items,
              operationStatus: {
                status: BDRequestStatus.SUCCEEDED,
              },
              pageTokens: {
                currentToken: token,
                previousToken,
                nextToken,
                firstPageItemIndex,
                totalItems,
              },
            },
          },
        },
        {
          arrayMerge: (_, sourceArray) => sourceArray,
        }
      );
    }
  );
  builder.addCase(
    VEHICLE_ORDERS_ACTIONS.getVehicleRecentOrder.rejected,
    (state) => {
      state.sessionConfigs = merge(
        state.sessionConfigs,
        {
          [VehicleOrderSessionViewType.VEHICLE_RECENT_ORDER_DASHBOARD]: {
            [DEFAULT_SESSION_CONFIG_KEY]: {
              operationStatus: {
                status: BDRequestStatus.FAILED,
              },
            },
          },
        },
        {
          arrayMerge: (_, sourceArray) => sourceArray,
        }
      );
    }
  );
};
export const VEHICLE_ORDERS_REDUCER_BUILDERS = {
  buildAddVehicleOrdersReducer,
  buildGetVehicleOrdersReducer,
  buildGetVehicleOrderDetailsReducer,
  buildGetVehicleOrderListExportReducer,
  buildGetVehicleOrdersSummaryReducer,
  buildRecentVehicleOrdersReducer,
  buildRemoveVehicleOrdersReducer,
};

export const VEHICLE_ORDERS_REDUCERS = {
  reducerSetVehicleOrdersSessionConfig,
  reduceResetVehicleOrdersSessionConfig,
  reducerSetSelectedVehicleOrdersSelected,
  reducerClearAllSelectedOrders,
};
