import { SvgIcon } from '@mui/material';
import { MUIDataTableMeta } from 'mui-datatables';

import { iconRenderer } from '~/common/components/table/renderers/columnRenderer';
import {
  DEFAULT_EMPTY_VALUE,
  UnitsType,
} from '~/common/constants/common.constant';
import {
  AssetReportItem,
  ReportDiagnosticState,
  TiresStatus,
} from '~/common/models/asset-report.model';
import { TableColumnConfig } from '~/common/models/table/table.model';

import TireClearIcon from '../../../../assets/icons/utils/tireClearIcon.svg?react';
import TireCriticalIcon from '../../../../assets/icons/utils/tireCriticalIcon.svg?react';
import TireImpairedIcon from '../../../../assets/icons/utils/tireImpairedIcon.svg?react';
import TireUnknownIcon from '../../../../assets/icons/utils/tireUnknownIcon.svg?react';
import { ASSET_LIST_REPORT_MESSAGES } from '../AssetListReportView.messages';
import {
  ASSET_REPORT_ADAPTERS,
  getCommonReportColumns,
} from './commonReportTable.config';

export const getTireReportColumns = (
  columnLabels: typeof ASSET_LIST_REPORT_MESSAGES.columnLabels,
  valueLabels: typeof ASSET_LIST_REPORT_MESSAGES.valueLabels,
  units: UnitsType,
  isParamAvailable: boolean,
  locale?: string
) => [
  ...getCommonReportColumns(
    columnLabels,
    valueLabels,
    isParamAvailable,
    locale
  ),
  FRONT_LEFT_COLUMN(columnLabels, valueLabels, units),
  FRONT_RIGHT_COLUMN(columnLabels, valueLabels, units),
  REAR_LEFT_COLUMN(columnLabels, valueLabels, units),
  REAR_RIGHT_COLUMN(columnLabels, valueLabels, units),
];
export const FRONT_LEFT_COLUMN = (
  columnLabels: typeof ASSET_LIST_REPORT_MESSAGES.columnLabels,
  valueLabels: typeof ASSET_LIST_REPORT_MESSAGES.valueLabels,
  units: UnitsType
): TableColumnConfig<AssetReportItem> => ({
  name: 'leftFrontTire',
  label: columnLabels['common:tires.frontLeft'],
  options: {
    sort: false,
    customBodyRender: (value, tableMeta) => {
      return tireValueRenderer(valueLabels, units)(value, tableMeta);
    },
  },
});

export const FRONT_RIGHT_COLUMN = (
  columnLabels: typeof ASSET_LIST_REPORT_MESSAGES.columnLabels,
  valueLabels: typeof ASSET_LIST_REPORT_MESSAGES.valueLabels,
  units: UnitsType
): TableColumnConfig<AssetReportItem> => ({
  name: 'rightFrontTire',
  label: columnLabels['common:tires.frontRight'],
  options: {
    sort: false,
    customBodyRender: (value, tableMeta) => {
      return tireValueRenderer(valueLabels, units)(value, tableMeta);
    },
  },
});

export const REAR_LEFT_COLUMN = (
  columnLabels: typeof ASSET_LIST_REPORT_MESSAGES.columnLabels,
  valueLabels: typeof ASSET_LIST_REPORT_MESSAGES.valueLabels,
  units: UnitsType
): TableColumnConfig<AssetReportItem> => ({
  name: 'leftRearTire',
  label: columnLabels['common:tires.rearLeft'],
  options: {
    sort: false,
    customBodyRender: (value, tableMeta) => {
      return tireValueRenderer(valueLabels, units)(value, tableMeta);
    },
  },
});

export const REAR_RIGHT_COLUMN = (
  columnLabels: typeof ASSET_LIST_REPORT_MESSAGES.columnLabels,
  valueLabels: typeof ASSET_LIST_REPORT_MESSAGES.valueLabels,
  units: UnitsType
): TableColumnConfig<AssetReportItem> => ({
  name: 'rightRearTire',
  label: columnLabels['common:tires.rearRight'],
  options: {
    sort: false,
    customBodyRender: (value, tableMeta) => {
      return tireValueRenderer(valueLabels, units)(value, tableMeta);
    },
  },
});

export const tireValueRenderer = (
  valueLabels: typeof ASSET_LIST_REPORT_MESSAGES.valueLabels,
  units: UnitsType
) => {
  const adaptTirePressure = ASSET_REPORT_ADAPTERS.tirePressure(units);
  const ValueRenderer = (
    value: TiresStatus,
    tableMeta: MUIDataTableMeta
  ): JSX.Element => {
    const icon = tireIconRenderer(
      valueLabels,
      (value: TiresStatus) => value?.state || ReportDiagnosticState.NO_DATA
    )(value, tableMeta);
    return (
      <div className="valueStatus individualTire">
        {value?.pressureInPSI ? (
          <>
            {icon}
            <div>{adaptTirePressure(value?.pressureInPSI)}</div>
          </>
        ) : (
          DEFAULT_EMPTY_VALUE
        )}
      </div>
    );
  };
  return ValueRenderer;
};

export const tireIconRenderer = <ValueType, AdaptedType>(
  valueLabels: typeof ASSET_LIST_REPORT_MESSAGES.valueLabels,
  valueAdapter: (value: ValueType) => string | AdaptedType
) =>
  iconRenderer({
    valueAdapter,
    iconConfig: {
      [ReportDiagnosticState.GOOD]: (
        <SvgIcon
          component={() => (
            <TireClearIcon
              className="iconGoodStatus"
              title={
                valueLabels[
                  `asset:filterModalLabels.fluidStatus.${ReportDiagnosticState.GOOD}`
                ]
              }
            />
          )}
        />
      ),
      [ReportDiagnosticState.IMPAIRED]: (
        <SvgIcon
          component={() => (
            <TireImpairedIcon
              title={
                valueLabels[
                  `asset:filterModalLabels.tireStatus.${ReportDiagnosticState.IMPAIRED}`
                ]
              }
            />
          )}
        />
      ),
      [ReportDiagnosticState.CRITICAL]: (
        <SvgIcon
          component={() => (
            <TireCriticalIcon
              title={
                valueLabels[
                  `asset:filterModalLabels.tireStatus.${ReportDiagnosticState.CRITICAL}`
                ]
              }
            />
          )}
        />
      ),
      [ReportDiagnosticState.NO_DATA]: (
        <TireUnknownIcon
          className="iconUnknownStatus"
          title={valueLabels['common:unknown']}
        />
      ),
    },
  });
