import { PropsWithChildren } from 'react';

import ProfileContext, { ProfileContextProps } from './ProfileContext';

const ProfileContextProvider = ({
  children,
  profile,
  actingProfile,
}: PropsWithChildren<ProfileContextProps>) => {
  return (
    <ProfileContext.Provider
      value={{
        profile,
        actingProfile,
      }}
    >
      {children}
    </ProfileContext.Provider>
  );
};

export default ProfileContextProvider;
