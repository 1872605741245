import { ApiToAssetTypeMap, AssetType } from '~/common/models/asset.model';
import {
  ApiDashboardSummary,
  DashboardSummary,
  DashboardSummaryDetail,
  SummaryType,
} from '~/common/models/dashboard.model';

const DEFAULT_INITIAL_SUMMARY = {
  total: 0,
  numberOfReady: 0,
  numberOfNotReady: 0,
  availability: {
    inService: 0,
    outOfService: 0,
  },
  batterySummary: {
    critical: 0,
    impaired: 0,
    info0: 0,
    info1: 0,
    noData: 0,
  },
  chargeSummary: {
    charging: 0,
    noData: 0,
  },
  diagnosticsSummary: {
    lowTire: 0,
    flatTire: 0,
    lowBrakeFluid: 0,
    lowWasherFluid: 0,
    criticalFault: 0,
    impairedFault: 0,
  },
  connectivitySummary: {
    online: 0,
    offline: 0,
    noData: 0,
  },
};
export const mapDashboardSummaryApiResponse = (
  apiDashboardSummary: ApiDashboardSummary
): DashboardSummary => {
  const { summaries, settings } = apiDashboardSummary;

  const summariesMap =
    summaries?.reduce((dashboardSummaries, apiSummary) => {
      const summaryType = ApiToAssetTypeMap[apiSummary.type];
      return {
        ...dashboardSummaries,
        [summaryType]: {
          total: apiSummary.total || 0,
          numberOfReady: apiSummary.ready || 0,
          numberOfNotReady: apiSummary.not_ready || 0,
          availability: {
            inService: apiSummary.availability?.in_service || 0,
            outOfService: apiSummary.availability?.out_of_service || 0,
          },
          batterySummary: {
            critical: apiSummary.battery?.critical || 0,
            impaired: apiSummary.battery?.impaired || 0,
            info0: apiSummary.battery?.info0 || 0,
            info1: apiSummary.battery?.info1 || 0,
            noData: apiSummary.battery?.no_data || 0,
          },
          chargeSummary: {
            charging: apiSummary.charge?.charging || 0,
            noData: apiSummary.charge?.no_data || 0,
          },
          diagnosticsSummary: {
            lowTire: apiSummary.diagnostics?.tire_low || 0,
            flatTire: apiSummary.diagnostics?.tire_flat || 0,
            lowBrakeFluid: apiSummary.diagnostics?.fluid_low_brake || 0,
            lowWasherFluid: apiSummary.diagnostics?.fluid_low_washer || 0,
            criticalFault: apiSummary.diagnostics?.mil_critical || 0,
            impairedFault: apiSummary.diagnostics?.mil_impaired || 0,
          },
          connectivitySummary: {
            online: apiSummary.connectivity?.online || 0,
            offline: apiSummary.connectivity?.offline || 0,
            noData: apiSummary.connectivity?.no_data || 0,
          },
        },
      };
    }, {} as SummaryType) || ({} as SummaryType);

  const mapped: DashboardSummary = {
    summaries: summariesMap,
    settings: settings?.battery?.thresholds
      ? {
          batterySettings: {
            thresholds: {
              critical: settings.battery.thresholds.critical,
              impaired: settings.battery.thresholds.impaired,
              info0: settings.battery.thresholds.info0,
              info1: settings.battery.thresholds.info1,
            },
          },
        }
      : undefined,
  };

  return mapped;
};

export const getAssetTypesSummary = (
  assetTypes: AssetType[],
  dashboardSummaryConfig?: SummaryType
) =>
  assetTypes.reduce((summary, assetType) => {
    const assetTypeSummary: DashboardSummaryDetail =
      dashboardSummaryConfig?.[assetType] || DEFAULT_INITIAL_SUMMARY;
    return {
      total: summary.total + assetTypeSummary?.total,
      numberOfReady: summary.numberOfReady + assetTypeSummary?.numberOfReady,
      numberOfNotReady:
        summary.numberOfNotReady + assetTypeSummary?.numberOfNotReady,
      availability: {
        inService:
          summary.availability.inService +
          assetTypeSummary?.availability?.inService,
        outOfService:
          summary.availability.outOfService +
          assetTypeSummary?.availability?.outOfService,
      },
      batterySummary: {
        critical:
          summary.batterySummary.critical +
          assetTypeSummary.batterySummary?.critical,
        impaired:
          summary.batterySummary.impaired +
          assetTypeSummary.batterySummary?.impaired,
        info0:
          summary.batterySummary.info0 + assetTypeSummary.batterySummary?.info0,
        info1:
          summary.batterySummary.info1 + assetTypeSummary.batterySummary?.info1,
        noData:
          summary.batterySummary.noData +
          assetTypeSummary.batterySummary?.noData,
      },
      chargeSummary: {
        charging:
          summary.chargeSummary.charging +
          assetTypeSummary.chargeSummary?.charging,
        noData:
          summary.chargeSummary.noData + assetTypeSummary.chargeSummary?.noData,
      },
      diagnosticsSummary: {
        lowTire:
          summary.diagnosticsSummary.lowTire +
          assetTypeSummary.diagnosticsSummary?.lowTire,
        flatTire:
          summary.diagnosticsSummary.flatTire +
          assetTypeSummary.diagnosticsSummary?.flatTire,
        lowBrakeFluid:
          summary.diagnosticsSummary.lowBrakeFluid +
          assetTypeSummary.diagnosticsSummary?.lowBrakeFluid,
        lowWasherFluid:
          summary.diagnosticsSummary.lowWasherFluid +
          assetTypeSummary.diagnosticsSummary?.lowWasherFluid,
        criticalFault:
          summary.diagnosticsSummary.criticalFault +
          assetTypeSummary.diagnosticsSummary?.criticalFault,
        impairedFault:
          summary.diagnosticsSummary.impairedFault +
          assetTypeSummary.diagnosticsSummary?.impairedFault,
      },
      connectivitySummary: {
        online:
          summary.connectivitySummary.online +
          assetTypeSummary.connectivitySummary?.online,
        offline:
          summary.connectivitySummary.offline +
          assetTypeSummary.connectivitySummary?.offline,
        noData:
          summary.connectivitySummary.noData +
          assetTypeSummary.connectivitySummary?.noData,
      },
    };
  }, DEFAULT_INITIAL_SUMMARY);
