import { PropsWithChildren, useMemo } from 'react';

import useFeatureFlags from '../hooks/useFeatureFlags';
import { FeatureFlagValueType } from '../models/featureFlags.model';

interface FeatureWrapperProps {
  featureName: string;
  predicate?: (value?: FeatureFlagValueType) => boolean;
}

export const createStrictEqualityPredicate =
  (expectedValue: FeatureFlagValueType) => (value?: FeatureFlagValueType) =>
    value === expectedValue;

const FeatureWrapper = ({
  featureName,
  predicate = createStrictEqualityPredicate(true),
  children,
}: PropsWithChildren<FeatureWrapperProps>): JSX.Element => {
  const { getFlag } = useFeatureFlags();
  const flagValue = getFlag(featureName);
  const shouldDisplay = useMemo(
    () => predicate(flagValue),
    [flagValue, predicate]
  );

  return <>{shouldDisplay ? children : null}</>;
};

export default FeatureWrapper;
