import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { selectEffectiveContext } from '../../features/profile/profileSlice.selectors';

type CurrentContext = {
  organizationsId?: string;
  hubsId?: string;
  fleetsId?: string;
};

const useCurrentContext = (): CurrentContext | undefined => {
  const effectiveContext = useSelector(selectEffectiveContext);

  const currentContext: CurrentContext | undefined = useMemo(() => {
    const { id, parentIds } = effectiveContext || {};

    if (!id) return undefined;

    const newContext: typeof currentContext = {
      organizationsId: parentIds?.[0] ?? id,
      hubsId: effectiveContext?.parentIds?.length === 1 ? id : parentIds?.[1],
      fleetsId: parentIds?.length === 2 ? id : undefined,
    };

    return newContext;
  }, [effectiveContext]);

  return currentContext;
};

export default useCurrentContext;
