import AppleAppDownload from '../../../../assets/images/alertMessage/appleAppDownload.svg?react';
import GoogleAppDownload from '../../../../assets/images/alertMessage/googleAppDownload.svg?react';

export type getMobileAppUrlProps = {
  id: string;
  title: string;
  url: string;
  img: React.ReactNode;
};

export const getMobileAppUrlConfig = (): getMobileAppUrlProps[] => {
  return [
    {
      id: 'google-download-app',
      title: 'google',
      //Ignoring go-brightdrop reference for now, until operator and driver roles are supported
      url: 'https://play.google.com/store/apps/details?id=com.gobrightdrop.saas.mobile',
      img: <GoogleAppDownload height="50px" />,
    },
    {
      id: 'apple-download-app',
      title: 'apple',
      url: 'https://apps.apple.com/us/app/brightdrop/id1586167828',
      img: <AppleAppDownload height="50px" />,
    },
  ];
};
