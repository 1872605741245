import { PropsWithChildren, ReactNode, useEffect, useState } from 'react';

interface DelayedContentProps<T> {
  isLoading?: boolean;
  isFailed?: boolean;
  data?: T;
  skeleton?: ReactNode;
  error?: ReactNode;
}
const DelayedContent = <T,>({
  children,
  isLoading,
  isFailed,
  data,
  skeleton,
  error,
}: PropsWithChildren<DelayedContentProps<T>>): JSX.Element => {
  const [displayData, setDisplayData] = useState<T>();
  const [isProcessing, setIsProcessing] = useState(true);

  useEffect(() => {
    if (!isLoading) {
      setDisplayData(data);
    }
  }, [data, isLoading]);

  useEffect(() => {
    if (!isLoading && (displayData !== undefined || isFailed)) {
      setIsProcessing(false);
    }
  }, [displayData, isLoading, isFailed]);

  useEffect(() => {
    if (isLoading) {
      setIsProcessing(true);
    }
  }, [isLoading]);

  return isProcessing && skeleton ? (
    <>{skeleton}</>
  ) : isFailed ? (
    <>{error}</>
  ) : (
    <>{children}</>
  );
};

export default DelayedContent;
