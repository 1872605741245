import { createSlice } from '@reduxjs/toolkit';

import { RootState } from '../../app/rootReducer';
import { INSPECTIONS_ACTIONS } from './state/inspectionsSlice.actions';
import { inspectionsAdapter } from './state/inspectionsSlice.adapters';
import { INITIAL_INSPECTIONS_STATE } from './state/inspectionsSlice.constants';
import {
  INSPECTIONS_EXTRA_REDUCER_BUILDERS,
  INSPECTIONS_REDUCERS,
} from './state/inspectionsSlice.reducers';

const {
  reduceSetInspectionsSessionConfig,
  reduceClearInspectionsFilterSession,
} = INSPECTIONS_REDUCERS;

export const inspectionsSlice = createSlice({
  name: 'inspections',
  initialState: INITIAL_INSPECTIONS_STATE,
  reducers: {
    setInspectionsSessionConfig: reduceSetInspectionsSessionConfig,
    clearInspectionsFilterSession: reduceClearInspectionsFilterSession,
  },
  extraReducers: (builder) => {
    INSPECTIONS_EXTRA_REDUCER_BUILDERS.buildGetInspectionsSummaryReducer(
      builder
    );
    INSPECTIONS_EXTRA_REDUCER_BUILDERS.buildGetInspectionDetailsReducer(
      builder
    );
    INSPECTIONS_EXTRA_REDUCER_BUILDERS.buildGetInspectionListReducer(builder);
    INSPECTIONS_EXTRA_REDUCER_BUILDERS.buildGetInspectionLongTermReportReducer(
      builder
    );
  },
});
export const {
  getInspectionSummary,
  getInspectionDetails,
  getInspectionList,
  getInspectionLongTermReport,
} = INSPECTIONS_ACTIONS;
export const { setInspectionsSessionConfig, clearInspectionsFilterSession } =
  inspectionsSlice.actions;

export const {
  selectAll: selectAllInspections,
  selectById: selectInspectionsById,
  selectIds: selectInspectionsIds,
} = inspectionsAdapter.getSelectors<RootState>(
  (state: RootState) => state.inspections
);

export const inspectionsReducer = inspectionsSlice.reducer;
