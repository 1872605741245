import {
  ActionReducerMapBuilder,
  CaseReducer,
  EntityState,
  PayloadAction,
} from '@reduxjs/toolkit';
import merge from 'deepmerge';

import {
  SearchConfigType,
  SessionConfigType,
} from '~/common/constants/common.constant';
import {
  BDRequestStatus,
  BDRequestType,
} from '~/common/models/apis/apiResponse.model';
import {
  Asset,
  AssetReportType,
  AssetType,
  LockStatus,
} from '~/common/models/asset.model';
import { AssetReportItem } from '~/common/models/asset-report.model';
import { SeverityLevel } from '~/common/models/common.model';
import { BDAppErrorType, BDError } from '~/common/models/error.model';
import { removeEmpty } from '~/common/utils/common.utils';
import { endDateTime, startDateTime } from '~/common/utils/date-time.utils';
import { NotificationSettingsConfigType } from '~/features/settings/settings.constant';

import {
  ASSET_REPORT_FILTER_EMPTY_STATE,
  AssetListFilterKey,
  AssetReportConfigType,
  AssetRequestType,
  AssetSessionConfigType,
  AssetsState,
  selectSelectedAssetsIds,
} from '../assetsSlice';
import { AssetListParams } from '../form/utils/assetForm.utils';
import { isPubSubEventMessage, mergeAssetStates } from '../utils/assets.utils';
import { ASSETS_ACTIONS } from './assetsSlice.actions';
import {
  assetComplianceAdapter,
  assetEventsAdapter,
  assetLocationsAdapter,
  assetReportItemsAdapter,
  assetsAdapter,
  assetSearchAdapter,
  assetVehicleNotificationAdapter,
  //assetVehicleNotificationAdapter,
} from './assetsSlice.adapters';
import { INITIAL_ASSETS_STATE } from './assetsSlice.constants';

const reduceSetAssetSessionConfig: CaseReducer<
  typeof INITIAL_ASSETS_STATE,
  PayloadAction<AssetSessionConfigType>
> = (state, action) => {
  const mergeSelectedIndices = (_existing: string[], incoming: string[]) =>
    incoming;
  const mergeExpandedRows = (_existing: number[], incoming: number[]) =>
    incoming;

  Object.keys(action.payload).forEach((key) => {
    const viewType = key as
      | SessionConfigType
      | AssetReportConfigType
      | SearchConfigType;
    const payload = action.payload[viewType] || {};
    Object.keys(payload).forEach((id) => {
      state.sessionConfigs = merge(
        state.sessionConfigs,
        {
          [viewType]: {
            [id]: payload[id] && {
              ...payload[id],
            },
          },
        },
        {
          customMerge: (key) => {
            if (key === 'selectedIndices') return mergeSelectedIndices;
            if (key === 'expandedRows') return mergeExpandedRows;
            if (
              key === AssetListFilterKey.ASSET_TYPE ||
              key === AssetListFilterKey.READY_STATUS ||
              key === AssetListFilterKey.CONNECTIVITY_STATUS ||
              key === AssetListFilterKey.BRAKE_FLUID_STATUS ||
              key === AssetListFilterKey.WASHER_FLUID_STATUS ||
              key === AssetListFilterKey.TIRE_STATUS ||
              key === AssetListFilterKey.BATTERY_SOC_STATUS ||
              key === AssetListFilterKey.BATTERY_CHARGING_STATUS ||
              key === AssetListFilterKey.SAFETY_COMPLIANCE_STATUS ||
              key === AssetListFilterKey.SELECTED_ASSET_TYPES ||
              key === AssetListFilterKey.FAULT_STATUS ||
              key === AssetListFilterKey.DIAGNOSTICS_HEALTH_STATUS ||
              key === AssetListFilterKey.AVAILABILITY_STATUS ||
              key === AssetListFilterKey.POWER_TYPE ||
              key === AssetListFilterKey.FUEL_STATUS ||
              key === AssetListFilterKey.OIL_LIFE_STATUS ||
              key === AssetListFilterKey.BRAKE_PAD_FRONT_STATUS ||
              key === AssetListFilterKey.BRAKE_PAD_REAR_STATUS ||
              key === AssetListFilterKey.ENGINE_AIR_FILTER_STATUS ||
              key === AssetListFilterKey.CONNECTIVITY_BOARDING_STATUS ||
              key === AssetListFilterKey.LAST_COMMUNICATED_STATUS ||
              key === AssetListFilterKey.DIESEL_EXHAUST_FLUID ||
              key === AssetListFilterKey.RECALL_STATUS
            )
              return mergeSelectedIndices;
          },
        }
      );
    });
  });
};

const reduceClearAssetListReportFilterSessionConfig: CaseReducer<
  typeof INITIAL_ASSETS_STATE,
  PayloadAction<{ viewType: SessionConfigType; id: string }>
> = (
  state,
  action: PayloadAction<{ viewType: SessionConfigType; id: string }>
) => {
  state.sessionConfigs = merge(
    state.sessionConfigs,
    {
      [action.payload.viewType]: {
        [action.payload.id]: {
          filterType: ASSET_REPORT_FILTER_EMPTY_STATE,
        },
      },
    },
    {
      arrayMerge: (_, sourceArray) => sourceArray,
    }
  );
};

const reduceResetAssetListReportSessionConfig: CaseReducer<
  typeof INITIAL_ASSETS_STATE,
  PayloadAction<{
    viewType: SessionConfigType;
    sessionId: string;
    activeReport: AssetReportType;
  }>
> = (
  state,
  action: PayloadAction<{
    viewType: SessionConfigType;
    sessionId: string;
    activeReport: AssetReportType;
  }>
) => {
  state.sessionConfigs = merge(
    state.sessionConfigs,
    {
      [action.payload.viewType]: {
        [action.payload.sessionId]: {
          activeReport: action.payload.activeReport,
          operationStatus: undefined,
          filterType: undefined,
          searchCriteria: undefined,
        },
      },
    },
    {
      arrayMerge: (_, sourceArray) => sourceArray,
    }
  );
};

const reduceResetEventDate: CaseReducer<typeof INITIAL_ASSETS_STATE> = (
  state
) => {
  state.startDate = startDateTime().toString();
  state.endDate = endDateTime().toString();
};

const reduceSetPendingAssetOperation: CaseReducer<
  typeof INITIAL_ASSETS_STATE,
  PayloadAction<LockStatus | undefined>
> = (state, action: PayloadAction<LockStatus | undefined>) => {
  state.pendingAssetOperation = action.payload;
};

const reduceSetAssetLocationAddress: CaseReducer<
  typeof INITIAL_ASSETS_STATE,
  PayloadAction<{ assetId: string; displayAddress: string }>
> = (
  state,
  action: PayloadAction<{ assetId: string; displayAddress: string }>
) => {
  const asset = state.entities[action.payload.assetId];
  if (asset && action.payload.displayAddress) {
    assetsAdapter.updateOne(state, {
      id: action.payload.assetId,
      changes: {
        ...asset,
        status: asset.status
          ? {
              ...asset.status,
              location: asset.status.location
                ? {
                    ...(asset.status?.location || {}),
                    displayAddress: action.payload.displayAddress,
                  }
                : undefined,
            }
          : undefined,
      },
    });
  }
};

const reduceClearOperations: CaseReducer<typeof INITIAL_ASSETS_STATE> = (
  state
) => {
  state.operations = {};
};

const reduceClearAssetEvents: CaseReducer<typeof INITIAL_ASSETS_STATE> = (
  state
) => {
  assetEventsAdapter.removeAll(state.assetEvents);
};

const reduceClearAssetLocations: CaseReducer<
  typeof INITIAL_ASSETS_STATE,
  PayloadAction<string>
> = (state, action: PayloadAction<string>) => {
  assetLocationsAdapter.removeAll(state.assetLocations);
  assetsAdapter.updateOne(state, {
    id: action?.payload,
    changes: {
      ...state.entities[action?.payload],
      locations: [],
    },
  });
};

const reduceRecallContinuationCache: CaseReducer<
  typeof INITIAL_ASSETS_STATE,
  PayloadAction<{
    viewType: SessionConfigType;
    sessionId: string;
  }>
> = (
  state,
  action: PayloadAction<{
    viewType: SessionConfigType;
    sessionId: string;
  }>
) => {
  state.sessionConfigs = merge(
    state.sessionConfigs,
    {
      [action.payload.viewType]: {
        [action.payload.sessionId]: {
          continuationToken: null,
          continuationCache: undefined,
        },
      },
    },
    {
      arrayMerge: (_, sourceArray) => sourceArray,
    }
  );
};
const reduceSetSelectedMarkerId: CaseReducer<
  typeof INITIAL_ASSETS_STATE,
  PayloadAction<string>
> = (state, action: PayloadAction<string>) => {
  state.selectedMarkerId = action.payload;
};

const reduceHandleAssetSubscriptionEvent: CaseReducer<
  typeof INITIAL_ASSETS_STATE,
  PayloadAction<string>
> = (state, action: PayloadAction<string>) => {
  try {
    const parsed = JSON.parse(action.payload);
    if (parsed.data && isPubSubEventMessage(parsed.data)) {
      const assetId = parsed.data.id;
      const existing = state.entities[assetId];
      if (existing) {
        const mergedAssetState = mergeAssetStates(existing, parsed.data);
        assetsAdapter.upsertOne(state, mergedAssetState);
      }
    }
  } catch (e) {
    console.error(
      'BrightDrop Web - Failed to map asset subscription event data: ',
      e
    );
  }
};

const reduceSetSelectedAssets: CaseReducer<
  typeof INITIAL_ASSETS_STATE,
  PayloadAction<{
    sessionId: string;
    addItems?: AssetReportItem[];
    removeItems?: AssetReportItem[] | string[];
    replace?: boolean;
  }>
> = (
  state,
  action: PayloadAction<{
    sessionId: string;
    addItems?: AssetReportItem[];
    removeItems?: AssetReportItem[] | string[];
    replace?: boolean;
  }>
) => {
  const { sessionId, addItems, removeItems, replace } = action.payload;
  const selectedAssetsState =
    state.sessionConfigs[SessionConfigType.LIST_VIEW]?.[sessionId]
      ?.selectedAssets;
  if (selectedAssetsState) {
    if (!addItems && !removeItems) {
      assetReportItemsAdapter.removeAll(selectedAssetsState);
      return;
    }
    if (replace) {
      assetReportItemsAdapter.setAll(selectedAssetsState, addItems || []);
    } else {
      if (addItems) {
        assetReportItemsAdapter.upsertMany(selectedAssetsState, addItems || []);
      }
      if (removeItems) {
        assetReportItemsAdapter.removeMany(
          selectedAssetsState,
          removeItems?.map((item) =>
            typeof item === 'string' ? item : item.id
          ) || []
        );
      }
    }
  }
};

const buildGetAssetsActionReducers = (
  builder: ActionReducerMapBuilder<EntityState<Asset> & AssetsState>
) => {
  builder.addCase(ASSETS_ACTIONS.getAssets.pending, (state, action) => {
    state.operations[BDRequestType.GET_ALL] = {
      status: BDRequestStatus.PENDING,
      errors: [],
    };

    const requestContexts = removeEmpty(action.meta.arg);
    if (
      Object.keys(requestContexts).length !==
        Object.keys(state.currentEntityContexts || {}).length ||
      Object.keys(requestContexts).some((key) => {
        const contextName = key as keyof AssetListParams;
        const existingContext = state.currentEntityContexts?.[contextName];
        const newContext = requestContexts[contextName];
        return (
          (!!newContext || !!existingContext) && newContext !== existingContext
        );
      })
    ) {
      assetsAdapter.removeMany(state, state.ids); // ensure no records when fetching
    }
    state.currentEntityContexts = requestContexts;

    // reset selected indices for all sessions as asset list may change
    const listConfigs = state.sessionConfigs[SessionConfigType.LIST_VIEW];
    if (listConfigs) {
      Object.keys(listConfigs).forEach((sessionId) => {
        const listSessionConfig = listConfigs[sessionId] || {};
        for (const assetType in AssetType) {
          const assetTypeConfig = listSessionConfig[assetType as AssetType];
          if (assetTypeConfig) {
            listSessionConfig[assetType as AssetType] = {
              ...assetTypeConfig,
              selectedIndices: [],
            };
          }
        }
        listConfigs[sessionId] = {
          ...listConfigs[sessionId],
          ...listSessionConfig,
        };
      });
      state.sessionConfigs[SessionConfigType.LIST_VIEW] = listConfigs;
    }
  });
  builder.addCase(ASSETS_ACTIONS.getAssets.fulfilled, (state, action) => {
    const { errors, ...apiResponse } = action.payload;
    const assetsToRemove = state.ids.filter((id) =>
      apiResponse.result.every((asset) => asset.id !== id)
    );
    if (assetsToRemove.length) {
      assetsAdapter.removeMany(state, assetsToRemove);
    }
    assetsAdapter.upsertMany(state, apiResponse.result);
    const listOfErrors = errors
      ? errors.map(
          (error) =>
            new BDError(`upload failure: ${error.message}`, {
              code: ('errorCode' in error && error.errorCode) || '',
            })
        )
      : [];
    state.operations[BDRequestType.GET_ALL] = {
      status: BDRequestStatus.SUCCEEDED,
      errors: listOfErrors,
    };
  });
  builder.addCase(ASSETS_ACTIONS.getAssets.rejected, (state, action) => {
    state.operations[BDRequestType.GET_ALL] = {
      status: BDRequestStatus.FAILED,
      errors: [
        {
          type: BDAppErrorType.API,
          ...(action.payload || (action.error as BDError)),
          severity: SeverityLevel.ERROR,
        },
      ],
    };
  });
};

const buildGetAssetDetailsActionReducers = (
  builder: ActionReducerMapBuilder<EntityState<Asset> & AssetsState>
) => {
  builder.addCase(ASSETS_ACTIONS.getAssetDetails.pending, (state, action) => {
    state.operations[BDRequestType.GET_BY_ID] = {
      status: BDRequestStatus.PENDING,
      errors: [],
    };

    // reset events if existing state does not match asset ID
    const assetId = action.meta.arg.assetsId;
    const eventAssetId = state.assetEvents.ids.length
      ? state.assetEvents.entities[state.assetEvents.ids[0]]?.assetId
      : '';
    if (assetId && eventAssetId && assetId !== eventAssetId) {
      assetEventsAdapter.removeAll(state.assetEvents);
    }
  });
  builder.addCase(ASSETS_ACTIONS.getAssetDetails.fulfilled, (state, action) => {
    const { errors, ...apiResponse } = action.payload;
    // performs shallow merge update of existing records, _will overwrite nested object properties_
    const assetId = action.payload.result.id;
    const newAsset = apiResponse.result;
    const newLocation = action.payload.result.status?.location;
    const existingLocation = assetId
      ? state.entities[assetId]?.status?.location
      : undefined;
    if (
      newLocation &&
      existingLocation &&
      newLocation.value.latitude === existingLocation.value.latitude &&
      newLocation.value.longitude === existingLocation.value.longitude &&
      existingLocation.displayAddress
    ) {
      newLocation.displayAddress = existingLocation.displayAddress;
      newAsset.status = {
        ...newAsset.status,
        location: newLocation,
      };
    }
    assetsAdapter.upsertOne(state, newAsset);
    const listOfErrors = errors
      ? errors.map(
          (error) =>
            new BDError(`upload failure: ${error.message}`, {
              code: ('errorCode' in error && error.errorCode) || '',
            })
        )
      : [];
    state.operations[BDRequestType.GET_BY_ID] = {
      status: BDRequestStatus.SUCCEEDED,
      errors: listOfErrors,
    };
    state.sessionConfigs = merge(
      state.sessionConfigs,
      {
        [SessionConfigType.DETAIL_VIEW]: {
          [assetId]: { faults: newAsset.faultsData },
        },
      },
      {
        arrayMerge: (_, sourceArray) => sourceArray,
      }
    );
  });
  builder.addCase(ASSETS_ACTIONS.getAssetDetails.rejected, (state, action) => {
    state.operations[BDRequestType.GET_BY_ID] = {
      status: BDRequestStatus.FAILED,
      errors: [
        {
          type: BDAppErrorType.API,
          ...(action.payload || (action.error as BDError)),
        },
      ],
    };
  });
};

const buildGetAssetRecallsActionReducers = (
  builder: ActionReducerMapBuilder<EntityState<Asset> & AssetsState>
) => {
  builder.addCase(ASSETS_ACTIONS.getAssetRecalls.pending, (state, action) => {
    const { assetsId } = action.meta.arg;

    if (assetsId) {
      state.sessionConfigs = merge(
        state.sessionConfigs,
        {
          [SessionConfigType.DETAIL_VIEW]: {
            [assetsId]: {
              recalls: {
                operationStatus: {
                  status: BDRequestStatus.PENDING,
                },
              },
            },
          },
        },
        {
          arrayMerge: (_, sourceArray) => sourceArray,
        }
      );
    }
  });

  builder.addCase(ASSETS_ACTIONS.getAssetRecalls.fulfilled, (state, action) => {
    const { assetsId } = action.meta.arg;
    const recalls = action.payload.result;
    if (assetsId) {
      state.sessionConfigs = merge(
        state.sessionConfigs,
        {
          [SessionConfigType.DETAIL_VIEW]: {
            [assetsId]: {
              recalls: {
                recallItems: recalls,
                operationStatus: {
                  status: BDRequestStatus.SUCCEEDED,
                },
              },
            },
          },
        },
        {
          arrayMerge: (_, sourceArray) => sourceArray,
        }
      );
    }
  });
  builder.addCase(ASSETS_ACTIONS.getAssetRecalls.rejected, (state, action) => {
    const { assetsId } = action.meta.arg;
    if (assetsId) {
      state.sessionConfigs = merge(
        state.sessionConfigs,
        {
          [SessionConfigType.DETAIL_VIEW]: {
            [assetsId]: {
              recalls: {
                operationStatus: {
                  status: BDRequestStatus.FAILED,
                  errors: [
                    {
                      type: BDAppErrorType.API,
                      ...(action.payload || (action.error as BDError)),
                    },
                  ],
                },
              },
            },
          },
        },
        {
          arrayMerge: (_, sourceArray) => sourceArray,
        }
      );
    }
  });
};

const buildGetAssetHistoryActionReducers = (
  builder: ActionReducerMapBuilder<EntityState<Asset> & AssetsState>
) => {
  builder.addCase(ASSETS_ACTIONS.getAssetHistory.pending, (state) => {
    state.operations[AssetRequestType.GET_ASSET_HISTORY] = {
      status: BDRequestStatus.PENDING,
      errors: [],
    };
  });
  builder.addCase(ASSETS_ACTIONS.getAssetHistory.fulfilled, (state, action) => {
    state.operations[AssetRequestType.GET_ASSET_HISTORY] = {
      status: BDRequestStatus.SUCCEEDED,
    };
    assetEventsAdapter.upsertMany(state.assetEvents, action.payload.result);
    // TODO: review whether saving events to the asset detail is necessary
    assetsAdapter.updateOne(state, {
      id: action?.meta.arg.assetsId,
      changes: {
        ...state.entities[action?.meta.arg.assetsId],
        events: assetEventsAdapter.getSelectors().selectAll(state.assetEvents),
      },
    });
  });
  builder.addCase(ASSETS_ACTIONS.getAssetHistory.rejected, (state, action) => {
    state.operations[AssetRequestType.GET_ASSET_HISTORY] = {
      status: BDRequestStatus.FAILED,
      errors: [
        {
          type: BDAppErrorType.API,
          ...(action.payload || (action.error as BDError)),
        },
      ],
    };
  });
};

const buildGetAssetLocationActionReducers = (
  builder: ActionReducerMapBuilder<EntityState<Asset> & AssetsState>
) => {
  builder.addCase(ASSETS_ACTIONS.getAssetLocation.pending, (state) => {
    state.operations[AssetRequestType.GET_ASSET_LOCATION] = {
      status: BDRequestStatus.PENDING,
      errors: [],
    };
  });
  builder.addCase(
    ASSETS_ACTIONS.getAssetLocation.fulfilled,
    (state, action) => {
      state.operations[AssetRequestType.GET_ASSET_LOCATION] = {
        status: BDRequestStatus.SUCCEEDED,
      };
      assetLocationsAdapter.upsertMany(
        state.assetLocations,
        action.payload.result
      );
      // TODO: review whether saving locations to the asset detail is necessary
      assetsAdapter.updateOne(state, {
        id: action?.meta.arg.assetsId,
        changes: {
          ...state.entities[action?.meta.arg.assetsId],
          locations: assetLocationsAdapter
            .getSelectors()
            .selectAll(state.assetLocations),
        },
      });
    }
  );
  builder.addCase(ASSETS_ACTIONS.getAssetLocation.rejected, (state, action) => {
    state.operations[AssetRequestType.GET_ASSET_LOCATION] = {
      status: BDRequestStatus.FAILED,
      errors: [
        {
          type: BDAppErrorType.API,
          ...(action.payload || (action.error as BDError)),
        },
      ],
    };
  });
};

export const buildAddAssetActionReducers = (
  builder: ActionReducerMapBuilder<EntityState<Asset> & AssetsState>
) => {
  builder.addCase(ASSETS_ACTIONS.addAsset.pending, (state) => {
    state.operations[BDRequestType.ADD] = {
      status: BDRequestStatus.PENDING,
      errors: [],
    };
  });
  builder.addCase(ASSETS_ACTIONS.addAsset.fulfilled, (state) => {
    state.operations[BDRequestType.ADD] = {
      status: BDRequestStatus.SUCCEEDED,
      errors: [],
    };
  });
  builder.addCase(ASSETS_ACTIONS.addAsset.rejected, (state, action) => {
    state.operations[BDRequestType.ADD] = {
      status: BDRequestStatus.FAILED,
      errors: [
        {
          type: BDAppErrorType.API,
          ...(action.payload || (action.error as BDError)),
        },
      ],
    };
  });
};

const buildUpdateAssetActionReducers = (
  builder: ActionReducerMapBuilder<EntityState<Asset> & AssetsState>
) => {
  builder.addCase(ASSETS_ACTIONS.updateAsset.pending, (state) => {
    state.operations[BDRequestType.UPDATE] = {
      status: BDRequestStatus.PENDING,
      errors: [],
    };
  });
  builder.addCase(ASSETS_ACTIONS.updateAsset.fulfilled, (state) => {
    state.operations[BDRequestType.UPDATE] = {
      status: BDRequestStatus.SUCCEEDED,
      errors: [],
    };
  });
  builder.addCase(ASSETS_ACTIONS.updateAsset.rejected, (state, action) => {
    state.operations[BDRequestType.UPDATE] = {
      status: BDRequestStatus.FAILED,
      errors: [
        {
          type: BDAppErrorType.API,
          ...(action.payload || (action.error as BDError)),
        },
      ],
    };
  });
};

const buildLockAssetActionReducers = (
  builder: ActionReducerMapBuilder<EntityState<Asset> & AssetsState>
) => {
  builder.addCase(ASSETS_ACTIONS.lockAsset.pending, (state) => {
    state.operations[AssetRequestType.LOCK_UNLOCK_ASSEST] = {
      status: BDRequestStatus.PENDING,
      errors: [],
    };
  });
  builder.addCase(ASSETS_ACTIONS.lockAsset.fulfilled, (state) => {
    state.operations[AssetRequestType.LOCK_UNLOCK_ASSEST] = {
      status: BDRequestStatus.SUCCEEDED,
      errors: [],
    };
  });
  builder.addCase(ASSETS_ACTIONS.lockAsset.rejected, (state, action) => {
    state.operations[AssetRequestType.LOCK_UNLOCK_ASSEST] = {
      status: BDRequestStatus.FAILED,
      errors: [
        {
          type: BDAppErrorType.API,
          ...(action.payload || (action.error as BDError)),
        },
      ],
    };
  });
};

const buildUpdateServiceAvailabilityActionReducers = (
  builder: ActionReducerMapBuilder<EntityState<Asset> & AssetsState>
) => {
  builder.addCase(ASSETS_ACTIONS.updateServiceAvailability.pending, (state) => {
    state.operations[BDRequestType.ADD] = {
      status: BDRequestStatus.PENDING,
      errors: [],
    };
  });
  builder.addCase(
    ASSETS_ACTIONS.updateServiceAvailability.fulfilled,
    (state) => {
      state.operations[BDRequestType.ADD] = {
        status: BDRequestStatus.SUCCEEDED,
        errors: [],
      };
    }
  );
  builder.addCase(
    ASSETS_ACTIONS.updateServiceAvailability.rejected,
    (state, action) => {
      state.operations[BDRequestType.ADD] = {
        status: BDRequestStatus.FAILED,
        errors: [
          {
            type: BDAppErrorType.API,
            ...(action.payload || (action.error as BDError)),
          },
        ],
      };
    }
  );
};

const buildGetReadinessReportActionReducers = (
  builder: ActionReducerMapBuilder<EntityState<Asset> & AssetsState>
) => {
  builder.addCase(
    ASSETS_ACTIONS.getReadinessReport.pending,
    (state, action) => {
      const { sessionId, skipStateUpdate } = action.meta.arg;
      if (!skipStateUpdate) {
        const existingConfigs =
          state.sessionConfigs[SessionConfigType.LIST_VIEW]?.[sessionId] || {};

        state.sessionConfigs[SessionConfigType.LIST_VIEW] = {
          ...state.sessionConfigs[SessionConfigType.LIST_VIEW],
          [sessionId]: Object.assign(existingConfigs, {
            selectedIndices: existingConfigs?.selectedIndices || [],
            selectedAssets:
              existingConfigs?.selectedAssets ||
              assetReportItemsAdapter.getInitialState(),
            operationStatus: {
              status: BDRequestStatus.PENDING,
              errors: [],
            },
          }),
        };
      }
    }
  );
  builder.addCase(
    ASSETS_ACTIONS.getReadinessReport.fulfilled,
    (state, action) => {
      const { sessionId, page, rowsPerPage, skipStateUpdate } = action.meta.arg;
      if (!skipStateUpdate) {
        const reportItems = action.payload.result.items;
        assetReportItemsAdapter.setAll(state.assetReportItems, reportItems);

        const existingConfigs =
          state.sessionConfigs[SessionConfigType.LIST_VIEW]?.[sessionId] || {};
        const allSelected = !!existingConfigs.allSelected;
        const selectedAssetsState = existingConfigs?.selectedAssets;
        let selectedIndices = new Array<number>();
        if (allSelected) {
          // only selects assets in view
          selectedIndices = reportItems.map((_, idx) => idx);
          if (selectedAssetsState) {
            assetReportItemsAdapter.upsertMany(
              selectedAssetsState,
              reportItems
            );
          }
        } else {
          if (selectedAssetsState) {
            const selectedAssetIds =
              selectSelectedAssetsIds(selectedAssetsState);
            selectedIndices = reportItems.reduce((selected, item, idx) => {
              if (selectedAssetIds.includes(item.id)) {
                selected.push(idx);
              }
              return selected;
            }, new Array<number>());
          }
        }

        state.sessionConfigs[SessionConfigType.LIST_VIEW] = {
          ...state.sessionConfigs[SessionConfigType.LIST_VIEW],
          [sessionId]: Object.assign(existingConfigs, {
            selectedIndices,
            selectedAssets: selectedAssetsState,
            operationStatus: {
              status: BDRequestStatus.SUCCEEDED,
              errors: action.payload.errors,
            },
            count: action.payload.result.total_items,
            page,
            rowsPerPage,
          }),
        };
      }
    }
  );
  builder.addCase(
    ASSETS_ACTIONS.getReadinessReport.rejected,
    (state, action) => {
      const { sessionId } = action.meta.arg;
      state.sessionConfigs = merge(
        state.sessionConfigs,
        {
          [SessionConfigType.LIST_VIEW]: {
            [sessionId]: {
              operationStatus: {
                status: BDRequestStatus.FAILED,
                errors: [
                  {
                    type: BDAppErrorType.API,
                    ...(action.payload || (action.error as BDError)),
                  },
                ],
              },
            },
          },
        },
        {
          arrayMerge: (_, sourceArray) => sourceArray,
        }
      );
    }
  );
};

const buildGetSettingsNotificationsActionReducers = (
  builder: ActionReducerMapBuilder<EntityState<Asset> & AssetsState>
) => {
  builder.addCase(
    ASSETS_ACTIONS.getVehicleNotificationAssets.pending,
    (state, action) => {
      const { sessionId, skipStateUpdate } = action.meta.arg;
      if (!skipStateUpdate) {
        const existingConfigs =
          state.sessionConfigs[NotificationSettingsConfigType.ADD_VIEW]?.[
            sessionId
          ] || {};

        state.sessionConfigs[NotificationSettingsConfigType.ADD_VIEW] = {
          ...state.sessionConfigs[NotificationSettingsConfigType.ADD_VIEW],
          [sessionId]: Object.assign(existingConfigs, {
            // selectedIndices: existingConfigs?.selectedIndices || [],
            // selectedAssets:
            //   existingConfigs?.selectedAssets ||
            //   assetReportItemsAdapter.getInitialState(),
            operationStatus: {
              status: BDRequestStatus.PENDING,
              errors: [],
            },
          }),
        };
        state.operations[AssetRequestType.SEARCH_NOTIFICATION_SETTINGS_ASSETS] =
          { status: BDRequestStatus.PENDING, errors: [] };
      }
    }
  );
  builder.addCase(
    ASSETS_ACTIONS.getVehicleNotificationAssets.fulfilled,
    (state, action) => {
      const { sessionId, page, rowsPerPage, skipStateUpdate } = action.meta.arg;
      if (!skipStateUpdate) {
        const reportItems = action.payload.result.items;
        assetVehicleNotificationAdapter.setAll(
          state.assetVehicleNotificationList,
          reportItems
        );

        const existingConfigs =
          state.sessionConfigs[NotificationSettingsConfigType.ADD_VIEW]?.[
            sessionId
          ] || {};
        const allSelected = !!existingConfigs.allSelected;
        const selectedAssetsState = existingConfigs?.selectedAssets;
        let selectedIndices = new Array<number>();
        if (allSelected) {
          // only selects assets in view
          selectedIndices = reportItems.map((_, idx) => idx);
          if (selectedAssetsState) {
            assetVehicleNotificationAdapter.upsertMany(
              selectedAssetsState,
              reportItems
            );
          }
        } else {
          if (selectedAssetsState) {
            const selectedAssetIds =
              selectSelectedAssetsIds(selectedAssetsState);
            selectedIndices = reportItems.reduce((selected, item, idx) => {
              if (selectedAssetIds.includes(item.id)) {
                selected.push(idx);
              }
              return selected;
            }, new Array<number>());
          }
        }

        state.sessionConfigs[SessionConfigType.LIST_VIEW] = {
          ...state.sessionConfigs[SessionConfigType.LIST_VIEW],
          [sessionId]: Object.assign(existingConfigs, {
            selectedIndices,
            selectedAssets: selectedAssetsState,
            operationStatus: {
              status: BDRequestStatus.SUCCEEDED,
              errors: [],
            },
            count: action.payload.result.total_items,
            page,
            rowsPerPage,
          }),
        };
        state.operations[AssetRequestType.SEARCH_NOTIFICATION_SETTINGS_ASSETS] =
          { status: BDRequestStatus.PENDING, errors: [] };
      }
    }
  );
  builder.addCase(
    ASSETS_ACTIONS.getVehicleNotificationAssets.rejected,
    (state, action) => {
      const { sessionId } = action.meta.arg;
      state.sessionConfigs = merge(
        state.sessionConfigs,
        {
          [NotificationSettingsConfigType.ADD_VIEW]: {
            [sessionId]: {
              operationStatus: {
                status: BDRequestStatus.FAILED,
                errors: [
                  {
                    type: BDAppErrorType.API,
                    ...(action.payload || (action.error as BDError)),
                  },
                ],
              },
            },
          },
        },
        {
          arrayMerge: (_, sourceArray) => sourceArray,
        }
      );
    }
  );
};

const buildGetTiresReportActionReducers = (
  builder: ActionReducerMapBuilder<EntityState<Asset> & AssetsState>
) => {
  builder.addCase(ASSETS_ACTIONS.getTiresReport.pending, (state, action) => {
    const { sessionId, skipStateUpdate } = action.meta.arg;
    if (!skipStateUpdate) {
      const existingConfigs =
        state.sessionConfigs[SessionConfigType.LIST_VIEW]?.[sessionId] || {};

      state.sessionConfigs[SessionConfigType.LIST_VIEW] = {
        ...state.sessionConfigs[SessionConfigType.LIST_VIEW],
        [sessionId]: Object.assign(existingConfigs, {
          selectedIndices: existingConfigs?.selectedIndices || [],
          selectedAssets:
            existingConfigs?.selectedAssets ||
            assetReportItemsAdapter.getInitialState(),
          operationStatus: {
            status: BDRequestStatus.PENDING,
            errors: [],
          },
        }),
      };
    }
  });
  builder.addCase(ASSETS_ACTIONS.getTiresReport.fulfilled, (state, action) => {
    const { sessionId, page, rowsPerPage, skipStateUpdate } = action.meta.arg;
    if (!skipStateUpdate) {
      const reportItems = action.payload.result.items;
      assetReportItemsAdapter.setAll(state.assetReportItems, reportItems);

      const existingConfigs =
        state.sessionConfigs[SessionConfigType.LIST_VIEW]?.[sessionId] || {};
      const allSelected = !!existingConfigs.allSelected;
      const selectedAssetsState = existingConfigs?.selectedAssets;
      let selectedIndices = new Array<number>();
      if (allSelected) {
        // only selects assets in view
        selectedIndices = reportItems.map((_, idx) => idx);
        if (selectedAssetsState) {
          assetReportItemsAdapter.upsertMany(selectedAssetsState, reportItems);
        }
      } else {
        if (selectedAssetsState) {
          const selectedAssetIds = selectSelectedAssetsIds(selectedAssetsState);
          selectedIndices = reportItems.reduce((selected, item, idx) => {
            if (selectedAssetIds.includes(item.id)) {
              selected.push(idx);
            }
            return selected;
          }, new Array<number>());
        }
      }

      state.sessionConfigs[SessionConfigType.LIST_VIEW] = {
        ...state.sessionConfigs[SessionConfigType.LIST_VIEW],
        [sessionId]: Object.assign(existingConfigs, {
          selectedIndices,
          selectedAssets: selectedAssetsState,
          operationStatus: {
            status: BDRequestStatus.SUCCEEDED,
            errors: [],
          },
          count: action.payload.result.total_items,
          page,
          rowsPerPage,
        }),
      };
    }
  });
  builder.addCase(ASSETS_ACTIONS.getTiresReport.rejected, (state, action) => {
    const { sessionId } = action.meta.arg;
    state.sessionConfigs = merge(
      state.sessionConfigs,
      {
        [SessionConfigType.LIST_VIEW]: {
          [sessionId]: {
            operationStatus: {
              status: BDRequestStatus.FAILED,
              errors: [
                {
                  type: BDAppErrorType.API,
                  ...(action.payload || (action.error as BDError)),
                },
              ],
            },
          },
        },
      },
      {
        arrayMerge: (_, sourceArray) => sourceArray,
      }
    );
  });
};

const buildGetBatteryReportActionReducers = (
  builder: ActionReducerMapBuilder<EntityState<Asset> & AssetsState>
) => {
  builder.addCase(ASSETS_ACTIONS.getBatteryReport.pending, (state, action) => {
    const { sessionId, skipStateUpdate } = action.meta.arg;
    if (!skipStateUpdate) {
      const existingConfigs =
        state.sessionConfigs[SessionConfigType.LIST_VIEW]?.[sessionId] || {};

      state.sessionConfigs[SessionConfigType.LIST_VIEW] = {
        ...state.sessionConfigs[SessionConfigType.LIST_VIEW],
        [sessionId]: Object.assign(existingConfigs, {
          selectedIndices: existingConfigs?.selectedIndices || [],
          selectedAssets:
            existingConfigs?.selectedAssets ||
            assetReportItemsAdapter.getInitialState(),
          operationStatus: {
            status: BDRequestStatus.PENDING,
            errors: [],
          },
        }),
      };
    }
  });
  builder.addCase(
    ASSETS_ACTIONS.getBatteryReport.fulfilled,
    (state, action) => {
      const { sessionId, page, rowsPerPage, skipStateUpdate } = action.meta.arg;
      if (!skipStateUpdate) {
        const reportItems = action.payload.result.items;
        assetReportItemsAdapter.setAll(state.assetReportItems, reportItems);

        const existingConfigs =
          state.sessionConfigs[SessionConfigType.LIST_VIEW]?.[sessionId] || {};
        const allSelected = !!existingConfigs.allSelected;
        const selectedAssetsState = existingConfigs?.selectedAssets;
        let selectedIndices = new Array<number>();
        if (allSelected) {
          // only selects assets in view
          selectedIndices = reportItems.map((_, idx) => idx);
          if (selectedAssetsState) {
            assetReportItemsAdapter.upsertMany(
              selectedAssetsState,
              reportItems
            );
          }
        } else {
          if (selectedAssetsState) {
            const selectedAssetIds =
              selectSelectedAssetsIds(selectedAssetsState);
            selectedIndices = reportItems.reduce((selected, item, idx) => {
              if (selectedAssetIds.includes(item.id)) {
                selected.push(idx);
              }
              return selected;
            }, new Array<number>());
          }
        }

        state.sessionConfigs[SessionConfigType.LIST_VIEW] = {
          ...state.sessionConfigs[SessionConfigType.LIST_VIEW],
          [sessionId]: Object.assign(existingConfigs, {
            selectedIndices,
            selectedAssets: selectedAssetsState,
            operationStatus: {
              status: BDRequestStatus.SUCCEEDED,
              errors: [],
            },
            count: action.payload.result.total_items,
            page,
            rowsPerPage,
          }),
        };
      }
    }
  );
  builder.addCase(ASSETS_ACTIONS.getBatteryReport.rejected, (state, action) => {
    const { sessionId } = action.meta.arg;
    state.sessionConfigs = merge(
      state.sessionConfigs,
      {
        [SessionConfigType.LIST_VIEW]: {
          [sessionId]: {
            operationStatus: {
              status: BDRequestStatus.FAILED,
              errors: [
                {
                  type: BDAppErrorType.API,
                  ...(action.payload || (action.error as BDError)),
                },
              ],
            },
          },
        },
      },
      {
        arrayMerge: (_, sourceArray) => sourceArray,
      }
    );
  });
};
const buildGetRecallsActionReducers = (
  builder: ActionReducerMapBuilder<EntityState<Asset> & AssetsState>
) => {
  builder.addCase(ASSETS_ACTIONS.getRecalls.pending, (state, action) => {
    const { sessionId, skipStateUpdate } = action.meta.arg;
    if (!skipStateUpdate) {
      const existingConfigs =
        state.sessionConfigs[SessionConfigType.LIST_VIEW]?.[sessionId] || {};

      state.sessionConfigs[SessionConfigType.LIST_VIEW] = {
        ...state.sessionConfigs[SessionConfigType.LIST_VIEW],
        [sessionId]: Object.assign(existingConfigs, {
          continuationCache: existingConfigs?.continuationCache || undefined,
          operationStatus: {
            status: BDRequestStatus.PENDING,
            errors: [],
          },
        }),
      };
    }
  });
  builder.addCase(ASSETS_ACTIONS.getRecalls.fulfilled, (state, action) => {
    const { sessionId, page, rowsPerPage, skipStateUpdate, continuationToken } =
      action.meta.arg;
    const reportItems = action.payload.result.items;
    assetReportItemsAdapter.setAll(state.assetReportItems, reportItems);
    if (!skipStateUpdate) {
      const existingConfigs =
        state.sessionConfigs[SessionConfigType.LIST_VIEW]?.[sessionId] || {};
      state.sessionConfigs[SessionConfigType.LIST_VIEW] = {
        ...state.sessionConfigs[SessionConfigType.LIST_VIEW],
        [sessionId]: Object.assign(existingConfigs, {
          count: action.payload.result.total_items,
          page,
          rowsPerPage,
          continuationToken,
          continuationCache: {
            ...existingConfigs.continuationCache,
            [Number(page)]: {
              data: reportItems,
              continuationToken:
                action.payload.result.continuation_token ?? null,
            },
          },
          operationStatus: {
            status: BDRequestStatus.SUCCEEDED,
            errors: [],
          },
        }),
      };
    }
  });
  builder.addCase(ASSETS_ACTIONS.getRecalls.rejected, (state, action) => {
    const { sessionId } = action.meta.arg;
    state.sessionConfigs = merge(
      state.sessionConfigs,
      {
        [SessionConfigType.LIST_VIEW]: {
          [sessionId]: {
            operationStatus: {
              status: BDRequestStatus.FAILED,
              errors: [
                {
                  type: BDAppErrorType.API,
                  ...(action.payload || (action.error as BDError)),
                },
              ],
            },
          },
        },
      },
      {
        arrayMerge: (_, sourceArray) => sourceArray,
      }
    );
  });
};
const buildGetAsseComplianceListActionReducers = (
  builder: ActionReducerMapBuilder<EntityState<Asset> & AssetsState>
) => {
  builder.addCase(
    ASSETS_ACTIONS.getAssetsCompliance.pending,
    (state, action) => {
      const { sessionId, skipStateUpdate } = action.meta.arg;
      if (!skipStateUpdate) {
        const existingConfigs =
          state.sessionConfigs[AssetReportConfigType.COMPLIANCE_VIEW]?.[
            sessionId
          ] || {};
        state.sessionConfigs[AssetReportConfigType.COMPLIANCE_VIEW] = {
          ...state.sessionConfigs[AssetReportConfigType.COMPLIANCE_VIEW],
          [sessionId]: {
            ...existingConfigs,
            operationStatus: {
              status: BDRequestStatus.PENDING,
              errors: [],
            },
          },
        };
      }
    }
  );
  builder.addCase(
    ASSETS_ACTIONS.getAssetsCompliance.fulfilled,
    (state, action) => {
      const { sessionId, page, rowsPerPage, skipStateUpdate } = action.meta.arg;
      if (!skipStateUpdate) {
        const assetsComplianceList = action.payload.result.items;
        assetComplianceAdapter.setAll(
          state.assetComplianceList,
          assetsComplianceList
        );
        const existingConfigs =
          state.sessionConfigs[AssetReportConfigType.COMPLIANCE_VIEW]?.[
            sessionId
          ] || {};
        state.sessionConfigs[AssetReportConfigType.COMPLIANCE_VIEW] = {
          ...state.sessionConfigs[AssetReportConfigType.COMPLIANCE_VIEW],
          [sessionId]: {
            ...existingConfigs,
            operationStatus: {
              status: BDRequestStatus.SUCCEEDED,
              errors: [],
            },
            count: action.payload.result.total_items,
            page,
            rowsPerPage,
          },
        };
      }
    }
  );
  builder.addCase(
    ASSETS_ACTIONS.getAssetsCompliance.rejected,
    (state, action) => {
      const { sessionId, skipStateUpdate } = action.meta.arg;
      if (!skipStateUpdate) {
        const existingConfigs =
          state.sessionConfigs[AssetReportConfigType.COMPLIANCE_VIEW]?.[
            sessionId
          ] || {};
        state.sessionConfigs[AssetReportConfigType.COMPLIANCE_VIEW] = {
          ...state.sessionConfigs[AssetReportConfigType.COMPLIANCE_VIEW],
          [sessionId]: {
            ...existingConfigs,
            operationStatus: {
              status: BDRequestStatus.FAILED,
              errors: [
                {
                  type: BDAppErrorType.API,
                  ...(action.payload || (action.error as BDError)),
                },
              ],
            },
          },
        };
      }
    }
  );
};

const buildSearchAssetsActionReducers = (
  builder: ActionReducerMapBuilder<EntityState<Asset> & AssetsState>
) => {
  builder.addCase(ASSETS_ACTIONS.searchAssets.pending, (state, action) => {
    const { sessionId } = action.meta.arg;
    state.sessionConfigs = merge(
      state.sessionConfigs,
      {
        [SearchConfigType.SEARCH_VIEW]: {
          [sessionId]: {
            operationStatus: {
              status: BDRequestStatus.PENDING,
              errors: [],
            },
          },
        },
      },
      {
        arrayMerge: (_, sourceArray) => sourceArray,
      }
    );
  });
  builder.addCase(ASSETS_ACTIONS.searchAssets.fulfilled, (state, action) => {
    const { sessionId, page, rowsPerPage } = action.meta.arg;
    assetSearchAdapter.setAll(state.assetList, action.payload.result.items);
    state.sessionConfigs = merge(
      state.sessionConfigs,
      {
        [SearchConfigType.SEARCH_VIEW]: {
          [sessionId]: {
            operationStatus: {
              status: BDRequestStatus.SUCCEEDED,
            },
            count: action.payload.result.total_items,
            page,
            rowsPerPage,
            selectedIndices: [],
          },
        },
      },
      {
        arrayMerge: (_, sourceArray) => sourceArray,
      }
    );
  });
  builder.addCase(ASSETS_ACTIONS.searchAssets.rejected, (state, action) => {
    const { sessionId } = action.meta.arg;
    state.sessionConfigs = merge(
      state.sessionConfigs,
      {
        [SearchConfigType.SEARCH_VIEW]: {
          [sessionId]: {
            operationStatus: {
              status: BDRequestStatus.FAILED,
              errors: [
                {
                  type: BDAppErrorType.API,
                  ...(action.payload || (action.error as BDError)),
                },
              ],
            },
          },
        },
      },
      {
        arrayMerge: (_, sourceArray) => sourceArray,
      }
    );
  });
};

/**
 * TODO: review for deletion
 * @deprecated
 */
const buildDeleteAssetActionReducers = (
  builder: ActionReducerMapBuilder<EntityState<Asset> & AssetsState>
) => {
  builder.addCase(ASSETS_ACTIONS.deleteAsset.pending, (state) => {
    state.operations[BDRequestType.DELETE] = {
      status: BDRequestStatus.PENDING,
      errors: [],
    };
  });
  builder.addCase(ASSETS_ACTIONS.deleteAsset.fulfilled, (state) => {
    state.operations[BDRequestType.DELETE] = {
      status: BDRequestStatus.SUCCEEDED,
      errors: [],
    };
  });
  builder.addCase(ASSETS_ACTIONS.deleteAsset.rejected, (state, action) => {
    state.operations[BDRequestType.DELETE] = {
      status: BDRequestStatus.FAILED,
      errors: [
        {
          type: BDAppErrorType.API,
          ...(action.payload || (action.error as BDError)),
        },
      ],
    };
  });
};

/**
 * TODO: review for deletion
 * @deprecated
 */
const buildGetNextEPalletSerialActionReducers = (
  builder: ActionReducerMapBuilder<EntityState<Asset> & AssetsState>
) => {
  builder.addCase(ASSETS_ACTIONS.getNextEPalletSerial.pending, (state) => {
    state.operations[AssetRequestType.GET_NEXT_EPALLET_SERIAL_NUMBER] = {
      status: BDRequestStatus.PENDING,
      errors: [],
    };
  });
  builder.addCase(
    ASSETS_ACTIONS.getNextEPalletSerial.fulfilled,
    (state, action) => {
      state.nextEPalletSerial = action.payload.result;
      state.operations[AssetRequestType.GET_NEXT_EPALLET_SERIAL_NUMBER] = {
        status: BDRequestStatus.SUCCEEDED,
      };
    }
  );
  builder.addCase(
    ASSETS_ACTIONS.getNextEPalletSerial.rejected,
    (state, action) => {
      state.operations[AssetRequestType.GET_NEXT_EPALLET_SERIAL_NUMBER] = {
        status: BDRequestStatus.FAILED,
        errors: [
          {
            type: BDAppErrorType.API,
            ...(action.payload || (action.error as BDError)),
          },
        ],
      };
    }
  );
};

/**
 * TODO: review for deletion
 * @deprecated
 */
const buildGetTrackerSerialsActionReducers = (
  builder: ActionReducerMapBuilder<EntityState<Asset> & AssetsState>
) => {
  builder.addCase(ASSETS_ACTIONS.getTrackerSerials.pending, (state) => {
    state.operations[AssetRequestType.GET_TRACKER_SERIALS] = {
      status: BDRequestStatus.PENDING,
      errors: [],
    };
  });
  builder.addCase(
    ASSETS_ACTIONS.getTrackerSerials.fulfilled,
    (state, action) => {
      state.trackerSerials = action.payload.result;
      state.operations[AssetRequestType.GET_TRACKER_SERIALS] = {
        status: BDRequestStatus.SUCCEEDED,
      };
    }
  );
  builder.addCase(
    ASSETS_ACTIONS.getTrackerSerials.rejected,
    (state, action) => {
      state.operations[AssetRequestType.GET_TRACKER_SERIALS] = {
        status: BDRequestStatus.FAILED,
        errors: [
          {
            type: BDAppErrorType.API,
            ...(action.payload || (action.error as BDError)),
          },
        ],
      };
    }
  );
};

/**
 * TODO: review for deletion
 * @deprecated
 */
const buildGetEPalletVersionsActionReducers = (
  builder: ActionReducerMapBuilder<EntityState<Asset> & AssetsState>
) => {
  builder.addCase(ASSETS_ACTIONS.getEPalletVersions.pending, (state) => {
    state.operations[AssetRequestType.GET_ASSET_EPALLET_VERSIONS] = {
      status: BDRequestStatus.PENDING,
      errors: [],
    };
  });
  builder.addCase(
    ASSETS_ACTIONS.getEPalletVersions.fulfilled,
    (state, action) => {
      state.ePalletVersions = action.payload.result;
      state.operations[AssetRequestType.GET_ASSET_EPALLET_VERSIONS] = {
        status: BDRequestStatus.SUCCEEDED,
      };
    }
  );
  builder.addCase(
    ASSETS_ACTIONS.getEPalletVersions.rejected,
    (state, action) => {
      state.operations[AssetRequestType.GET_ASSET_EPALLET_VERSIONS] = {
        status: BDRequestStatus.FAILED,
        errors: [
          {
            type: BDAppErrorType.API,
            ...(action.payload || (action.error as BDError)),
          },
        ],
      };
    }
  );
};

export const ASSETS_REDUCERS = {
  reduceSetAssetSessionConfig,
  reduceClearAssetListReportFilterSessionConfig,
  reduceResetAssetListReportSessionConfig,
  reduceResetEventDate,
  reduceSetPendingAssetOperation,
  reduceSetAssetLocationAddress,
  reduceClearOperations,
  reduceClearAssetEvents,
  reduceClearAssetLocations,
  reduceSetSelectedMarkerId,
  reduceHandleAssetSubscriptionEvent,
  reduceSetSelectedAssets,
  reduceRecallContinuationCache,
};

export const ASSETS_EXTRA_REDUCER_BUILDERS = {
  buildGetAssetsActionReducers,
  buildGetAssetDetailsActionReducers,
  buildGetAssetRecallsActionReducers,
  buildGetAssetHistoryActionReducers,
  buildGetAssetLocationActionReducers,
  buildAddAssetActionReducers,
  buildUpdateAssetActionReducers,
  buildLockAssetActionReducers,
  buildUpdateServiceAvailabilityActionReducers,
  buildGetReadinessReportActionReducers,
  buildGetTiresReportActionReducers,
  buildGetBatteryReportActionReducers,
  buildGetAsseComplianceListActionReducers,
  buildSearchAssetsActionReducers,
  buildGetSettingsNotificationsActionReducers,
  buildGetRecallsActionReducers,
  buildDeleteAssetActionReducers,
  buildGetNextEPalletSerialActionReducers,
  buildGetTrackerSerialsActionReducers,
  buildGetEPalletVersionsActionReducers,
};
