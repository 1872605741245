import {
  AssetExceptionStatus,
  AssetMapItem,
} from '~/common/models/asset.model';

const EXCEPTIONS_PRIORITY = [
  AssetExceptionStatus.SEVERE_FAULT,
  AssetExceptionStatus.FLAT_TIRE,
  AssetExceptionStatus.IMPAIRED_FAULT,
  AssetExceptionStatus.LOW_BRAKE_FLUID,
  AssetExceptionStatus.LOW_TIRE,
  AssetExceptionStatus.LOW_WASHER_FLUID,
  AssetExceptionStatus.TEMP_CRITICAL,
  AssetExceptionStatus.TEMP_IMPAIRED,
];

const DEFAULT_WEIGHT = EXCEPTIONS_PRIORITY.length * 2;
const READINESS_WEIGHT = DEFAULT_WEIGHT + 1;
const LOW_WEIGHT = 1;
const MAX_ENERGY_CHARGE = 100;

export const getExceptionsWeight = (asset?: AssetMapItem): number => {
  const exceptionsWeight =
    asset?.exceptions?.reduce((accumulator, current) => {
      const exceptionIndex =
        EXCEPTIONS_PRIORITY.findIndex((exception) => exception === current) + 1;
      return accumulator + (DEFAULT_WEIGHT - exceptionIndex);
    }, 0) || 0;
  const batteryChargeWeight =
    typeof asset?.battery?.value !== 'undefined'
      ? LOW_WEIGHT - asset?.battery?.value / MAX_ENERGY_CHARGE
      : 0;

  return (
    exceptionsWeight +
    batteryChargeWeight +
    +!!(asset?.ready === false) * READINESS_WEIGHT
  );
};

export const mapItemsByExceptionsSorter = (
  first: AssetMapItem | undefined,
  second: AssetMapItem | undefined
): number => {
  if (first && second) {
    const firstWeight = getExceptionsWeight(first);
    const secondWeight = getExceptionsWeight(second);
    if (firstWeight === secondWeight) {
      return first.name?.localeCompare(second.name);
    } else {
      return firstWeight < secondWeight ? 1 : -1;
    }
  }
  if (first === undefined) return -1;
  return 1;
};
