import Box from '@mui/material/Box';

import BDSpinner from '../BDSpinner/BDSpinner';
import useStyles from './BDPageSpinner.styles';

const BDPageSpinner = (): JSX.Element => {
  const { classes } = useStyles();

  return (
    <Box className={classes.spinnerContainer} data-testid="page-spinner">
      <BDSpinner />
    </Box>
  );
};

export default BDPageSpinner;
