import { createSlice } from '@reduxjs/toolkit';

/* XXX: FEAT-44231 Placeholder slice for dispatch management. Here to get some initial state
   management scaffolding into position as feature is being built. Highly subject to change as
   the feature is being built out. */
export const dispatchSlice = createSlice({
  name: 'dispatch',
  initialState: {},
  reducers: {},
});

export const dispatchReducer = dispatchSlice.reducer;
