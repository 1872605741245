import { makeStyles } from 'tss-react/mui';

const searchContainerHeight = '40px';
const searchContainerBorderRadius = '50px';
const searchContainerWidth = '400px';

const useStyles = makeStyles()((theme) => ({
  searchContainer: {
    backgroundColor: theme.new.color.inputBackground,
    borderRadius: searchContainerBorderRadius,
    height: searchContainerHeight,
    flex: `0 1 ${searchContainerWidth}`,

    '&:active, &:hover': {
      '.MuiInputBase-root': {
        fieldset: {
          borderColor: theme.new.color.brandPrimary,
        },
      },
    },

    '&:focus-within': {
      boxShadow: `0 0 0 2px ${theme.new.color.common.white}, 0 0 0 4px ${theme.new.color.brand[500]}`,

      '.MuiInputLabel-root': {
        display: 'none',
      },
    },

    '.MuiInputLabel-root': {
      bottom: '10px',
      color: theme.new.color.textTertiary,
      height: searchContainerHeight,
      marginLeft: '30px',
      top: 'unset',
    },

    '.MuiInputBase-root': {
      borderRadius: searchContainerBorderRadius,
      height: searchContainerHeight,
      padding: '12px',

      //Important necessary to override mui specifity to place the cancel icon at the furthest right part of the textbox.
      paddingRight: '12px !important',

      input: {
        color: theme.new.color.textPrimary,
        fontFamily: theme.typography.small_body_regular.fontFamily,
        fontSize: theme.typography.small_body_regular.fontSize,
        fontWeight: theme.typography.small_body_regular.fontWeight,
        lineHeight: theme.typography.small_body_regular.lineHeight,
      },

      fieldset: {
        borderColor: theme.new.color.line.border,
      },

      '.MuiAutocomplete-input': {
        paddingLeft: 0,
      },
    },
  },
  popper: {
    background: theme.new.color.common.white,
    border: `1px solid ${theme.new.color.line.hairline}`,
    boxShadow: theme.new.boxShadows.elevation2,
    borderRadius: '8px',
    height: 'fit-content',
    padding: '16px',
    width: searchContainerWidth,
    zIndex: 400,
  },
  noOptions: {
    height: '56px',
    padding: '16px',
  },
  searchTypeLabel: {
    alignItems: 'center',
    background: theme.new.color.grey[200],
    borderRadius: '8px',
    color: theme.new.color.textSecondary,
    display: 'flex',
    height: '32px',
    marginBottom: '4px',
    padding: '4px 12px',
    width: '100%',

    path: {
      stroke: theme.new.color.textSecondary,
    },
  },
  viewAllResults: {
    alignItems: 'center',
    borderRadius: '8px',
    color: theme.new.color.brandPrimary,
    cursor: 'pointer',
    display: 'flex',
    height: '48px',
    justifyContent: 'left',
    padding: '12px',
    width: '100%',

    '&:focus': {
      boxShadow: `0 0 0 2px ${theme.new.color.common.white}, 0 0 0 4px ${theme.new.color.brand[500]}`,
    },

    '&:hover': {
      backgroundColor: theme.new.color.brand[100],
    },

    rect: {
      fill: theme.new.color.brandPrimary,
    },
  },
}));

export default useStyles;
