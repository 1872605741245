import { GLOBAL_ROLES, Role } from '@gm-commercial/profile-model';
import { useCallback, useMemo } from 'react';

import useProfile from './useProfile';

type UseRoleOutput = {
  hasRole: (authorizedRoles: Role[]) => boolean;
  roleExcluded: (excludedRoles: Role[]) => boolean;
  isRoleAuthorized: ({
    authorizedRoles,
    excludedRoles,
  }: {
    authorizedRoles?: Role[];
    excludedRoles?: Role[];
  }) => boolean;
  hasGlobalRole: boolean;
};

/**
 * Custom hook to determine if the current user has a specific role or set of roles.
 * This hook utilizes the user's profile information to check against provided role criteria.
 *
 * @param {boolean} actingRole - A boolean flag to determine if the acting role should be used.
 * If true, the role from the actingProfile is used, otherwise, the role from the profile is used.
 *
 * @returns {UseRoleOutput} An object containing two properties:
 * - `hasRole`: A function that takes an array of `authorizedRoles` and returns a boolean indicating if the user has any of the specified roles.
 * - `roleExcluded`: A function that takes an array of `excludedRoles` and returns a boolean indicating if the user's role is not in the excluded roles.
 * - `isAuthorized`: A function that takes an object with `authorizedRoles` and `excludedRoles` arrays and returns a boolean indicating if the user's role is authorized.
 * - `hasGlobalRole`: A boolean indicating if the user has a global role.
 */

const useRole = (actingRole = true): UseRoleOutput => {
  const { profile, actingProfile } = useProfile();

  const userRole = useMemo(
    () => (actingRole ? actingProfile?.role : profile?.role),
    [actingRole, actingProfile?.role, profile?.role]
  );

  const hasRole = useCallback(
    (authorizedRoles: Role[]) =>
      !!userRole && authorizedRoles.some((role) => role === userRole),
    [userRole]
  );

  const roleExcluded = useCallback(
    (excludedRoles: Role[]) =>
      (!!userRole && !excludedRoles.length) ||
      excludedRoles.some((role) => role === userRole),
    [userRole]
  );

  const isRoleAuthorized = useCallback(
    ({
      authorizedRoles,
      excludedRoles,
    }: {
      authorizedRoles?: Role[];
      excludedRoles?: Role[];
    }) => {
      return (
        (!authorizedRoles || hasRole(authorizedRoles)) &&
        (!excludedRoles || !roleExcluded(excludedRoles))
      );
    },
    [hasRole, roleExcluded]
  );

  const hasGlobalRole = useMemo(() => hasRole(GLOBAL_ROLES), [hasRole]);

  return { hasRole, roleExcluded, isRoleAuthorized, hasGlobalRole };
};

export default useRole;
