import { getYAxisUpperBound } from '~/common/components/assetUtilization/utils/utilizationChart.utils';
import {
  DEFAULT_EMPTY_VALUE,
  LanguageLocale,
} from '~/common/constants/common.constant';
import { EnergyUnit } from '~/common/models/asset.model';
import { Entity } from '~/common/models/common.model';
import {
  ApiInsight,
  ApiInsightDetails,
  ApiInsightRecommedationDetails,
  Insight,
  InsightChartType,
  InsightCostPatch,
  InsightCostPatchDetails,
  InsightCostPatchTimePeriod,
  InsightDetails,
  InsightLinePlotData,
  InsightListDetails,
  InsightPlotItem,
  InsightRecommedationDetails,
  InsightSavings,
  InsightTimeSeriesData,
  InsightType,
  InsightTypeDetails,
} from '~/common/models/insight.model';
import { formatDateString } from '~/common/utils/date-time.utils';

import { INSIGHT_CHART_MESSAGES } from '../card/chart/InsightChart';
import {
  convertTimestampToAxisValue,
  formatCurrency,
  getInsightChartName,
  getInsightSavingDetails,
  hasInsightRecommendations,
  insightListDefaultSortCompare,
} from '../utils/insight.utils';

export const mapApiResponseToInsight = (apiInsight: ApiInsight): Insight => ({
  id: apiInsight.id,
  name: apiInsight.id,
  organizationId: apiInsight.organizationId,
  hubId: apiInsight.hubId,
  fleetId: apiInsight.fleetId,
  type: apiInsight.insightType,
  date: apiInsight.createdAt,
  details: mapInsightDetails(apiInsight?.insightPayload),
});

export const mapRecommendationInsightDetails = (
  details: Array<ApiInsightRecommedationDetails>,
  timeZone: string
): Array<InsightRecommedationDetails> => {
  return details?.map((item) => ({
    bodyText: item.bodyText,
    detailRecommendations: item.detailRecommendations,
    type: item.recommendationInsightDetailType,
    chartDetails: { ...item.plot, timeZone },
  }));
};

export const mapInsightDetails = (
  apiInsightDetails?: ApiInsightDetails
): InsightDetails | undefined => {
  return apiInsightDetails
    ? {
        id: apiInsightDetails.id,
        name: apiInsightDetails.insightName,
        createdAt: apiInsightDetails.createdAt,
        analysisStartAt: apiInsightDetails.analysisStartAt,
        analysisEndAt: apiInsightDetails.analysisEndAt,
        type: apiInsightDetails.insightType,
        fleetId: apiInsightDetails.fleetId,
        hubId: apiInsightDetails.hubId,
        organizationId: apiInsightDetails.organizationId,
        mainMessage: apiInsightDetails.mainMessage,
        mainMessageDetails: apiInsightDetails.mainMessageInfo,
        recommendations: apiInsightDetails.recommendations,
        recommendationDetails: mapRecommendationInsightDetails(
          apiInsightDetails.recommendationInsightDetails,
          apiInsightDetails.timeZone
        ),
        timeZone: apiInsightDetails.timeZone,
        recommendedSettings: apiInsightDetails?.recommendedSettings,
      }
    : undefined;
};

export const mapLineChartData = ({
  data,
  locale = LanguageLocale.EN,
  timeZone,
}: {
  data: InsightTimeSeriesData;
  locale?: string;
  timeZone?: string;
}): InsightLinePlotData => {
  const { counterfactualPowerInKw, factualPowerInKw } = data;
  const factualPower: InsightPlotItem[] = [];
  const counterfactualPower: InsightPlotItem[] = [];
  let maxFactualPowerInKw = 0;
  let maxCounterfactualPowerInKw = 0;
  Object.keys(factualPowerInKw).forEach((key, index) => {
    const dateString = key;
    const value = factualPowerInKw[dateString];
    const time = convertTimestampToAxisValue(dateString, locale, timeZone);
    if (index === 0) {
      maxFactualPowerInKw = value;
    }
    maxFactualPowerInKw = Math.max(maxFactualPowerInKw, value);
    factualPower.push({ x: time, y: value });
  });
  Object.keys(counterfactualPowerInKw).forEach((key, index) => {
    const dateString = key;
    const value = counterfactualPowerInKw[dateString];
    const time = convertTimestampToAxisValue(dateString, locale, timeZone);
    if (index === 0) {
      maxCounterfactualPowerInKw = value;
    }
    maxCounterfactualPowerInKw = Math.max(maxCounterfactualPowerInKw, value);
    counterfactualPower.push({ x: time, y: value });
  });

  counterfactualPower.push({
    x: 24,
    y: counterfactualPower[counterfactualPower.length - 1].y,
  });

  factualPower.push({
    x: 24,
    y: factualPower[factualPower.length - 1].y,
  });

  return {
    recommendationLineData: counterfactualPower,
    historicalLineData: factualPower,
    max: getYAxisUpperBound(
      Math.max(maxFactualPowerInKw, maxCounterfactualPowerInKw)
    ),
  };
};

export const mapCostPatchChartData = ({
  data,
  locale = LanguageLocale.EN,
  timeZone,
  timeFormat,
  max,
  chartType,
}: {
  data: Array<InsightCostPatch>;
  max: number;
  chartType: InsightChartType;
  locale?: string;
  timeZone?: string;
  timeFormat?: string;
}): InsightCostPatchDetails => {
  const costPatchDetails: InsightCostPatchDetails = {};
  data.forEach((key, index) => {
    const { timePeriods, costTranslationBundle } = key;
    const patchValues: InsightPlotItem[] = [];
    const mappedTimePeriods = mapTimePeriods({
      timePeriods,
      locale,
      timeZone,
      timeFormat,
    });
    let bundleKey = '';
    let value = 0;
    let displayValue = '';

    Object.keys(costTranslationBundle.parameters).forEach((key) => {
      bundleKey = key;
      value = costTranslationBundle.parameters[key];
    });
    const formatData = costTranslationBundle?.formatParams?.[bundleKey];

    if (
      typeof formatData === 'object' &&
      Object.hasOwn(formatData, 'currency') &&
      typeof formatData.currency === 'string'
    ) {
      displayValue = `${formatCurrency(
        value,
        locale,
        formatData.currency,
        2
      )}/${mapInsightChartUnitType(chartType)}`;
    }
    let time = '';

    for (let i = 0; i <= 24; i += 0.25) {
      const valueExistInTimePeriod = mappedTimePeriods.some((period) => {
        const result =
          period.min === period.max || (i >= period.min && i <= period.max);
        if (result) {
          time = period.time;
        }
        return result;
      });

      if (valueExistInTimePeriod) {
        patchValues.push({ x: i, y: max, time, value: displayValue });
      } else {
        patchValues.push({ x: i, y: 0 });
      }
    }
    costPatchDetails[index] = patchValues;
  });
  return costPatchDetails;
};

export const mapTimePeriods = ({
  timePeriods,
  locale = LanguageLocale.EN,
  timeZone,
  timeFormat,
}: {
  timePeriods: Array<InsightCostPatchTimePeriod>;
  locale?: string;
  timeZone?: string;
  timeFormat?: string;
}): Array<{ min: number; max: number; time: string }> => {
  const periods: Array<{ min: number; max: number; time: string }> = [];
  timePeriods.forEach((period) => {
    const formatedStartAt =
      formatDateString({
        value: period.startAt,
        dateFormat: timeFormat,
        locale,
        timeZone,
      }) || DEFAULT_EMPTY_VALUE;
    const formatedEndAt =
      formatDateString({
        value: period.endAt,
        dateFormat: timeFormat,
        locale,
        timeZone,
      }) || DEFAULT_EMPTY_VALUE;
    const startAtTime = convertTimestampToAxisValue(
      period.startAt,
      locale,
      timeZone
    );
    const endAtTime = convertTimestampToAxisValue(
      period.endAt,
      locale,
      timeZone
    );

    periods.push({
      min: startAtTime,
      max: endAtTime < startAtTime ? 24 : endAtTime,
      time: `${formatedStartAt} - ${formatedEndAt}`,
    });
  });
  return periods;
};

export const mapInsightListDetails = ({
  data,
  labels,
  fleetInfo,
  locale = LanguageLocale.EN,
}: {
  data: Array<Insight>;
  labels: { [index: string]: string };
  fleetInfo: Entity[];
  locale?: string;
}): Array<InsightListDetails> => {
  const details = data
    .reduce((mappedInsights, insight) => {
      const insightDetails = insight?.details;
      if (!insightDetails) {
        return mappedInsights;
      }
      const existingInsightIndex = mappedInsights.findIndex(
        (insight) => insight.id === insightDetails.fleetId
      );
      if (existingInsightIndex < 0) {
        const item = {
          id: insightDetails.fleetId,
          hubsId: insightDetails.hubId,
          organizationsId: insightDetails.organizationId,
          name:
            fleetInfo.find((result) => result.id === insightDetails.fleetId)
              ?.name || DEFAULT_EMPTY_VALUE,
          date: insightDetails.createdAt,
          typeDetails: [
            mapInsightTypeDetails({
              details: insightDetails,
              labels,
              locale,
            }),
          ],
        };
        mappedInsights.push(item);
      } else {
        mappedInsights[existingInsightIndex] = {
          ...mappedInsights[existingInsightIndex],
          date:
            insightDetails.createdAt >=
            mappedInsights[existingInsightIndex].date
              ? insightDetails.createdAt
              : mappedInsights[existingInsightIndex].date,
          typeDetails: [
            ...(mappedInsights[existingInsightIndex]?.typeDetails || []),
            mapInsightTypeDetails({
              details: insightDetails,
              labels,
              locale,
            }),
          ],
        };
      }

      return mappedInsights;
    }, new Array<InsightListDetails>())
    .sort(insightListDefaultSortCompare);
  return details;
};

export const mapInsightTypeDetails = ({
  details,
  labels,
  locale = LanguageLocale.EN,
}: {
  details: InsightDetails;
  labels: { [index: string]: string };
  locale?: string;
}): InsightTypeDetails => {
  const hasRecommendations = hasInsightRecommendations(details.name);
  let name = '';
  let savings: InsightSavings = { value: 0 };
  switch (details.type) {
    case InsightType.ELECTRICITY_COST:
      name = labels['insight:type.electricityCost'];
      if (hasRecommendations) {
        savings = getInsightSavingDetails({ details, locale });
      }
      break;
    case InsightType.BATTERY_HEALTH:
      name = labels['insight:type.batteryHealth'];
      break;
    case InsightType.FUEL_COST_EMISSIONS:
      name = labels['insight:type.fuelCostAndEmissions'];
      break;
  }
  return {
    name,
    type: details.type,
    status: true,
    savings,
    hasRecommendations,
  };
};

export const mapInsightChartUnitType = (type: InsightChartType): string => {
  const unitName = '';
  switch (type) {
    case InsightChartType.DEMAND_CHARGE:
    case InsightChartType.DEMAND_COST:
      return EnergyUnit.kW;
    case InsightChartType.ENERGY_COST:
      return EnergyUnit.kWh;
  }
  return unitName;
};

export const mapCostPatchLegendData = ({
  data,
  locale = LanguageLocale.EN,
  type,
  labels,
}: {
  data?: Array<InsightCostPatch>;
  type: InsightChartType;
  labels: typeof INSIGHT_CHART_MESSAGES;
  locale?: string;
}): Array<{ name: string; value: string }> => {
  const details: { name: string; value: string }[] = [];
  data?.forEach((key, index) => {
    const { costTranslationBundle } = key;
    let bundleKey = '';
    let value = 0;
    let displayValue = undefined;
    let name = '';

    Object.keys(costTranslationBundle.parameters).forEach((key) => {
      bundleKey = key;
      value = costTranslationBundle.parameters[key];
    });
    const formatData = costTranslationBundle?.formatParams?.[bundleKey];

    if (
      typeof formatData === 'object' &&
      Object.hasOwn(formatData, 'currency') &&
      typeof formatData.currency === 'string'
    ) {
      displayValue = `${formatCurrency(
        value,
        locale,
        formatData.currency,
        2
      )}/${mapInsightChartUnitType(type)}`;
      name =
        data.length > 1
          ? `${getInsightChartName(type, labels.tooltipLabels)} ${index + 1}`
          : getInsightChartName(type, labels.tooltipLabels);
      details.push({ name, value: displayValue });
    }
  });
  return details;
};
