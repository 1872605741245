import { useTranslations } from '@brightdrop/localization-client';
import { Role } from '@gm-commercial/profile-model';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import ButtonLink from '@mui/material/Link';
import { createSelector } from '@reduxjs/toolkit';
import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { useAppDispatch } from '~/app/store';
import DelayedContent from '~/common/components/delayedContent/DelayedContent';
import TranslatedLabel from '~/common/components/TranslatedLabel/TranslatedLabel';
import useActingProfile from '~/common/hooks/useActingProfile';
import useProfileWithPermissions from '~/common/hooks/useProfileWithPermissions';
import { setActingProfile } from '~/features/profile/profileSlice';
import {
  selectActingProfileFleet,
  selectActingProfileHub,
  selectActingProfileOrganization,
  selectActingRole,
} from '~/features/profile/profileSlice.selectors';

import useStyles from './ViewAsHeader.styles';

const MESSAGES = {
  'common:header.removeSettings': 'Remove Settings',
  roleList: {
    [`common:roleList.${Role.ORG_MANAGER}`]: 'Account Admin',
    [`common:roleList.${Role.HUB_MANAGER}`]: 'Hub Manager',
    [`common:roleList.${Role.FLEET_MANAGER}`]: 'Fleet Manager',
  },
};

const selectViewAsProfileParams = createSelector(
  selectActingRole,
  selectActingProfileOrganization,
  selectActingProfileHub,
  selectActingProfileFleet,
  (role, organization, hub, fleet) => ({ role, organization, hub, fleet })
);

const SUPPORTED_VIEW_AS_ROLES = [
  Role.ORG_MANAGER,
  Role.HUB_MANAGER,
  Role.FLEET_MANAGER,
];

const ViewAsHeader = (): JSX.Element => {
  const { classes } = useStyles();
  const { translations } = useTranslations(MESSAGES);
  const dispatch = useAppDispatch();
  const viewAsProfile = useSelector(selectViewAsProfileParams);

  const { profile, isLoading, isUpdating } = useProfileWithPermissions();
  const { isActing } = useActingProfile();

  const isProfileInProgress = useMemo(
    () => isLoading || isUpdating,
    [isLoading, isUpdating]
  );
  const actingRole = useMemo(
    () =>
      !!viewAsProfile.role &&
      SUPPORTED_VIEW_AS_ROLES.includes(viewAsProfile.role)
        ? viewAsProfile.role
        : undefined,
    [viewAsProfile.role]
  );

  const actingScope = useMemo(() => {
    if (actingRole) {
      switch (actingRole) {
        case Role.ORG_MANAGER: {
          return viewAsProfile.organization?.name;
        }
        case Role.HUB_MANAGER: {
          return viewAsProfile.hub?.name;
        }
        case Role.FLEET_MANAGER: {
          return viewAsProfile.fleet?.name;
        }
      }
    }
    return undefined;
  }, [viewAsProfile, actingRole]);

  const handleActingProfileToggle = useCallback(() => {
    dispatch(setActingProfile());
  }, [dispatch]);

  return (
    <>
      <DelayedContent
        isLoading={isProfileInProgress}
        data={profile}
        skeleton={<></>}
      >
        {isActing && !isProfileInProgress && (
          <Container className={classes.viewAsContainer} maxWidth={false}>
            <Box className={classes.viewAsMessageContainer}>
              <TranslatedLabel
                dataTestId="view-message"
                translationKey={
                  actingScope
                    ? 'common:viewAs.headerMessage'
                    : 'common:viewAs.headerMessage_noName'
                }
                variables={{
                  role: translations.roleList[
                    `common:roleList.${actingRole}` as keyof typeof translations['roleList']
                  ],
                  entity: actingScope,
                }}
              />
              <ButtonLink
                component="button"
                variant="body2_deprecated"
                className={classes.viewAslink}
                onClick={handleActingProfileToggle}
                data-testid="remove-settings-link"
              >
                {translations['common:header.removeSettings']}
              </ButtonLink>
            </Box>
          </Container>
        )}
      </DelayedContent>
    </>
  );
};

export default ViewAsHeader;
