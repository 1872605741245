import isEqual from 'lodash/isEqual';
import { DateTime } from 'luxon';

import { SearchType } from '~/common/constants/common.constant';
import {
  InspectionsDateRange,
  ReportSeverity,
} from '~/common/models/assetInspection.model';
import {
  getEndOfWeekDvic,
  getStartOfWeek,
} from '~/common/utils/date-time.utils';

import { LONG_TERM_REPORTING_DATE_RANGE_STATE } from '../config/AssetInspections.constant';
import { InspectionsListSession } from '../inspectionsSlice.model';

export const INSPECTION_ID_LENGTH = 8;
export const DATE_LENGTH = 10;

type DateRangeReducerAction = {
  type: InspectionsDateRange;
  timeZone: string;
  currentDate?: string;
};

export function getAssetInspectionsDateRangeConfig(
  _: typeof LONG_TERM_REPORTING_DATE_RANGE_STATE,
  action: DateRangeReducerAction
): typeof LONG_TERM_REPORTING_DATE_RANGE_STATE {
  const currentDay = DateTime.fromJSDate(new Date(action.currentDate as string))
    .setZone(action.timeZone)
    .startOf('day');
  const startOfToday: DateTime = DateTime.now()
    .setZone(action.timeZone)
    .startOf('day');

  // Any date range that includes today should end yesterday
  const endOfYesterday: DateTime = startOfToday
    .setZone(action.timeZone)
    .endOf('day')
    .minus({ days: 1 });

  switch (action.type) {
    case InspectionsDateRange.THIS_DATE: {
      return {
        start: currentDay.toJSDate(),
        end: undefined,
      };
    }
    case InspectionsDateRange.THIS_WEEK: {
      const startOfWeek = getStartOfWeek(currentDay);
      const endOfWeek = getEndOfWeekDvic(currentDay, startOfToday);

      return {
        start: startOfWeek.toJSDate(),
        end: endOfWeek.toJSDate(),
      };
    }
    case InspectionsDateRange.THIS_MONTH: {
      const startOfMonth = currentDay.startOf('month');
      const isCurrentDayThisMonth = isEqual(
        startOfMonth,
        startOfToday.startOf('month')
      );

      const endOfMonth = isCurrentDayThisMonth
        ? endOfYesterday
        : currentDay.endOf('month');

      return {
        start: startOfMonth.toJSDate(),
        end: endOfMonth.toJSDate(),
      };
    }

    case InspectionsDateRange.THIS_YEAR: {
      const startOfYear = currentDay.startOf('year');
      const isCurrentDayThisYear = isEqual(
        startOfYear,
        startOfToday.startOf('year')
      );

      const endOfYear = isCurrentDayThisYear
        ? endOfYesterday
        : currentDay.endOf('year');

      return {
        start: startOfYear.toJSDate(),
        end: endOfYear.toJSDate(),
      };
    }
  }
}

export const getDisplayInspectionId = (uuid: string | null) => {
  if (uuid) {
    return uuid.length > INSPECTION_ID_LENGTH ? uuid.split('-')[0] : uuid;
  }
};

const mapSearchFieldToValidType = (searchType: SearchType) => {
  switch (searchType) {
    case SearchType.ASSET:
      return 'ASSET_NAME';
    case SearchType.VIN:
      return 'ASSET_VIN';
    case SearchType.OPERATOR:
      return 'OPERATOR_NAME';
    default:
      return '';
  }
};

export const createInspectionListRequestParams = (
  date: string,
  page: number,
  pageSize: number,
  sessionConfig?: Partial<InspectionsListSession>
) => {
  const validatedSessionConfig = {
    searchField: '',
    searchValue: '',
    outcome: '',
    hasMajorIssues: '',
    hasMinorIssues: '',
  };
  if (
    sessionConfig?.searchCriteria?.searchType &&
    sessionConfig.searchCriteria.input &&
    sessionConfig.searchCriteria.input.length > 1
  ) {
    validatedSessionConfig.searchField = mapSearchFieldToValidType(
      sessionConfig.searchCriteria.searchType
    );
    validatedSessionConfig.searchValue = sessionConfig.searchCriteria.input;
  }
  if (
    sessionConfig?.filterCriteria?.outcome &&
    sessionConfig?.filterCriteria?.outcome?.length === 1
  ) {
    validatedSessionConfig.outcome = sessionConfig.filterCriteria.outcome[0];
  }
  if (sessionConfig?.filterCriteria?.severity) {
    if (sessionConfig.filterCriteria.severity.includes(ReportSeverity.MAJOR)) {
      validatedSessionConfig.hasMajorIssues = 'true';
    }
    if (sessionConfig.filterCriteria.severity.includes(ReportSeverity.MINOR)) {
      validatedSessionConfig.hasMinorIssues = 'true';
    }
  }
  return {
    date,
    pageSize: String(pageSize),
    page: String(page),
    searchField: validatedSessionConfig.searchField,
    searchValue: validatedSessionConfig.searchValue,
    outcome: validatedSessionConfig.outcome,
    hasMajorIssues: validatedSessionConfig.hasMajorIssues,
    hasMinorIssues: validatedSessionConfig.hasMinorIssues,
  };
};

export const formatInspectionDate = (date?: string) => {
  return date ? date.slice(0, DATE_LENGTH) : '';
};
