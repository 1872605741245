import { Typography, TypographyPropsVariantOverrides } from '@mui/material';
import { type OverridableStringUnion } from '@mui/types';
import type { CSSProperties, ElementType } from 'react';

import i18next from '~/common/configs/i18n.config';

export interface TranslationVariables {
  [key: string]: string | number | undefined;
}

interface FormatLabelProps {
  translationKey: string;
  variables?: TranslationVariables;
  typographyVariant?:
    | OverridableStringUnion<'inherit', TypographyPropsVariantOverrides>
    | undefined;
  style?: CSSProperties;
  className?: string;
  dataTestId?: string;
  component?: ElementType;
}

export const translateText = (
  key: string,
  variables?: TranslationVariables
): string => {
  return i18next.t(key, variables);
};

const TranslatedLabel = ({
  translationKey,
  variables,
  typographyVariant,
  style,
  className,
  dataTestId,
  component = 'span',
}: FormatLabelProps) => {
  return (
    <Typography
      variant={typographyVariant}
      className={className}
      style={style}
      data-testid={dataTestId}
      component={component}
    >
      {i18next.t(translationKey, variables)}
    </Typography>
  );
};

export default TranslatedLabel;
