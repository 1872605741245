import { BDQuery, BDQueryCriteriaType } from '../models/query.model';

export const buildQuery = (queries: BDQuery[]): BDQuery | undefined => {
  if (queries.length === 0) {
    return undefined;
  }
  return buildQueryTree(queries, 0, queries.length - 1);
};

export const buildQueryTree = (
  queries: BDQuery[],
  left: number,
  right: number,
  useAndOperator?: boolean
): BDQuery | undefined => {
  if (left === right) {
    return queries[left];
  } else {
    const mid = Math.floor((left + right) / 2);
    return {
      qualifier: useAndOperator
        ? BDQueryCriteriaType.AND
        : BDQueryCriteriaType.OR,
      leftQuery: buildQueryTree(queries, left, mid, useAndOperator),
      rightQuery: buildQueryTree(queries, mid + 1, right, useAndOperator),
    } as BDQuery;
  }
};
