import { Address } from '@gm-commercial/location-model';
import {
  MUIDataTableColumnState,
  MUISortOptions,
  ToolbarButton,
} from 'mui-datatables';

import {
  ComponentLabels,
  Entity,
  SummaryCounts,
} from '~/common/models/common.model';

import {
  DEFAULT_DATE_FORMAT,
  DEFAULT_EMPTY_VALUE,
  DIAGNOSTIC_SEVERITY_LEVELS,
} from '../../../constants/common.constant';
import {
  ConnectionStatus,
  DiagnosticSeverity,
  TelemetryStatus,
} from '../../../models/asset.model';
import { Fleet } from '../../../models/fleet.model';
import { Hub } from '../../../models/hub.model';
import { AssetCountSummary } from '../../../models/organization.model';
import {
  BDTableData,
  TableRowDownload,
} from '../../../models/table/table.model';
import { format } from '../../../utils/date-time.utils';

const hasDisplayAddress = (
  addr: unknown
): addr is { displayAddress: string } => {
  return (addr as { displayAddress: string }).displayAddress !== undefined;
};

export const getFullAddress = (addr: Address | BDTableData): string => {
  if (addr && typeof addr !== 'string') {
    if (hasDisplayAddress(addr)) {
      return addr.displayAddress;
    }
    const result = [
      addr.address1,
      addr.address2 || '',
      addr.city,
      addr.stateProvinceTerritory,
      addr.postalCode,
    ]
      .filter((part) => !!part && part !== ' ')
      .join(', ')
      .trim();
    return result || DEFAULT_EMPTY_VALUE;
  } else {
    return addr || DEFAULT_EMPTY_VALUE;
  }
};

export const flattenSummaryCounts = <
  T extends { summaryCounts?: SummaryCounts }
>(
  data: T
): T & {
  [k in keyof SummaryCounts]: number;
} => {
  return {
    ...data,
    ...data.summaryCounts,
  };
};

export const getDefaultDownloadOptions = (
  baseFileName: string,
  selectedAssetType?: string,
  filterOptions: {
    useDisplayedColumnsOnly: boolean;
    useDisplayedRowsOnly: boolean;
  } = {
    useDisplayedColumnsOnly: true,
    useDisplayedRowsOnly: true,
  }
): {
  filename: string;
  separator: string;
  filterOptions: {
    useDisplayedColumnsOnly: boolean;
    useDisplayedRowsOnly: boolean;
  };
} => {
  return {
    filename: !selectedAssetType
      ? `${baseFileName.toLocaleUpperCase().replace(/\s/g, '_')}_${format(
          new Date(),
          'MMddyyHHmm'
        )}.csv`
      : `${baseFileName
          .concat('_')
          .concat(selectedAssetType)
          .toLocaleUpperCase()
          .replace(/\s/g, '_')}_${format(new Date(), 'MMddyyHHmm')}.csv`,
    separator: ',',
    filterOptions,
  };
};

const parseObjectData = (
  columns: MUIDataTableColumnState[],
  row: TableRowDownload,
  adapters = {} as {
    [k: string]: (
      value: BDTableData,
      columnLabels?: Exclude<ComponentLabels, string>,
      dateFormat?: string
    ) => string;
  },
  labels = {} as Exclude<ComponentLabels, string>,
  dateFormat = DEFAULT_DATE_FORMAT as string
) => {
  return row.data.map((currentValue: BDTableData, colIdx: number) => {
    if (Object.prototype.hasOwnProperty.call(adapters, columns[colIdx].name)) {
      const adapter = adapters[columns[colIdx].name];
      return adapter(currentValue, labels, dateFormat);
    } else {
      return currentValue;
    }
  });
};

export const customOnDownload =
  (
    adapters = {} as {
      [k: string]: (
        value: BDTableData,
        columnLabels?: Exclude<ComponentLabels, string>,
        dateFormat?: string
      ) => string;
    },
    labels = {} as Exclude<ComponentLabels, string>,
    dateFormat = DEFAULT_DATE_FORMAT as string
  ) =>
  (
    buildHead: (columns: MUIDataTableColumnState[]) => string,
    buildBody: (data: TableRowDownload[]) => string,
    columns: MUIDataTableColumnState[],
    data: TableRowDownload[]
  ): string | boolean => {
    const json = data.reduce(
      (
        accResults: TableRowDownload[],
        row: TableRowDownload,
        rowIdx: number,
        _source: TableRowDownload[]
      ) => {
        const parsedRow = {
          index: rowIdx,
          data: parseObjectData(columns, row, adapters, labels, dateFormat),
        };
        accResults.push(parsedRow);
        return accResults;
      },
      []
    );

    // Reason for \uFEFF...mui-datatables added this link to support for UTF-8 which are for special characters: https://github.com/gregnb/mui-datatables/pull/722#issuecomment-526346440
    return '\uFEFF' + buildHead(columns) + buildBody(json);
  };

export const stringSortCompare = (
  order: MUISortOptions['direction'],
  first: string | undefined,
  second: string | undefined
): number => {
  if (order === 'asc') {
    if (first && second) {
      return first.localeCompare(second);
    } else {
      return first ? -1 : second ? 1 : 0;
    }
  } else {
    if (first && second) {
      return second.localeCompare(first);
    } else {
      return second ? -1 : first ? 1 : 0;
    }
  }
};

export const numberSortCompare = (
  order: MUISortOptions['direction'],
  first: number | undefined,
  second: number | undefined
): number => {
  if ((first || first === 0) && (second || second === 0)) {
    return (first - second) * (order === 'asc' ? 1 : -1);
  }
  if (order === 'asc') {
    return first || first === 0 ? -1 : second || second === 0 ? 1 : 0;
  } else {
    return first || first === 0 ? 1 : second || second === 0 ? -1 : 0;
  }
};

export const entitySortCompare = <T extends Entity>(
  order: MUISortOptions['direction']
) => {
  return (first: { data: T }, second: { data: T }): number => {
    return stringSortCompare(order, first.data?.name, second.data?.name);
  };
};

export const addressSortCompare = (order: MUISortOptions['direction']) => {
  return (first: { data: Address }, second: { data: Address }): number => {
    const firstAddress = getFullAddress(first.data);
    const secondAddress = getFullAddress(second.data);
    return stringSortCompare(
      order,
      firstAddress !== DEFAULT_EMPTY_VALUE ? firstAddress : undefined,
      secondAddress !== DEFAULT_EMPTY_VALUE ? secondAddress : undefined
    );
  };
};

export const telemetrySortCompare = (order: MUISortOptions['direction']) => {
  return (
    ...statuses:
      | { data: TelemetryStatus<string> }[]
      | { data: TelemetryStatus<number> }[]
  ): number => {
    if (statuses.length < 2) {
      // do not sort if not enough statuses to compare
      return 0;
    }

    const first = statuses[0].data?.value;
    const second = statuses[1].data?.value;

    const sameType = <T>(first: T, second: unknown): second is T => {
      return typeof second === typeof first;
    };

    if (first && second) {
      if (typeof first === 'string' && sameType(first, second)) {
        return stringSortCompare(order, first, second);
      } else if (typeof first === 'number' && sameType(first, second)) {
        return numberSortCompare(order, first, second);
      } else {
        // do not sort if status values have different type
        return 0;
      }
    } else {
      if (order === 'asc') {
        return first ? -1 : second ? 1 : 0;
      } else {
        return first ? 1 : second ? -1 : 0;
      }
    }
  };
};

export const mapConnectionStatus = (
  connectionStatus?: ConnectionStatus
): number =>
  connectionStatus === 'ONLINE' ? 100 : connectionStatus === 'OFFLINE' ? 1 : 0;

export const mapDiagnosticSeverity = (severity?: DiagnosticSeverity): number =>
  (severity !== undefined && DIAGNOSTIC_SEVERITY_LEVELS[severity]) || -1;

export const mapChargingStatus = (
  chargingStatus?: TelemetryStatus<boolean>
): number => (chargingStatus?.value ? 1 : 0);

export const mapperSortCompare = <T>(
  map: (value?: T) => number,
  order: MUISortOptions['direction'],
  first?: T,
  second?: T
): number => {
  return numberSortCompare(order, map(first), map(second));
};

export const processTableData = <T extends { summaryCounts?: SummaryCounts }>(
  entities: T[]
): (T & {
  [k in keyof SummaryCounts]: number;
})[] => {
  return entities.map((entity: T) => {
    return {
      ...flattenSummaryCounts(entity),
    };
  });
};

export const processAssetCountSummaryForHubs = (
  rows: Hub[],
  assetCountSummary: AssetCountSummary
): (Hub & { assetCount?: number })[] => {
  const { hubs } = assetCountSummary;
  return rows.map((row: Hub) => {
    let assetCount;
    if (hubs) {
      const hubId = Object.keys(hubs).find((key) => key === row.id);
      assetCount = hubId ? hubs[hubId]?.numberOfAssets : 0;
    }
    return {
      ...row,
      assetCount,
    };
  });
};

export const processAssetCountSummaryForFleets = ({
  rows,
  assetCountSummary,
  hubsId = '',
}: {
  rows: Fleet[];
  assetCountSummary: AssetCountSummary;
  hubsId?: string;
}): (Fleet & { assetCount?: number })[] => {
  const hubs = assetCountSummary.hubs;
  if (hubs) {
    return rows.map((row: Fleet) => {
      const fleets = hubsId
        ? hubs[hubsId]?.fleets
        : row.hub?.id
        ? hubs[row.hub.id]?.fleets
        : undefined;
      let assetCount;
      if (fleets) {
        const fleetId = Object.keys(fleets).find((key) => key === row.id);
        assetCount = fleetId ? fleets[fleetId].numberOfAssets : 0;
      }
      return {
        ...row,
        assetCount,
      };
    });
  }
  return rows;
};

export const getFormattedPhone = (phone: BDTableData): string => {
  if (typeof phone === 'string') {
    const phoneMatch = phone
      .replace(/\D/g, '')
      .match(/^(\+1|1)?(\d{3})(\d{3})(\d{4})$/);

    if (phoneMatch) {
      const countryCode = '+1 ';

      return [
        countryCode,
        '(',
        phoneMatch[2],
        ') ',
        phoneMatch[3],
        '-',
        phoneMatch[4],
      ].join('');
    }
  }
  return DEFAULT_EMPTY_VALUE;
};

export const setDownloadState = (
  list: unknown[],
  filteredCount: number
): ToolbarButton => {
  return list && list.length > 0
    ? filteredCount === -1 || filteredCount > 0
      ? true
      : 'disabled'
    : 'disabled';
};
