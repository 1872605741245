import { DateTime } from 'luxon';

import { BDQuery, BDQueryCriteriaType } from '../models/query.model';

export const createScheduledDateBetweenQuery = (
  field: string,
  range: {
    start: DateTime;
    end?: DateTime;
  }
): BDQuery => {
  return {
    qualifier: BDQueryCriteriaType.BETWEEN,
    field: field,
    values: [
      range.start.toISO(),
      range.end ? range.end.toISO() : range.start.endOf('day').toISO(),
    ],
  };
};
