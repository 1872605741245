import { i18n, TFunction } from 'i18next';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

type MessageModel = string | { [key: string]: MessageModel };

const useTranslations = <T extends { [key: string]: MessageModel }>(
  messages?: T
): {
  translate: TFunction;
  translations: T;
  instance: i18n;
  updateLocale: (locale: string) => void;
} => {
  const { t, i18n } = useTranslation();

  const translateMessages = <T extends { [key: string]: MessageModel }>(
    msgObj: T
  ): T => {
    const translations = Object.assign(msgObj);
    for (const key of Object.keys(translations)) {
      const message = translations[key];
      if (typeof message === 'string') {
        translations[key] = t(key, message);
      } else {
        translations[key] = translateMessages(message);
      }
    }
    return translations;
  };

  const updateLocale = useCallback(
    (locale: string) => {
      i18n.changeLanguage(locale);
    },
    [i18n]
  );

  return messages && typeof messages === 'object'
    ? {
        translations: translateMessages(messages),
        translate: t,
        instance: i18n,
        updateLocale,
      }
    : { translations: {} as T, translate: t, instance: i18n, updateLocale };
};

export default useTranslations;
