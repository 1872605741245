import { createSlice } from '@reduxjs/toolkit';

import { RootState } from '~/app/rootReducer';

import { CS_DASHBOARD_ACTIONS } from './state/csDashboardSlice.actions';
import { INITIAL_CS_DASHBOARD_STATE } from './state/csDashboardSlice.constants';
import {
  CS_DASHBOARD_REDUCER_BUILDERS,
  CS_DASHBOARD_REDUCERS,
} from './state/csDashboardSlice.reducers';

const { reducerSetDashboardSessionConfig } = CS_DASHBOARD_REDUCERS;

export const csDashboardSlice = createSlice({
  name: 'csDashboard',
  initialState: INITIAL_CS_DASHBOARD_STATE,
  reducers: {
    setDashboardSessionConfig: reducerSetDashboardSessionConfig,
  },
  extraReducers: (builder) => {
    CS_DASHBOARD_REDUCER_BUILDERS.buildGetConnectivityStatusSummaryReducer(
      builder
    );
    CS_DASHBOARD_REDUCER_BUILDERS.buildGetDiagnosticsSummaryReducer(builder);
    CS_DASHBOARD_REDUCER_BUILDERS.buildGetRecallSummaryReducer(builder);
    CS_DASHBOARD_REDUCER_BUILDERS.buildOilLifeSummaryReducer(builder);
    CS_DASHBOARD_REDUCER_BUILDERS.buildChargeLevelSummaryReducer(builder);
  },
});

export const {
  getConnectivityStatusSummary,
  getDiagnosticsSummary,
  postRecallSummary,
  getOilLifeSummary,
  getChargeLevelSummary,
} = CS_DASHBOARD_ACTIONS;
export const { setDashboardSessionConfig } = csDashboardSlice.actions;

export const selectCSDashboardState = (
  state: RootState
): RootState['csDashboard'] => state.csDashboard;

export const csDashboardReducer = csDashboardSlice.reducer;
