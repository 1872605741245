import { MUIDataTableChip, MUIDataTableOptions } from 'mui-datatables';

export const DEFAULT_FIRST_PAGE = 0;
export const DEFAULT_ROWS_PER_PAGE = 10;

export const DEFAULT_FILTER_CHIP_PROPS: MUIDataTableChip = {
  color: 'default',
  variant: 'outlined',
  className: 'CustomChipWrapper',
};

export const DEFAULT_DATA_TABLE_OPTIONS: MUIDataTableOptions = {
  download: false,
  filter: false,
  filterType: 'textField',
  pagination: true,
  print: false,
  responsive: 'standard',
  rowsPerPage: DEFAULT_ROWS_PER_PAGE,
  search: false,
  selectableRows: 'none',
  setFilterChipProps: () => DEFAULT_FILTER_CHIP_PROPS,
  viewColumns: false,
  fixedHeader: false,
  rowsPerPageOptions: [10, 25, 50, 100],
};
