import { User } from '@gm-commercial/profile-model';
import {
  createEntityAdapter,
  createSlice,
  EntityState,
  PayloadAction,
} from '@reduxjs/toolkit';
import merge from 'deepmerge';

import {
  API_VERSION_DEFAULTS,
  DEFAULT_API_CONFIG,
} from '~/common/apis/api.constants';
import { RouteParams } from '~/common/configs/route.config';
import {
  ApiResponse,
  BDRequestStatus,
  BDRequestType,
  OperationStatus,
} from '~/common/models/apis/apiResponse.model';
import {
  ApiSafetyComplianceSummary,
  Asset,
  SafetyComplianceSummary,
} from '~/common/models/asset.model';
import {
  AggregateCounts,
  FormPayload,
  ListViewSession,
  SeverityLevel,
} from '~/common/models/common.model';
import { BDAppErrorType, BDError } from '~/common/models/error.model';
import { Fleet, FleetFormFields } from '~/common/models/fleet.model';
import {
  addAcceptLanguageHeader,
  addOrganizationIdHeader,
  hasApiResult,
} from '~/common/utils/apis/api.utils';
import {
  makeDeletePayloadCreator,
  makeGetPayloadCreator,
  makePostPayloadCreator,
  makePutPayloadCreator,
  makeThunk,
} from '~/common/utils/store/thunk.helper';

import { RootState } from '../../app/rootReducer';
import { FleetConfigurationViewType } from './details/widget/fleetConfiguration/FleetConfigurationTabWidget';
import { FleetField, ValidFleetForm } from './form/FleetForm';
import {
  ApiFleetFields,
  mapApiResponseToFleet,
  mapFleetFormToApiRequest,
} from './mappers/fleet.mappers';

export enum FleetRequestType {
  GET_SAFETY_COMPLIANCE_SUMMARY = 'Get Safety Compliance Summary',
}

const fleetsAdapter = createEntityAdapter<Fleet>({
  // redundant, but makes obvious the key used for fetching records
  selectId: (fleet: Fleet) => fleet.id,
});

const fleetSafetyComplianceAdapter = createEntityAdapter<{
  fleetId: string;
  complianceSummary: SafetyComplianceSummary;
}>({
  selectId: (safetyCompliance) => safetyCompliance.fleetId,
});

export enum FleetViewType {
  DETAIL = 'Fleet Detail',
  LIST = 'Fleet List',
  SUBLIST = 'Fleet Sublist',
}
export type FleetConfiguration = Partial<ListViewSession> & {
  currentEditConfigTab?: FleetConfigurationViewType;
  selectedField?: Partial<FleetFormFields>;
  selectedTab?: { [key in FleetConfigurationViewType]?: FleetField };
  isSuccess?: boolean;
};

export interface FleetSettingsSession extends FleetConfiguration {
  isAutoLockOpen?: boolean;
  isDriverSafetySettingsExpanded?: boolean;
  isDetailPropsExpanded?: boolean;
}

export interface FleetListSession
  extends Record<keyof Fleet, string>,
    ListViewSession {}

/**
 * FleetSessionConfigType represent an object where session is scoped by the view and entity id
 *
 * @interface FleetSessionConfigType
 * @property {FleetViewType} key The view that we are storing sessions.
 * @property {string} id The id of the entity you are viewing, E.g.
 */
export interface FleetSessionConfigType {
  [FleetViewType.DETAIL]?: {
    [id: string]: Partial<FleetSettingsSession>;
  };
  [FleetViewType.LIST]?: {
    [id: string]: Partial<FleetListSession> | undefined;
  };
  [FleetViewType.SUBLIST]?: {
    [id: string]: Partial<FleetListSession> | undefined;
  };
}

interface FleetsState {
  operations: {
    [key in BDRequestType | FleetRequestType]?: OperationStatus;
  };
  sessionConfigs: {
    [k in FleetViewType]?: FleetSessionConfigType[k];
  };
  safetyCompliance: EntityState<{
    fleetId: string;
    complianceSummary: SafetyComplianceSummary;
  }>;
}

const initialState = fleetsAdapter.getInitialState<FleetsState>({
  operations: {},
  sessionConfigs: {},
  safetyCompliance: fleetSafetyComplianceAdapter.getInitialState(),
});

export type FleetRouteParams = Required<Pick<RouteParams, 'organizationsId'>> &
  Partial<Pick<RouteParams, 'hubsId' | 'fleetsId'>>;

export type FleetComplianceParams = Required<
  Pick<RouteParams, 'organizationsId' | 'fleetsId'>
>;

export type FleetInsightsParams = Required<Pick<RouteParams, 'fleetsId'>> &
  Pick<RouteParams, 'organizationsId' | 'hubsId'>;

const CONFIG = DEFAULT_API_CONFIG;
const COLD_CHAIN_FLEET_CONFIG = {
  ...DEFAULT_API_CONFIG,
  axiosConfig: {
    ...DEFAULT_API_CONFIG.axiosConfig,
  },
};

export const getFleets = makeThunk(
  'fleets/fetchFleets',
  makeGetPayloadCreator<ApiResponse<Fleet[]>, FleetRouteParams>({
    url: (params: FleetRouteParams) => {
      let endpointUrl = `${globalThis.appConfig.apiBaseUrl}/organizations/${API_VERSION_DEFAULTS.default}/:organizationsId`;
      if (params.hubsId) {
        endpointUrl += '/hubs/:hubsId/fleets';
      } else endpointUrl += '/fleets';
      return endpointUrl;
    },
    axiosOptions: (_, state) =>
      addAcceptLanguageHeader(CONFIG, state.profile.currentLocale),
    argAdapter: ({ organizationsId, hubsId }) => {
      const requestParams = { organizationsId };
      return hubsId
        ? { requestParams: { ...requestParams, hubsId } }
        : { requestParams };
    },
    responseAdapter: (response: any) => ({
      ...response,
      result:
        response && Array.isArray(response.result)
          ? response.result.map((data: ApiFleetFields) =>
              mapApiResponseToFleet(data)
            )
          : [],
    }),
  })
);

export const getFleetDetails = makeThunk(
  'fleets/fetchDetails',
  makeGetPayloadCreator<ApiResponse<Fleet>, Required<FleetRouteParams>>({
    url: `${globalThis.appConfig.apiBaseUrl}/organizations/${API_VERSION_DEFAULTS.coldChainFleetAPI}/:organizationsId/hubs/:hubsId/fleets/:fleetsId`,
    axiosOptions: (_, state) =>
      addAcceptLanguageHeader(
        COLD_CHAIN_FLEET_CONFIG,
        state.profile.currentLocale
      ),
    argAdapter: ({ organizationsId, fleetsId, hubsId }) => ({
      requestParams: { organizationsId, fleetsId, hubsId },
    }),
    responseAdapter: (response: any) => ({
      ...response,
      result: mapApiResponseToFleet(response?.result),
    }),
  })
);

export const getFleetSafetyCompliance = makeThunk(
  'fleets/fetchFleetSafetyCompliance',
  makeGetPayloadCreator<
    ApiResponse<SafetyComplianceSummary>,
    Required<FleetComplianceParams>
  >({
    url: `${globalThis.appConfig.apiBaseUrl}/assets/${API_VERSION_DEFAULTS.default}/views/safetyCompliance`,
    axiosOptions: ({ organizationsId }, state) =>
      addAcceptLanguageHeader(
        addOrganizationIdHeader(CONFIG, organizationsId),
        state.profile.currentLocale
      ),
    argAdapter: (params) => {
      return {
        requestParams: {
          fleetId: params.fleetsId,
        },
      };
    },
    responseAdapter: (response, _, params) => {
      if (
        !!response &&
        hasApiResult<ApiSafetyComplianceSummary>(
          response,
          (result): result is ApiSafetyComplianceSummary =>
            !!result && !!(result as ApiSafetyComplianceSummary).models
        )
      ) {
        return { ...response, result: response.result.models };
      }
      throw new BDError(
        'Unable to parse safety compliance response for fleet',
        {
          data: { response, params },
        }
      );
    },
  })
);

//TODO: remove if summary counts are returned with fleet list, or an endpoint is provided for this purpose (phase 2)
export const getFleetListAssetCounts = makeThunk(
  'fleets/aggregateListAssetCounts',
  makeGetPayloadCreator<
    ApiResponse<AggregateCounts<'assets'>>,
    FleetRouteParams
  >({
    url: `${globalThis.appConfig.apiBaseUrl}/assets/${API_VERSION_DEFAULTS.default}`,
    axiosOptions: (_, state) =>
      addAcceptLanguageHeader(CONFIG, state.profile.currentLocale),
    argAdapter: (routeParams) => {
      return {
        requestParams: {
          organizationId: routeParams.organizationsId || '',
          hubId: routeParams.hubsId,
          fleetId: routeParams.fleetsId,
        },
      };
    },
    responseAdapter: (response: unknown, _, params) => {
      if (!!response && hasApiResult(response)) {
        return {
          ...response,
          result: Array.isArray(response?.result)
            ? response?.result.reduce(
                (fleetAssets: AggregateCounts<'assets'>, asset: Asset) => {
                  if (asset.fleet?.id) {
                    fleetAssets[asset.fleet.id] = {
                      assets: (fleetAssets[asset.fleet.id]?.assets || 0) + 1,
                    };
                  }
                  return fleetAssets;
                },
                {} as AggregateCounts<'assets'>
              )
            : {},
        };
      }
      throw new BDError('Unable to parse assets response for fleet', {
        data: { response, params },
      });
    },
  })
);

//TODO: remove if summary counts are returned with fleet list, or an endpoint is provided for this purpose (phase 2)
export const getFleetListUserCounts = makeThunk(
  'fleets/aggregateListUserCounts',
  makeGetPayloadCreator<
    ApiResponse<AggregateCounts<'users'>>,
    FleetRouteParams
  >({
    url: `${globalThis.appConfig.apiBaseUrl}/users/${API_VERSION_DEFAULTS.default}`,
    axiosOptions: (_, state) =>
      addAcceptLanguageHeader(CONFIG, state.profile.currentLocale),
    argAdapter: (params) => ({
      requestParams: {
        organizationId: params.organizationsId || '',
        hubId: params.hubsId || '',
      },
    }),
    responseAdapter: (response: unknown, _, params) => {
      if (!!response && hasApiResult(response)) {
        return {
          ...response,
          result: Array.isArray(response?.result)
            ? response?.result.reduce(
                (fleetUsers: AggregateCounts<'users'>, user: User) => {
                  if (user.fleet?.id) {
                    fleetUsers[user.fleet.id] = {
                      users: (fleetUsers[user.fleet.id]?.users || 0) + 1,
                    };
                  }
                  return fleetUsers;
                },
                {} as AggregateCounts<'users'>
              )
            : {},
        };
      }
      throw new BDError('Unable to parse users response for fleet', {
        data: { response, params },
      });
    },
  })
);

export const addFleet = makeThunk(
  'fleets/addNewFleet',
  makePostPayloadCreator<
    ApiResponse<Fleet>,
    FormPayload<FleetFormFields, 'organizationsId'>
  >({
    url: () => {
      return `${globalThis.appConfig.apiBaseUrl}/organizations/${API_VERSION_DEFAULTS.fleetAPI}/:organizationsId/fleets`;
    },
    axiosOptions: (_, state) =>
      addAcceptLanguageHeader(CONFIG, state.profile.currentLocale),
    argAdapter: ({ formFields, params }) => {
      return {
        requestParams: {
          organizationsId: params.organizationsId,
        },
        requestBody: mapFleetFormToApiRequest(
          formFields as ValidFleetForm,
          true
        ),
      };
    },
  })
);

export const updateFleet = makeThunk(
  'fleets/updateFleet',
  makePutPayloadCreator<
    ApiResponse<Fleet>,
    {
      form: FormPayload<
        ValidFleetForm,
        'organizationsId' | 'fleetsId',
        'hubsId'
      >;
      existing?: Fleet;
    }
  >({
    url: ({ form, existing }) => {
      if (existing?.hub?.id && form.formFields.hub.id === existing.hub.id) {
        return `${globalThis.appConfig.apiBaseUrl}/organizations/${API_VERSION_DEFAULTS.coldChainFleetAPI}/:organizationsId/hubs/:hubsId/fleets/:fleetsId`;
      }
      return `${globalThis.appConfig.apiBaseUrl}/organizations/${API_VERSION_DEFAULTS.coldChainFleetAPI}/:organizationsId/fleets/:fleetsId`;
    },
    axiosOptions: (_, state) =>
      addAcceptLanguageHeader(
        COLD_CHAIN_FLEET_CONFIG,
        state.profile.currentLocale
      ),
    argAdapter: ({ form, existing }) => {
      const requestParams = {
        organizationsId: form.params.organizationsId,
        fleetsId: form.params.fleetsId,
      };
      const requestBody = mapFleetFormToApiRequest(form.formFields);

      if (existing?.hub?.id && form.formFields.hub.id === existing.hub.id) {
        return {
          requestParams: { ...requestParams, hubsId: existing.hub.id },
          requestBody,
        };
      }
      return { requestParams, requestBody };
    },
    responseAdapter: (response: any) => ({
      ...response,
      result: mapApiResponseToFleet(response?.result),
    }),
  })
);

export const deleteFleet = makeThunk(
  'fleets/deleteFleet',
  makeDeletePayloadCreator<ApiResponse, Required<FleetRouteParams>>({
    url: `${globalThis.appConfig.apiBaseUrl}/organizations/${API_VERSION_DEFAULTS.default}/:organizationsId/hubs/:hubsId/fleets/:fleetsId`,
    argAdapter: ({ organizationsId, hubsId, fleetsId }) => ({
      requestParams: { organizationsId, hubsId, fleetsId },
    }),
  })
);

export const fleetsSlice = createSlice({
  name: 'fleets',
  initialState,
  reducers: {
    setFleetSessionConfig: (
      state,
      action: PayloadAction<FleetSessionConfigType>
    ) => {
      Object.keys(action.payload).forEach((key) => {
        const scope = key as FleetViewType;
        const payload = action.payload[scope] || {};
        Object.keys(payload).forEach((id) => {
          state.sessionConfigs = merge(
            state.sessionConfigs,
            {
              [scope]: {
                [id]: payload[id] && {
                  ...payload[id],
                },
              },
            },
            {
              arrayMerge: (_, sourceArray) => sourceArray,
            }
          );
        });
      });
    },
  },
  extraReducers: (builder) => {
    // fleet list
    builder.addCase(getFleets.pending, (state) => {
      state.operations[BDRequestType.GET_ALL] = {
        status: BDRequestStatus.PENDING,
        errors: [],
      };
      fleetsAdapter.removeMany(state, state.ids); // ensure no records when fetching
    });
    builder.addCase(getFleets.fulfilled, (state, action) => {
      state.operations[BDRequestType.GET_ALL] = {
        status: BDRequestStatus.SUCCEEDED,
      };
      fleetsAdapter.upsertMany(state, action.payload.result);
    });
    builder.addCase(getFleets.rejected, (state, action) => {
      state.operations[BDRequestType.GET_ALL] = {
        status: BDRequestStatus.FAILED,
        errors: [
          {
            type: BDAppErrorType.API,
            ...(action.payload || (action.error as BDError)),
            requestType: BDRequestType.GET_ALL,
            severity: SeverityLevel.ERROR,
          },
        ],
      };
    });

    // fleet details
    builder.addCase(getFleetDetails.pending, (state) => {
      state.operations[BDRequestType.GET_BY_ID] = {
        status: BDRequestStatus.PENDING,
        errors: [],
      };
      state.operations[BDRequestType.GET_USER_SUMMARY] = {
        status: BDRequestStatus.IDLE,
      };
      state.operations[BDRequestType.GET_ASSET_SUMMARY] = {
        status: BDRequestStatus.IDLE,
      };
    });
    builder.addCase(getFleetDetails.fulfilled, (state, action) => {
      state.operations[BDRequestType.GET_BY_ID] = {
        status: BDRequestStatus.SUCCEEDED,
      };
      // performs shallow merge update of existing records, _will overwrite nested object properties_
      fleetsAdapter.upsertOne(state, action.payload.result);
    });
    builder.addCase(getFleetDetails.rejected, (state, action) => {
      state.operations[BDRequestType.GET_BY_ID] = {
        status: BDRequestStatus.FAILED,
        errors: [
          {
            type: BDAppErrorType.API,
            ...(action.payload || (action.error as BDError)),
            requestType: BDRequestType.GET_BY_ID,
          },
        ],
      };
    });

    // fleet safety compliance
    builder.addCase(getFleetSafetyCompliance.pending, (state) => {
      state.operations[FleetRequestType.GET_SAFETY_COMPLIANCE_SUMMARY] = {
        status: BDRequestStatus.PENDING,
        errors: [],
      };
    });
    builder.addCase(getFleetSafetyCompliance.fulfilled, (state, action) => {
      state.operations[FleetRequestType.GET_SAFETY_COMPLIANCE_SUMMARY] = {
        status: BDRequestStatus.SUCCEEDED,
      };
      fleetSafetyComplianceAdapter.upsertOne(state.safetyCompliance, {
        fleetId: action.meta.arg.fleetsId,
        complianceSummary: action.payload.result,
      });
    });
    builder.addCase(getFleetSafetyCompliance.rejected, (state, action) => {
      state.operations[FleetRequestType.GET_SAFETY_COMPLIANCE_SUMMARY] = {
        status: BDRequestStatus.FAILED,
        errors: [
          {
            type: BDAppErrorType.API,
            ...(action.payload || (action.error as BDError)),
          },
        ],
      };
    });

    // fleet list user counts
    //TODO: Remove once API adds usersCount to the fleet stats by ID (Phase 2)
    builder.addCase(getFleetListUserCounts.pending, (state) => {
      state.operations[BDRequestType.GET_USER_COUNTS] = {
        status: BDRequestStatus.PENDING,
        errors: [],
      };
    });
    builder.addCase(getFleetListUserCounts.fulfilled, (state, action) => {
      state.operations[BDRequestType.GET_USER_COUNTS] = {
        status: BDRequestStatus.SUCCEEDED,
      };
      const userCounts = Object.entries(action.payload.result);
      if (userCounts.length > 0) {
        fleetsAdapter.updateMany(
          state,
          userCounts.map((userCount) => ({
            id: userCount[0],
            changes: {
              ...state.entities[userCount[0]],
              summaryCounts: {
                ...state.entities[userCount[0]]?.summaryCounts,
                users: userCount[1].users,
              },
            },
          }))
        );
      }
    });
    builder.addCase(getFleetListUserCounts.rejected, (state, action) => {
      state.operations[BDRequestType.GET_USER_COUNTS] = {
        status: BDRequestStatus.FAILED,
        errors: [
          {
            type: BDAppErrorType.API,
            ...(action.payload || (action.error as BDError)),
            requestType: BDRequestType.GET_USER_COUNTS,
          },
        ],
      };
    });

    // fleet list asset counts
    //TODO: Remove once API adds assetCount to the fleet stats by ID (Phase 2)
    builder.addCase(getFleetListAssetCounts.pending, (state) => {
      state.operations[BDRequestType.GET_ASSET_COUNTS] = {
        status: BDRequestStatus.PENDING,
        errors: [],
      };
    });
    builder.addCase(getFleetListAssetCounts.fulfilled, (state, action) => {
      state.operations[BDRequestType.GET_ASSET_COUNTS] = {
        status: BDRequestStatus.SUCCEEDED,
      };
      const assetCounts = Object.entries(action.payload.result);
      if (assetCounts.length > 0) {
        fleetsAdapter.updateMany(
          state,
          assetCounts.map((assetCount) => ({
            id: assetCount[0],
            changes: {
              ...state.entities[assetCount[0]],
              summaryCounts: {
                ...state.entities[assetCount[0]]?.summaryCounts,
                assets: assetCount[1].assets,
              },
            },
          }))
        );
      }
    });
    builder.addCase(getFleetListAssetCounts.rejected, (state, action) => {
      state.operations[BDRequestType.GET_ASSET_COUNTS] = {
        status: BDRequestStatus.FAILED,
        errors: [
          {
            type: BDAppErrorType.API,
            ...(action.payload || (action.error as BDError)),
            requestType: BDRequestType.GET_ASSET_COUNTS,
          },
        ],
      };
    });

    // add fleet
    builder.addCase(addFleet.pending, (state) => {
      state.operations[BDRequestType.ADD] = {
        status: BDRequestStatus.PENDING,
        errors: [],
      };
    });
    builder.addCase(addFleet.fulfilled, (state) => {
      state.operations[BDRequestType.ADD] = {
        status: BDRequestStatus.SUCCEEDED,
      };
    });
    builder.addCase(addFleet.rejected, (state, action) => {
      state.operations[BDRequestType.ADD] = {
        status: BDRequestStatus.FAILED,
        errors: [
          {
            type: BDAppErrorType.API,
            ...(action.payload || (action.error as BDError)),
            requestType: BDRequestType.ADD,
          },
        ],
      };
    });

    // update fleet
    builder.addCase(updateFleet.pending, (state) => {
      state.operations[BDRequestType.UPDATE] = {
        status: BDRequestStatus.PENDING,
        errors: [],
      };
    });
    builder.addCase(updateFleet.fulfilled, (state, action) => {
      state.operations[BDRequestType.UPDATE] = {
        status: BDRequestStatus.SUCCEEDED,
      };
      fleetsAdapter.upsertOne(state, action.payload.result);
    });
    builder.addCase(updateFleet.rejected, (state, action) => {
      state.operations[BDRequestType.UPDATE] = {
        status: BDRequestStatus.FAILED,
        errors: [
          {
            type: BDAppErrorType.API,
            ...(action.payload || (action.error as BDError)),
            requestType: BDRequestType.UPDATE,
          },
        ],
      };
    });

    // delete fleet
    builder.addCase(deleteFleet.pending, (state) => {
      state.operations[BDRequestType.DELETE] = {
        status: BDRequestStatus.PENDING,
        errors: [],
      };
    });
    builder.addCase(deleteFleet.fulfilled, (state) => {
      state.operations[BDRequestType.DELETE] = {
        status: BDRequestStatus.SUCCEEDED,
      };
    });
    builder.addCase(deleteFleet.rejected, (state, action) => {
      state.operations[BDRequestType.DELETE] = {
        status: BDRequestStatus.FAILED,
        errors: [
          {
            type: BDAppErrorType.API,
            ...(action.payload || (action.error as BDError)),
            requestType: BDRequestType.DELETE,
          },
        ],
      };
    });
  },
});

export const selectFleetsState = (state: RootState): RootState['fleets'] =>
  state.fleets;

export const {
  selectAll: selectAllFleets,
  selectById: selectFleetById,
  selectIds: selectFleetIds,
} = fleetsAdapter.getSelectors<RootState>((state: RootState) => state.fleets);

export const {
  selectAll: selectAllSafetyCompliance,
  selectById: selectAllSafetyComplianceById,
  selectIds: selectAllSafetyComplianceByIds,
} = fleetSafetyComplianceAdapter.getSelectors<RootState>(
  (state: RootState) => state.fleets.safetyCompliance
);
export const { setFleetSessionConfig } = fleetsSlice.actions;
export const fleetsReducer = fleetsSlice.reducer;
