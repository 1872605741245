import { ActionReducerMapBuilder } from '@reduxjs/toolkit';
import merge from 'deepmerge';

import {
  API_VERSION_DEFAULTS,
  DEFAULT_API_CONFIG,
} from '~/common/apis/api.constants';
import { SessionConfigType } from '~/common/constants/common.constant';
import {
  ApiResponse,
  BDRequestStatus,
} from '~/common/models/apis/apiResponse.model';
import {
  ListViewSession,
  PagedResult,
  PagedResultWithErrors,
} from '~/common/models/common.model';
import { BDAppErrorType, BDError } from '~/common/models/error.model';
import {
  RootWorkPlan,
  RootWorkPlanRequestPayload,
  ScheduledDateRange,
} from '~/common/models/workplan.model';
import {
  addAcceptLanguageHeader,
  addOrganizationIdHeader,
  hasApiResult,
} from '~/common/utils/apis/api.utils';
import {
  makePostPayloadCreator,
  makeThunk,
} from '~/common/utils/store/thunk.helper';

import {
  DEFAULT_ITEMS_PER_PAGE,
  FIRST_PAGE,
} from '../../../assets/assetsSlice';
import { getSearchCriteriaRequestKey } from '../../WorkPlanList/WorkPlanList.utils';
import { getWorkPlanScheduledDateDateRangeConfig } from '../utils/WorkPlan.utils';
import {
  WorkPlansListFilterSessionState,
  WorkPlansState,
} from '../workPlansSlice.model';

export type GetRootWorkPlanParams = Partial<
  Pick<ListViewSession, 'page' | 'rowsPerPage'>
> & {
  sessionId: string;
  organizationsId: string;
  hubsId?: string | null;
  fleetsId?: string | null;
  timeZone?: string;
};

const CONFIG = DEFAULT_API_CONFIG;

export const getRootWorkPlans = makeThunk(
  'worksplans/getRootWorkPlans',
  makePostPayloadCreator<
    ApiResponse<PagedResultWithErrors<RootWorkPlan>>,
    GetRootWorkPlanParams
  >({
    url: `${globalThis.appConfig.apiBaseUrl}/dispatch/${API_VERSION_DEFAULTS.dispatchAPI}/workplans/search`,
    axiosOptions: ({ organizationsId }, state) =>
      addAcceptLanguageHeader(
        addOrganizationIdHeader(CONFIG, organizationsId),
        state.profile.currentLocale
      ),
    argAdapter: (args, state) => {
      const { sessionId, organizationsId, hubsId, fleetsId, timeZone } = args;

      const sessionFilterConfig = (state.workPlans?.filterSessions?.[
        SessionConfigType.LIST_VIEW
      ]?.[sessionId] || {}) as WorkPlansListFilterSessionState;

      const { searchCriteria, selectedScheduledDateRange, page, rowsPerPage } =
        sessionFilterConfig;
      const { searchType, input: searchInput } = searchCriteria || {};
      const requestBodyParams: { [k: string]: string | number } = {
        page: (page || FIRST_PAGE) + 1,
        size: rowsPerPage || DEFAULT_ITEMS_PER_PAGE,
      };
      const range = selectedScheduledDateRange
        ? selectedScheduledDateRange
        : ScheduledDateRange.TODAY;
      const { start: scheduledStartDT, end: scheduledEndDT } =
        getWorkPlanScheduledDateDateRangeConfig(range, timeZone);

      requestBodyParams[RootWorkPlanRequestPayload.SCHEDULED_AFTER_DATE] =
        scheduledStartDT.toISODate();
      requestBodyParams[RootWorkPlanRequestPayload.SCHEDULED_BEFORE_DATE] =
        scheduledEndDT.toISODate();

      if (searchInput && searchType) {
        const exisitngSearchRequestKey =
          getSearchCriteriaRequestKey(searchType);
        if (exisitngSearchRequestKey) {
          requestBodyParams[exisitngSearchRequestKey] =
            exisitngSearchRequestKey === RootWorkPlanRequestPayload.STATUS
              ? searchInput.toUpperCase()
              : searchInput;
        }
      }

      if (organizationsId) {
        requestBodyParams[RootWorkPlanRequestPayload.ORG_ID] = organizationsId;
      }

      if (hubsId) {
        requestBodyParams[RootWorkPlanRequestPayload.HUB_ID] = hubsId;
      }

      if (fleetsId) {
        requestBodyParams[RootWorkPlanRequestPayload.FLEET_ID] = fleetsId;
      }

      return {
        requestBody: {
          ...requestBodyParams,
        },
      };
    },
    responseAdapter: (response: unknown | ApiResponse<unknown>) => {
      if (!!response && hasApiResult<PagedResult<RootWorkPlan>>(response)) {
        return response;
      }

      throw new BDError('Unexpected work plan list response', {
        data: response,
      });
    },
  })
);

export function buildGetRootWorkPlansExtraReducers(
  builder: ActionReducerMapBuilder<WorkPlansState>
) {
  builder.addCase(getRootWorkPlans.pending, (state, action) => {
    const { sessionId } = action.meta.arg;

    state.workPlanEntitiesSessions = merge(
      state.workPlanEntitiesSessions,
      {
        [SessionConfigType.LIST_VIEW]: {
          [sessionId]: {
            count: undefined,
            entities: undefined,
            operationStatus: {
              status: BDRequestStatus.PENDING,
              errors: [],
            },
          },
        },
      },
      {
        arrayMerge: (_, sourceArray) => sourceArray,
      }
    );
  });

  builder.addCase(getRootWorkPlans.fulfilled, (state, action) => {
    const { sessionId } = action.meta.arg;

    state.workPlanEntitiesSessions = merge(
      state.workPlanEntitiesSessions,
      {
        [SessionConfigType.LIST_VIEW]: {
          [sessionId]: {
            count: action.payload.result.total_items,
            entities: action.payload.result.items,
            operationStatus: {
              status: BDRequestStatus.SUCCEEDED,
              errors: [],
            },
          },
        },
      },
      {
        arrayMerge: (_, sourceArray) => sourceArray,
      }
    );
  });

  builder.addCase(getRootWorkPlans.rejected, (state, action) => {
    const { sessionId } = action.meta.arg;
    state.workPlanEntitiesSessions = merge(
      state.workPlanEntitiesSessions,
      {
        [SessionConfigType.LIST_VIEW]: {
          [sessionId]: {
            operationStatus: {
              status: BDRequestStatus.FAILED,
              errors: [
                {
                  type: BDAppErrorType.API,
                  ...(action.payload || (action.error as BDError)),
                },
              ],
            },
          },
        },
      },
      {
        arrayMerge: (_, sourceArray) => sourceArray,
      }
    );
  });
}
