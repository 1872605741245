import {
  ApiWorkPlanDetail,
  ApiWorkPlanFormFields,
  RootWorkPlan,
  ValidWorkPlanForm,
} from '~/common/models/workplan.model';

import { CreateWorkPlanParams } from '../state/createWorkPlan';

export const mapApiWorkPlanDetailsResponseToWorkPlan = (
  apiWorkPlan: ApiWorkPlanDetail
): RootWorkPlan => ({
  id: apiWorkPlan.id,
  name: apiWorkPlan.name,
  status: apiWorkPlan.status,
  organizationId: apiWorkPlan.organizationId,
  hubId: apiWorkPlan.hubId,
  fleetId: apiWorkPlan.fleetId,
  scheduledDate: apiWorkPlan.scheduledDate,
  createdAt: apiWorkPlan.createdAt,
  tasks: apiWorkPlan.tasks,
  deleted: apiWorkPlan.deleted,
  operator: apiWorkPlan.operator || null,
  assetId: apiWorkPlan.asset?.id,
  fileName: apiWorkPlan.fileName,
  stopCount: apiWorkPlan.stopCount,
});

export const mapWorkplanFormToApiRequest = (
  workplanForm: ValidWorkPlanForm,
  params: CreateWorkPlanParams
): ApiWorkPlanFormFields => {
  const stopTasks = workplanForm.tasks?.map((task, index) => ({
    stopIndex: index,
    type: task.type,
    address: task.address,
    location: task.location,
    instructions: task.instructions?.map((instruction) => ({
      type: instruction.type,
      value: instruction.value,
    })),
    actions: [],
  }));

  const tasks = [...stopTasks, workplanForm.depotTask];

  return {
    name: workplanForm.name,
    createdByJobId: 'jobId',
    scheduledDate: workplanForm.scheduledDate,
    organizationId: params.organizationsId,
    hubId: params.hubsId,
    fleetId: params.fleetsId,
    tasks,
  };
};
