import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import {
  selectActingProfile,
  selectProfile,
} from '../../features/profile/profileSlice.selectors';

const useActingProfile = (): { isActing: boolean } => {
  const profile = useSelector(selectProfile);
  const actingProfile = useSelector(selectActingProfile);

  const isActing = useMemo(() => {
    if (!profile || !actingProfile) return false;

    return !(JSON.stringify(profile) === JSON.stringify(actingProfile));
  }, [profile, actingProfile]);

  return { isActing };
};

export default useActingProfile;
