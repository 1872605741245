import {
  ActionReducerMapBuilder,
  createAction,
  PayloadAction,
} from '@reduxjs/toolkit';
import merge from 'deepmerge';
import { WritableDraft } from 'immer/dist/types/types-external';

import { SessionConfigType } from '~/common/constants/common.constant';
import { BDRequestStatus } from '~/common/models/apis/apiResponse.model';

import { WorkPlansState } from '../workPlansSlice.model';

export interface UpdateWorkPlanAssignedVehicleOperationState {
  status: BDRequestStatus;
  error: {
    message: string;
    code: string;
  } | null;
}

export type EditWorkPlanAssignedVehicleActivityStatus =
  | 'inactive'
  | 'active'
  | 'updating';

export interface EditWorkPlanAssignedVehicleActivitySessionState {
  status: EditWorkPlanAssignedVehicleActivityStatus;
  workPlanId: string | null;
  selectedVehicle: string | null;
  updateOperation: UpdateWorkPlanAssignedVehicleOperationState;
}

export interface EditWorkPlanAssignedVehicleActivitySessionsConfigState {
  [id: string]: EditWorkPlanAssignedVehicleActivitySessionState;
}

export interface UpdateEditWorkPlanAssignedVehicleActivitySessionPayload {
  [id: string]: EditWorkPlanAssignedVehicleActivitySessionState;
}

const INIT_UPDATE_OPERATION_STATE: UpdateWorkPlanAssignedVehicleOperationState =
  {
    status: BDRequestStatus.IDLE,
    error: null,
  };

const INIT_EDIT_ASSIGNED_VEHICLE_ACTIVITY_SESSION_STATE: EditWorkPlanAssignedVehicleActivitySessionState =
  {
    status: 'inactive',
    workPlanId: null,
    selectedVehicle: null,
    updateOperation: INIT_UPDATE_OPERATION_STATE,
  };

function getEditAssignedVehicleActivitySessionState(
  rootState: WritableDraft<WorkPlansState>,
  sessionId: string
) {
  return rootState?.editAssignedVehicleActivitySessions?.[
    SessionConfigType.LIST_VIEW
  ]?.[sessionId];
}

function updateEditAssignedVehicleActivitySessionState(
  sessionId: string,
  rootState: WritableDraft<WorkPlansState>,
  sessionState: Partial<EditWorkPlanAssignedVehicleActivitySessionState>
) {
  const currentSessionState =
    getEditAssignedVehicleActivitySessionState(rootState, sessionId) ||
    INIT_EDIT_ASSIGNED_VEHICLE_ACTIVITY_SESSION_STATE;

  const nextSessionState = {
    ...currentSessionState,
    ...sessionState,
  };

  rootState.editAssignedVehicleActivitySessions = merge(
    rootState.editAssignedVehicleActivitySessions,
    {
      [SessionConfigType.LIST_VIEW]: {
        [sessionId]: nextSessionState,
      },
    },
    {
      arrayMerge: (_, sourceArray) => sourceArray,
    }
  );
}

/// Reset Session State

type ResetSessionStateActionPayload = {
  sessionId: string;
};

export const resetSessionStateAction =
  createAction<ResetSessionStateActionPayload>(
    'workPlans/editAssignedVehicleActivity/resetSessionState'
  );

export function resetSessionStateReducer(
  state: WritableDraft<WorkPlansState>,
  action: PayloadAction<ResetSessionStateActionPayload>
) {
  updateEditAssignedVehicleActivitySessionState(
    action.payload.sessionId,
    state,
    INIT_EDIT_ASSIGNED_VEHICLE_ACTIVITY_SESSION_STATE
  );
}

/// Reset Update Operation

type ResetUpdateOperationActionPayload = {
  sessionId: string;
};

export const resetUpdateOperationAction =
  createAction<ResetUpdateOperationActionPayload>(
    'workPlans/editAssignedVehicleActivity/resetUpdateOperation'
  );

export function resetUpdateOperationReducer(
  state: WritableDraft<WorkPlansState>,
  action: PayloadAction<ResetUpdateOperationActionPayload>
) {
  updateEditAssignedVehicleActivitySessionState(
    action.payload.sessionId,
    state,
    {
      updateOperation: INIT_UPDATE_OPERATION_STATE,
    }
  );
}

/// Activate Edit Activity

type ActivateEditActivityActionPayload = {
  sessionId: string;
  workPlanId: string;
};

export const activateEditActivityAction =
  createAction<ActivateEditActivityActionPayload>(
    'workPlans/editAssignedVehicleActivity/activateEditActivity'
  );

export function activateEditActivityReducer(
  state: WritableDraft<WorkPlansState>,
  action: PayloadAction<ActivateEditActivityActionPayload>
) {
  updateEditAssignedVehicleActivitySessionState(
    action.payload.sessionId,
    state,
    {
      workPlanId: action.payload.workPlanId,
      status: 'active',
      updateOperation: INIT_UPDATE_OPERATION_STATE,
    }
  );
}

/// Cancel Edit Activity

type CancelEditActivityActionPayload = {
  sessionId: string;
};

export const cancelEditActivityAction =
  createAction<CancelEditActivityActionPayload>(
    'workPlans/editAssignedVehicleActivity/cancelEditActivity'
  );

export function cancelEditActivityReducer(
  state: WritableDraft<WorkPlansState>,
  action: PayloadAction<CancelEditActivityActionPayload>
) {
  updateEditAssignedVehicleActivitySessionState(
    action.payload.sessionId,
    state,
    INIT_EDIT_ASSIGNED_VEHICLE_ACTIVITY_SESSION_STATE
  );
}

/// Set Edit Activity Updating

type SetEditActivityUpdatingActionPayload = {
  sessionId: string;
};

export const setEditActivityUpdatingAction =
  createAction<SetEditActivityUpdatingActionPayload>(
    'workPlans/editAssignedVehicleActivity/setEditActivityUpdating'
  );

export function setEditActivityUpdatingReducer(
  state: WritableDraft<WorkPlansState>,
  action: PayloadAction<SetEditActivityUpdatingActionPayload>
) {
  updateEditAssignedVehicleActivitySessionState(
    action.payload.sessionId,
    state,
    {
      status: 'updating',
      updateOperation: {
        status: BDRequestStatus.PENDING,
        error: null,
      },
    }
  );
}

/// Set Edit Activity Update Succeeded

type SetEditActivityUpdateSucceededActionPayload = {
  sessionId: string;
};

export const setEditActivityUpdateSucceededAction =
  createAction<SetEditActivityUpdateSucceededActionPayload>(
    'workPlans/editAssignedVehicleActivity/setEditActivityUpdateSucceeded'
  );

export function setEditActivityUpdateSucceededReducer(
  state: WritableDraft<WorkPlansState>,
  action: PayloadAction<SetEditActivityUpdateSucceededActionPayload>
) {
  updateEditAssignedVehicleActivitySessionState(
    action.payload.sessionId,
    state,
    {
      status: 'inactive',
      updateOperation: {
        status: BDRequestStatus.SUCCEEDED,
        error: null,
      },
    }
  );
}

/// Set Edit Activity Update Failed

type SetEditActivityUpdateFailedActionPayload = {
  sessionId: string;
  error: { code: string; message: string };
};

export const setEditActivityUpdateFailedAction =
  createAction<SetEditActivityUpdateFailedActionPayload>(
    'workPlans/editAssignedVehicleActivity/setEditActivityUpdateFailed'
  );

export function setEditActivityUpdateFailedReducer(
  state: WritableDraft<WorkPlansState>,
  action: PayloadAction<SetEditActivityUpdateFailedActionPayload>
) {
  updateEditAssignedVehicleActivitySessionState(
    action.payload.sessionId,
    state,
    {
      status: 'inactive',
      updateOperation: {
        status: BDRequestStatus.FAILED,
        error: action.payload.error,
      },
    }
  );
}

/// Build Extra Reducers

export function buildEditWorkPlanAssignedVehicleActivityExtraReducers(
  builder: ActionReducerMapBuilder<WorkPlansState>
) {
  builder.addCase(activateEditActivityAction, activateEditActivityReducer);
  builder.addCase(cancelEditActivityAction, cancelEditActivityReducer);
  builder.addCase(resetSessionStateAction, resetSessionStateReducer);
  builder.addCase(resetUpdateOperationAction, resetUpdateOperationReducer);

  builder.addCase(
    setEditActivityUpdatingAction,
    setEditActivityUpdatingReducer
  );

  builder.addCase(
    setEditActivityUpdateFailedAction,
    setEditActivityUpdateFailedReducer
  );

  builder.addCase(
    setEditActivityUpdateSucceededAction,
    setEditActivityUpdateSucceededReducer
  );
}
