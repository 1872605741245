import { Address } from '@gm-commercial/location-model';
import { DateTime } from 'luxon';

import {
  DEFAULT_EMPTY_VALUE,
  LanguageLocale,
} from '../constants/common.constant';
import { Entity, TimeZoneOption } from '../models/common.model';
import { BDTableData } from '../models/table/table.model';

export const omitFrom = <
  T extends Partial<Record<string, unknown>>,
  K extends [...(keyof T)[]]
>(
  obj: T,
  ...keys: K
): Omit<T, K[number]> => {
  const withoutOmitted = {} as T;
  for (const k in obj) {
    if (!keys.includes(k)) {
      withoutOmitted[k] = obj[k];
    }
  }
  return { ...withoutOmitted };
};

export const pickFrom = <T, K extends [...(keyof T)[]]>(
  obj: T,
  ...keys: K
): Pick<T, K[number]> => {
  return keys.reduce(
    (picked, key) => ({ ...picked, [key]: obj[key] }),
    {} as Pick<T, K[number]>
  );
};

export const pickEntityProps = <T extends Entity>(
  obj: T
): Pick<T, keyof Entity> => {
  return pickFrom(obj, 'id', 'name');
};

export const removeUndefined = <T extends Partial<Record<string, unknown>>>(
  obj: T
): Partial<Record<string, unknown>> => {
  const withoutOmitted = {} as T;
  for (const k in obj) {
    if (obj[k] !== undefined) {
      withoutOmitted[k] = obj[k];
    }
  }
  return { ...withoutOmitted };
};
export const removeEmpty = <T extends Partial<Record<string, unknown>>>(
  obj: T
): Partial<Record<string, unknown>> => {
  const withoutEmpty = {} as T;
  for (const k in obj) {
    if (!!obj[k] || typeof obj[k] === 'number' || typeof obj[k] === 'boolean') {
      withoutEmpty[k] = obj[k];
    }
  }
  return { ...withoutEmpty };
};

export const capitalize = (
  str?: string,
  defaultEmptyValue?: string
): string => {
  return str
    ? str.length > 1
      ? str.charAt(0).toLocaleUpperCase() + str.slice(1).toLocaleLowerCase()
      : str.toLocaleUpperCase()
    : defaultEmptyValue || DEFAULT_EMPTY_VALUE;
};

export const uppercaseLiteral = <T extends string>(
  s?: T
): `${Uppercase<T>}` => {
  return s?.toUpperCase() as `${Uppercase<T>}`;
};

export const lowercaseLiteral = <T extends string>(
  s?: T
): `${Lowercase<T>}` => {
  return s?.toLocaleLowerCase() as `${Lowercase<T>}`;
};

export const useDefault = <T>(input: T, defaultValue: T): T => {
  return (typeof input === 'number' && (input as number) === 0) ||
    (typeof input === 'boolean' && (input as boolean) === false) ||
    input
    ? input
    : defaultValue;
};

export const getDisplayKeys = <T extends Record<string, unknown>>(
  e: T
): Array<keyof T> => {
  return Object.keys(e).filter((k) => isNaN(Number(k)));
};

export const isObject = (
  value: unknown
): value is Record<string | number | symbol, unknown> =>
  typeof value === 'object' && value !== null;

export const notEmpty = <T>(
  value: T
): value is Exclude<T, undefined | null> => {
  if (Array.isArray(value) || typeof value === 'string')
    return value.length !== 0;
  if (isObject(value)) return Object.keys(value).length !== 0;
  return !(value === null || value === undefined);
};

export const getPercentage = (total: number, value: number): number =>
  total ? Math.round((value * 100) / total) : 0;

export const getRandomEnumValue = (enumeration: { [x: string]: unknown }) => {
  const values = getDisplayKeys(enumeration);
  const enumKey = values[Math.floor(Math.random() * values.length)];
  return enumeration[enumKey];
};

export const getRoundedValue = (value: number, decimalPlaces: number): string =>
  value ? value.toFixed(decimalPlaces) : '0';

export const countArrayAttributeValues = (obj?: object) =>
  obj
    ? Object.values(obj).reduce(
        (count, attribute) => count + (attribute?.length || 0),
        0
      )
    : 0;

export const filterByPermanentKeys = (obj?: object, permanentKeys = []) =>
  obj
    ? Object.entries(obj)
        ?.filter(([name]) => !permanentKeys?.find((value) => value === name))
        .reduce((accumulator, [name, value]) => {
          return { ...accumulator, [name]: value };
        }, {})
    : undefined;

export const getTimezone = (
  value: string,
  locale: string = LanguageLocale.EN
): string => {
  const timeZoneName = Intl.DateTimeFormat(locale, {
    timeZone: value,
    timeZoneName: 'long',
  }).formatToParts(new Date());
  const currentDate = DateTime.now();
  const offsetValue = DateTime.fromObject(
    {
      year: currentDate.year,
      month: currentDate.month,
      day: currentDate.day,
    },
    {
      zone: value,
    }
  ).toFormat('ZZ');
  const timeZoneLabelValue = value.replace(/[^a-zA-Z ]/g, ' ');
  return value
    ? ((`(GMT${offsetValue})` +
        ' ' +
        timeZoneName.find((part) => part.type === 'timeZoneName')
          ?.value) as string) +
        ' - ' +
        timeZoneLabelValue
    : DEFAULT_EMPTY_VALUE;
};
export const getAllTimezones = () => {
  let timeZones: string[] | undefined = undefined;
  let updatedTimeZones = [] as TimeZoneOption[];
  if ((Intl as any).supportedValuesOf) {
    // any type wil be removed in Typescript 5
    timeZones = (Intl as any).supportedValuesOf('timeZone') as string[];
    updatedTimeZones = timeZones.map((timeZone) => ({
      value: timeZone,
      label: getTimezone(timeZone),
    }));

    return updatedTimeZones;
  }
  return updatedTimeZones;
};

const hasDisplayAddress = (
  addr: unknown
): addr is { displayAddress: string } => {
  return (addr as { displayAddress: string }).displayAddress !== undefined;
};

export const getFullAddress = (addr: Address | BDTableData): string => {
  if (addr && typeof addr !== 'string') {
    if (hasDisplayAddress(addr)) {
      return addr.displayAddress;
    }
    const result = [
      addr.address1,
      addr.address2 || '',
      addr.city,
      addr.stateProvinceTerritory,
      addr.postalCode,
    ]
      .filter((part) => !!part && part !== ' ')
      .join(', ')
      .trim();

    return result || DEFAULT_EMPTY_VALUE;
  } else {
    return addr || DEFAULT_EMPTY_VALUE;
  }
};
