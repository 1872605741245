import { Role, User } from '@gm-commercial/profile-model';
import { ActionReducerMapBuilder } from '@reduxjs/toolkit';
import merge from 'deepmerge';

import { API_VERSION_DEFAULTS } from '~/common/apis/api.constants';
import { SessionConfigType } from '~/common/constants/common.constant';
import {
  ApiResponse,
  BDRequestStatus,
} from '~/common/models/apis/apiResponse.model';
import { BDAppErrorType, BDError } from '~/common/models/error.model';
import { RootWorkPlanRequestPayload } from '~/common/models/workplan.model';
import { hasApiResult } from '~/common/utils/apis/api.utils';
import {
  makeGetPayloadCreator,
  makeThunk,
} from '~/common/utils/store/thunk.helper';

import { WorkPlansState } from '../workPlansSlice.model';

export type GetOperatorsParams = {
  organizationsId?: string | null;
  hubsId?: string | null;
  fleetsId?: string | null;
  sessionId: string;
};

export const getOperators = makeThunk(
  'worksplans/getOperators',
  makeGetPayloadCreator<ApiResponse<Array<User>>, GetOperatorsParams>({
    url: `${globalThis.appConfig.apiBaseUrl}/users/${API_VERSION_DEFAULTS.default}`,
    argAdapter(args) {
      const { organizationsId, hubsId, fleetsId } = args;

      const requestParams: { [k: string]: string } = {
        role: Role.OPERATOR,
      };

      if (organizationsId) {
        requestParams[RootWorkPlanRequestPayload.ORG_ID] = organizationsId;
      }

      if (hubsId) {
        requestParams[RootWorkPlanRequestPayload.HUB_ID] = hubsId;
      }

      if (fleetsId) {
        requestParams[RootWorkPlanRequestPayload.FLEET_ID] = fleetsId;
      }

      return { requestParams };
    },
    responseAdapter: (response: unknown | ApiResponse<unknown>) => {
      if (!!response && hasApiResult<Array<User>>(response)) {
        return response;
      }

      throw new BDError('Unexpected user list response', {
        data: response,
      });
    },
  })
);

export function buildGetDriversExtraReducers(
  builder: ActionReducerMapBuilder<WorkPlansState>
) {
  builder.addCase(getOperators.pending, (state, action) => {
    const { sessionId } = action.meta.arg;

    state.operatorEntitiesSessions = merge(
      state.operatorEntitiesSessions,
      {
        [SessionConfigType.LIST_VIEW]: {
          [sessionId]: {
            count: undefined,
            entities: undefined,
            operationStatus: {
              status: BDRequestStatus.PENDING,
              errors: [],
            },
          },
        },
      },
      {
        arrayMerge: (_, sourceArray) => sourceArray,
      }
    );
  });

  builder.addCase(getOperators.fulfilled, (state, action) => {
    const { sessionId } = action.meta.arg;

    state.operatorEntitiesSessions = merge(
      state.operatorEntitiesSessions,
      {
        [SessionConfigType.LIST_VIEW]: {
          [sessionId]: {
            count: action.payload.result.length,
            entities: action.payload.result,
            operationStatus: {
              status: BDRequestStatus.SUCCEEDED,
              errors: [],
            },
          },
        },
      },
      {
        arrayMerge: (_, sourceArray) => sourceArray,
      }
    );
  });

  builder.addCase(getOperators.rejected, (state, action) => {
    const { sessionId } = action.meta.arg;
    state.operatorEntitiesSessions = merge(
      state.operatorEntitiesSessions,
      {
        [SessionConfigType.LIST_VIEW]: {
          [sessionId]: {
            operationStatus: {
              status: BDRequestStatus.FAILED,
              errors: [
                {
                  type: BDAppErrorType.API,
                  ...(action.payload || (action.error as BDError)),
                },
              ],
            },
          },
        },
      },
      {
        arrayMerge: (_, sourceArray) => sourceArray,
      }
    );
  });
}
