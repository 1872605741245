import {
  API_VERSION_DEFAULTS,
  BD_FLEET_HEADER_NAME,
  BD_HUB_HEADER_NAME,
  CONTINUATION_TOKEN_HEADER_NAME,
  DEFAULT_API_CONFIG,
  PERIPHERALS_API_CONFIG,
} from '~/common/apis/api.constants';
import { RouteParams } from '~/common/configs/route.config';
import { ApiResponse } from '~/common/models/apis/apiResponse.model';
import {
  FormPayload,
  PagedResult,
  PagedResultWithErrors,
} from '~/common/models/common.model';
import { BDAppErrorType, BDError } from '~/common/models/error.model';
import {
  ApiPeripheralMoveFleetSummary,
  PeripheralListItem,
  PeripheralOnboardStatusApiResponse,
  PeripheralsOnboardApiResponse,
  ValidPeripheralOnboardForm,
} from '~/common/models/peripheral.model';
import {
  addAcceptLanguageHeader,
  addHeader,
  addOrganizationIdHeader,
  hasApiResult,
} from '~/common/utils/apis/api.utils';
import {
  makeGetPayloadCreator,
  makePostPayloadCreator,
  makeThunk,
} from '~/common/utils/store/thunk.helper';

import { AssignPeripheralItem } from '../hooks/useAssignPeripherals';
import {
  DEFAULT_PERIPHERAL_TYPE,
  ERROR_MSG_GET_PERIPHERALS_ITEMS,
  ERROR_MSG_MAPPING_PERIPHERALS,
  ERROR_MSG_UNEXPECTED_ONBOARD_RESPONSE,
  ERROR_MSG_UNEXPECTED_RESPONSE,
} from '../peripherals.constants';
import { PeripheralsSessionViewType } from '../peripheralsSlice.model';
import { buildPeripheralRequestParams } from '../utils/peripheralsQuery.utils';

export enum PeripheralsActionType {
  GET_PERIPHERALS = 'peripherals/getPeripherals',
  ONBOARD_PERIPHERALS = 'peripherals/postPeripheralsOnboard',
  ONBOARD_STATUS_PERIPHERALS = 'peripherals/getOnboardStatus',
  POST_LINK_TO_VEHICLE = 'peripherals/postLinkToVehicle',
  POST_UNLINK_TO_VEHICLE = 'peripherals/postUnlinkToVehicle',
  POST_MOVE_FLEET = 'peripherals/postMoveFleet',
}

export type GetPeripheralsParams = {
  sessionId: string;
  pageIndex: number;
  continuationToken?: string | null;
};

const getPeripherals = makeThunk(
  PeripheralsActionType.GET_PERIPHERALS,
  makeGetPayloadCreator<
    ApiResponse<PagedResultWithErrors<PeripheralListItem>>,
    GetPeripheralsParams
  >({
    url: `${globalThis.appConfig.apiBaseUrl}/peripherals/${API_VERSION_DEFAULTS.peripheralsOnboardingAPI}`,
    axiosOptions: ({ continuationToken }, state) => {
      const commonHeaders = addAcceptLanguageHeader(
        DEFAULT_API_CONFIG,
        state.profile.currentLocale
      );
      return continuationToken
        ? addHeader(
            commonHeaders,
            CONTINUATION_TOKEN_HEADER_NAME,
            continuationToken
          )
        : commonHeaders;
    },
    argAdapter: (args, state) => {
      const { sessionId } = args;
      const session =
        state.peripherals.sessionConfigs[
          PeripheralsSessionViewType.LIST_VIEW
        ]?.[sessionId];
      const { searchCriteria, filterType, peripheralType } = session || {};
      const finalPeripheralType = peripheralType || DEFAULT_PERIPHERAL_TYPE;
      const requestParams = buildPeripheralRequestParams(
        finalPeripheralType,
        searchCriteria,
        filterType
      );
      return { requestParams };
    },
    responseAdapter: (response: unknown | ApiResponse<unknown>) => {
      if (response && hasApiResult<PagedResult<PeripheralListItem>>(response)) {
        const errors = new Array<BDError>();
        const { items, total_items, continuation_token } = response.result;
        const mappedItems =
          items?.reduce((acc: PeripheralListItem[], apiPeripheral) => {
            try {
              acc.push(apiPeripheral);
            } catch (e) {
              errors.push({
                name: ERROR_MSG_GET_PERIPHERALS_ITEMS,
                type: BDAppErrorType.VALIDATION,
                message:
                  e instanceof Error
                    ? e.message
                    : ERROR_MSG_MAPPING_PERIPHERALS,
                data: apiPeripheral,
              });
            }
            return acc;
          }, []) || [];
        const finalResult = {
          ...response,
          result: {
            total_items,
            continuation_token,
            items: mappedItems,
          },
        };
        return errors.length
          ? { ...finalResult, errors: errors.map((e) => BDError.asJson(e)) }
          : finalResult;
      }
      throw new BDError(ERROR_MSG_UNEXPECTED_RESPONSE, { data: response });
    },
  })
);

export type PeripheralsOnboardStatusParams = {
  id: string;
  organizationId: string;
};

const postPeripheralsOnboard = makeThunk(
  PeripheralsActionType.ONBOARD_PERIPHERALS,
  makePostPayloadCreator<
    ApiResponse<PeripheralsOnboardApiResponse>,
    FormPayload<ValidPeripheralOnboardForm, 'organizationsId'>
  >({
    url: `${globalThis.appConfig.apiBaseUrl}/peripherals/${API_VERSION_DEFAULTS.peripheralsOnboardingAPI}/onboard`,
    axiosOptions: (FormPayload, state) =>
      addAcceptLanguageHeader(
        addOrganizationIdHeader(
          PERIPHERALS_API_CONFIG,
          FormPayload.formFields.organizationsId
        ),
        state.profile.currentLocale
      ),
    argAdapter: ({ formFields }) => ({
      requestBody: { file: formFields.csvFile },
      requestParams: {
        organizationId: formFields.organizationsId,
        type: formFields.peripheralType,
      },
    }),
    responseAdapter: (response: any) => {
      if (response) {
        return {
          ...response,
          result: response.result,
        };
      }
      throw new BDError(ERROR_MSG_UNEXPECTED_ONBOARD_RESPONSE, {
        data: response,
      });
    },
  })
);

const CONFIG = DEFAULT_API_CONFIG;

const getPeripheralsOnboardStatus = makeThunk(
  PeripheralsActionType.ONBOARD_STATUS_PERIPHERALS,
  makeGetPayloadCreator<
    ApiResponse<PeripheralOnboardStatusApiResponse>,
    PeripheralsOnboardStatusParams
  >({
    url: `${globalThis.appConfig.apiBaseUrl}/peripherals/${API_VERSION_DEFAULTS.peripheralsOnboardingAPI}/batch-statuses/:id`,
    axiosOptions: ({ organizationId }, state) =>
      addAcceptLanguageHeader(
        addOrganizationIdHeader(CONFIG, organizationId),
        state.profile.currentLocale
      ),
    argAdapter: ({ id }) => {
      return {
        requestParams: {
          id,
        },
      };
    },
    responseAdapter: (response: any) => {
      if (response) {
        return {
          ...response,
          result: response.result,
        };
      }
      throw new BDError(ERROR_MSG_UNEXPECTED_ONBOARD_RESPONSE, {
        data: response,
      });
    },
  })
);

type LinkParams = Pick<
  RouteParams,
  'organizationsId' | 'hubsId' | 'fleetsId'
> & {
  id: string;
  vin: string;
};

const postLinkVehicle = makeThunk(
  PeripheralsActionType.POST_LINK_TO_VEHICLE,
  makePostPayloadCreator<ApiResponse, LinkParams>({
    url: ({ id }) =>
      `${globalThis.appConfig.apiBaseUrl}/peripherals/${API_VERSION_DEFAULTS.peripheralsAPI}/${id}/link-vehicle`,
    axiosOptions: ({ organizationsId, hubsId, fleetsId }, state) =>
      addAcceptLanguageHeader(
        addOrganizationIdHeader(
          addHeader(
            addHeader(CONFIG, BD_FLEET_HEADER_NAME, fleetsId),
            BD_HUB_HEADER_NAME,
            hubsId
          ),
          organizationsId
        ),
        state.profile.currentLocale
      ),
    argAdapter: ({ vin }) => {
      return {
        requestBody: {
          vin,
        },
      };
    },
  })
);

type UnlinkParams = Pick<
  RouteParams,
  'organizationsId' | 'hubsId' | 'fleetsId'
> & {
  id: string;
  sessionId: string;
};

const postUnlinkVehicle = makeThunk(
  PeripheralsActionType.POST_UNLINK_TO_VEHICLE,
  makePostPayloadCreator<ApiResponse, UnlinkParams>({
    url: `${globalThis.appConfig.apiBaseUrl}/peripherals/${API_VERSION_DEFAULTS.peripheralsUnlinkAPI}/:id/unlink-vehicle`,
    axiosOptions: ({ organizationsId, hubsId, fleetsId }, state) =>
      addAcceptLanguageHeader(
        addOrganizationIdHeader(
          addHeader(
            addHeader(CONFIG, BD_FLEET_HEADER_NAME, fleetsId),
            BD_HUB_HEADER_NAME,
            hubsId
          ),
          organizationsId
        ),
        state.profile.currentLocale
      ),
    argAdapter: ({ id }) => {
      return {
        requestParams: {
          id,
        },
      };
    },
  })
);

type MoveFleetPayload = Pick<RouteParams, 'organizationsId'> & {
  peripherals: AssignPeripheralItem[];
  sessionId: string;
  fleetId?: string;
};

export const postMoveFleet = makeThunk(
  PeripheralsActionType.POST_MOVE_FLEET,
  makePostPayloadCreator<
    ApiResponse<ApiPeripheralMoveFleetSummary>,
    MoveFleetPayload
  >({
    url: `${globalThis.appConfig.apiBaseUrl}/peripherals/${API_VERSION_DEFAULTS.peripheralsMoveAPI}/move-fleet`,
    axiosOptions: ({ organizationsId }, state) =>
      addAcceptLanguageHeader(
        addOrganizationIdHeader(CONFIG, organizationsId),
        state.profile.currentLocale
      ),
    argAdapter: ({ peripherals, fleetId }) => ({
      requestBody: {
        destination: {
          fleetId,
        },
        peripheralIds: peripherals.map((peripheral) => peripheral.id),
      },
    }),
    responseAdapter: (response: unknown | ApiResponse<unknown>) => {
      if (!!response && hasApiResult<ApiPeripheralMoveFleetSummary>(response)) {
        return {
          ...response,
          result: response.result,
        };
      }
      throw new BDError('Unexpected Move Fleet response', {
        data: response,
      });
    },
  })
);
export const PERIPHERALS_ACTIONS = {
  getPeripherals,
  postPeripheralsOnboard,
  getPeripheralsOnboardStatus,
  postLinkVehicle,
  postUnlinkVehicle,
  postMoveFleet,
};
