import { ReactElement, ReactNode } from 'react';

import vehicleDefaultIcon from '~/assets/icons/new/vehicleDefault.svg';

import ChargingIcon from '../../../assets/icons/battery/charging.svg?react';
import ChargeBattery from '../../../assets/icons/battery/chargingBattery.svg?react';
import ChargeCompleteIcon from '../../../assets/icons/battery/check.svg?react';
import WaitingToChargeIcon from '../../../assets/icons/battery/clock.svg?react';
import HighBattery from '../../../assets/icons/battery/highBattery.svg?react';
import InterruptedIcon from '../../../assets/icons/battery/interrupted.svg?react';
import LowBattery from '../../../assets/icons/battery/lowBattery.svg?react';
import MediumBattery from '../../../assets/icons/battery/mediumBattery.svg?react';
import NotPluggedInIcon from '../../../assets/icons/battery/notPluggedIn.svg?react';
import UnknownBattery from '../../../assets/icons/battery/unknownBattery.svg?react';
import TemperatureCriticalIcon from '../../../assets/icons/temperatureCritical.svg?react';
import TemperatureImpairedIcon from '../../../assets/icons/temperatureHigh.svg?react';
import BrakeFluidIcon from '../../../assets/icons/utils/brakeFluid.svg?react';
import SevereFaultIcon from '../../../assets/icons/utils/severeFaultIcon.svg?react';
import FlatTireIcon from '../../../assets/icons/utils/tireCriticalIcon.svg?react';
import LowTireIcon from '../../../assets/icons/utils/tireImpairedIcon.svg?react';
import WindowFluidIcon from '../../../assets/icons/utils/windowFluid.svg?react';
import zevoThumbnailPng from '../../../assets/icons/zevoThumbnail.png';
import {
  AssetExceptionStatus,
  AssetProductModel,
  BatteryRange,
} from '../../models/asset.model';
import { BatteryChargingStatus } from '../../models/asset-report.model';

export const EXCEPTION_ICONS = {
  [AssetExceptionStatus.LOW_WASHER_FLUID]: WindowFluidIcon,
  [AssetExceptionStatus.LOW_BRAKE_FLUID]: BrakeFluidIcon,
  [AssetExceptionStatus.LOW_TIRE]: LowTireIcon,
  [AssetExceptionStatus.FLAT_TIRE]: FlatTireIcon,
  [AssetExceptionStatus.TEMP_CRITICAL]: TemperatureCriticalIcon,
  [AssetExceptionStatus.TEMP_IMPAIRED]: TemperatureImpairedIcon,
  [AssetExceptionStatus.SEVERE_FAULT]: SevereFaultIcon,
  [AssetExceptionStatus.IMPAIRED_FAULT]: SevereFaultIcon,
};
export const getAssetIconByModel = (model?: string): ReactElement => {
  switch (model) {
    case AssetProductModel.ZEVO:
      return (
        <img
          src={zevoThumbnailPng}
          data-testid={`${AssetProductModel.ZEVO}-image`}
        />
      );
    default:
      return (
        <img
          src={vehicleDefaultIcon}
          data-testid="vehicle-default-image"
          width="80%"
          height="80%"
          style={{ margin: 'auto' }}
        />
      );
  }
};

export const getBatteryIcon = ({
  isCharging,
  range,
  value,
}: {
  isCharging?: boolean;
  range?: BatteryRange;
  value?: number;
}) => {
  if (isCharging) {
    return <ChargeBattery data-testid="chargeBatteryIcon" />;
  }
  if (!range) {
    return <UnknownBattery data-testid="unknownBatteryIcon" />;
  }
  switch (range) {
    case BatteryRange.LOW:
      return value === 0 ? (
        <UnknownBattery
          className="zeroBattery"
          data-testid="unknownBatteryIcon"
        />
      ) : (
        <LowBattery data-testid="lowBatteryIcon" />
      );
    case BatteryRange.HIGH:
      return <HighBattery data-testid="highBatteryIcon" />;
    case BatteryRange.MEDIUM:
      return <MediumBattery data-testid="mediumBatteryIcon" />;
    case BatteryRange.UNKNOWN:
      return <UnknownBattery data-testid="unknownBatteryIcon" />;
    default:
      return <UnknownBattery data-testid="unknownBatteryIcon" />;
  }
};

export const CHARGING_STATUS_ICONS: {
  [key in BatteryChargingStatus]?: ReactNode;
} = {
  [BatteryChargingStatus.COMPLETED]: <ChargeCompleteIcon />,
  [BatteryChargingStatus.CHARGING]: (
    <ChargingIcon className={`${BatteryChargingStatus.CHARGING}Icon`} />
  ),
  [BatteryChargingStatus.NOT_CHARGING]: <NotPluggedInIcon />,
  [BatteryChargingStatus.WAITING]: <WaitingToChargeIcon />,
  [BatteryChargingStatus.INTERRUPTED]: (
    <InterruptedIcon className={`${BatteryChargingStatus.INTERRUPTED}Icon`} />
  ),
  [BatteryChargingStatus.NOT_PLUGGED_IN]: <NotPluggedInIcon />,
  [BatteryChargingStatus.NO_DATA]: '',
  [BatteryChargingStatus.UNKNOWN]: '',
};
