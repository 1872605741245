import { RouteParams } from '~/common/configs/route.config';
import {
  AssetInspectionDetail,
  AssetInspectionsOverviewResultType,
  AssetInspectionsSummary,
  ReportOutcome,
  ReportSeverity,
} from '~/common/models/assetInspection.model';
import {
  ListViewSession,
  OperationSession,
  SearchCriteria,
  SkippableStateUpdate,
} from '~/common/models/common.model';

export enum InspectionsSessionViewType {
  INSPECTION_LIST = 'Inspections List',
  INSPECTION_DETAILS = 'Inspections Details',
  INSPECTION_SUMMARY = 'Inspections Summary',
  INSPECTION_LONG_TERM_REPORTING = 'Inspections Long Term Reporting',
}

export enum InspectionsActionType {
  GET_INSPECTION_SUMMARY = 'inspections/getInspectionSummary',
  GET_INSPECTION_DETAILS = 'inspections/getInspectionDetails',
  GET_INSPECTION_LIST = 'inspections/getInspectionList',
  GET_INSPECTION_LONG_TERM_REPORT = 'inspections/getInspectionLongTermReport',
}

export type InspectionsRouteParams = Required<
  Pick<RouteParams, 'organizationsId' | 'hubsId' | 'fleetsId'>
> & {
  sessionId: string;
};

export type InspectionsSummaryParams = InspectionsRouteParams & {
  date: string;
};

export type InspectionsDetailsParams = InspectionsRouteParams & {
  inspectionsId: string;
};
export type InspectionsLongTermsReportingParams = InspectionsRouteParams & {
  issueType: string;
  period: string;
  periodDate: string;
  targetType: string;
};

export type InspectionsListParams = InspectionsRouteParams &
  SkippableStateUpdate & {
    date: string;
    pageSize: number;
    page: number;
  };

export interface InspectionsListFilterCriteria {
  outcome?: ReportOutcome[];
  severity?: ReportSeverity[];
}

export interface InspectionsListSession
  extends ListViewSession,
    OperationSession {
  filterCriteria: InspectionsListFilterCriteria;
  overviewTab: AssetInspectionsOverviewResultType;
  filterModalOpen?: boolean;
  summaryOverview?: AssetInspectionsSummary;
  currentDate?: string;
  searchCriteria: SearchCriteria;
}

export interface InspectionsSummarySession extends OperationSession {
  summaryOverview?: AssetInspectionsSummary;
}

export interface InspectionsDetailsSession extends OperationSession {
  report?: AssetInspectionDetail;
}

export type InspectionsSessionConfig = {
  [InspectionsSessionViewType.INSPECTION_LIST]?: {
    [key: string]: Partial<InspectionsListSession>;
  };
  [InspectionsSessionViewType.INSPECTION_DETAILS]?: {
    [key: string]: Partial<InspectionsDetailsSession>;
  };
  [InspectionsSessionViewType.INSPECTION_SUMMARY]?: {
    [key: string]: Partial<InspectionsSummarySession>;
  };
  [InspectionsSessionViewType.INSPECTION_LONG_TERM_REPORTING]?: {
    [key: string]: Partial<OperationSession>;
  };
};

export interface InspectionsState {
  sessionConfigs: InspectionsSessionConfig;
}
