import { ActionReducerMapBuilder } from '@reduxjs/toolkit';
import merge from 'deepmerge';

import {
  API_VERSION_DEFAULTS,
  BD_FLEET_HEADER_NAME,
  BD_HUB_HEADER_NAME,
  DEFAULT_API_CONFIG,
} from '~/common/apis/api.constants';
import { SessionConfigType } from '~/common/constants/common.constant';
import {
  ApiResponse,
  BDRequestStatus,
} from '~/common/models/apis/apiResponse.model';
import { BDAppErrorType, BDError } from '~/common/models/error.model';
import { RootWorkPlan } from '~/common/models/workplan.model';
import {
  addAcceptLanguageHeader,
  addHeader,
  addOrganizationIdHeader,
  hasApiResult,
} from '~/common/utils/apis/api.utils';
import {
  makeGetPayloadCreator,
  makeThunk,
} from '~/common/utils/store/thunk.helper';

import { mapApiWorkPlanDetailsResponseToWorkPlan } from '../mappers/WorkPlan.mappers.ts';
import { WorkPlansState } from '../workPlansSlice.model';

export type GetWorkPlansDetailsParams = {
  organizationsId: string;
  hubsId: string;
  fleetsId: string;
  sessionId: string;
  workplanId?: string | null;
};

const CONFIG = DEFAULT_API_CONFIG;

export const getWorkPlansDetails = makeThunk(
  'workplans/getWorkPlansDetails',
  makeGetPayloadCreator<ApiResponse<RootWorkPlan>, GetWorkPlansDetailsParams>({
    url: `${globalThis.appConfig.apiBaseUrl}/dispatch/${API_VERSION_DEFAULTS.dispatchAPI}/workplans/:workplanId`,
    axiosOptions: ({ organizationsId, hubsId, fleetsId }, state) =>
      addAcceptLanguageHeader(
        addOrganizationIdHeader(
          addHeader(
            addHeader(CONFIG, BD_FLEET_HEADER_NAME, fleetsId),
            BD_HUB_HEADER_NAME,
            hubsId
          ),
          organizationsId
        ),
        state.profile.currentLocale
      ),
    argAdapter: ({ workplanId }) => {
      return {
        requestParams: {
          workplanId,
        },
      };
    },
    responseAdapter: (response: unknown | ApiResponse<unknown>) => {
      if (!!response && hasApiResult<RootWorkPlan>(response)) {
        return {
          ...response,
          result: mapApiWorkPlanDetailsResponseToWorkPlan(response?.result),
        };
      }
      throw new BDError('Unexpected Root Work Detail response', {
        data: response,
      });
    },
  })
);

export function buildGetWorkPlansDetailsExtraReducers(
  builder: ActionReducerMapBuilder<WorkPlansState>
) {
  builder.addCase(getWorkPlansDetails.pending, (state, action) => {
    const { sessionId } = action.meta.arg;
    if (sessionId) {
      state.workPlanEntitySession = merge(
        state.workPlanEntitySession,
        {
          [SessionConfigType.DETAIL_VIEW]: {
            [sessionId]: {
              operationStatus: {
                status: BDRequestStatus.PENDING,
                errors: [],
              },
            },
          },
        },
        {
          arrayMerge: (_, sourceArray) => sourceArray,
        }
      );
    }
  });
  builder.addCase(getWorkPlansDetails.fulfilled, (state, action) => {
    const { sessionId } = action.meta.arg;
    if (sessionId) {
      state.workPlanEntitySession = merge(
        state.workPlanEntitySession,
        {
          [SessionConfigType.DETAIL_VIEW]: {
            [sessionId]: {
              detailsSummary: action.payload.result,
              operationStatus: {
                status: BDRequestStatus.SUCCEEDED,
                errors: [],
              },
            },
          },
        },
        {
          arrayMerge: (_, sourceArray) => sourceArray,
        }
      );
    }
  });
  builder.addCase(getWorkPlansDetails.rejected, (state, action) => {
    const { sessionId } = action.meta.arg;
    if (sessionId) {
      state.workPlanEntitySession = merge(
        state.workPlanEntitySession,
        {
          [SessionConfigType.DETAIL_VIEW]: {
            [sessionId]: {
              operationStatus: {
                status: BDRequestStatus.FAILED,
                errors: [
                  {
                    type: BDAppErrorType.API,
                    ...(action.payload || (action.error as BDError)),
                  },
                ],
              },
            },
          },
        },
        {
          arrayMerge: (_, sourceArray) => sourceArray,
        }
      );
    }
  });
}
