import {
  createEntityAdapter,
  createSlice,
  EntityState,
  PayloadAction,
} from '@reduxjs/toolkit';
import merge from 'deepmerge';
import { PURGE } from 'redux-persist';

import { RootState } from '~/app/rootReducer';
import {
  ALERTS_API_CONFIG,
  API_VERSION_DEFAULTS,
} from '~/common/apis/api.constants';
import { RouteParams } from '~/common/configs/route.config';
import { DEFAULT_SESSION_CONFIG_KEY } from '~/common/constants/common.constant';
import {
  AlertCardAssetAlerts,
  AlertDetail,
  AlertEvent,
  AlertQueryFieldType,
  AlertRead,
  AlertSubscription,
  AlertUnread,
  ApiAlertDetail,
  ApiAlertEntity,
  AssetAlert,
  AvailableSubscription,
  Subscription,
  SubscriptionType,
} from '~/common/models/alert.model';
import {
  ApiResponse,
  BDRequestStatus,
  BDRequestType,
  OperationStatus,
} from '~/common/models/apis/apiResponse.model';
import { ApiAssetType } from '~/common/models/asset.model';
import {
  DateRange as AlertDateRange,
  ListViewSession,
  PagedResult,
  PagedResultWithErrors,
  SearchCriteria,
  SeverityLevel,
  SkippableStateUpdate,
} from '~/common/models/common.model';
import { BDAppErrorType, BDError } from '~/common/models/error.model';
import {
  FeatureFlagValueType,
  TRACE_SUPPRESSOR_FLAG_NAME,
} from '~/common/models/featureFlags.model';
import { BDQuery } from '~/common/models/query.model';
import {
  addAcceptLanguageHeader,
  hasApiResult,
} from '~/common/utils/apis/api.utils';
import {
  makeGetPayloadCreator,
  makePostPayloadCreator,
  makePutPayloadCreator,
  makeThunk,
} from '~/common/utils/store/thunk.helper';

import { selectFeatureFlagById } from '../featureFlags/featureFlagsSlice';
import {
  mapAlertApiEntityToAlertHistoryDetail,
  mapAlertApiToAlertDetail,
  mapAlertUnreadApiResponseToAlertUnread,
} from './mappers/alert.mappers';
import {
  createAlertQuery,
  createAlertsEntitiesQuery,
} from './utils/alerts.utils';

export const DEFAULT_ALERT_SORT: AlertSort = {
  properties: [AlertQueryFieldType.TIMESTAMP],
  direction: 'DESC',
};

export enum AlertSessionViewType {
  FEED = 'Feed List',
  SUBSCRIPTION = 'Subscription List',
  DASHBOARD = 'Dashboard List',
  MAP_DETAILS = 'Map Details',
  ASSET_DETAILS = 'Asset Details',
  HISTORY = 'History List',
}

export enum AlertRequestType {
  GET_ALERT_UNREAD = 'Get Alert Unread',
  PUT_ALERT_UNREAD = 'Put Alert Unread',
  POST_ALERT_UNREAD = 'Post Alert Unread',
  POST_ALERT_READ = 'Post Alert Read',
  GET_SUBSCRIPTIONS = 'Get User Subscriptions',
  POST_SUBSCRIPTIONS = 'Post User Subscription',
  PUT_SUBSCRIPTIONS = 'PUT User Subscription',
  GET_SUBSCRIPTION_TYPES = 'Get User Subscription Types',
  GET_AVAILABLE_SUBSCRIPTIONS = 'Merged GET_SUBSCRIPTIONS and GET_SUBSCRIPTION_TYPES',
}

export interface AlertSort {
  direction: 'ASC' | 'DESC';
  properties: Array<string>;
}

export interface AlertsPayload {
  page: number;
  size: number;
  sort?: Array<AlertSort>;
  token?: string;
  direct?: boolean;
  query?: BDQuery;
}

export interface AlertsPutUnreadRequest {
  lastUnread: string;
}

export interface AlertHistorySession
  extends Record<keyof AlertDetail, string>,
    ListViewSession {}
export interface AlertSubscriptionSession
  extends Record<keyof AlertSubscription, string>,
    ListViewSession {}

export interface AlertListFilterSession {
  selectedAssetTypes?: ApiAssetType[];
  selectedAlertTypes?: AlertEvent[];
  selectedDateRange?: AlertDateRange;
  searchCriteria?: SearchCriteria;
}

export type AlertDetailsState = Partial<ListViewSession> & {
  operationStatus: OperationStatus;
  assetAlerts?: AlertCardAssetAlerts[];
};

export type AlertsState = Partial<ListViewSession> & {
  operationStatus: OperationStatus;
  alerts?: AssetAlert[];
};

export type AlertsEntitiesState = Partial<ListViewSession> & {
  operationStatus: OperationStatus;
  entities?: (AlertCardAssetAlerts & { alertsState?: AlertsState })[];
};

export type AlertParams = Partial<ListViewSession> & {
  viewType: AlertSessionViewType;
  sessionId: string;
} & Pick<RouteParams, 'organizationsId' | 'hubsId' | 'fleetsId' | 'assetsId'>;

export type DetailsState = {
  [key: string]: {
    [key: string]: AlertDetailsState;
  };
};

export type AlertsEntitiesSessionState = {
  [key: string]: {
    [key: string]: AlertsEntitiesState;
  };
};

export type AlertsSessionState = {
  [key: string]: {
    [key: string]: {
      [key: string]: AlertsState;
    };
  };
};

export type AlertsFiltersSessionState = {
  [key in AlertSessionViewType]?: {
    [key: string]: AlertListFilterSession;
  };
};

export interface AlertSessionConfigType {
  [AlertSessionViewType.FEED]?: {
    [id: string]: Partial<ListViewSession>;
  };
  [AlertSessionViewType.SUBSCRIPTION]?: {
    [id: string]: Partial<AlertHistorySession>;
  };
  [AlertSessionViewType.DASHBOARD]?: {
    [id: string]: Partial<AlertDetailsState>;
  };
  [AlertSessionViewType.MAP_DETAILS]?: {
    [id: string]: Partial<AlertDetailsState>;
  };
  [AlertSessionViewType.ASSET_DETAILS]?: {
    [id: string]: Partial<AlertDetailsState>;
  };
  [AlertSessionViewType.HISTORY]?: {
    [id: string]: Partial<AlertDetailsState>;
  };
}

export const DEFAULT_MAX_ALERTS_REQUEST_SIZE = 200;
export const DEFAULT_ALERTS_ROW_PER_PAGE = 20;
export const DEFAULT_ALERTS_DATE_RANGE = AlertDateRange.TODAY;

interface AlertState {
  entitiesSessions: AlertsEntitiesSessionState;
  alertsSessions: AlertsSessionState;
  filterSessions: AlertsFiltersSessionState;
  unread: AlertUnread;
  subscriptions: EntityState<Subscription>;
  subscriptionTypes: EntityState<SubscriptionType>;
  availableSubscriptions: EntityState<AvailableSubscription>;
  operations: {
    [key in BDRequestType | AlertRequestType]?: OperationStatus;
  };
  rowsPerPage?: number;
  editableSubscriptionId?: string;
  highlightedSubscriptionId?: string;
}

const CONFIG = ALERTS_API_CONFIG;

export const subscriptionsAdapter = createEntityAdapter<Subscription>({
  selectId: (sub: Subscription) => `${sub.category}-${sub.event}`,
});
export const subscriptionTypeAdapter = createEntityAdapter<SubscriptionType>({
  selectId: (type: SubscriptionType) => `${type.category}-${type.event}`,
});

export const availableSubscriptionsAdapter =
  createEntityAdapter<AvailableSubscription>({
    selectId: (availableSubscription: AvailableSubscription) =>
      `${availableSubscription.category}-${availableSubscription.event}`,
  });

const alertsAdapter = createEntityAdapter<DetailsState>({});

const initialAlertState = alertsAdapter.getInitialState<AlertState>({
  entitiesSessions: {},
  alertsSessions: {},
  filterSessions: {},
  unread: { hasUnread: false, approximateCount: 0 } as AlertUnread,
  operations: {},
  subscriptions: subscriptionsAdapter.getInitialState(),
  subscriptionTypes: subscriptionTypeAdapter.getInitialState(),
  availableSubscriptions: availableSubscriptionsAdapter.getInitialState(),
  editableSubscriptionId: undefined,
  highlightedSubscriptionId: undefined,
});

export const getAlertsEntities = makeThunk(
  'alerts/getAlertsEntities',
  makePostPayloadCreator<
    ApiResponse<PagedResultWithErrors<AlertCardAssetAlerts>>,
    AlertParams
  >({
    url: `${globalThis.appConfig.apiBaseUrl}/alerts/${API_VERSION_DEFAULTS.alertsAPI}/me/entities`,
    axiosOptions: (_, state: RootState) =>
      addAcceptLanguageHeader(CONFIG, state.profile.currentLocale),
    argAdapter: (
      { page, rowsPerPage: size, viewType, sessionId, ...params },
      state
    ) => {
      let sessionConfig = state.alerts?.filterSessions?.[viewType]?.[sessionId];
      const traceSuppressorEnabled =
        (selectFeatureFlagById(state, TRACE_SUPPRESSOR_FLAG_NAME)
          ?.value as FeatureFlagValueType) || undefined;
      if (traceSuppressorEnabled) {
        sessionConfig = {
          ...sessionConfig,
          selectedAssetTypes: [ApiAssetType.ELCV],
        };
      }
      const query = createAlertsEntitiesQuery(params, sessionConfig);
      return {
        requestBody: {
          page,
          size,
          query,
        },
      };
    },
    responseAdapter: (
      response: unknown | ApiResponse<unknown>,
      state,
      params
    ) => {
      if (!!response && hasApiResult<PagedResult<ApiAlertEntity>>(response)) {
        const errors = new Array<BDError>();
        const { items, total_items } = response.result;
        const result = {
          ...response,
          result: {
            total_items,
            items: items?.length
              ? response.result.items.reduce((mappedAlerts, apiAlertDetail) => {
                  try {
                    mappedAlerts.push(
                      mapAlertApiEntityToAlertHistoryDetail(
                        apiAlertDetail,
                        params,
                        state.profile.allowedContexts
                      )
                    );
                  } catch (e: unknown) {
                    errors.push({
                      name: 'Get Alerts Entities error',
                      type: BDAppErrorType.VALIDATION,
                      message:
                        e instanceof Error
                          ? e.message
                          : 'Failed to map Alert API response tp alert history',
                      data: apiAlertDetail,
                    });
                  }
                  return mappedAlerts;
                }, new Array<AlertCardAssetAlerts>())
              : [],
          },
        };
        return errors.length
          ? { ...result, errors: errors.map((e) => BDError.asJson(e)) }
          : result;
      }
      throw new BDError('Unexpected alert list response', { data: response });
    },
  })
);

export const getAlerts = makeThunk(
  'alerts/getAlerts',
  makePostPayloadCreator<
    ApiResponse<PagedResultWithErrors<AssetAlert>>,
    AlertParams & SkippableStateUpdate
  >({
    url: `${globalThis.appConfig.apiBaseUrl}/alerts/${API_VERSION_DEFAULTS.alertsAPI}/me`,
    axiosOptions: (_, state: RootState) =>
      addAcceptLanguageHeader(CONFIG, state.profile.currentLocale),
    argAdapter: (
      { viewType, sessionId, page, rowsPerPage: size, ...params },
      state
    ) => {
      const sessionConfig =
        state.alerts?.filterSessions?.[viewType]?.[sessionId];
      const query = createAlertQuery(params, sessionConfig);
      return {
        requestBody: {
          page,
          size,
          query,
        },
      };
    },
    responseAdapter: (response: unknown | ApiResponse<unknown>, state) => {
      if (!!response && hasApiResult<PagedResult<ApiAlertDetail>>(response)) {
        const errors = new Array<BDError>();
        const { items, total_items } = response.result;
        const result = {
          ...response,
          result: {
            total_items,
            items: items?.length
              ? response.result.items.reduce((mappedAlerts, apiAlertDetail) => {
                  try {
                    mappedAlerts.push(
                      mapAlertApiToAlertDetail(
                        apiAlertDetail,
                        state.profile.allowedContexts
                      )
                    );
                  } catch (e: unknown) {
                    errors.push({
                      name: 'Get Alerts error',
                      type: BDAppErrorType.VALIDATION,
                      message:
                        e instanceof Error
                          ? e.message
                          : 'Failed to map Alert API response to alert detail',
                      data: apiAlertDetail,
                    });
                  }
                  return mappedAlerts;
                }, new Array<AssetAlert>())
              : [],
          },
        };
        return errors.length
          ? { ...result, errors: errors.map((e) => BDError.asJson(e)) }
          : result;
      }
      throw new BDError('Unexpected alert list response', { data: response });
    },
  })
);

export const getUnread = makeThunk(
  'alerts/getUnread',
  makeGetPayloadCreator<ApiResponse<AlertUnread>, void>({
    url: `${globalThis.appConfig.apiBaseUrl}/alerts/${API_VERSION_DEFAULTS.alertsAPI}/me/unread`,
    axiosOptions: (_, state: RootState) =>
      addAcceptLanguageHeader(CONFIG, state.profile.currentLocale),
    responseAdapter: (response: unknown | ApiResponse<unknown>) => {
      if (!!response && hasApiResult<AlertUnread>(response)) {
        return {
          ...response,
          result: mapAlertUnreadApiResponseToAlertUnread(response.result),
        };
      }
      throw new BDError('Unexpected alert get unread response', {
        data: response,
      });
    },
  })
);
export const updateUnread = makeThunk(
  'alerts/me/unread/put',
  makePutPayloadCreator<ApiResponse<AlertUnread>, AlertsPutUnreadRequest>({
    url: `${globalThis.appConfig.apiBaseUrl}/alerts/${API_VERSION_DEFAULTS.alertsAPI}/me/unread`,
    argAdapter: (payload) => ({
      requestBody: payload,
    }),
    responseAdapter: (response: unknown | ApiResponse<unknown>) => {
      if (!!response && hasApiResult<AlertUnread>(response)) {
        return {
          ...response,
          result: mapAlertUnreadApiResponseToAlertUnread(response.result),
        };
      }
      throw new BDError('Unexpected alert put unread response', {
        data: response,
      });
    },
  })
);
export const markAlertAsUnread = makeThunk(
  'alerts/markAlertAsUnread',
  makePostPayloadCreator<ApiResponse<AlertUnread>, { alertId: string }>({
    url: ({ alertId }) =>
      `${globalThis.appConfig.apiBaseUrl}/alerts/${API_VERSION_DEFAULTS.alertsAPI}/me/${alertId}/unread`,
    axiosOptions: (_, state: RootState) =>
      addAcceptLanguageHeader(CONFIG, state.profile.currentLocale),
    responseAdapter: (response: unknown | ApiResponse<unknown>) => {
      if (!!response && hasApiResult<AlertUnread>(response)) {
        return {
          ...response,
          result: mapAlertUnreadApiResponseToAlertUnread(response.result),
        };
      }
      throw new BDError('Unexpected response marking alert as unread', {
        data: response,
      });
    },
  })
);
export const markAlertAsRead = makeThunk(
  'alerts/markAlertAsRead',
  makePostPayloadCreator<ApiResponse<AlertRead>, { alertId: string }>({
    url: ({ alertId }) =>
      `${globalThis.appConfig.apiBaseUrl}/alerts/${API_VERSION_DEFAULTS.alertsAPI}/me/${alertId}/read`,
    argAdapter: ({ alertId }) => ({
      requestBody: {},
    }),
    responseAdapter: (response: unknown | ApiResponse<unknown>) => {
      if (response && hasApiResult<AlertRead>(response)) {
        return response;
      }
      throw new BDError('Unexpected response marking alert as read', {
        data: response,
      });
    },
  })
);
export const markAllAlertAsRead = makeThunk(
  'alerts/markAllAlertAsRead',
  makePostPayloadCreator<ApiResponse<AlertRead>, void>({
    url: `${globalThis.appConfig.apiBaseUrl}/alerts/${API_VERSION_DEFAULTS.alertsAPI}/me/read`,
    argAdapter: () => ({
      requestBody: {},
    }),
    responseAdapter: (response: unknown | ApiResponse<unknown>) => {
      if (response && hasApiResult<AlertRead>(response)) {
        return response;
      }
      throw new BDError('Unexpected response marking alert as read', {
        data: response,
      });
    },
  })
);
export const alertsSlice = createSlice({
  name: 'alerts',
  initialState: initialAlertState,
  reducers: {
    setAlertsEntitiesSession: (
      state,
      action: PayloadAction<AlertSessionConfigType>
    ) => {
      Object.keys(action.payload).forEach((key) => {
        const scope = key as AlertSessionViewType;
        const payload = action.payload[scope] || {};
        Object.keys(payload).forEach((id) => {
          state.entitiesSessions = merge(
            state.entitiesSessions,
            {
              [scope]: {
                [id]: payload[id] && {
                  ...payload[id],
                },
              },
            },
            {
              arrayMerge: (_, sourceArray) => sourceArray,
            }
          );
        });
      });
    },
    setAlertsFilterSession: (
      state,
      action: PayloadAction<Partial<AlertsFiltersSessionState>>
    ) => {
      Object.keys(action.payload).forEach((key) => {
        const scope = key as AlertSessionViewType;
        const payload = action.payload[scope] || {};
        Object.keys(payload).forEach((id) => {
          state.filterSessions = merge(
            state.filterSessions,
            {
              [scope]: {
                [id]: payload[id] && {
                  ...payload[id],
                },
              },
            },
            {
              arrayMerge: (_, sourceArray) => sourceArray,
            }
          );
        });
      });
    },
    setAlertsRowsPerPage: (state, action) => {
      state.rowsPerPage = action.payload;
    },
    setEditableSubscriptionId: (state, action) => {
      state.editableSubscriptionId = action.payload;
    },
    setHighlightedSubscriptionId: (state, action) => {
      state.highlightedSubscriptionId = action.payload;
    },
    setUnreadCount: (state, action: PayloadAction<AlertUnread>) => {
      state.unread = action.payload;
    },
  },
  extraReducers: (builder) => {
    // alerts entities
    builder.addCase(getAlertsEntities.pending, (state, action) => {
      const { sessionId, viewType } = action.meta.arg;
      state.entitiesSessions = merge(
        state.entitiesSessions,
        {
          [viewType]: {
            [sessionId]: {
              count: undefined,
              entities: undefined,
              rowsExpanded: undefined,
              operationStatus: {
                status: BDRequestStatus.PENDING,
                errors: [],
              },
            },
          },
        },
        {
          arrayMerge: (_, sourceArray) => sourceArray,
        }
      );
    });
    builder.addCase(getAlertsEntities.fulfilled, (state, action) => {
      const { sessionId, viewType, ...session } = action.meta.arg;
      state.entitiesSessions = merge(
        state.entitiesSessions,
        {
          [viewType]: {
            [sessionId]: {
              ...session,
              count: action.payload.result?.total_items,
              entities: action.payload.result.items,
              operationStatus: {
                status: BDRequestStatus.SUCCEEDED,
              },
            },
          },
        },
        {
          arrayMerge: (_, sourceArray) => sourceArray,
        }
      );
    });
    builder.addCase(getAlertsEntities.rejected, (state, action) => {
      const { sessionId, viewType } = action.meta.arg;
      state.entitiesSessions = merge(
        state.entitiesSessions,
        {
          [viewType]: {
            [sessionId]: {
              operationStatus: {
                status: BDRequestStatus.FAILED,
                errors: [
                  {
                    type: BDAppErrorType.API,
                    ...(action.payload || (action.error as BDError)),
                  },
                ],
              },
            },
          },
        },
        {
          arrayMerge: (_, sourceArray) => sourceArray,
        }
      );
    });
    // alerts details
    builder.addCase(getAlerts.pending, (state, action) => {
      const { skipStateUpdate, sessionId, viewType, assetsId } =
        action.meta.arg;
      if (skipStateUpdate) {
        return;
      }
      const alertsKey = assetsId || DEFAULT_SESSION_CONFIG_KEY;
      state.alertsSessions = merge(
        state.alertsSessions,
        {
          [viewType]: {
            [sessionId]: {
              [alertsKey]: {
                alerts: undefined,
                operationStatus: {
                  status: BDRequestStatus.PENDING,
                  errors: [],
                },
              },
            },
          },
        },
        {
          arrayMerge: (_, sourceArray) => sourceArray,
        }
      );
    });
    builder.addCase(getAlerts.fulfilled, (state, action) => {
      const { skipStateUpdate, sessionId, viewType, assetsId } =
        action.meta.arg;
      if (skipStateUpdate) {
        return;
      }

      const alertsKey = assetsId || DEFAULT_SESSION_CONFIG_KEY;
      state.alertsSessions = merge(
        state.alertsSessions,
        {
          [viewType]: {
            [sessionId]: {
              [alertsKey]: {
                count: action.payload.result.total_items,
                alerts: action.payload.result.items,
                operationStatus: {
                  status: BDRequestStatus.SUCCEEDED,
                  errors: [],
                },
              },
            },
          },
        },
        {
          arrayMerge: (_, sourceArray) => sourceArray,
        }
      );
    });
    builder.addCase(getAlerts.rejected, (state, action) => {
      const { skipStateUpdate, sessionId, viewType, assetsId } =
        action.meta.arg;
      if (skipStateUpdate) {
        return;
      }
      const alertsKey = assetsId || DEFAULT_SESSION_CONFIG_KEY;
      state.alertsSessions = merge(
        state.alertsSessions,
        {
          [viewType]: {
            [sessionId]: {
              [alertsKey]: {
                alerts: undefined,
                operationStatus: {
                  status: BDRequestStatus.FAILED,
                  errors: [
                    {
                      type: BDAppErrorType.API,
                      ...(action.payload || (action.error as BDError)),
                    },
                  ],
                },
              },
            },
          },
        },
        {
          arrayMerge: (_, sourceArray) => sourceArray,
        }
      );
    });

    // alert unread
    builder.addCase(getUnread.pending, (state) => {
      state.operations[AlertRequestType.GET_ALERT_UNREAD] = {
        status: BDRequestStatus.PENDING,
        errors: [],
      };
    });
    builder.addCase(getUnread.fulfilled, (state, action) => {
      const { errors, ...apiResponse } = action.payload;

      const listOfErrors = errors
        ? errors.map(
            (error) =>
              new BDError(`upload failure: ${error.message}`, {
                code: ('errorCode' in error && error.errorCode) || '',
              })
          )
        : [];
      state.operations[AlertRequestType.GET_ALERT_UNREAD] = {
        status: BDRequestStatus.SUCCEEDED,
        errors: listOfErrors,
      };
    });
    builder.addCase(getUnread.rejected, (state, action) => {
      state.operations[AlertRequestType.GET_ALERT_UNREAD] = {
        status: BDRequestStatus.FAILED,
        errors: [
          {
            type: BDAppErrorType.API,
            ...(action.payload || (action.error as BDError)),
            severity: SeverityLevel.ERROR,
          },
        ],
      };
    });

    builder.addCase(updateUnread.pending, (state) => {
      state.operations[AlertRequestType.PUT_ALERT_UNREAD] = {
        status: BDRequestStatus.PENDING,
        errors: [],
      };
    });
    builder.addCase(updateUnread.fulfilled, (state, action) => {
      const { errors, ...apiResponse } = action.payload;

      const listOfErrors = errors
        ? errors.map(
            (error) =>
              new BDError(`upload failure: ${error.message}`, {
                code: ('errorCode' in error && error.errorCode) || '',
              })
          )
        : [];
      state.operations[AlertRequestType.PUT_ALERT_UNREAD] = {
        status: BDRequestStatus.SUCCEEDED,
        errors: listOfErrors,
      };
    });
    builder.addCase(updateUnread.rejected, (state, action) => {
      state.operations[AlertRequestType.PUT_ALERT_UNREAD] = {
        status: BDRequestStatus.FAILED,
        errors: [
          {
            type: BDAppErrorType.API,
            ...(action.payload || (action.error as BDError)),
            severity: SeverityLevel.ERROR,
          },
        ],
      };
    });

    // reset state when persist store is purged on logout
    builder.addCase(PURGE, (state) => {
      state = initialAlertState;
    });

    // mark notification as unread
    builder.addCase(markAlertAsUnread.pending, (state) => {
      state.operations[AlertRequestType.POST_ALERT_UNREAD] = {
        status: BDRequestStatus.PENDING,
        errors: [],
      };
    });
    builder.addCase(markAlertAsUnread.fulfilled, (state, action) => {
      const { errors, ...apiResponse } = action.payload;
      const listOfErrors = errors
        ? errors.map(
            (error) =>
              new BDError(`upload failure: ${error.message}`, {
                code: ('errorCode' in error && error.errorCode) || '',
              })
          )
        : [];
      state.operations[AlertRequestType.POST_ALERT_UNREAD] = {
        status: BDRequestStatus.SUCCEEDED,
        errors: listOfErrors,
      };
    });
    builder.addCase(markAlertAsUnread.rejected, (state, action) => {
      state.operations[AlertRequestType.POST_ALERT_UNREAD] = {
        status: BDRequestStatus.FAILED,
        errors: [
          {
            type: BDAppErrorType.API,
            ...(action.payload || (action.error as BDError)),
            severity: SeverityLevel.ERROR,
          },
        ],
      };
    });
    // mark notification as read
    builder.addCase(markAlertAsRead.pending, (state) => {
      state.operations[AlertRequestType.POST_ALERT_READ] = {
        status: BDRequestStatus.PENDING,
        errors: [],
      };
    });
    builder.addCase(markAlertAsRead.fulfilled, (state, action) => {
      const { errors, ...apiResponse } = action.payload;

      const listOfErrors = errors
        ? errors.map(
            (error) =>
              new BDError(`upload failure: ${error.message}`, {
                code: ('errorCode' in error && error.errorCode) || '',
              })
          )
        : [];
      state.operations[AlertRequestType.POST_ALERT_READ] = {
        status: BDRequestStatus.SUCCEEDED,
        errors: listOfErrors,
      };
    });
    builder.addCase(markAlertAsRead.rejected, (state, action) => {
      state.operations[AlertRequestType.POST_ALERT_READ] = {
        status: BDRequestStatus.FAILED,
        errors: [
          {
            type: BDAppErrorType.API,
            ...(action.payload || (action.error as BDError)),
            severity: SeverityLevel.ERROR,
          },
        ],
      };
    });

    // mark all notification as read
    builder.addCase(markAllAlertAsRead.pending, (state) => {
      state.operations[AlertRequestType.POST_ALERT_READ] = {
        status: BDRequestStatus.PENDING,
        errors: [],
      };
    });
    builder.addCase(markAllAlertAsRead.fulfilled, (state, action) => {
      const { errors, ...apiResponse } = action.payload;

      const listOfErrors = errors
        ? errors.map(
            (error) =>
              new BDError(`upload failure: ${error.message}`, {
                code: ('errorCode' in error && error.errorCode) || '',
              })
          )
        : [];
      state.operations[AlertRequestType.POST_ALERT_READ] = {
        status: BDRequestStatus.SUCCEEDED,
        errors: listOfErrors,
      };
    });
    builder.addCase(markAllAlertAsRead.rejected, (state, action) => {
      state.operations[AlertRequestType.POST_ALERT_READ] = {
        status: BDRequestStatus.FAILED,
        errors: [
          {
            type: BDAppErrorType.API,
            ...(action.payload || (action.error as BDError)),
            severity: SeverityLevel.ERROR,
          },
        ],
      };
    });
  },
});

export const {
  selectAll: selectAllSubscriptions,
  selectById: selectSubscrptionById,
  selectIds: selectSubscrptionIds,
} = subscriptionsAdapter.getSelectors<RootState>(
  (state: RootState) => state.alerts.subscriptions
);

export const {
  selectAll: selectAllSubTypes,
  selectById: selectSubTypeById,
  selectIds: selectSubTypeIds,
} = subscriptionTypeAdapter.getSelectors<RootState>(
  (state: RootState) => state.alerts.subscriptionTypes
);
export const {
  selectAll: selectAllAvailableSubscriptions,
  selectById: selectAvailableSubscrptionById,
  selectIds: selectAvailableSubscrptionIds,
} = availableSubscriptionsAdapter.getSelectors<RootState>(
  (state: RootState) => state.alerts.availableSubscriptions
);

export const {
  setAlertsEntitiesSession,
  setAlertsFilterSession,
  setAlertsRowsPerPage,
  setEditableSubscriptionId,
  setHighlightedSubscriptionId,
  setUnreadCount,
} = alertsSlice.actions;

export const alertsReducer = alertsSlice.reducer;
