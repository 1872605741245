import {
  API_VERSION_DEFAULTS,
  BD_FLEET_HEADER_NAME,
  BD_HUB_HEADER_NAME,
  DEFAULT_API_CONFIG,
  INSPECTION_API_CONFIG,
} from '~/common/apis/api.constants';
import { ApiResponse } from '~/common/models/apis/apiResponse.model';
import {
  ApiInspectionDetail,
  AssetInspectionDetail,
  AssetInspectionListApi,
  AssetInspectionListItem,
  AssetInspectionsSummary,
} from '~/common/models/assetInspection.model';
import { PagedResult } from '~/common/models/common.model';
import { BDError } from '~/common/models/error.model';
import {
  addAcceptLanguageHeader,
  addHeader,
  addOrganizationIdHeader,
  hasApiResult,
} from '~/common/utils/apis/api.utils';
import {
  makeGetPayloadCreator,
  makeThunk,
} from '~/common/utils/store/thunk.helper';

import {
  InspectionsActionType,
  InspectionsDetailsParams,
  InspectionsListParams,
  InspectionsLongTermsReportingParams,
  InspectionsSessionViewType,
  InspectionsSummaryParams,
} from '../inspectionsSlice.model';
import { mapApiInspectionToInspectionDetails } from '../mappers/inspections.mappers';
import { createInspectionListRequestParams } from '../utils/AssetInspections.utils';

const CONFIG = DEFAULT_API_CONFIG;

const getInspectionSummary = makeThunk(
  InspectionsActionType.GET_INSPECTION_SUMMARY,
  makeGetPayloadCreator<
    ApiResponse<AssetInspectionsSummary>,
    InspectionsSummaryParams
  >({
    url: `${globalThis.appConfig.apiBaseUrl}/maintenances/${API_VERSION_DEFAULTS.inspectionsAPI}/inspections_summaries`,
    axiosOptions: ({ organizationsId, hubsId, fleetsId }, state) =>
      addAcceptLanguageHeader(
        addOrganizationIdHeader(
          addHeader(
            addHeader(CONFIG, BD_FLEET_HEADER_NAME, fleetsId),
            BD_HUB_HEADER_NAME,
            hubsId
          ),
          organizationsId
        ),
        state.profile.currentLocale
      ),
    argAdapter: ({ date }) => {
      return {
        requestParams: {
          date,
        },
      };
    },
    responseAdapter: (response: any) => {
      if (!!response && hasApiResult<AssetInspectionsSummary>(response)) {
        return {
          ...response,
          result: response.result,
        };
      }
      throw new BDError('Unexpected Inspection Summary response', {
        data: response,
      });
    },
  })
);
const getInspectionLongTermReport = makeThunk(
  InspectionsActionType.GET_INSPECTION_LONG_TERM_REPORT,
  makeGetPayloadCreator<BlobPart, InspectionsLongTermsReportingParams>({
    url: `${globalThis.appConfig.apiBaseUrl}/maintenances/${API_VERSION_DEFAULTS.inspectionsAPI}/report`,

    axiosOptions: ({ organizationsId, hubsId }, state) => {
      const thunkConfig = addOrganizationIdHeader(
        addHeader(INSPECTION_API_CONFIG, BD_HUB_HEADER_NAME, hubsId),
        organizationsId
      );
      return {
        ...thunkConfig,
        axiosConfig: {
          ...thunkConfig.axiosConfig,
          responseType: 'blob',
        },
      };
    },
    argAdapter: ({ issueType, period, periodDate, targetType, fleetsId }) => ({
      requestParams: {
        type: issueType,
        period,
        targetType,
        targetId: fleetsId,
        periodDate,
      },
    }),
    // responseAdapter: (response) => {
    //   if (!!response && hasApiResult<unknown>(response)) {
    //     return {
    //       error: null,
    //       success: true,
    //       unauthorizedRequest: false,
    //       targetUrl: '',
    //       __bd: true,
    //       result: { inspectionReportBlob: response },
    //     };
    //   }
    //   throw new BDError('Unexpected Inspection Summary response', {
    //     data: { inspectionReportBlob: response },
    //   });
    // },
  })
);

export const getInspectionDetails = makeThunk(
  InspectionsActionType.GET_INSPECTION_DETAILS,
  makeGetPayloadCreator<
    ApiResponse<AssetInspectionDetail>,
    InspectionsDetailsParams
  >({
    url: `${globalThis.appConfig.apiBaseUrl}/maintenances/${API_VERSION_DEFAULTS.inspectionsAPI}/inspections/:inspectionsId`,
    axiosOptions: ({ organizationsId, hubsId, fleetsId }, state) =>
      addAcceptLanguageHeader(
        addOrganizationIdHeader(
          addHeader(
            addHeader(CONFIG, BD_FLEET_HEADER_NAME, fleetsId),
            BD_HUB_HEADER_NAME,
            hubsId
          ),
          organizationsId
        ),
        state.profile.currentLocale
      ),
    argAdapter: ({ inspectionsId }) => {
      return {
        requestParams: {
          inspectionsId,
        },
      };
    },
    responseAdapter: (response: unknown | ApiResponse<unknown>) => {
      if (!!response && hasApiResult<ApiInspectionDetail>(response)) {
        return {
          ...response,
          result: mapApiInspectionToInspectionDetails(response.result),
        };
      }
      throw new BDError('Unexpected inspection details response', {
        data: response,
      });
    },
  })
);

export const getInspectionList = makeThunk(
  InspectionsActionType.GET_INSPECTION_LIST,
  makeGetPayloadCreator<
    ApiResponse<PagedResult<AssetInspectionListItem>>,
    InspectionsListParams
  >({
    url: `${globalThis.appConfig.apiBaseUrl}/maintenances/${API_VERSION_DEFAULTS.inspectionsAPI}/inspections`,
    axiosOptions: ({ organizationsId, hubsId, fleetsId }, state) =>
      addAcceptLanguageHeader(
        addOrganizationIdHeader(
          addHeader(
            addHeader(CONFIG, BD_FLEET_HEADER_NAME, fleetsId),
            BD_HUB_HEADER_NAME,
            hubsId
          ),
          organizationsId
        ),
        state.profile.currentLocale
      ),
    argAdapter: ({ page, pageSize, date, sessionId }, state) => {
      const sessionConfig =
        state.inspections.sessionConfigs?.[
          InspectionsSessionViewType.INSPECTION_LIST
        ]?.[sessionId];
      return {
        requestParams: createInspectionListRequestParams(
          date,
          page,
          pageSize,
          sessionConfig
        ),
      };
    },
    responseAdapter: (response: unknown | ApiResponse<unknown>) => {
      if (!!response && hasApiResult<AssetInspectionListApi>(response)) {
        const { items, totalCount } = response.result;
        return {
          ...response,
          result: {
            items: items,
            total_items: totalCount,
          },
        };
      }
      throw new BDError('Unexpected inspection list item response', {
        data: response,
      });
    },
  })
);

export const INSPECTIONS_ACTIONS = {
  getInspectionSummary,
  getInspectionDetails,
  getInspectionList,
  getInspectionLongTermReport,
};
