import {
  FileUploadErrorData,
  FileUploadErrorType,
  Flex,
} from '@brightdrop/bd-ui';
import { useTranslations } from '@brightdrop/localization-client';
import { cx } from '@emotion/css';
import { Button, Typography, useTheme } from '@mui/material';
import { ReactNode, useState } from 'react';
import { FileUploader } from 'react-drag-drop-files';

import CloseIcon from '../../assets/close.svg?react';
import CSVIcon from '../../assets/csvIcon.svg?react';
import ErrorIcon from '../../assets/error.svg?react';
import TrashIcon from '../../assets/trash.svg?react';
import UploadIcon from '../../assets/upload.svg?react';
import useStyles from './FileUpload.styles';

enum FileUploadStatus {
  Success = 'success',
  Error = 'error',
  NoFile = 'no_file',
}

export const KILOBYTE_IN_BYTES = 1024;
export const MEGABYTE_IN_BYTES = KILOBYTE_IN_BYTES * 1024;

const FILE_UPLOAD_MESSAGES = {
  'asset:addVehicleDialog.modalFileUploadErrorMessage':
    'Unable to upload file. Please follow these file requirements:',
  'asset:addVehicleDialog.modalFileUploadErrorTypeMessage': 'File type is ',
  'asset:addVehicleDialog.modalFileUploadErrorSizeMessage':
    'Maximum file size is ',
  'asset:addVehicleDialog.modalFileUploadErrorQuantityMessage':
    '1 File per upload',
  'asset:addVehicleDialog.fileUploadTypesHelperText': 'Supported file types: ',
  'asset:addVehicleDialog.fileUploadSizeHelperText': ' Maximum file size: ',
  'asset:addVehicleDialog.modalFileUploadLabel': 'Drop file here or ',
  'asset:addVehicleDialog.modalFileUploadLabelLink': 'browse files',
  fileSizes: {
    'common:bytes.unit.bytes_short': 'B',
    'common:bytes.unit.kilobytes_short': 'KB',
    'common:bytes.unit.megabytes_short': 'MB',
  },
};

export function transformFileSizeToLabel(
  fileSize: number,
  messages: typeof FILE_UPLOAD_MESSAGES.fileSizes,
  decimalPlaces: number = 1
): string {
  if (fileSize < KILOBYTE_IN_BYTES) {
    return `${fileSize}${messages['common:bytes.unit.bytes_short']}`;
  }

  if (fileSize < MEGABYTE_IN_BYTES) {
    return `${(fileSize / KILOBYTE_IN_BYTES).toFixed(decimalPlaces)}${
      messages['common:bytes.unit.kilobytes_short']
    }`;
  }

  return `${(fileSize / MEGABYTE_IN_BYTES).toFixed(decimalPlaces)}${
    messages['common:bytes.unit.megabytes_short']
  }`;
}

type FileUploadProps = {
  onFileUpload: (file: File | null) => void; // Callback for file upload
  onUploadError: (error: boolean, data?: FileUploadErrorData) => void; // Callback for upload error
  acceptedFileTypes: string[];
  maxFileSize?: number;
  successfulMarkupOverride?: ReactNode | null;
  failedMarkupOverride?: ReactNode | null;
  validateFileContent?: (file: File) => Promise<boolean>; // async validation function
  name?: string;
  multiple?: boolean;
  datatestId?: string;
};

const FileUpload: React.FC<FileUploadProps> = ({
  onFileUpload,
  onUploadError,
  acceptedFileTypes,
  maxFileSize = 200 * 1024, // Default max size in bytes (200 KB)
  successfulMarkupOverride,
  failedMarkupOverride,
  validateFileContent,
  name,
  multiple = false,
  datatestId,
}) => {
  const theme = useTheme();
  const { classes } = useStyles();
  const [status, setStatus] = useState<FileUploadStatus>(
    FileUploadStatus.NoFile
  );
  const [uploadedFile, setUploadedFile] = useState<File | null>(null);
  const { translations } = useTranslations(FILE_UPLOAD_MESSAGES);

  /**
   * Clears the uploaded file state, resets the file upload status, and triggers the provided callbacks.
   *
   * This function is typically used to reset the file upload process, clearing the file details
   * and resetting the error state.
   *
   * @returns {void} This function does not return anything.
   */
  const handleClear = () => {
    setStatus(FileUploadStatus.NoFile);
    setUploadedFile(null);
    onUploadError(false);
    onFileUpload(null);
  };

  /**
   * Renders the details of the uploaded file, including the file name, file size, and file type icon.
   *
   * @returns {JSX.Element} The JSX element representing the file details display, including the file's name, size, and type icon.
   */
  const renderFileDetails = () => (
    <Flex className={classes.uploadFileDetails}>
      <Flex className={classes.fileTypeIcon}>
        <CSVIcon />
      </Flex>
      <Flex gap={16}>
        <Typography variant="label_regular">{uploadedFile?.name}</Typography>
        <Typography variant="label_regular">
          {transformFileSizeToLabel(
            uploadedFile?.size || 0,
            translations.fileSizes
          )}
        </Typography>
      </Flex>
    </Flex>
  );

  /**
   * Renders a button that allows the user to remove a file.
   *
   * @param {React.ReactNode} icon - The icon to display within the button (usually a delete or remove icon).
   * @param {function(): void} onClick - The callback function to be invoked when the button is clicked.
   *
   * @returns {JSX.Element} The JSX element representing the remove button.
   */
  const renderRemoveButton = (icon: React.ReactNode, onClick: () => void) => (
    <Button
      aria-label="reset-uploader-button"
      className={classes.removeFileIcon}
      onClick={onClick}
      data-testid="reset-uploader-button"
      startIcon={icon}
    />
  );

  /**
   * Renders the success markup for a successful file upload.
   *
   * @returns {JSX.Element} The JSX element representing the success message and file details.
   * This includes the file details, a remove button, and the custom `successfulMarkupOverride` if provided.
   */
  const renderSuccessMarkup = () => (
    <Flex>
      {renderFileDetails()}
      {renderRemoveButton(
        <TrashIcon
          stroke={theme.new.color.textPrimary}
          height={16}
          width={16}
        />,
        handleClear
      )}
      {successfulMarkupOverride}
    </Flex>
  );

  /**
   * Renders the error markup for a failed file upload.
   *
   * @returns {JSX.Element} The JSX element representing the error message and file details.
   * This includes either the custom `failedMarkupOverride` or a default error message with file details.
   */
  const renderErrorMarkup = () => (
    <>
      {renderRemoveButton(
        <CloseIcon
          stroke={theme.new.color.textPrimary}
          height={16}
          width={16}
        />,
        handleClear
      )}
      {failedMarkupOverride ? (
        failedMarkupOverride
      ) : (
        <Flex className={classes.errorFileRequirements}>
          <Flex className={classes.errorFileRequirementsLabel}>
            <ErrorIcon />
            <Typography variant="body_medium">
              {
                translations[
                  'asset:addVehicleDialog.modalFileUploadErrorMessage'
                ]
              }
            </Typography>
          </Flex>
          <ul>
            <li>
              <Typography variant="small_body_regular">
                {
                  translations[
                    'asset:addVehicleDialog.modalFileUploadErrorTypeMessage'
                  ]
                }
                {acceptedFileTypes.join(', ')}
              </Typography>
            </li>
            <li>
              <Typography variant="small_body_regular">
                {
                  translations[
                    'asset:addVehicleDialog.modalFileUploadErrorSizeMessage'
                  ]
                }
                {transformFileSizeToLabel(
                  maxFileSize,
                  translations.fileSizes,
                  0
                )}
              </Typography>
            </li>
            {!multiple && (
              <li>
                <Typography variant="small_body_regular">
                  {
                    translations[
                      'asset:addVehicleDialog.modalFileUploadErrorQuantityMessage'
                    ]
                  }
                </Typography>
              </li>
            )}
          </ul>
        </Flex>
      )}
    </>
  );

  /**
   * Validates the provided file based on its type, size, and content (if the validateFileContent callback is provided).
   *
   * @param {File} file - The file to be validated.
   * @returns {Promise<boolean>} A promise that resolves to `true` if the file is valid, or `false` if any validation fails.
   */
  const handleFileValidation = async (file: File): Promise<boolean> => {
    const fileType = file.type.split('/')[1].toUpperCase();
    setUploadedFile(file); // Store the file for details display.

    if (!acceptedFileTypes.includes(fileType)) {
      const errorDetails: FileUploadErrorData = {
        type: FileUploadErrorType.FILE_TYPE,
        details: {
          acceptedType: acceptedFileTypes,
          providedType: [fileType],
        },
      };

      setStatus(FileUploadStatus.Error);
      onUploadError(true, errorDetails);
      onFileUpload(null);
      return false;
    }

    if (file.size > maxFileSize) {
      const errorDetails: FileUploadErrorData = {
        type: FileUploadErrorType.MAX_SIZE,
        details: {
          currentSize: file.size,
          maxSize: maxFileSize,
        },
      };

      setStatus(FileUploadStatus.Error);
      onUploadError(true, errorDetails);
      onFileUpload(null);
      return false;
    }

    if (validateFileContent && !(await validateFileContent(file))) {
      const errorDetails: FileUploadErrorData = {
        type: FileUploadErrorType.CONTENT_VALIDATION,
      };

      setStatus(FileUploadStatus.Error);
      onUploadError(true, errorDetails);
      onFileUpload(null);
      return false;
    }

    return true;
  };

  /**
   * Handles the file change event, validates the file, and updates the upload status if the file is valid.
   *
   * This function validates the file by checking its type, size, and content (if the validateFileContent callback is provided). If the file is valid,
   * it updates the upload status to success and triggers the provided callbacks for file upload and error handling.
   *
   * @param {File} file - The file to be validated and processed.
   *
   * @returns {Promise<void>} This function returns a promise that resolves once the validation and processing are complete.
   */
  const handleChange = async (file: File) => {
    if (await handleFileValidation(file)) {
      setStatus(FileUploadStatus.Success);
      onUploadError(false);
      onFileUpload(file);
    }
  };

  /**
   * Renders the file uploader component with a label, helper text, and an icon.
   *
   * This function wraps the `FileUploader` component, passing the required props such as `name`,
   * `handleChange`, accepted file types, and helper text for the upload process. It also includes an icon
   * and provides guidance for users on file types and size constraints.
   *
   * @returns {JSX.Element} The JSX element representing the file uploader with label, icon, and helper text.
   */
  const renderUploader = () => (
    <FileUploader
      name={name}
      handleChange={handleChange}
      types={acceptedFileTypes}
      hoverTitle={`${translations['asset:addVehicleDialog.modalFileUploadLabel']} ${translations['asset:addVehicleDialog.modalFileUploadLabelLink']}`}
    >
      <Flex className={classes.uploadContentWrapper}>
        <Flex className={classes.uploadLabel}>
          <UploadIcon height={24} width={24} />
          <Typography variant="body_medium">
            {translations['asset:addVehicleDialog.modalFileUploadLabel']}
            <span className={classes.browseFilesText}>
              {translations['asset:addVehicleDialog.modalFileUploadLabelLink']}
            </span>
          </Typography>
        </Flex>
        <Typography
          variant="caption_regular"
          className={classes.uploadHelperText}
        >
          {translations['asset:addVehicleDialog.fileUploadTypesHelperText']}
          {acceptedFileTypes.join(', ').toLowerCase()}.
          {translations['asset:addVehicleDialog.fileUploadSizeHelperText']}
          {transformFileSizeToLabel(maxFileSize, translations.fileSizes, 0)}
          {'. '}
          {!multiple &&
            translations[
              'asset:addVehicleDialog.modalFileUploadErrorQuantityMessage'
            ]}
        </Typography>
      </Flex>
    </FileUploader>
  );

  return (
    <Flex className={classes.fileUploadWrapper} dataTestId={datatestId}>
      {!uploadedFile && renderUploader()}
      {status !== FileUploadStatus.NoFile && (
        <Flex className={cx(classes.uploadMessageWrapper, status)}>
          {status === FileUploadStatus.Success && renderSuccessMarkup()}
          {status === FileUploadStatus.Error && renderErrorMarkup()}
        </Flex>
      )}
    </Flex>
  );
};

export default FileUpload;
