import { PermissionScope, Role } from '@gm-commercial/profile-model';
import { useCallback, useMemo } from 'react';

import useProfile from './useProfile';

/**
 * Custom hook for managing and checking user permissions.
 * Utilizes the user's profile information to determine if they have the required permissions and scopes.
 *
 * @returns An object containing:
 * - `hasPermission`: Function that takes an array of permission strings and returns a boolean indicating if the user has some of the specified permissions.
 * - `getPermissionScope`: Function that takes a permission string and returns the associated Role or PermissionScope.
 * - `hasPermissionScope`: Function that checks if a given permission has the specified Role or PermissionScope.
 */
const usePermissions = (): {
  hasPermission: (reqs: string[]) => boolean;
  getPermissionScope: (permission: string) => Role | PermissionScope;
  hasPermissionScope: (
    permission: string,
    scope: Role | PermissionScope
  ) => boolean;
} => {
  const { profile } = useProfile();
  const permissions = useMemo(
    () => profile?.permissions,
    [profile?.permissions]
  );

  const hasPermission = useCallback(
    (authorizedPerms: string[]) =>
      !!permissions &&
      (!authorizedPerms.length ||
        hasAuthorizedPermissionsWithoutScope(permissions, authorizedPerms)),
    [permissions]
  );

  const getPermissionScope = useCallback(
    (permission: string): Role | PermissionScope => {
      const existingPermission = permissions?.find((perm) =>
        perm.startsWith(permission)
      );
      return findPermissionScope(existingPermission);
    },
    [permissions]
  );

  const hasPermissionScope = useCallback(
    (permission: string, scope: Role | PermissionScope) =>
      getPermissionScope(permission) === scope,
    [getPermissionScope]
  );

  return { hasPermission, getPermissionScope, hasPermissionScope };
};

// Regex to match scope in permission like Asset.Read(scope=Technician)
const scope = /\(.*\)/;

const SCOPE_VALUE_INDEX = 1;

/**
 * Checks if the provided permissions array contains any of the authorized permissions,
 * ignoring any specific scopes that might be attached to the permissions.
 * This is useful for cases where the permission validity is considered regardless of the scope.
 *
 * @param {string[]} permissions - The array of permissions to check, which may include scopes.
 * @param {string[]} authorizedPerms - The array of authorized permissions without scopes.
 * @returns {boolean} - Returns true if any of the provided permissions match the authorized permissions
 * after removing their scopes, otherwise returns false.
 */
const hasAuthorizedPermissionsWithoutScope = (
  permissions: string[],
  authorizedPerms: string[]
) =>
  permissions.some((perm) =>
    authorizedPerms.some((authPerm) => authPerm === perm.replace(scope, ''))
  );

/**
 * Finds the scope of an existing permission string.
 * This function searches for a 'scope' value within the permission string and determines if it matches
 * any predefined Role. If a match is found, it returns the corresponding Role or PermissionScope.
 *
 * @param {string} [existingPermission] - The permission string to analyze. Optional.
 * @returns {(PermissionScope | Role )} - The found Role or PermissionScope based on the 'scope' value in the permission string.
 */
const findPermissionScope = (
  existingPermission?: string
): PermissionScope | Role => {
  const scopeValue =
    existingPermission?.match(/scope=(\w+)/)?.[SCOPE_VALUE_INDEX];
  if (scopeValue) {
    if (Object.values(Role).includes(scopeValue as Role)) {
      return scopeValue as Role;
    }
    if (
      Object.values(PermissionScope).includes(scopeValue as PermissionScope)
    ) {
      return scopeValue as PermissionScope;
    }
  }
  return existingPermission ? PermissionScope.ALL : PermissionScope.NONE;
};

export default usePermissions;
