import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  notificationList: {
    [theme.breakpoints.down('md')]: {
      maxHeight: '100vh',
      width: '100vw',
    },

    display: 'flex',
    flexDirection: 'column',
    maxHeight: '95vh',
    overflowY: 'hidden',
    width: '500px',
  },

  wrapperListItem: {
    borderBottom: `1px solid ${theme.new.color.border}`,
    overflowY: 'auto',
    overflowX: 'hidden',
    flex: 1,
    scrollbarWidth: 'thin',
    scrollbarColor: `${theme.new.color.grey[300]} ${theme.new.color.common.white}`,
  },

  notificationTitle: {
    paddingBlock: '16px',
    paddingLeft: '16px',
    position: 'sticky',
    top: 0,
    backgroundColor: theme.new.color.common.white,
    zIndex: 3,
  },

  seeAllButton: {
    position: 'sticky',
    margin: '16px',
    backgroundColor: theme.new.color.common.white,
    border: `1px solid ${theme.new.color.brand[600]}`,
    borderRadius: '8px',
    color: theme.new.color.brandSecondary,
    display: 'flex',
    justifyContent: 'center',
    cursor: 'pointer',
    zIndex: 2,

    '&:hover': {
      textDecoration: 'none',
    },
  },

  closeIcon: {
    width: 24,
    height: 24,
    position: 'absolute',
    cursor: 'pointer',
    right: '16px',
    top: '16px',
    zIndex: 2,

    '& path': {
      stroke: theme.new.color.grey[500],
    },
  },
}));

export default useStyles;
