import {
  ActionReducerMapBuilder,
  CaseReducer,
  EntityState,
  PayloadAction,
} from '@reduxjs/toolkit';
import merge from 'deepmerge';

import { SessionConfigType } from '~/common/constants/common.constant';
import {
  BDRequestStatus,
  BDRequestType,
} from '~/common/models/apis/apiResponse.model';
import { SeverityLevel } from '~/common/models/common.model';
import { BDAppErrorType, BDError } from '~/common/models/error.model';
import { Insight } from '~/common/models/insight.model';

import {
  getInsightDetails,
  getInsights,
  InsightSessionConfig,
  InsightsState,
} from '../insightsSlice';
import { insightsAdapter } from './insightsSlice.adapters';
import { INITIAL_INSIGHTS_STATE } from './insightsSlice.constants';

const reduceSetInsightsSession: CaseReducer<
  typeof INITIAL_INSIGHTS_STATE,
  PayloadAction<Partial<InsightSessionConfig>>
> = (state, action) => {
  Object.keys(action.payload).forEach((key) => {
    const scope = key as SessionConfigType;
    const payload = action.payload[scope] || {};
    Object.keys(payload).forEach((id) => {
      state.sessionConfigs = merge(
        state.sessionConfigs,
        {
          [scope]: {
            [id]: payload[id] && {
              ...payload[id],
            },
          },
        },
        {
          arrayMerge: (_, sourceArray) => sourceArray,
        }
      );
    });
  });
};

const buildGetInsightsActionReducers = (
  builder: ActionReducerMapBuilder<EntityState<Insight> & InsightsState>
) => {
  builder.addCase(getInsights.pending, (state, action) => {
    const { sessionId } = action.meta.arg;

    insightsAdapter.removeMany(state, state.ids);
    if (sessionId) {
      state.sessionConfigs = merge(
        state.sessionConfigs,
        {
          [SessionConfigType.LIST_VIEW]: {
            [sessionId]: {
              operationStatus: {
                status: BDRequestStatus.PENDING,
              },
            },
          },
        },
        {
          arrayMerge: (_, sourceArray) => sourceArray,
        }
      );
    }
  });
  builder.addCase(getInsights.fulfilled, (state, action) => {
    const { sessionId } = action.meta.arg;
    insightsAdapter.upsertMany(state, action.payload.result);
    if (sessionId) {
      state.sessionConfigs = merge(
        state.sessionConfigs,
        {
          [SessionConfigType.LIST_VIEW]: {
            [sessionId]: {
              count: action.payload.result.length || 0,
              operationStatus: {
                status: BDRequestStatus.SUCCEEDED,
              },
            },
          },
        },
        {
          arrayMerge: (_, sourceArray) => sourceArray,
        }
      );
    }
  });
  builder.addCase(getInsights.rejected, (state, action) => {
    const { sessionId } = action.meta.arg;
    if (sessionId) {
      state.sessionConfigs = merge(
        state.sessionConfigs,
        {
          [SessionConfigType.LIST_VIEW]: {
            [sessionId]: {
              operationStatus: {
                status: BDRequestStatus.FAILED,
                errors: [
                  {
                    type: BDAppErrorType.API,
                    ...(action.payload || (action.error as BDError)),
                    requestType: BDRequestType.GET_ALL,
                    severity: SeverityLevel.ERROR,
                  },
                ],
              },
            },
          },
        },
        {
          arrayMerge: (_, sourceArray) => sourceArray,
        }
      );
    }
  });
};

const buildGetInsightDetailsActionReducer = (
  builder: ActionReducerMapBuilder<EntityState<Insight> & InsightsState>
) => {
  builder.addCase(getInsightDetails.pending, (state, action) => {
    const { sessionId } = action.meta.arg;
    if (sessionId) {
      const existingConfigs =
        state.sessionConfigs[SessionConfigType.DETAIL_VIEW] || {};
      state.sessionConfigs[SessionConfigType.DETAIL_VIEW] = {
        ...existingConfigs,
        [sessionId]: {
          ...existingConfigs?.[sessionId],
          operationStatus: {
            status: BDRequestStatus.PENDING,
            errors: [],
          },
        },
      };
    }
  });
  builder.addCase(getInsightDetails.fulfilled, (state, action) => {
    const orderDetails = action.payload.result;
    const existingOrder = state.entities[orderDetails.id];

    if (existingOrder) {
      insightsAdapter.upsertOne(
        state,
        merge<Insight>(existingOrder, orderDetails, {
          arrayMerge: (_, sourceArray) => sourceArray,
        })
      );
    } else {
      insightsAdapter.upsertOne(state, orderDetails);
    }

    const { sessionId } = action.meta.arg;
    if (sessionId) {
      const existingConfigs =
        state.sessionConfigs[SessionConfigType.DETAIL_VIEW] || {};
      state.sessionConfigs[SessionConfigType.DETAIL_VIEW] = {
        ...existingConfigs,
        [sessionId]: {
          ...existingConfigs?.[sessionId],
          operationStatus: {
            status: BDRequestStatus.SUCCEEDED,
            errors: [],
          },
        },
      };
    }
  });
  builder.addCase(getInsightDetails.rejected, (state, action) => {
    const { sessionId } = action.meta.arg;
    if (sessionId) {
      const existingConfigs =
        state.sessionConfigs[SessionConfigType.DETAIL_VIEW] || {};
      state.sessionConfigs[SessionConfigType.DETAIL_VIEW] = {
        ...existingConfigs,
        [sessionId]: {
          ...existingConfigs?.[sessionId],
          operationStatus: {
            status: BDRequestStatus.FAILED,
            errors: [
              {
                type: BDAppErrorType.API,
                ...(action.payload || (action.error as BDError)),
              },
            ],
          },
        },
      };
    }
  });
};

export const INSIGHTS_REDUCERS = {
  reduceSetInsightsSession,
};

export const INSIGHTS_EXTRA_REDUCER_BUILDERS = {
  buildGetInsightsActionReducers,
  buildGetInsightDetailsActionReducer,
};
