import { FormState, getFormAsState } from '@brightdrop/bd-form';
import { User } from '@gm-commercial/profile-model';
import { DateTime } from 'luxon';

import {
  DepotTask,
  Operator,
  ScheduledDateRange,
  StopTask,
  TaskType,
  WorkPlanFormFields,
} from '~/common/models/workplan.model';

import { WORK_PLAN_NAME_PREFIX } from '../form/workplan.form.constants';

export function getWorkPlanScheduledDateDateRangeConfig(
  range: ScheduledDateRange,
  timeZone?: string
): {
  start: DateTime;
  end: DateTime;
} {
  const startOfToday: DateTime = DateTime.now()
    .setZone(timeZone)
    .startOf('day');
  const endOfToday: DateTime = startOfToday.endOf('day');

  switch (range) {
    case ScheduledDateRange.TODAY: {
      return {
        start: startOfToday,
        end: endOfToday,
      };
    }
    case ScheduledDateRange.TOMORROW: {
      const startOfTomorrow = startOfToday.plus({ day: 1 });
      const endOfTomorrow = endOfToday.plus({ day: 1 });
      return {
        start: startOfTomorrow,
        end: endOfTomorrow,
      };
    }
    case ScheduledDateRange.YESTERDAY: {
      const startOfYesterday = startOfToday.minus({ day: 1 });
      const endOfYesterday = endOfToday.minus({ day: 1 });
      return {
        start: startOfYesterday,
        end: endOfYesterday,
      };
    }
    case ScheduledDateRange.THIS_WEEK: {
      const startOfThisWeek = startOfToday.startOf('week');
      const endOfThisWeek = startOfToday.endOf('week');
      return {
        start: startOfThisWeek,
        end: endOfThisWeek,
      };
    }
    case ScheduledDateRange.LAST_WEEK: {
      const startOfLastWeek = startOfToday.startOf('week').minus({ week: 1 });
      const endOfLastWeek = startOfToday.endOf('week').minus({ week: 1 });
      return {
        start: startOfLastWeek,
        end: endOfLastWeek,
      };
    }
  }
}

export const mapUsersToOperators = (users?: User[]): Operator[] => {
  if (users) {
    const operators: Operator[] = users.map((user) => ({
      id: user.id,
      displayName: user.displayName,
    }));
    return operators;
  }
  return [];
};

export const INITIAL_TASK = {
  type: TaskType.STOP,
  address: '',
  instructions: [],
};

export const getWorkPlanFormState = ({
  name,
  scheduledDate,
  organizationsId,
  hubsId,
  fleetsId,
  tasks,
  depotTask,
}: {
  name: string;
  scheduledDate: string;
  organizationsId?: string;
  hubsId?: string;
  fleetsId?: string;
  tasks?: Partial<StopTask>[];
  depotTask?: Partial<DepotTask>;
}): FormState<WorkPlanFormFields> =>
  getFormAsState({
    name,
    scheduledDate,
    organizationsId: organizationsId || '',
    hubsId: hubsId || '',
    fleetsId: fleetsId || '',
    tasks: tasks || [INITIAL_TASK],
    depotTask: depotTask || {},
  });

export const getDefaultWorkPlanName = (dateTime: DateTime): string =>
  WORK_PLAN_NAME_PREFIX + dateTime.toFormat("MMddyyyy'_'HHmmss'");

export const formatCoordinate = (coordinate: number): string => {
  return (coordinate >= 0 ? '' : '-') + Math.abs(coordinate).toFixed(7);
};
