import { PayloadAction } from '@reduxjs/toolkit';
import { useCallback } from 'react';

import {
  DEFAULT_PAGINATION_MAX_ROWS_PER_PAGE,
  DEFAULT_PAGINATION_PAGE,
} from '../constants/common.constant';
import { ApiResponse } from '../models/apis/apiResponse.model';
import { PagedResultWithErrors } from '../models/common.model';
import { BDError } from '../models/error.model';

export type GetPageSuccess<T> = PayloadAction<
  ApiResponse<PagedResultWithErrors<T>> | undefined,
  string,
  { requestStatus: 'fulfilled' }
>;

export type GetPageFailure<T> = PayloadAction<
  BDError<ApiResponse<PagedResultWithErrors<T> | null> | null> | undefined,
  string,
  { requestStatus: 'rejected' }
>;

export type GetPageResponse<T> = Promise<GetPageSuccess<T> | GetPageFailure<T>>;

const useGetAsyncPagedResults = <T>({
  action,
  rowsPerPage = DEFAULT_PAGINATION_MAX_ROWS_PER_PAGE,
}: {
  action: (page: number) => GetPageResponse<T>;
  rowsPerPage?: number;
}): {
  getPageResults: (page: number, limit: number) => Promise<T[]>;
  getAllResults: () => Promise<T[] | undefined>;
} => {
  const getPageResults: (page: number, limit: number) => Promise<T[]> =
    useCallback(
      async (page: number, limit: number) => {
        const pageItems = await new Promise<T[]>((resolve, reject) =>
          action(page)
            .then((pageResult) => {
              if (
                pageResult?.meta?.requestStatus === 'fulfilled' &&
                !(pageResult?.payload instanceof Error) &&
                pageResult?.payload?.result?.items &&
                pageResult?.payload?.result?.total_items
              ) {
                resolve(pageResult.payload.result.items);
              }
            })
            .catch((err) => {
              reject(err);
            })
        );
        return [
          ...pageItems,
          ...(page < limit ? await getPageResults(page + 1, limit) : []),
        ];
      },
      [action]
    );

  const getAllResults = useCallback(async () => {
    const initialPage = await action(DEFAULT_PAGINATION_PAGE);

    if (
      initialPage?.meta.requestStatus === 'fulfilled' &&
      !(initialPage?.payload instanceof Error) &&
      initialPage?.payload?.result?.items &&
      initialPage?.payload?.result?.total_items
    ) {
      const firstPageItems = initialPage.payload.result.items || [];
      const itemCount = initialPage.payload.result.total_items || 0;

      if (itemCount > rowsPerPage) {
        const pageCount = Math.ceil(itemCount / rowsPerPage) - 1;

        const otherPageItems = await getPageResults(1, pageCount);
        return firstPageItems.concat(otherPageItems);
      } else {
        return firstPageItems;
      }
    }
  }, [action, getPageResults, rowsPerPage]);

  return { getPageResults, getAllResults };
};

export default useGetAsyncPagedResults;
