import { AxiosError, isAxiosError } from 'axios';

import { RootState } from '../../app/rootReducer';
import { BDError } from '../models/error.model';

export const mapError = <T = null>(
  err: AxiosError<T> | BDError<unknown>,
  config = {} as { data?: unknown; state?: RootState }
): BDError<T> => {
  const { name, message, stack } = err;
  const bdError: BDError<T> = {
    name,
    message,
    stack,
    data: config?.data,
  };

  if (isAxiosError(err) && err.response) {
    const { status, statusText, data } = err.response;
    bdError.status = status;
    bdError.message =
      bdError.message && statusText
        ? `${bdError.message}: ${statusText}`
        : bdError.message || statusText;
    bdError.response = data;
  }

  return bdError;
};
