import { Profile } from '@gm-commercial/profile-model';
import { createContext } from 'react';

export interface ProfileContextProps {
  profile?: Profile;
  // Added for parity with BD core, but probably needs to be removed once all BD features are removed, since 'View as' is not part of the CS platform
  actingProfile?: Profile;
}

const ProfileContext = createContext({} as ProfileContextProps);

export default ProfileContext;
