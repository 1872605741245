import { ApiJob, Entity, JobOutcome, JobStatus } from './common.model';

export enum UploadHistoryJobStatus {
  DONE = 'done',
  QUEUED = 'queued',
  RUNNING = 'running',
  ERROR = 'error',
}

export enum DispatchWorkspaceTab {
  UPLOAD_HISTORY = 'upload_history',
  MANAGE_WORK_PLANS = 'manage_work_plans',
}

export enum UploadJobResultType {
  PASSED = 'passed_work_plans',
  FAILED = 'failed_work_plans',
}

export interface UploadHistoryResult {
  succeededImportCount: number;
  failedImportCount: number;
}

export interface UploadWorkPlanHistory extends Entity {
  uploadedTime: string;
  jobStatus: UploadHistoryJobStatus;
  diagnostics: WorkPlanJobDiagnostics[];
  result?: UploadHistoryResult;
  actions?: boolean;
  organizationId?: string;
  hubId?: string;
  fleetId?: string;
}

export interface ApiJobWorkPlanHistory {
  totalElements: number;
  content: ApiWorkPlanJob[];
}

export interface WorkPlanJobDiagnostics {
  id: string;
  name: string;
  status: JobOutcome;
  scheduledDate: string;
  errors?: WorkPlanJobErrorDetail[];
  message?: string;
}

export interface WorkPlanJobErrorDetail {
  errorCode: string;
  errorMessage: string;
  line?: number;
}

export interface ApiWorkPlanJobDiagnostics {
  status: JobOutcome;
  errorCode?: string;
  errorMessage?: string;
  workplans?: Array<{
    id: string;
    name: string;
    status: JobOutcome;
    scheduledDate: string | undefined;
    errors?: WorkPlanJobErrorDetail[];
  }>;
}

export interface ApiWorkPlanJob extends ApiJob {
  status: {
    status: JobStatus;
    lastUpdatedTimestamp: string;
    startedTimestamp?: string;
    endedTimestamp?: string;
    diagnostics?: ApiWorkPlanJobDiagnostics;
  };
  parameters: {
    fileUrl: string;
    fileName: string;
    fileContentType: string;
    organizationId: string;
    hubId: string;
    fleetId: string;
  };
}

export enum UploadWorkPlanHistoryFieldType {
  STATUS = 'status.status',
  FILE_NAME = 'parameters.fileName',
  JOB_TYPE = 'jobType',
  HUB_ID = 'parameters.hubId',
  FLEET_ID = 'parameters.fleetId',
  CREATED_DATE = 'createdDate',
}
