import { Configuration, LogLevel, RedirectRequest } from '@azure/msal-browser';

// Config options used to instantiate PublicClientApplication for authentication via MSAL
// https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/configuration.md
export const msalConfig: Configuration = {
  auth: {
    clientId: globalThis.appConfig.auth.clientId,
    authority: globalThis.appConfig.auth.authority,
    knownAuthorities: [globalThis.appConfig.auth.knownAuthority],
    redirectUri:
      globalThis.appConfig.auth.staticLoginRedirectUri !== ''
        ? globalThis.appConfig.auth.staticLoginRedirectUri
        : `${globalThis.appConfig.auth.redirectUri}/auth/sso`,
    postLogoutRedirectUri:
      window?.location?.origin !== undefined
        ? `${window?.location?.origin}/auth/sso/login`
        : `${globalThis.appConfig.auth.redirectUri}/auth/sso/login`,
  },
  cache: {
    cacheLocation: 'localStorage', // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO between tabs.
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
  system:
    process.env.NODE_ENV === 'production'
      ? undefined
      : {
          loggerOptions: {
            loggerCallback: (
              level: LogLevel,
              message: string,
              containsPii: boolean
            ): void => {
              if (containsPii) {
                console.log(
                  'BrightDrop Web - MSAL operation contains PII, skipping...'
                );
                return;
              }
              let consoleOperation = console.log;
              switch (level) {
                case LogLevel.Error: {
                  consoleOperation = console.error;
                  break;
                }
                case LogLevel.Info: {
                  consoleOperation = console.log;
                  break;
                }
                case LogLevel.Trace: {
                  consoleOperation = console.trace;
                  break;
                }
                case LogLevel.Verbose: {
                  consoleOperation = console.debug;
                  break;
                }
                case LogLevel.Warning: {
                  consoleOperation = console.warn;
                  break;
                }
              }
              consoleOperation('BrightDrop Web - MSAL: ', message);
            },
            piiLoggingEnabled: false,
          },
        },
};

export const msalCdiConfig = {
  ...msalConfig,
  auth: {
    ...msalConfig.auth,
    redirectUri:
      globalThis.appConfig.auth.staticLoginRedirectUri !== ''
        ? globalThis.appConfig.auth.staticLoginRedirectUri
        : `${globalThis.appConfig.auth.redirectUri}/auth/cdi`,
    postLogoutRedirectUri: `${globalThis.appConfig.auth.cdiLogoutBaseUrl}?post_logout_redirect_uri=${window.location.origin}/auth/cdi/login`,
    authority: globalThis.appConfig.auth.cdiAuthority,
  },
};

// Add here scopes for id token to be used at MS Identity Platform endpoints.
export const loginRequest: RedirectRequest = {
  scopes: [globalThis.appConfig.auth.clientId],
  prompt: 'login',
};

export const DEFAULT_TIMEOUT = 1800; // seconds
export const TIMEOUT_MODAL_THRESHOLD = 450; // seconds
export const LOGIN_ATTEMPT_TIMEOUT = 10000;
export const MAX_LOGIN_ATTEMPTS = 3;
