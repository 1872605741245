import { useTranslations } from '@brightdrop/localization-client';
import { Box, Typography } from '@mui/material';

import { InsightChartTooltip } from '~/common/models/insight.model';

import useStyles from './BatteryHealthChartTooltip.styles';

export interface BatteryHealthChartToolTipProps {
  tooltip: InsightChartTooltip;
}

const MESSAGES = {
  'common:timeMeasure.hour_long_short_plural': 'Hrs',
};

const BatteryHealthChartToolTip = ({
  tooltip,
}: BatteryHealthChartToolTipProps): JSX.Element => {
  const { classes } = useStyles();
  const { translations } = useTranslations(MESSAGES);

  return (
    <>
      <Box
        className={classes.toolTip}
        style={{
          top: tooltip.top,
          left: tooltip.left,
          opacity: tooltip.opacity,
        }}
      >
        <Box className={classes.title}>
          <Typography
            variant="h5_deprecated"
            data-testid="battery-health-chart-tooltip-title"
          >
            {tooltip.title}
          </Typography>
        </Box>
        <Box className={classes.bodyWrapper}>
          <Box className={classes.body} style={{ color: tooltip.colors[0] }}>
            <Typography
              variant="h5_deprecated"
              data-testid="battery-health-chart-tooltip-body-label"
            >
              {tooltip.bodyLabels[0]}
            </Typography>
          </Box>
          <Box>
            <Typography
              variant="h5_deprecated"
              data-testid="battery-health-chart-tooltip-body-value"
            >
              {`${tooltip.bodyValues[0]} ${translations[
                'common:timeMeasure.hour_long_short_plural'
              ].toLowerCase()}`}
            </Typography>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default BatteryHealthChartToolTip;
