import { FeatureFlagsProvider } from '@brightdrop/feature-flags-client';
import { useTranslations } from '@brightdrop/localization-client';
import { createBDTheme } from '@brightdrop/mui-theme-override';
import { TelemetryProvider } from '@brightdrop/telemetry-client';
import { datadogRum } from '@datadog/browser-rum';
import { ProfileContextProvider } from '@gm-commercial/profile-context';
import {
  Box,
  CssBaseline,
  StyledEngineProvider,
  ThemeProvider,
} from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { useEffect, useMemo } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { useSelector } from 'react-redux';
import { BrowserRouter as Router, matchPath } from 'react-router-dom';

import {
  DATADOG_LOGS_DEFAULT_CONFIG,
  DATADOG_RUM_DEFAULT_CONFIG,
} from '~/common/configs/instrumentation.config';
import { UserSupportedLocale } from '~/common/models/common.model';
import { getNoAsyncFeatureFlagRoutes } from '~/common/utils/route/route.utils';

import GlobalNavWrapper from '../features/navigationDrawer/GlobalNavWrapper';
import { setCurrentLocale } from '../features/profile/profileSlice';
import {
  selectActingProfile,
  selectCurrentLocale,
  selectProfile,
} from '../features/profile/profileSlice.selectors';
import ScrollToTop from '../features/scrollToTop/ScrollToTop';
import AppContent from './appContent/AppContent';
import ErrorFallback from './errorFallback/ErrorFallback';
import { useAppDispatch } from './store';

const MESSAGES = {
  'common:filterKeywords': 'FILTER KEYWORDS',
  'common:alertMsg.messageView.pageNotFound.header': 'Page not found',
  'common:alertMsg.messageView.pageNotFound.button': 'Return home',
  'common:alertMsg.messageView.unauthorized.header': 'Unauthorized Access',
  'common:alertMsg.messageView.unauthorized.body':
    'Your account does not have access to this page.',
  'common:alertMsg.messageView.unauthorized.button': 'Go to main page',
  'common:alertMsg.messageView.inviteRequired.header': 'Invite Required',
  'common:alertMsg.messageView.inviteRequired.body':
    'Please contact your manager for access to the BrightDrop application.',
  'common:alertMsg.messageView.serviceUnavailable.header':
    'Temporarily unavailable',
  'common:alertMsg.messageView.serviceUnavailable.body': `This page isn't available right now. Please try again later.`,

  'common:alertMsg.messageView.mobileAppRequired.header': 'Mobile App Required',
  'common:alertMsg.messageView.mobileAppRequired.body':
    'BrightDrop Operator may only access the system through the BrightDrop mobile app.  Please contact your manager to request access to the BrightDrop mobile application.',
  'common:cancel': 'Cancel',
  'common:ok': 'OK',
};

const APPLICATION_NAME = 'BrightDrop Web';

const LOCALIZATION_UNSUPPORTED_LOCALES = [UserSupportedLocale.en_CA];

const logError = (error: Error, info: { componentStack: string }) => {
  datadogRum.addError(error, info);
};

const App = (): JSX.Element => {
  const { translations, instance, updateLocale } = useTranslations(MESSAGES);
  const { pathname } = location;
  const matchNoAsyncFeatureFlagRequiredRoutes = matchPath(pathname, {
    path: getNoAsyncFeatureFlagRoutes(),
  });
  const tableFilterMessages = useMemo(
    () => ({
      filterKeywords: translations['common:filterKeywords'],
    }),
    [translations]
  );
  const dispatch = useAppDispatch();
  const locale = useSelector(selectCurrentLocale);
  const profile = useSelector(selectProfile);
  const actingProfile = useSelector(selectActingProfile);

  const localizationProviderLocale = useMemo(() => {
    return locale &&
      LOCALIZATION_UNSUPPORTED_LOCALES.includes(locale as UserSupportedLocale)
      ? UserSupportedLocale.en_US
      : locale;
  }, [locale]);

  useEffect(() => {
    //Set browser locale only once to do not override profile locale
    dispatch(setCurrentLocale(instance.language));
  }, [dispatch, instance.language]);

  useEffect(() => {
    if (locale) {
      updateLocale(locale);
    }
  }, [updateLocale, locale]);

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={createBDTheme(tableFilterMessages)}>
        <CssBaseline />

        <Router>
          <TelemetryProvider
            applicationName={APPLICATION_NAME}
            sessionTackingConfig={DATADOG_RUM_DEFAULT_CONFIG}
            logTrackingConfig={DATADOG_LOGS_DEFAULT_CONFIG}
            withSessionTracking={process.env.NODE_ENV === 'production'}
            withLogTracking={process.env.NODE_ENV === 'production'}
          >
            <ErrorBoundary FallbackComponent={ErrorFallback} onError={logError}>
              <LocalizationProvider
                dateAdapter={AdapterLuxon}
                adapterLocale={localizationProviderLocale}
                localeText={{
                  cancelButtonLabel: translations['common:cancel'],
                  okButtonLabel: translations['common:ok'],
                }}
              >
                <Box display={'flex'} width={'100%'}>
                  <ScrollToTop />
                  <ProfileContextProvider
                    profile={profile}
                    actingProfile={actingProfile}
                  >
                    <FeatureFlagsProvider
                      clientToken={globalThis.appConfig.featureFlagProviderKey}
                      async={!matchNoAsyncFeatureFlagRequiredRoutes}
                    >
                      <GlobalNavWrapper>
                        <AppContent />
                      </GlobalNavWrapper>
                    </FeatureFlagsProvider>
                  </ProfileContextProvider>
                </Box>
              </LocalizationProvider>
            </ErrorBoundary>
          </TelemetryProvider>
        </Router>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

export default App;
