import {
  DEFAULT_EMPTY_VALUE,
  SearchType,
} from '~/common/constants/common.constant';
import {
  RootWorkPlan,
  RootWorkPlanRequestPayload,
  WorkPlanStatus,
} from '~/common/models/workplan.model';

import { WorkPlanListItemDetails } from './WorkPlanList.model';

type Labels = { [index: string]: string };

export const getWorkPlanStatusLabel = (
  status: WorkPlanStatus,
  labels: Labels
): string => {
  switch (status) {
    case WorkPlanStatus.STARTED:
      return labels['dispatch:tab.workplans.status.started'];
    case WorkPlanStatus.PENDING:
      return labels['dispatch:tab.workplans.status.pending'];
    case WorkPlanStatus.ABORTED:
      return labels['dispatch:tab.workplans.status.aborted'];
    case WorkPlanStatus.FINISHED:
      return labels['dispatch:tab.workplans.status.finished'];
    default:
      return DEFAULT_EMPTY_VALUE;
  }
};

export const mapWorkPlansToListItemDetails = (
  data: Array<RootWorkPlan>
): Array<WorkPlanListItemDetails> => {
  return data
    .filter((record) => !record?.deleted)
    .map<WorkPlanListItemDetails>((record: RootWorkPlan) => ({
      id: record.id,
      organizationId: record.organizationId,
      hubId: record.hubId,
      fleetId: record.fleetId,
      name: record.name,
      status: record.status.toLowerCase() as WorkPlanStatus,
      fileName: record.fileName || null,
      scheduledDate: record.scheduledDate.replace(/-/g, '/'),
      numberOfStops: record.stopCount || 0,
      createdAt: record.createdAt,
      assignedOperator: record.operator || null,
      assignedVehicle: record.vehicle || null,
    }));
};

export const getSearchCriteriaRequestKey = (
  type: SearchType
): RootWorkPlanRequestPayload | undefined => {
  switch (type) {
    case SearchType.WORK_PLAN_NAME:
      return RootWorkPlanRequestPayload.NAME;
    case SearchType.WORK_PLAN_STATUS:
      return RootWorkPlanRequestPayload.STATUS;
    case SearchType.FILE_NAME:
      return RootWorkPlanRequestPayload.FILE_NAME;
  }
};

export const isOperatorFieldEditable = (
  scheduledDate?: string,
  status?: WorkPlanStatus
) => {
  if (scheduledDate && status) {
    const isTodayOrFutureDate =
      new Date().setHours(0, 0, 0, 0) <=
      new Date(scheduledDate).setHours(0, 0, 0, 0);
    const isPending = status.toLowerCase() === WorkPlanStatus.PENDING;
    return isTodayOrFutureDate && isPending;
  }
  return false;
};
