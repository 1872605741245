import { Asset } from './asset.model';
import { Entity } from './common.model';

export enum UtilizationReportType {
  DISTANCE_DRIVEN = 'distanceDriven',
  VEHICLE_ON_TIME = 'vehicleOnTime',
  ENERGY_CONSUMPTION = 'energyConsumption',
  ENERGY_CONSUMPTION_RATE = 'energyConsumptionRate',
}

export enum UtilizationViewType {
  DASHBOARD = 'dashboard',
  ASSET_DETAILS = 'asset_details',
}
export interface UtilizationSummaries {
  timeUnitSummaries: TimePeriodUtilizationSummary[];
  assetSummaries: AssetUtilizationSummary[];
  utilizedDates: string[];
  daysOfOperation: number;
}

export interface UtilizationSummary {
  timeDriven: number;
  distanceDriven: number;
  daysDriven?: number;
  energyUsed?: number;
  energyUsedRate?: number;
  rangeUsed?: number;
  batteryUsed?: number;
}

export interface AssetUtilizationSummary extends UtilizationSummary {
  id: string;
  daysDriven: number;
  averageDistanceDriven?: number;
}

export interface TimePeriodUtilizationSummary extends UtilizationSummary {
  date: string;
  numberOfVehicles: number;
  batteryStart: number;
  batteryEnd: number;
  estimatedRangeStart: number;
  estimatedRangeEnd: number;
  odometerEnd: number;
  odometerStart: number;
  ignitionStart: string;
  ignitionEnd: string;
  distanceDriven: number;
  daysDriven: number;
  vehicleId?: string;
  timeZone?: string | null;
}

export type UtilizationDetail = Asset & AssetUtilizationSummary;
export type SingleUtilizationDetail = Entity & TimePeriodUtilizationSummary;
