import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  formControl: {
    width: '100%',
  },
  label: {
    color: theme.new.color.textSecondary,
    fontSize: 14,
    lineHeight: 1.6,
    transform: 'translate(16px, 12px) scale(1)',
    '&.MuiInputLabel-root.MuiInputLabel-shrink': {
      transform: 'translate(16px, 7px) scale(.75)',
    },
  },
  select: {
    '&.MuiInputBase-root': {
      height: 48,
      '&.error': {
        borderColor: theme.new.color.errorPrimary,
      },
    },
    '.MuiInputBase-input': {
      padding: '12px 32px 12px 16px',
      '&.MuiFilledInput-input': {
        padding: '20px 40px 4px 16px',
      },
    },
    'svg.MuiSelect-icon': {
      insetInlineEnd: 12,
      '&.Mui-disabled > path': {
        fill: 'transparent',
      },
    },
  },
  helperTextWrapper: {
    margin: '4px 0 0',
  },
  helperText: {
    alignItems: 'center',
    display: 'flex',
    gap: 6,
    '&.error': {
      color: theme.new.color.errorSecondary,
    },
  },
}));

export default useStyles;
