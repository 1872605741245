import { useEffect } from 'react';

const DEFAULT_TITLE = 'GM Envolve';
const usePageTitle = (title?: string): void => {
  useEffect(() => {
    document.title = title ? `${DEFAULT_TITLE} - ${title}` : DEFAULT_TITLE;

    return () => {
      document.title = DEFAULT_TITLE;
    };
  }, [title]);
};

export default usePageTitle;
