export const INSIGHT_TRANSLATION_PREFIX = 'insight:messages.';

export const INSIGHT_DEFAULT_TARGET_SOC = 80;

export const INSIGHTS_MESSAGES = {
  'insight:messages.insights.common.saveUpTo': 'Save up to',
  'insight:messages.insights.common.estimatedSavingsPerMonthMessage':
    '{{savingsInLcuPerMonth}} / month',
  'insight:messages.insights.common.perVehicle': 'Per Vehicle',
  'insight:messages.insights.demandCost.recommendationStaggerCharging':
    'Stagger fleet charging to avoid sharp peaks in demand',
  'insight:messages.insights.demandCost.plot.demandCost':
    '{{ demandCostInLcuPerKwMonth, currency }} / kW',
  'insight:messages.insights.demandCost.insightDetail.bodyText':
    'Peak power demand (kW) is the rate at which you consume energy. Higher demand enables faster charging, though is can be more expensive. Demand charges ($/kW) are incremental to energy cost.',
  'insight:messages.insights.demandCost.insightDetail.recommendationReducePower':
    'Fast charging can be convenient, but has implications for your wallet or battery. Opting for lower peak power demand can reduce the cost of charging and benefit your vehicle battery long-term.',
  'insight:messages.insights.demandCost.insightDetail.recommendationSpaceOutCharging':
    'Space out the start times of each vehicle’s charging to reduce strain on the electrical grid and lower fleet-wide power demand.',
  'insight:messages.insights.energyCost.recommendationChargeDuringOffPeakHours':
    'Charge vehicles during off-peak hours',
  'insight:messages.insights.energyCost.plot.energyCost':
    '{{ energyCostInLcuPerKwh, currency }} / kWh',
  'insight:messages.insights.energyCost.insightDetail.bodyText':
    'Energy price ($/kWh) from your utility provider varies throughout the day.',
  'insight:messages.insights.energyCost.insightDetail.detailRecommendationChargeDuringOffPeakHours':
    'Shifting your charge schedule to off-peak hours can help you take advantage of lower electricity costs. Off-peak hours are typically during the night or early morning when demand is lower.',
  'insight:messages.insights.energyCost.insightDetail.detailRecommendationReduceStrainOnGrid':
    'Charging during off-peak hours can also help reduce the strain on the electrical grid.',
  'insight:messages.insights.batteryHealth.improveBatteryHealth':
    'Improve long-term battery health',
  'insight:messages.insights.batteryHealth.insightDetail.bodyText':
    'State of charge represents the vehicle’s battery level of charge or range at any given point in time.',
  'insight:messages.insights.batteryHealth.recommendationChargeMax':
    'Charge vehicles to a maximum of {{socThreshold}}%',
  'insight:messages.insights.batteryHealth.insightDetail.detailRecommendationReduceTimeAboveSocThreshold':
    'Reducing the amount of time your vehicle’s battery spends at a state of charge above {{socThreshold}}% can improve long-term battery health.',
  'insight:messages.insights.batteryHealth.insightDetail.detailRecommendationSetSocTargetBelowThreshold':
    'It is recommended to set your target state of charge level to {{socThreshold}}% unless your duty cycle requires more range.',
  'insight:messages.insights.electricityCost.noRecommendationMessage':
    'Charging is being done efficiently',
  'insight:messages.insights.electricityCost.noRecommendationChargeDuringOffPeakHours':
    'You are charging your vehicles during off-peak hours',
  'insight:messages.insights.electricityCost.noRecommendationStaggerCharging':
    'You are staggering your fleet charging',
  'insight:messages.insights.batteryHealth.noRecommendationMessage':
    "You've taken steps to maintain long term battery health",
  'insight:messages.insights.batteryHealth.noRecommendationChargeMax':
    'You are charging your vehicles to a maximum of {{socThreshold}}%',
  'insight:messages.insights.batteryHealth.enableChargingScehdule':
    'Enable the charging schedule and set target state of charge to {{targetSoc}}% in the',
};
