export enum BDQueryCriteriaType {
  IS_EQUAL = 'IS_EQUAL',
  STARTS_WITH = 'STARTS_WITH',
  IN = 'IN',
  NOT_IN = 'NOT_IN',
  IS_NOT_NULL = 'IS_NOT_NULL',
  IS_NULL = 'IS_NULL',
  AND = 'AND',
  OR = 'OR',
  GREATER_THAN = 'GREATER_THAN',
  LESS_THAN = 'LESS_THAN',
  CONTAINING = 'CONTAINING',
  BETWEEN = 'BETWEEN',
  ARRAY_CONTAINS = 'ARRAY_CONTAINS',
}

export interface BDQuery {
  qualifier: BDQueryCriteriaType;
  field?: string;
  values?: Array<string | boolean | number>;
  leftQuery?: BDQuery;
  rightQuery?: BDQuery;
}

export interface BDSort {
  direction: BDSortDirection;
  properties: Array<string>;
}

export type BDSortDirection = 'ASC' | 'DESC';
