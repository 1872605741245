import { BDDialog as BDDialogUI, DialogWrapperProps } from '@brightdrop/bd-ui';
import { useTranslations } from '@brightdrop/localization-client';

const MESSAGES = {
  'common:form.closeDialog': 'Close the dialog',
  'common:form.dialogWindow': 'Dialog window',
};

const BDDialog = <T,>(props: DialogWrapperProps<T>): JSX.Element => {
  const { translations } = useTranslations(MESSAGES);

  return (
    <BDDialogUI
      {...props}
      labels={{
        close: translations['common:form.closeDialog'],
        aria: translations['common:form.dialogWindow'],
      }}
    />
  );
};
export default BDDialog;
