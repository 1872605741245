import { cx } from '@emotion/css';
import BuildCircleOutlinedIcon from '@mui/icons-material/BuildCircleOutlined';
import { Box, Typography } from '@mui/material';
import { DateTime } from 'luxon';
import { MUIDataTableMeta, MUISortOptions } from 'mui-datatables';
import { Link } from 'react-router-dom';

import ImageWithFallback from '~/common/components/ImageWithFallback/ImageWithFallback';
import { mapperSortCompare } from '~/common/components/table/utils/table.utils';
import {
  ASSET_TIRE_PRESSURE_UNIT,
  DEFAULT_EMPTY_VALUE,
  UnitsType,
} from '~/common/constants/common.constant';
import {
  AssetServiceStatus,
  DiagnosticSeverity,
} from '~/common/models/asset.model';
import {
  AssetReportItem,
  BatteryChargingStatus,
  EstimatedRangedInKM,
  LastCommunicated,
  PowerType,
  ReportDiagnosticState,
  VehicleDetailsType,
} from '~/common/models/asset-report.model';
import { RouteName } from '~/common/models/route.model';
import { TableColumnConfig } from '~/common/models/table/table.model';
import { BATTERY_RANGE_VALUE_MAPPERS } from '~/common/utils/battery.utils';
import { capitalize } from '~/common/utils/common.utils';
import { getBatteryIcon } from '~/common/utils/icons/icons.utils';
import { findRoute, makeRoutePath } from '~/common/utils/route/route.utils';

import {
  convertDistance,
  convertTirePressure,
} from '../../details/utils/assetDetails.utils';
import { getTargetSocValue } from '../../utils/assets.utils';
import { ASSET_LIST_REPORT_MESSAGES } from '../AssetListReportView.messages';
import {
  formatEstTimeToCompletion,
  formatLastDateCommunicated,
  getFuelLevelDiagnosticStatus,
  getReportDiagnosticStatus,
  getVehicleTypeDetails,
} from '../utils/report.utils';

const ASSET_ID_INDEX = 0;
export const TYPE_COLUMN_INDEX = 5;
const LICENSE_PLATE_INDEX = 6;
const FUEL_LEVEL_INDEX = 14;
const VEHICLE_TYPE_INDEX = 7;
const MINUTE = 1;
export const ASSET_REPORT_ADAPTERS = {
  assetType: (
    type: Partial<{
      model: string;
      version: string;
    }>
  ) =>
    type?.model && type?.version
      ? `${type?.model} ${type?.version}`
      : type?.model || DEFAULT_EMPTY_VALUE,
  diagnosticsHealth: (
    valueLabels: typeof ASSET_LIST_REPORT_MESSAGES.valueLabels,
    diagnostic?: ReportDiagnosticState | null
  ) => {
    if (diagnostic === ReportDiagnosticState.CRITICAL) {
      return capitalize(
        valueLabels[`asset:diagnosticsHealth.${DiagnosticSeverity.CRITICAL}`]
      );
    }
    if (diagnostic === ReportDiagnosticState.IMPAIRED) {
      return capitalize(
        valueLabels[`asset:diagnosticsHealth.${DiagnosticSeverity.IMPAIRED}`]
      );
    }
    if (diagnostic === ReportDiagnosticState.CLEAR) {
      return valueLabels['common:ok'];
    }

    return valueLabels['common:unknown'];
  },
  availability: (
    valueLabels: typeof ASSET_LIST_REPORT_MESSAGES.valueLabels,
    availability?: AssetServiceStatus
  ) => {
    if (availability) {
      return valueLabels[`asset:serviceStatus.${availability}`];
    }
    return DEFAULT_EMPTY_VALUE;
  },
  tirePressure: (units: UnitsType) => (value?: number) =>
    typeof value === 'number' &&
    `${Math.round(
      convertTirePressure(value, ASSET_TIRE_PRESSURE_UNIT, units.tirePressure)
    )} ${units.tirePressure}`,
  batteryValue: (battery?: AssetReportItem['battery']) => {
    if (typeof battery?.value !== 'number') {
      return DEFAULT_EMPTY_VALUE;
    }
    if (battery?.range !== undefined) {
      return `${BATTERY_RANGE_VALUE_MAPPERS[battery.range](battery.value)}%`;
    }
    return `${Math.trunc(battery?.value)}%`;
  },
  estimatedRange:
    (units: UnitsType) => (estimatedRange?: EstimatedRangedInKM) => {
      return typeof estimatedRange?.value === 'number'
        ? `${Math.trunc(
            convertDistance(
              estimatedRange.value,
              estimatedRange.unitOfMeasure,
              units.distance
            )
          )} ${units.distance}`
        : DEFAULT_EMPTY_VALUE;
    },
  stateOfCharge: (units: UnitsType, battery?: AssetReportItem['battery']) => {
    if (typeof battery?.value !== 'number') {
      return DEFAULT_EMPTY_VALUE;
    }

    if (battery?.range !== undefined) {
      if (typeof battery?.estimatedRange?.value === 'number') {
        return `${BATTERY_RANGE_VALUE_MAPPERS[battery.range](
          battery.value
        )}% (${Math.round(
          convertDistance(
            battery?.estimatedRange.value,
            battery?.estimatedRange.unitOfMeasure,
            units.distance
          )
        )} ${units.distance.toLocaleLowerCase()})`;
      }
      return `${BATTERY_RANGE_VALUE_MAPPERS[battery.range](battery.value)}%`;
    }

    return typeof battery?.estimatedRange?.value === 'number'
      ? `${Math.trunc(battery?.value)}% (${Math.round(
          convertDistance(
            battery?.estimatedRange.value,
            battery?.estimatedRange.unitOfMeasure,
            units.distance
          )
        )} ${units.distance.toLocaleLowerCase()})`
      : `${Math.trunc(battery?.value)}%`;
  },
  stateOfChargeWithIcon: (
    units: UnitsType,
    battery?: AssetReportItem['battery']
  ) => {
    if (typeof battery?.value === 'number') {
      const icon = getBatteryIcon(battery);
      const value = (
        <Box display="flex" alignItems="center" data-testid="soc-with-icon">
          {icon}
          <span>{ASSET_REPORT_ADAPTERS.stateOfCharge(units, battery)}</span>
        </Box>
      );
      return value;
    }
    return DEFAULT_EMPTY_VALUE;
  },
  chargingStatus: (
    valueLabels: typeof ASSET_LIST_REPORT_MESSAGES.valueLabels,
    status?: BatteryChargingStatus
  ) => {
    return status &&
      status !== BatteryChargingStatus.NO_DATA &&
      status !== BatteryChargingStatus.UNKNOWN
      ? valueLabels[`asset:filterModalLabels.chargingStatus.${status}`]
      : DEFAULT_EMPTY_VALUE;
  },
  powerType: (
    valueLabels: typeof ASSET_LIST_REPORT_MESSAGES.valueLabels,
    type?: PowerType,
    isNotPluggedIn?: boolean
  ) => {
    return type && type !== PowerType.UNKNOWN && !isNotPluggedIn
      ? valueLabels[`asset:power.${type}`]
      : DEFAULT_EMPTY_VALUE;
  },
  targetSOC: (
    status?: BatteryChargingStatus,
    targetSOC?: {
      value?: number;
      estimatedRange?: EstimatedRangedInKM;
    }
  ) => {
    if (
      typeof targetSOC === 'object' &&
      typeof targetSOC.value === 'number' &&
      status !== undefined
    ) {
      return getTargetSocValue(targetSOC.value, status);
    }
    return DEFAULT_EMPTY_VALUE;
  },
  estimatedTimeSummary: (
    timeFormat: string,
    duration?: number,
    locale?: string,
    timeZone?: string,
    date?: string,
    status?: BatteryChargingStatus
  ) => {
    return status !== BatteryChargingStatus.INTERRUPTED &&
      status !== BatteryChargingStatus.NOT_PLUGGED_IN
      ? formatEstTimeToCompletion(
          timeFormat,
          duration,
          locale,
          timeZone,
          date,
          true
        )
      : DEFAULT_EMPTY_VALUE;
  },
  lastCommunicated: (
    valueLabels: typeof ASSET_LIST_REPORT_MESSAGES.valueLabels,
    lastCommunicated?: {
      status?: LastCommunicated;
      deviceTimestamp?: string;
    },
    locale?: string
  ) => {
    if (lastCommunicated?.deviceTimestamp) {
      const now = DateTime.utc();
      const newDateTime = DateTime.fromISO(lastCommunicated.deviceTimestamp, {
        zone: 'utc',
      });
      const diffInMinutes = now.diff(newDateTime, 'minutes').minutes;

      //If event was < 1 min ago, show Just Now
      if (Math.abs(diffInMinutes) < MINUTE) {
        return valueLabels['asset:justNow'];
      } else {
        const formatedLastCommunicatedDate = formatLastDateCommunicated(
          lastCommunicated.deviceTimestamp,
          'long',
          locale
        );
        return formatedLastCommunicatedDate
          ? `${formatedLastCommunicatedDate} ${valueLabels['asset:ago']}`
          : DEFAULT_EMPTY_VALUE;
      }
    }
    return DEFAULT_EMPTY_VALUE;
  },

  fuel: (fuel?: AssetReportItem['fuel']) => {
    return typeof fuel?.value === 'number'
      ? `${Math.round(fuel.value)}%`
      : DEFAULT_EMPTY_VALUE;
  },

  oilLife: (oilLife?: AssetReportItem['oilLife']) => {
    return typeof oilLife?.value === 'number'
      ? `${Math.round(oilLife.value)}%`
      : DEFAULT_EMPTY_VALUE;
  },

  tireStatus: (
    valueLabels: typeof ASSET_LIST_REPORT_MESSAGES.valueLabels,
    status?: AssetReportItem['tires']
  ) => {
    return status !== undefined
      ? valueLabels[
          `asset:filterModalLabels.tireStatus.${status}` as keyof typeof valueLabels
        ]
      : DEFAULT_EMPTY_VALUE;
  },

  fluidDiagnostic: (
    valueLabels: typeof ASSET_LIST_REPORT_MESSAGES.valueLabels,
    fluidDiagnostic?: AssetReportItem['brakeFluid']
  ) => {
    return fluidDiagnostic !== undefined
      ? valueLabels[
          `asset:filterModalLabels.fluidStatus.${fluidDiagnostic}` as keyof typeof valueLabels
        ]
      : DEFAULT_EMPTY_VALUE;
  },

  brakePadRear: (brakePadRear?: AssetReportItem['brakePadRear']) => {
    return typeof brakePadRear?.value === 'number'
      ? `${Math.round(brakePadRear.value)}%`
      : DEFAULT_EMPTY_VALUE;
  },
  brakePadFront: (brakePadFront?: AssetReportItem['brakePadFront']) => {
    return typeof brakePadFront?.value === 'number'
      ? `${Math.round(brakePadFront.value)}%`
      : DEFAULT_EMPTY_VALUE;
  },
  engineAirFilter: (
    valueLabels: typeof ASSET_LIST_REPORT_MESSAGES.valueLabels,
    engineAirFilter?: AssetReportItem['engineAirFilter']
  ) => {
    return engineAirFilter?.state !== undefined
      ? valueLabels[
          `asset:filterModalLabels.engineAirFilter.${engineAirFilter?.state}` as keyof typeof valueLabels
        ]
      : DEFAULT_EMPTY_VALUE;
  },
  connectivityBoardingStatus: (
    valueLabels: typeof ASSET_LIST_REPORT_MESSAGES.valueLabels,
    status?: AssetReportItem['connectivityBoardingStatus']
  ) => {
    return status !== undefined
      ? valueLabels[
          `asset:filterModalLabels.connectivityBoardingStatus.${status}` as keyof typeof valueLabels
        ]
      : DEFAULT_EMPTY_VALUE;
  },
  vehicleType: (type?: VehicleDetailsType) => {
    return typeof type === 'object' && type
      ? getVehicleTypeDetails(type)
      : DEFAULT_EMPTY_VALUE;
  },
};

export const getIdentityReportColumns = (
  columnLabels: typeof ASSET_LIST_REPORT_MESSAGES.columnLabels,
  isParamAvailable: boolean
) => [
  ID_COLUMN,
  ORGANIZATION_COLUMN,
  HUB_COLUMN,
  FLEET_COLUMN,
  ASSET_DETAILS_COLUMN(columnLabels, isParamAvailable),
  TYPE_COLUMN(columnLabels),
  LICENSE_PLATE_COLUMN,
  VEHICLE_TYPE_COLUMN,
];

export const getCommonReportColumns = (
  columnLabels: typeof ASSET_LIST_REPORT_MESSAGES.columnLabels,
  valueLabels: typeof ASSET_LIST_REPORT_MESSAGES.valueLabels,
  isParamAvailable: boolean,
  locale?: string
) => [
  ...getIdentityReportColumns(columnLabels, isParamAvailable),
  SERVICE_COLUMN(columnLabels, valueLabels),
  READINESS_COLUMN(columnLabels),
  LAST_COMMUNICATED_COLUMN(columnLabels, valueLabels, locale),
];

export const ASSET_DETAILS_COLUMN = (
  columnLabels: typeof ASSET_LIST_REPORT_MESSAGES.columnLabels,
  isParamAvailable: boolean
): TableColumnConfig<AssetReportItem> => {
  return {
    name: 'name',
    label: columnLabels['asset:title'],
    options: {
      sort: false,
      setCellProps: () => ({ className: 'nameColumnCell' }),
      setCellHeaderProps: () => ({ className: 'nameColumnCellHeader' }),
      customBodyRender: (name: string, tableMeta: MUIDataTableMeta) => {
        const assetsId = tableMeta.rowData?.[ASSET_ID_INDEX];
        const licensePlate = tableMeta.rowData?.[LICENSE_PLATE_INDEX];
        const vehicleType = tableMeta.rowData?.[VEHICLE_TYPE_INDEX];
        const vehicleTypeDetail =
          ASSET_REPORT_ADAPTERS.vehicleType(vehicleType);
        const assetDetailPath = makeRoutePath(
          findRoute(RouteName.CS_PLATFORM_ASSET_DETAILS),
          {
            assetsId,
          },
          false,
          true
        );

        const assetDetail = (
          <Box className="vehicleInfoWrapper">
            <Box className="vehicleImg">
              <ImageWithFallback
                src={''}
                alt="Vehicle image"
                data-testid="vehicle-image"
                errorImageClass="fallbackImageWrapper"
              />
            </Box>
            <Box
              className="assetInfo"
              sx={{ display: 'flex', flexDirection: 'column' }}
            >
              <Box className="assetInfo--name">
                <Link
                  data-heap-redact-attributes="id"
                  to={assetDetailPath}
                  data-testid="asset-list-name-link-value"
                  id={`asset-name_${name}`}
                >
                  <Typography variant="small_body_medium" data-heap-redact-text>
                    <span data-testid="asset-name">{name}</span>
                    {licensePlate && (
                      <Box component="span" data-testid="license-plate">
                        {' '}
                        {`(${licensePlate})`}
                      </Box>
                    )}
                  </Typography>
                </Link>
              </Box>
              {assetsId && (
                <Box component="span" data-testid={`asset-list-vin-value`}>
                  {' '}
                  {`${assetsId}`}
                </Box>
              )}
              <Box component={'span'} className="vehicle-details">
                {vehicleTypeDetail ? (
                  <>{vehicleTypeDetail}</>
                ) : (
                  DEFAULT_EMPTY_VALUE
                )}
              </Box>
            </Box>
          </Box>
        );
        return assetDetail;
      },
    },
  };
};
//TODO: Remove the TYPE_COLUMN when the image integration is done
export const TYPE_COLUMN = (
  columnLabels: typeof ASSET_LIST_REPORT_MESSAGES.columnLabels,
  isChargeStatusFlagEnabled?: boolean
): TableColumnConfig<AssetReportItem> => ({
  name: 'type',
  label: columnLabels['common:type'],
  options: {
    display: false,
    sort: false,
    customBodyRender: ASSET_REPORT_ADAPTERS.assetType,
  },
});

export const DIAGNOSTICS_COLUMN = (
  columnLabels: typeof ASSET_LIST_REPORT_MESSAGES.columnLabels,
  valueLabels: typeof ASSET_LIST_REPORT_MESSAGES.valueLabels
): TableColumnConfig<AssetReportItem> => ({
  name: 'diagnosticHealth',
  label: columnLabels['asset:tableColumnLabels.diagnosticsHealth'],
  options: {
    sort: false,
    customBodyRender: (diagnostic) => {
      const textDisplay = ASSET_REPORT_ADAPTERS.diagnosticsHealth(
        valueLabels,
        diagnostic
      );

      return textDisplay === DEFAULT_EMPTY_VALUE ? (
        textDisplay
      ) : (
        <Box
          display="flex"
          alignItems="center"
          className={cx('diagnostic', {
            critical: diagnostic === ReportDiagnosticState.CRITICAL,
            impaired: diagnostic === ReportDiagnosticState.IMPAIRED,
            ok: diagnostic === ReportDiagnosticState.CLEAR,
            unknown: diagnostic === ReportDiagnosticState.UNKNOWN,
          })}
        >
          <BuildCircleOutlinedIcon fontSize="small" />
          <Box ml="4px">{textDisplay}</Box>
        </Box>
      );
    },
  },
});

export const SERVICE_COLUMN = (
  columnLabels: typeof ASSET_LIST_REPORT_MESSAGES.columnLabels,
  valueLabels: typeof ASSET_LIST_REPORT_MESSAGES.valueLabels
): TableColumnConfig<AssetReportItem> => ({
  name: 'availability',
  label: columnLabels['asset:vehicle.details.availability'],
  options: {
    setCellProps: () => ({ style: { minWidth: '110px' } }),
    sort: false,
    customBodyRender: (availability) => {
      const textDisplay = ASSET_REPORT_ADAPTERS.availability(
        valueLabels,
        availability
      );

      return textDisplay === DEFAULT_EMPTY_VALUE ? (
        textDisplay
      ) : (
        <Box
          className={cx('service', {
            inLabel: availability === AssetServiceStatus.IN_SERVICE,
            outLabel: availability === AssetServiceStatus.OUT_OF_SERVICE,
          })}
        >
          {textDisplay}
        </Box>
      );
    },
  },
});

export const READINESS_COLUMN = (
  columnLabels: typeof ASSET_LIST_REPORT_MESSAGES.columnLabels
): TableColumnConfig<AssetReportItem> => ({
  name: 'ready',
  label: columnLabels['asset:ready'],
  options: {
    display: false,
    sort: false,
  },
});

export const LAST_COMMUNICATED_COLUMN = (
  columnLabels: typeof ASSET_LIST_REPORT_MESSAGES.columnLabels,
  valueLabels: typeof ASSET_LIST_REPORT_MESSAGES.valueLabels,
  locale?: string,
  isChargeStatusFlagEnabled?: boolean
): TableColumnConfig<AssetReportItem> => ({
  name: 'lastCommunicated',
  label: capitalize(columnLabels['common:lastCommunicated']),
  options: {
    sort: false,
    customBodyRender: (lastCommunicated) => {
      if (typeof lastCommunicated.deviceTimestamp === 'string') {
        return ASSET_REPORT_ADAPTERS.lastCommunicated(
          valueLabels,
          lastCommunicated,
          locale
        );
      }
      return DEFAULT_EMPTY_VALUE;
    },
  },
});

export const BRAKE_FLUID_COLUMN = (
  columnLabels: typeof ASSET_LIST_REPORT_MESSAGES.columnLabels,
  valueLabels: typeof ASSET_LIST_REPORT_MESSAGES.valueLabels
): TableColumnConfig<AssetReportItem> => ({
  name: 'brakeFluid',
  label: columnLabels['asset:tableColumnLabels.brakeFluidStatus'],
  options: {
    sort: false,
    customBodyRender: (value) => {
      if (value) {
        return getReportDiagnosticStatus(
          'brake-fluid',
          value,
          ASSET_REPORT_ADAPTERS.fluidDiagnostic(valueLabels, value)
        );
      }
      return DEFAULT_EMPTY_VALUE;
    },
    sortCompare: (order: MUISortOptions['direction']) => {
      return (first: { data: boolean }, second: { data: boolean }): number => {
        return mapperSortCompare(
          mapFluidStatus,
          order,
          first?.data,
          second?.data
        );
      };
    },
  },
});

export const WASHER_FLUID_COLUMN = (
  columnLabels: typeof ASSET_LIST_REPORT_MESSAGES.columnLabels,
  valueLabels: typeof ASSET_LIST_REPORT_MESSAGES.valueLabels
): TableColumnConfig<AssetReportItem> => ({
  name: 'washerFluid',
  label: columnLabels['asset:tableColumnLabels.washerFluidStatus'],
  options: {
    sort: false,
    customBodyRender: (value) => {
      if (value) {
        return getReportDiagnosticStatus(
          'washer-fluid',
          value,
          ASSET_REPORT_ADAPTERS.fluidDiagnostic(valueLabels, value)
        );
      }
      return DEFAULT_EMPTY_VALUE;
    },
    sortCompare: (order: MUISortOptions['direction']) => {
      return (first: { data: boolean }, second: { data: boolean }): number => {
        return mapperSortCompare(
          mapFluidStatus,
          order,
          first?.data,
          second?.data
        );
      };
    },
  },
});

export const FUEL_BATTERY_COLUMN = (
  columnLabels: typeof ASSET_LIST_REPORT_MESSAGES.columnLabels
): TableColumnConfig<AssetReportItem> =>
  ({
    name: 'battery' as keyof AssetReportItem,
    label: `${columnLabels['asset:tableColumnLabels.fuelOrCharge']}`,
    options: {
      setCellProps: () => ({ style: { minWidth: '150px' } }),
      sort: false,
      customBodyRender: (battery: any, tableMeta: any) => {
        const fuel = tableMeta.rowData?.[FUEL_LEVEL_INDEX];

        const fuelContent =
          fuel && typeof fuel?.value === 'number'
            ? getFuelLevelDiagnosticStatus(
                'fuel-level',
                fuel.state,
                ASSET_REPORT_ADAPTERS.fuel(fuel)
              )
            : null;

        const batteryContent =
          battery && typeof battery?.value === 'number' ? (
            <div className="valueStatus">
              {getBatteryIcon(battery)}
              <span>{ASSET_REPORT_ADAPTERS.batteryValue(battery)}</span>
            </div>
          ) : null;

        const noContent =
          fuelContent === null && batteryContent === null ? (
            <Box
              component="span"
              data-testid="no-fuel-no-battery-data-diagnostic-status"
            >
              {DEFAULT_EMPTY_VALUE}
            </Box>
          ) : null;

        return (
          <Box display="flex" flexDirection="column">
            <Box data-testid={'fuel-or-charge'}>
              {fuelContent || batteryContent || noContent}
            </Box>
          </Box>
        );
      },
    },
  } as TableColumnConfig<AssetReportItem>);

export const BATTERY_COLUMN = (
  columnLabels: typeof ASSET_LIST_REPORT_MESSAGES.columnLabels
): TableColumnConfig<AssetReportItem> => ({
  name: 'battery',
  label: columnLabels['asset:tableColumnLabels.charge'],
  options: {
    setCellProps: () => ({ style: { minWidth: '100px' } }),
    sort: false,
    customBodyRender: (battery) => {
      if (typeof battery?.value === 'number') {
        const icon = getBatteryIcon(battery);
        const value = (
          <div className="valueStatus">
            {icon}
            <span>{ASSET_REPORT_ADAPTERS.batteryValue(battery)}</span>
          </div>
        );
        return value;
      }
      return DEFAULT_EMPTY_VALUE;
    },
  },
});

export const OIL_LIFE_COLUMN = (
  columnLabels: typeof ASSET_LIST_REPORT_MESSAGES.columnLabels,
  valueLabels: typeof ASSET_LIST_REPORT_MESSAGES.valueLabels
): TableColumnConfig<AssetReportItem> => ({
  name: 'oilLife',
  label: columnLabels['asset:oilLife'],
  options: {
    setCellProps: () => ({ style: { minWidth: '70px' } }),
    sort: false,
    customBodyRender: (oilLife) => {
      if (oilLife?.state) {
        return getReportDiagnosticStatus(
          'oil-life',
          oilLife.state,
          ASSET_REPORT_ADAPTERS.oilLife(oilLife)
        );
      }
      return DEFAULT_EMPTY_VALUE;
    },
  },
});

export const ID_COLUMN: TableColumnConfig<AssetReportItem> = {
  name: 'id',
  label: '',
  options: {
    display: false,
  },
};

export const ORGANIZATION_COLUMN: TableColumnConfig<AssetReportItem> = {
  name: 'organization',
  label: '',
  options: {
    display: false,
  },
};

export const HUB_COLUMN: TableColumnConfig<AssetReportItem> = {
  name: 'hub',
  label: '',
  options: {
    display: false,
  },
};

export const FLEET_COLUMN: TableColumnConfig<AssetReportItem> = {
  name: 'fleet',
  label: '',
  options: {
    display: false,
  },
};
export const LICENSE_PLATE_COLUMN: TableColumnConfig<AssetReportItem> = {
  name: 'licensePlate',
  label: '',
  options: {
    display: false,
  },
};
export const VEHICLE_TYPE_COLUMN: TableColumnConfig<AssetReportItem> = {
  name: 'vehicleType',
  label: '',
  options: {
    display: false,
  },
};
const mapFluidStatus = (flag?: boolean): number => (flag ? 0 : 1);
