import { createContext } from 'react';

import { TelemetryLog } from '../models/telemetry.model';

export interface TelemetryProviderContextProps {
  addTelemetryContext: (key: string, value: unknown) => void;
  log: (props: TelemetryLog) => void;
}

const TelemetryProviderContext = createContext<TelemetryProviderContextProps>(
  {} as TelemetryProviderContextProps
);

export default TelemetryProviderContext;
