import { Box, useTheme } from '@mui/material';

import { Asset } from '~/common/models/asset.model';

import { GlobalSearchResult, GlobalSearchType } from '../globalSearch.model';
import HighlightableSearchText from '../HighlightableSearchText/HighlightableSearchText';
import useStyles from './VehicleSearchResult.styles';

interface GlobalSearchResultProps<T extends GlobalSearchType> {
  index: number;
  searchResult: GlobalSearchResult<T>;
  searchTerm: string;
  selected: boolean;
  handleNavigation: () => void;
}

export const VehicleSearchResult = (
  props: GlobalSearchResultProps<Asset>
): JSX.Element => {
  const { index, searchResult, searchTerm, selected, handleNavigation } = props;
  const { classes } = useStyles();
  const theme = useTheme();

  const name = searchResult.resultInfo.name;
  const licensePlate = searchResult.resultInfo.licensePlate;
  const vin = searchResult.resultInfo.id;
  const year = searchResult.resultInfo.vehicleType?.year || '';
  const make = searchResult.resultInfo.vehicleType?.make || '';
  const model = searchResult.resultInfo.vehicleType?.model || '';

  return (
    <Box
      component="li"
      className={`${classes.resultContainer} ${
        selected ? classes.selectedResult : ''
      }`}
      data-testid={`vehicle-search-result-${index}`}
      data-selected={selected}
      onClick={() => handleNavigation()}
    >
      <HighlightableSearchText
        searchTerm={searchTerm}
        displayText={`${name} (${licensePlate})`}
        typographyVariant="small_body_medium"
        data-heap-redact-text
      />
      <HighlightableSearchText
        searchTerm={searchTerm}
        displayText={vin}
        color={theme.new.color.textSecondary}
        typographyVariant="label_regular"
      />
      <HighlightableSearchText
        searchTerm={searchTerm}
        displayText={`${year} ${make} ${model}`}
        color={theme.new.color.textSecondary}
        typographyVariant="label_medium"
      />
    </Box>
  );
};
