import { AuthenticationResult } from '@azure/msal-common';
import {
  AxiosError,
  AxiosHeaders,
  AxiosResponse,
  InternalAxiosRequestConfig,
} from 'axios';
import { DateTime } from 'luxon';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

import { RootState } from '~/app/rootReducer';
import { enableForceLogout, setAuthToken } from '~/features/session/authSlice';
import {
  selectAuthDomainHint,
  selectAuthToken,
  selectAuthType,
} from '~/features/session/authSlice.selectors';
import { AuthenticationType } from '~/features/session/model/authentication.model';
import { getMsalInstance } from '~/features/session/msalInstance';

import { DEFAULT_TIMEOUT } from '../configs/auth.config';
import {
  getCurrentEnvAccount,
  getDomainHintFromAccountInfo,
  isAuthExpired,
} from '../utils/auth/auth.utils';
import { DEFAULT_API_HEADERS } from './api.constants';
import { instance } from './apiBase';

/**
 * Attempt to refresh auth token for currently-active MSAL account
 * @param authenticationType - SSO or CDI currently
 * @param dispatch ThunkDispatch from payload creator
 * @param forceRefresh
 * @param forceLoginOnSilentFailure
 * @param domainHint
 * @returns Promise based on token fetch result
 */
export const refreshAuthToken = ({
  authenticationType,
  dispatch,
  forceRefresh = false,
  forceLoginOnSilentFailure = true,
  domainHint,
}: {
  authenticationType: AuthenticationType;
  dispatch: ThunkDispatch<RootState, unknown, AnyAction>;
  forceRefresh?: boolean;
  forceLoginOnSilentFailure?: boolean;
  domainHint?: string;
}): Promise<AuthenticationResult | void> => {
  const msalInstance = getMsalInstance(authenticationType);
  const account = getCurrentEnvAccount(msalInstance.getAllAccounts());
  const defaultAccessTokenRequest = {
    scopes: [`${globalThis.appConfig.auth.clientId}`],
    authority:
      authenticationType === AuthenticationType.SSO
        ? globalThis.appConfig.auth.authority
        : globalThis.appConfig.auth.cdiAuthority,
    forceRefresh,
  };

  if (!account || forceRefresh) {
    // no account found matching the configured app auth authorities
    dispatch(enableForceLogout());
  }
  return msalInstance
    .acquireTokenSilent({ ...defaultAccessTokenRequest, account })
    .then((accessTokenResponse) => {
      let expiration = DateTime.now().plus({
        seconds: DEFAULT_TIMEOUT,
      });
      let b2cExpiration = expiration;
      if (accessTokenResponse.expiresOn) {
        b2cExpiration = DateTime.fromJSDate(accessTokenResponse.expiresOn);

        expiration = DateTime.min(b2cExpiration, expiration);
      }
      dispatch(
        setAuthToken({
          value: accessTokenResponse.accessToken,
          expiration: expiration.toMillis(),
          b2cExpiration: b2cExpiration.toMillis(),
          domainHint: account
            ? getDomainHintFromAccountInfo(account) || domainHint
            : domainHint,
          authenticationType,
        })
      );
      return accessTokenResponse;
    })
    .catch((authErr) => {
      if (forceLoginOnSilentFailure) {
        dispatch(enableForceLogout());
      }
      return Promise.reject(authErr);
    });
};

/**
 * Interceptor which adds default and auth headers to BD API requests, fetching token as needed
 */

export const bdAuthRequestInterceptor = async (
  requestConfig: InternalAxiosRequestConfig
): Promise<InternalAxiosRequestConfig> => {
  const authToken = requestConfig.bdExtra?.getState
    ? selectAuthToken(requestConfig.bdExtra.getState())
    : null;
  const domainHint = requestConfig.bdExtra?.getState
    ? selectAuthDomainHint(requestConfig.bdExtra.getState())
    : undefined;
  if (
    !requestConfig.bdExtra?.skipAuth &&
    !isAuthExpired(authToken) &&
    authToken?.value
  ) {
    // if is BD request and authToken exists, apply to auth header
    return {
      ...requestConfig,
      headers: new AxiosHeaders({
        ...DEFAULT_API_HEADERS,
        Authorization: `Bearer ${authToken.value}`,
        ...requestConfig.headers,
      }),
    };
  } else if (requestConfig.bdExtra?.skipAuth) {
    return {
      ...requestConfig,
      headers: new AxiosHeaders({
        ...DEFAULT_API_HEADERS,
        ...requestConfig.headers,
      }),
    };
  } else if (requestConfig.bdExtra?.dispatch && authToken?.authenticationType) {
    /**
     * BD requests without authToken should provide a reference to dispatch
     * so we can attempt to retrieve the auth token. This logic should only trigger
     * on initial login, after we authenticate the user and attempt to fetch
     * the profile and permissions.
     */

    const dispatch = requestConfig.bdExtra?.dispatch;
    const tokenResponse = await refreshAuthToken({
      authenticationType: authToken.authenticationType,
      dispatch,
      domainHint,
    }).catch((error) => {
      console.error('BrightDrop Web - acquire auth token failure', error);
      dispatch(enableForceLogout());
    });
    return {
      ...requestConfig,
      headers: new AxiosHeaders({
        ...DEFAULT_API_HEADERS,
        Authorization: `Bearer ${
          (tokenResponse && tokenResponse.accessToken) || ''
        }`,
        ...requestConfig.headers,
      }),
    };
  }

  // pass through non-BD requests
  return requestConfig;
};

/**
 * Interceptor which detects errors related to auth token expiration, attempting to
 * refresh token and re-attempt request if possible
 */
export const bdAuthErrorResponseInterceptor = async (
  err: AxiosError<unknown>
): Promise<AxiosResponse<unknown> | AxiosError<unknown> | void> => {
  const originalRequest = err.config;
  const dispatch = err.config?.bdExtra?.dispatch;
  const domainHint = err.config?.bdExtra?.getState
    ? selectAuthDomainHint(err.config.bdExtra.getState())
    : undefined;

  const authenticationType = err.config?.bdExtra?.getState
    ? selectAuthType(err.config.bdExtra.getState())
    : undefined;

  if (
    err.isAxiosError &&
    !err.config?._retry &&
    (err.message === 'Network Error' || err.response?.status === 401) &&
    dispatch &&
    authenticationType
  ) {
    if (originalRequest) {
      originalRequest._retry = true; // prevent infinite loops on refresh handling
    }
    return refreshAuthToken({ authenticationType, dispatch, domainHint })
      .then((_) => {
        // retry initial request
        return instance.request(err.config || {});
      })
      .catch((authErr) => {
        console.error('BrightDrop Web - auth token refresh failure', authErr);
        return Promise.reject(authErr);
      });
  }
  return Promise.reject(err);
};
