import { Address } from '@gm-commercial/location-model';

import { DEFAULT_EMPTY_VALUE } from '../constants/common.constant';

export type ApiAddressFields = {
  line1: string;
  city: string;
  postalCode: string;
  country: string;
  line2?: string;
  state?: string;
};

export type ApiPrimaryContactFields = {
  id: number;
  name: string;
};

export const mapApiResponseToAddress = (
  apiAddress: ApiAddressFields
): Address => ({
  address1: apiAddress.line1,
  address2: apiAddress.line2 ? apiAddress.line2 : '',
  city: apiAddress.city,
  stateProvinceTerritory: apiAddress.state,
  postalCode: apiAddress.postalCode,
  country: apiAddress.country,
});

export const mapAddressToApiResponse = (
  address: Address
): ApiAddressFields => ({
  line1: address.address1,
  line2: address.address2 || '',
  city: address.city,
  state: address.stateProvinceTerritory,
  postalCode: address.postalCode,
  country: address.country,
});

export const stripPhoneNumber = (phone: string): string => {
  //normalize phone value
  phone = phone.replace(/[^+\d]+/g, '');

  //fix for phone input doesn't handle numbers without country code
  if (phone.length === 10) {
    phone = '+1' + phone;
  } else {
    if (
      (phone.length === 12 && phone.slice(0, 2) !== '+1') ||
      phone.length > 12
    ) {
      phone = DEFAULT_EMPTY_VALUE;
    }
  }
  return phone;
};
