import {
  AUTH_TOKEN_COOKIE_NAME,
  AUTH_TYPE_COOKIE_NAME,
} from '@brightdrop/auth-client';
import { useCallback, useContext, useMemo } from 'react';
import { useCookies } from 'react-cookie';

import AuthenticationContext from '../../features/session/context/AuthenticationContext';
import {
  AuthenticationConfig,
  AuthenticationType,
  DEFAULT_AUTHENTICATION_TYPE,
} from '../../features/session/model/authentication.model';

const AUTHENTICATION_SCOPE = '/';

const useCustomAuthentication = (): {
  token: string | undefined;
  authenticationType: AuthenticationType;
  setAuthenticationType: (authenticationType: AuthenticationType) => void;
  setToken: (token: string, expiration: Date) => void;
  clearCustomAuthentication: () => void;
} => {
  const { authenticationType: scopeAuthenticationType } =
    useContext<AuthenticationConfig>(AuthenticationContext);

  const [cookie, setCookie, removeCookie] = useCookies([
    AUTH_TYPE_COOKIE_NAME,
    AUTH_TOKEN_COOKIE_NAME,
  ]);

  const setAuthenticationType = useCallback(
    (authenticationType: AuthenticationType) => {
      setCookie(AUTH_TYPE_COOKIE_NAME, authenticationType, {
        path: AUTHENTICATION_SCOPE,
      });
    },
    [setCookie]
  );
  const setToken = useCallback(
    (token: string, expiration: Date) => {
      setCookie(AUTH_TOKEN_COOKIE_NAME, token, {
        path: AUTHENTICATION_SCOPE,
        expires: expiration,
      });
    },
    [setCookie]
  );
  const token = cookie[AUTH_TOKEN_COOKIE_NAME];

  const authenticationType = useMemo(
    () =>
      scopeAuthenticationType ||
      cookie[AUTH_TYPE_COOKIE_NAME] ||
      DEFAULT_AUTHENTICATION_TYPE,
    [cookie, scopeAuthenticationType]
  );

  const clearCustomAuthentication = useCallback(() => {
    removeCookie(AUTH_TOKEN_COOKIE_NAME, {
      path: AUTHENTICATION_SCOPE,
    });
    removeCookie(AUTH_TYPE_COOKIE_NAME, {
      path: AUTHENTICATION_SCOPE,
    });
  }, [removeCookie]);

  return {
    token,
    authenticationType,
    setAuthenticationType,
    setToken,
    clearCustomAuthentication,
  };
};

export default useCustomAuthentication;
