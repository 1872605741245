import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  toolTip: {
    borderRadius: '12px',
    border: `1px solid ${theme.palette.border}`,
    boxShadow: '0px 1px 12px 0px rgba(26, 34, 51, 0.06)',
    padding: '1rem',
    backgroundColor: theme.palette.common.white,
    color: theme.palette.text.primary,
    display: 'flex',
    justifyContent: 'flex-start',
    minWidth: '225px',
    minHeight: '104px',
    flexDirection: 'column',
    position: 'absolute',
    pointerEvents: 'none',
    transition: 'all .1s ease',
  },
  titleWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    '& .MuiTypography-root': {
      lineHeight: '22px',
      fontSize: '0.9rem',
      color: theme.palette.grey[900],
    },
    marginBottom: '0.5rem',
  },
  bodyWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    color: theme.palette.text.primary,
    '& .MuiTypography-root': {
      fontSize: '0.75rem',
      lineHeight: '1.125rem',
    },
  },
  body: { width: '12px', height: '2.4px', marginRight: '7px' },
  title: {
    display: 'flex',
  },
  date: {
    '& .MuiTypography-root': {
      color: theme.palette.text.secondary,
    },
  },
}));

export default useStyles;
