import { GlobalSearchResult, GlobalSearchType } from './globalSearch.model';

export const splitBySearchTerm = (
  searchTerm: string,
  displayText: string
): { before: string; match: string; after: string } => {
  const searchIndex = displayText.indexOf(searchTerm);

  /* If the search term is not found, return the full displayText as the "match" */
  if (searchIndex === -1) {
    return { before: '', match: displayText, after: '' };
  }

  /* Grab the text before the match */
  const before = displayText.slice(0, searchIndex);

  /* Grab the matching text */
  const match = displayText.slice(searchIndex, searchIndex + searchTerm.length);

  /* Grab the text after the match */
  const after = displayText.slice(searchIndex + searchTerm.length);

  return { before, match, after };
};

export const convertSearchTypeToSearchResultList = (
  input: Array<GlobalSearchType>
): Array<GlobalSearchResult<GlobalSearchType>> => {
  const convertedList: Array<GlobalSearchResult<GlobalSearchType>> = [];
  input.forEach((item) => {
    convertedList.push({
      resultInfo: item,
      isPreviousSearch: false,
    });
  });
  return convertedList;
};
