import { DEFAULT_EMPTY_VALUE } from '~/common/constants/common.constant';
import { JobOutcome, JobStatus } from '~/common/models/common.model';
import {
  ApiWorkPlanJob,
  ApiWorkPlanJobDiagnostics,
  UploadHistoryJobStatus,
  UploadHistoryResult,
  UploadWorkPlanHistory,
  WorkPlanJobDiagnostics,
} from '~/common/models/dispatch.model';

export const mapApiResponseToUploadWorkPlanHistory = (
  apiJob: ApiWorkPlanJob
): UploadWorkPlanHistory => {
  const diagnostics = apiJob.status?.diagnostics
    ? mapDiagnostics(apiJob.status.diagnostics)
    : [];
  const jobStatus = mapStatus(apiJob.status.status);
  const result = mapResult(diagnostics, jobStatus);
  return {
    id: apiJob.jobId,
    name: apiJob.parameters.fileName,
    uploadedTime: apiJob.status.lastUpdatedTimestamp,
    jobStatus,
    diagnostics,
    result,
    actions: mapViewActions(result),
    organizationId: apiJob?.parameters?.organizationId,
    hubId: apiJob?.parameters?.hubId,
    fleetId: apiJob?.parameters?.fleetId,
  };
};

export const mapStatus = (status: JobStatus): UploadHistoryJobStatus => {
  let currentStatus = UploadHistoryJobStatus.QUEUED;
  switch (status) {
    case JobStatus.ACTIVE:
      currentStatus = UploadHistoryJobStatus.RUNNING;
      break;
    case JobStatus.FAILED:
    case JobStatus.TERMINATED_BY_TIMEOUT:
    case JobStatus.TERMINATED_BY_USER:
    case JobStatus.TERMINATION_REQUESTED:
      currentStatus = UploadHistoryJobStatus.ERROR;
      break;
    case JobStatus.NOT_STARTED:
      currentStatus = UploadHistoryJobStatus.QUEUED;
      break;
    case JobStatus.SUCCEEDED:
      currentStatus = UploadHistoryJobStatus.DONE;
      break;
  }
  return currentStatus;
};

export const mapDiagnostics = (
  data: ApiWorkPlanJobDiagnostics
): Array<WorkPlanJobDiagnostics> => {
  let diagnostics: WorkPlanJobDiagnostics[] = [];

  if (data.workplans) {
    diagnostics = data.workplans.map((item) => {
      return {
        ...item,
        id: item?.id || DEFAULT_EMPTY_VALUE,
        scheduledDate: item.scheduledDate || DEFAULT_EMPTY_VALUE,
      };
    });
  }
  return diagnostics;
};

export const mapResult = (
  diagnostics: Array<WorkPlanJobDiagnostics>,
  status: UploadHistoryJobStatus
): UploadHistoryResult | undefined => {
  if (
    status === UploadHistoryJobStatus.DONE ||
    status === UploadHistoryJobStatus.ERROR
  ) {
    const succeededImportCount = diagnostics.filter(
      (item) => item.status === JobOutcome.SUCCEEDED
    ).length;
    const failedImportCount = diagnostics.filter(
      (item) => item.status === JobOutcome.FAILED
    ).length;
    return {
      succeededImportCount,
      failedImportCount,
    };
  }
  return undefined;
};

export const mapViewActions = (
  result: UploadHistoryResult | undefined
): boolean | undefined => {
  return result ? (result?.failedImportCount ? false : true) : undefined;
};
