import {
  createEntityAdapter,
  createSlice,
  EntityState,
  PayloadAction,
} from '@reduxjs/toolkit';
import merge from 'deepmerge';
import { PURGE } from 'redux-persist';

import {
  API_VERSION_DEFAULTS,
  DEFAULT_API_CONFIG,
} from '~/common/apis/api.constants';
import { RouteParams } from '~/common/configs/route.config';
import { SessionConfigType } from '~/common/constants/common.constant';
import {
  ApiResponse,
  BDRequestStatus,
  BDRequestType,
  OperationStatus,
} from '~/common/models/apis/apiResponse.model';
import { AssetReportFieldType } from '~/common/models/asset-report.model';
import {
  FormPayload,
  ListViewSession,
  NonNullablePick,
  OperationSession,
  SeverityLevel,
  SummaryCounts,
} from '~/common/models/common.model';
import { BDAppErrorType, BDError } from '~/common/models/error.model';
import {
  ApiAssetCountSummary,
  ApiOrganization,
  AssetCountSummary,
  Organization,
  OrganizationTenant,
  OrgFormFields,
} from '~/common/models/organization.model';
import { BDQueryCriteriaType } from '~/common/models/query.model';
import {
  addAcceptLanguageHeader,
  hasApiResult,
} from '~/common/utils/apis/api.utils';
import {
  makeDeletePayloadCreator,
  makeGetPayloadCreator,
  makePostPayloadCreator,
  makePutPayloadCreator,
  makeThunk,
} from '~/common/utils/store/thunk.helper';

import { RootState } from '../../app/rootReducer';
import { createQuery } from '../assets/utils/assets.utils';
import {
  mapApiResponseToOrg,
  mapApiToAssetCountSummary,
  mapOrgFormToApiRequest,
  mapStatsApiResponseToSummaryCounts,
} from './mappers/organizations.mappers';

enum OrganizationsActionType {
  GET_ASSET_COUNT_SUMMARY = 'organizations/getAssetCountSummary',
}

export interface OrgListSession
  extends Record<keyof Organization, string>,
    ListViewSession,
    OperationSession {}

export interface OrgDetailSession extends OperationSession {
  isDetailPropsOpen?: boolean;
  assetCountSummary?: AssetCountSummary;
}

/**
 * OrgSessionConfigType represent an object where session is scoped by the view and entity id
 *
 * @interface OrgSessionConfigType
 * @property {SessionConfigType} key The view that we are storing sessions.
 * @property {string} id The id of the entity you are viewing
 */
export interface OrgSessionConfigType {
  [SessionConfigType.DETAIL_VIEW]?: {
    [id: string]: Partial<OrgDetailSession>;
  };
  [SessionConfigType.LIST_VIEW]?: {
    [id: string]: Partial<OrgListSession> | undefined;
  };
}

export type AddOrgFormPayload = {
  formFields: NonNullablePick<
    OrgFormFields,
    'name' | 'address' | 'email' | 'phoneNumber' | 'orgType'
  > &
    Pick<OrgFormFields, 'locale' | 'orgMasterNumber'>;
  params: null;
};
export type UpdateOrgFormPayload = FormPayload<
  NonNullablePick<
    OrgFormFields,
    'name' | 'address' | 'email' | 'phoneNumber' | 'orgType'
  > &
    Pick<OrgFormFields, 'primaryContact' | 'locale' | 'orgMasterNumber'>,
  'organizationsId'
>;
export type OrgRouteParams = Required<Pick<RouteParams, 'organizationsId'>> &
  Partial<Pick<RouteParams, 'hubsId'>> & { sessionId?: string };

export type ActivateOrgPayload = FormPayload<
  {
    urls: { termsAndConditions: string; privacyPolicy: string };
    accepted: boolean;
  },
  'organizationsId'
>;

const organizationsAdapter = createEntityAdapter<Organization>({
  // redundant, but makes obvious the key used for fetching records
  selectId: (org: Organization) => org.id,
});

export enum OrganizationRequestType {
  SUBMIT_TERMS_AND_CONDITIONS = 'Accept terms and conditions',
  ORGANIZATION_TENANTS = 'Organization Tenants',
}

interface OrganizationsState {
  operations: {
    [key in BDRequestType | OrganizationRequestType]?: OperationStatus;
  };
  sessionConfigs: {
    [k in SessionConfigType]?: OrgSessionConfigType[k];
  };
  tenants: EntityState<OrganizationTenant>;
}

const tenantsAdapter = createEntityAdapter<OrganizationTenant>({
  selectId: (tenant: OrganizationTenant) => tenant.id,
});

const initialState = organizationsAdapter.getInitialState<OrganizationsState>({
  operations: {},
  sessionConfigs: {},
  tenants: tenantsAdapter.getInitialState(),
});

const CONFIG = DEFAULT_API_CONFIG;
const ORG_DETAILS_CONFIG = {
  ...DEFAULT_API_CONFIG,
  axiosConfig: {
    ...DEFAULT_API_CONFIG.axiosConfig,
  },
};
export const getOrganizations = makeThunk(
  'organizations/fetchOrganizations',
  makeGetPayloadCreator<ApiResponse<Organization[]>, void>({
    url: `${globalThis.appConfig.apiBaseUrl}/organizations/${API_VERSION_DEFAULTS.orgTypeAPI}`,
    axiosOptions: (_, state) =>
      addAcceptLanguageHeader(ORG_DETAILS_CONFIG, state.profile.currentLocale),
    responseAdapter: (response: unknown) => {
      if (
        hasApiResult(response, (result): result is ApiOrganization[] =>
          Array.isArray(result)
        )
      ) {
        return {
          ...response,
          result: response.result
            .filter((data) => data && typeof data === 'object')
            .map((data) => {
              return mapApiResponseToOrg(data);
            }),
        };
      }

      throw new BDError('Unable to parse org list response', {
        type: BDAppErrorType.VALIDATION,
        data: { response },
      });
    },
  })
);

export const getOrganizationsTenants = makeThunk(
  'organizations/fetchOrganizationsTenants',
  makeGetPayloadCreator<ApiResponse<OrganizationTenant[]>, void>({
    url: `${globalThis.appConfig.apiBaseUrl}/organizations/${API_VERSION_DEFAULTS.default}/tenants`,
    axiosOptions: (_, state) =>
      addAcceptLanguageHeader(CONFIG, state.profile.currentLocale),
    responseAdapter: (response: unknown) => {
      if (
        hasApiResult(response, (result): result is OrganizationTenant[] =>
          Array.isArray(result)
        )
      ) {
        return {
          ...response,
          result: response.result,
        };
      }

      throw new BDError('Unable to parse org tenants response', {
        type: BDAppErrorType.VALIDATION,
        data: { response },
      });
    },
  })
);

export const getOrganizationDetails = makeThunk(
  'organizations/fetchDetails',
  makeGetPayloadCreator<ApiResponse<Organization>, OrgRouteParams>({
    url: `${globalThis.appConfig.apiBaseUrl}/organizations/${API_VERSION_DEFAULTS.orgTypeAPI}/:organizationsId`,
    axiosOptions: (_, state) =>
      addAcceptLanguageHeader(ORG_DETAILS_CONFIG, state.profile.currentLocale),
    argAdapter: ({ organizationsId }) => ({
      requestParams: { organizationsId },
    }),
    responseAdapter: (response: any) => ({
      ...response,
      result: mapApiResponseToOrg(response?.result),
    }),
  })
);

export const getOrganizationStats = makeThunk(
  'organizations/fetchStats',
  makeGetPayloadCreator<ApiResponse<SummaryCounts>, OrgRouteParams>({
    url: `${globalThis.appConfig.apiBaseUrl}/organizations/${API_VERSION_DEFAULTS.default}/:organizationsId/stats`,
    axiosOptions: (_, state) =>
      addAcceptLanguageHeader(CONFIG, state.profile.currentLocale),
    argAdapter: ({ organizationsId }) => ({
      requestParams: { organizationsId },
    }),
    responseAdapter: (response: any) => ({
      ...response,
      result: response?.result
        ? mapStatsApiResponseToSummaryCounts(response?.result)
        : {},
    }),
  })
);

//TODO: Remove once API adds userCount to the Organization Stats by ID(Phase 2)
export const getOrgUserStats = makeThunk(
  'organizations/getOrgUserStats',
  makeGetPayloadCreator<ApiResponse<number>, OrgRouteParams>({
    url: `${globalThis.appConfig.apiBaseUrl}/users/${API_VERSION_DEFAULTS.default}`,
    axiosOptions: (_, state) =>
      addAcceptLanguageHeader(CONFIG, state.profile.currentLocale),
    argAdapter: (params) => ({
      requestParams: { organizationId: params.organizationsId || '' },
    }),
    responseAdapter: (response: any) => ({
      ...response,
      result: Array.isArray(response?.result) ? response?.result.length : 0,
    }),
  })
);

//TODO: Remove once API adds assetCount to the Organization Stats by ID(Phase 2)
export const getOrgAssetStats = makeThunk(
  'organizations/getOrgAssetStats',
  makePostPayloadCreator<ApiResponse<number>, OrgRouteParams>({
    url: `${globalThis.appConfig.apiBaseUrl}/assets/${API_VERSION_DEFAULTS.orgTypeAPI}/views/readinessReport`,
    axiosOptions: (_, state) =>
      addAcceptLanguageHeader(CONFIG, state.profile.currentLocale),
    argAdapter: (params) => {
      const requestParams = [params.organizationsId];
      const query = createQuery(
        BDQueryCriteriaType.IS_EQUAL,
        AssetReportFieldType.ORG_ID,
        requestParams
      );
      return {
        requestBody: { query },
      };
    },
    responseAdapter: (response: any) => ({
      ...response,
      result: Array.isArray(response?.result.items)
        ? response?.result.total_items
        : 0,
    }),
  })
);

export const addOrganization = makeThunk(
  'organizations/addNewOrganization',
  makePostPayloadCreator<ApiResponse<Organization>, AddOrgFormPayload>({
    url: `${globalThis.appConfig.apiBaseUrl}/organizations/${API_VERSION_DEFAULTS.orgTypeAPI}`,
    axiosOptions: (_, state) =>
      addAcceptLanguageHeader(ORG_DETAILS_CONFIG, state.profile.currentLocale),
    argAdapter: (payload) => ({
      requestBody: mapOrgFormToApiRequest(payload),
    }),
  })
);

export const submitOrgTCs = makeThunk(
  'users/submitOrgTCs',
  makePutPayloadCreator<ApiResponse<Organization>, ActivateOrgPayload>({
    url: `${globalThis.appConfig.apiBaseUrl}/organizations/${API_VERSION_DEFAULTS.default}/:organizationsId/terms-and-conditions`,
    axiosOptions: (_, state) =>
      addAcceptLanguageHeader(CONFIG, state.profile.currentLocale),
    argAdapter: (payload) => ({
      requestParams: { organizationsId: payload.params.organizationsId },
      requestBody: payload.formFields,
    }),
  })
);

export const updateOrganization = makeThunk(
  'organizations/updateOrganization',
  makePutPayloadCreator<ApiResponse<Organization>, UpdateOrgFormPayload>({
    url: `${globalThis.appConfig.apiBaseUrl}/organizations/${API_VERSION_DEFAULTS.orgTypeAPI}/:organizationsId`,
    axiosOptions: (_, state) =>
      addAcceptLanguageHeader(ORG_DETAILS_CONFIG, state.profile.currentLocale),
    argAdapter: (payload) => ({
      requestParams: { organizationsId: payload.params.organizationsId },
      requestBody: mapOrgFormToApiRequest(payload),
    }),
    responseAdapter: (response: any) => ({
      ...response,
      result: mapApiResponseToOrg(response?.result),
    }),
  })
);

export const deleteOrganization = makeThunk(
  'organizations/deleteOrganization',
  makeDeletePayloadCreator<ApiResponse, OrgRouteParams>({
    url: `${globalThis.appConfig.apiBaseUrl}/organizations/${API_VERSION_DEFAULTS.default}/:organizationsId`,
    argAdapter: ({ organizationsId }) => ({
      requestParams: { organizationsId },
    }),
  })
);

export const getAssetCountSummary = makeThunk(
  OrganizationsActionType.GET_ASSET_COUNT_SUMMARY,
  makeGetPayloadCreator<
    ApiResponse<ApiAssetCountSummary> & {
      errors?: BDError[];
    },
    OrgRouteParams
  >({
    url: `${globalThis.appConfig.apiBaseUrl}/assets/${API_VERSION_DEFAULTS.assetCountSummaryAPI}/views/assetCountSummary`,
    axiosOptions: (_, state) =>
      addAcceptLanguageHeader(CONFIG, state.profile.currentLocale),
    argAdapter: (params) => ({
      requestParams: {
        organizationId: params.organizationsId || '',
        hubId: params.hubsId || '',
      },
    }),
    responseAdapter: (response: any) => ({
      ...response,
      result: mapApiToAssetCountSummary(response.result),
    }),
  })
);

export const organizationsSlice = createSlice({
  name: 'organizations',
  initialState,
  reducers: {
    setOrgSessionConfig: (
      state,
      action: PayloadAction<OrgSessionConfigType>
    ) => {
      Object.keys(action.payload).forEach((key) => {
        const scope = key as SessionConfigType;
        const payload = action.payload[scope] || {};
        Object.keys(payload).forEach((id) => {
          state.sessionConfigs = merge(state.sessionConfigs, {
            [scope]: {
              [id]: payload[id] && {
                ...payload[id],
              },
            },
          });
        });
      });
    },
  },
  extraReducers: (builder) => {
    // org list
    builder.addCase(getOrganizations.pending, (state) => {
      state.operations[BDRequestType.GET_ALL] = {
        status: BDRequestStatus.PENDING,
        errors: [],
      };
      organizationsAdapter.removeMany(state, state.ids); // ensure no records when fetching
    });
    builder.addCase(getOrganizations.fulfilled, (state, action) => {
      organizationsAdapter.upsertMany(state, action.payload.result);
      state.operations[BDRequestType.GET_ALL] = {
        status: BDRequestStatus.SUCCEEDED,
      };
    });
    builder.addCase(getOrganizations.rejected, (state, action) => {
      state.operations[BDRequestType.GET_ALL] = {
        status: BDRequestStatus.FAILED,
        errors: [
          {
            type: BDAppErrorType.API,
            ...(action.payload || (action.error as BDError)),
            requestType: BDRequestType.GET_ALL,
            severity: SeverityLevel.ERROR,
          },
        ],
      };
    });

    // org tenants
    builder.addCase(getOrganizationsTenants.pending, (state) => {
      state.operations[OrganizationRequestType.ORGANIZATION_TENANTS] = {
        status: BDRequestStatus.PENDING,
        errors: [],
      };
      tenantsAdapter.removeAll(state.tenants);
    });
    builder.addCase(getOrganizationsTenants.fulfilled, (state, action) => {
      tenantsAdapter.upsertMany(state.tenants, action.payload.result);
      state.operations[OrganizationRequestType.ORGANIZATION_TENANTS] = {
        status: BDRequestStatus.SUCCEEDED,
      };
    });
    builder.addCase(getOrganizationsTenants.rejected, (state, action) => {
      state.operations[OrganizationRequestType.ORGANIZATION_TENANTS] = {
        status: BDRequestStatus.FAILED,
        errors: [
          {
            type: BDAppErrorType.API,
            ...(action.payload || (action.error as BDError)),
            severity: SeverityLevel.ERROR,
          },
        ],
      };
    });

    // org details
    builder.addCase(getOrganizationDetails.pending, (state) => {
      state.operations[BDRequestType.GET_STATS] = {
        status: BDRequestStatus.IDLE,
      };
      state.operations[BDRequestType.GET_USER_SUMMARY] = {
        status: BDRequestStatus.IDLE,
      };
      state.operations[BDRequestType.GET_ASSET_SUMMARY] = {
        status: BDRequestStatus.IDLE,
      };
      state.operations[BDRequestType.GET_BY_ID] = {
        status: BDRequestStatus.PENDING,
        errors: [],
      };
    });
    builder.addCase(getOrganizationDetails.fulfilled, (state, action) => {
      state.operations[BDRequestType.GET_BY_ID] = {
        status: BDRequestStatus.SUCCEEDED,
      };
      organizationsAdapter.upsertOne(state, action.payload.result);
    });
    builder.addCase(getOrganizationDetails.rejected, (state, action) => {
      state.operations[BDRequestType.GET_BY_ID] = {
        status: BDRequestStatus.FAILED,
        errors: [
          {
            type: BDAppErrorType.API,
            ...(action.payload || (action.error as BDError)),
            requestType: BDRequestType.GET_BY_ID,
          },
        ],
      };
    });

    // org stats
    builder.addCase(getOrganizationStats.pending, (state) => {
      state.operations[BDRequestType.GET_STATS] = {
        status: BDRequestStatus.PENDING,
        errors: [],
      };
    });
    builder.addCase(getOrganizationStats.fulfilled, (state, action) => {
      state.operations[BDRequestType.GET_STATS] = {
        status: BDRequestStatus.SUCCEEDED,
      };
      organizationsAdapter.updateOne(state, {
        id: action?.meta.arg.organizationsId,
        changes: {
          ...state.entities[action?.meta.arg.organizationsId],
          summaryCounts: {
            ...state.entities[action?.meta.arg.organizationsId]?.summaryCounts,
            ...action.payload.result,
          },
        },
      });
    });
    builder.addCase(getOrganizationStats.rejected, (state, action) => {
      organizationsAdapter.updateOne(state, {
        id: action?.meta.arg.organizationsId,
        changes: {
          ...state.entities[action?.meta.arg.organizationsId],
          summaryCounts: {
            ...state.entities[action?.meta.arg.organizationsId]?.summaryCounts,
            fleets: undefined,
            hubs: undefined,
          },
        },
      });
      state.operations[BDRequestType.GET_STATS] = {
        status: BDRequestStatus.FAILED,
        errors: [
          {
            type: BDAppErrorType.API,
            ...(action.payload || (action.error as BDError)),
            requestType: BDRequestType.GET_STATS,
          },
        ],
      };
    });

    // org user stats
    //TODO: Remove once API adds userCount to the Organization Stats by ID(Phase 2)
    builder.addCase(getOrgUserStats.pending, (state) => {
      state.operations[BDRequestType.GET_USER_SUMMARY] = {
        status: BDRequestStatus.PENDING,
        errors: [],
      };
    });
    builder.addCase(getOrgUserStats.fulfilled, (state, action) => {
      state.operations[BDRequestType.GET_USER_SUMMARY] = {
        status: BDRequestStatus.SUCCEEDED,
      };
      organizationsAdapter.updateOne(state, {
        id: action?.meta.arg.organizationsId,
        changes: {
          ...state.entities[action?.meta.arg.organizationsId],
          summaryCounts: {
            ...state.entities[action?.meta.arg.organizationsId]?.summaryCounts,
            users: action.payload.result,
          },
        },
      });
    });
    builder.addCase(getOrgUserStats.rejected, (state, action) => {
      state.operations[BDRequestType.GET_USER_SUMMARY] = {
        status: BDRequestStatus.FAILED,
        errors: [
          {
            type: BDAppErrorType.API,
            ...(action.payload || (action.error as BDError)),
            requestType: BDRequestType.GET_USER_SUMMARY,
          },
        ],
      };
    });

    // org asset stats
    //TODO: Remove once API adds assetCount to the Organization Stats by ID(Phase 2)
    builder.addCase(getOrgAssetStats.pending, (state) => {
      state.operations[BDRequestType.GET_ASSET_SUMMARY] = {
        status: BDRequestStatus.PENDING,
        errors: [],
      };
    });
    builder.addCase(getOrgAssetStats.fulfilled, (state, action) => {
      state.operations[BDRequestType.GET_ASSET_SUMMARY] = {
        status: BDRequestStatus.SUCCEEDED,
      };
      organizationsAdapter.updateOne(state, {
        id: action?.meta.arg.organizationsId,
        changes: {
          ...state.entities[action?.meta.arg.organizationsId],
          summaryCounts: {
            ...state.entities[action?.meta.arg.organizationsId]?.summaryCounts,
            assets: action.payload.result,
          },
        },
      });
    });
    builder.addCase(getOrgAssetStats.rejected, (state, action) => {
      state.operations[BDRequestType.GET_ASSET_SUMMARY] = {
        status: BDRequestStatus.FAILED,
        errors: [
          {
            type: BDAppErrorType.API,
            ...(action.payload || (action.error as BDError)),
            requestType: BDRequestType.GET_ASSET_SUMMARY,
          },
        ],
      };
    });

    // add org
    builder.addCase(addOrganization.pending, (state) => {
      state.operations[BDRequestType.ADD] = {
        status: BDRequestStatus.PENDING,
        errors: [],
      };
    });
    builder.addCase(addOrganization.fulfilled, (state) => {
      state.operations[BDRequestType.ADD] = {
        status: BDRequestStatus.SUCCEEDED,
      };
    });
    builder.addCase(addOrganization.rejected, (state, action) => {
      state.operations[BDRequestType.ADD] = {
        status: BDRequestStatus.FAILED,
        errors: [
          {
            type: BDAppErrorType.API,
            ...(action.payload || (action.error as BDError)),
            requestType: BDRequestType.ADD,
          },
        ],
      };
    });

    // submit org t&c
    builder.addCase(submitOrgTCs.pending, (state) => {
      state.operations[OrganizationRequestType.SUBMIT_TERMS_AND_CONDITIONS] = {
        status: BDRequestStatus.PENDING,
        errors: [],
      };
    });
    builder.addCase(submitOrgTCs.fulfilled, (state) => {
      state.operations[OrganizationRequestType.SUBMIT_TERMS_AND_CONDITIONS] = {
        status: BDRequestStatus.SUCCEEDED,
      };
    });
    builder.addCase(submitOrgTCs.rejected, (state, action) => {
      state.operations[OrganizationRequestType.SUBMIT_TERMS_AND_CONDITIONS] = {
        status: BDRequestStatus.FAILED,
        errors: [
          {
            type: BDAppErrorType.API,
            ...(action.payload || (action.error as BDError)),
            requestType: BDRequestType.UPDATE,
          },
        ],
      };
    });

    // update org
    builder.addCase(updateOrganization.pending, (state) => {
      state.operations[BDRequestType.UPDATE] = {
        status: BDRequestStatus.PENDING,
        errors: [],
      };
    });
    builder.addCase(updateOrganization.fulfilled, (state, action) => {
      state.operations[BDRequestType.UPDATE] = {
        status: BDRequestStatus.SUCCEEDED,
      };
      organizationsAdapter.upsertOne(state, action.payload.result);
    });
    builder.addCase(updateOrganization.rejected, (state, action) => {
      state.operations[BDRequestType.UPDATE] = {
        status: BDRequestStatus.FAILED,
        errors: [
          {
            type: BDAppErrorType.API,
            ...(action.payload || (action.error as BDError)),
            requestType: BDRequestType.UPDATE,
          },
        ],
      };
    });

    // delete org
    builder.addCase(deleteOrganization.pending, (state) => {
      state.operations[BDRequestType.DELETE] = {
        status: BDRequestStatus.PENDING,
        errors: [],
      };
    });
    builder.addCase(deleteOrganization.fulfilled, (state) => {
      state.operations[BDRequestType.DELETE] = {
        status: BDRequestStatus.SUCCEEDED,
      };
    });
    builder.addCase(deleteOrganization.rejected, (state, action) => {
      state.operations[BDRequestType.DELETE] = {
        status: BDRequestStatus.FAILED,
        errors: [
          {
            type: BDAppErrorType.API,
            ...(action.payload || (action.error as BDError)),
            requestType: BDRequestType.DELETE,
          },
        ],
      };
    });

    // getAssetCountSummary
    builder.addCase(getAssetCountSummary.pending, (state, action) => {
      const { sessionId } = action.meta.arg;

      if (sessionId) {
        state.sessionConfigs = merge(
          state.sessionConfigs,
          {
            [SessionConfigType.DETAIL_VIEW]: {
              [sessionId]: {
                assetCountSummary: {},
                operationStatus: {
                  status: BDRequestStatus.PENDING,
                },
              },
            },
          },
          {
            arrayMerge: (_, sourceArray) => sourceArray,
          }
        );
      }
    });
    builder.addCase(getAssetCountSummary.fulfilled, (state, action) => {
      const { sessionId } = action.meta.arg;

      if (sessionId) {
        state.sessionConfigs = merge(
          state.sessionConfigs,
          {
            [SessionConfigType.DETAIL_VIEW]: {
              [sessionId]: {
                assetCountSummary: action.payload.result,
                operationStatus: {
                  status: BDRequestStatus.SUCCEEDED,
                },
              },
            },
          },
          {
            arrayMerge: (_, sourceArray) => sourceArray,
          }
        );
      }
    });
    builder.addCase(getAssetCountSummary.rejected, (state, action) => {
      const { sessionId } = action.meta.arg;

      if (sessionId) {
        state.sessionConfigs = merge(
          state.sessionConfigs,
          {
            [SessionConfigType.DETAIL_VIEW]: {
              [sessionId]: {
                operationStatus: {
                  status: BDRequestStatus.FAILED,
                  errors: [
                    {
                      type: BDAppErrorType.API,
                      ...(action.payload || (action.error as BDError)),
                    },
                  ],
                },
              },
            },
          },
          {
            arrayMerge: (_, sourceArray) => sourceArray,
          }
        );
      }
    });

    // reset state when persist store is purged on logout
    builder.addCase(PURGE, (state) => {
      state = initialState;
    });
  },
});

export const { setOrgSessionConfig } = organizationsSlice.actions;

export const {
  selectAll: selectAllOrgs,
  selectById: selectOrgById,
  selectIds: selectOrgIds,
} = organizationsAdapter.getSelectors<RootState>(
  (state: RootState) => state.organizations
);

export const { selectAll: selectAllOrganizationTenants } =
  tenantsAdapter.getSelectors<RootState>(
    (state: RootState) => state.organizations.tenants
  );

export const organizationsReducer = organizationsSlice.reducer;
