import { usePermissions, useRole } from '@gm-commercial/profile-context';
import { Permission, Role } from '@gm-commercial/profile-model';
import {
  Children,
  createElement,
  Fragment,
  PropsWithChildren,
  ReactNode,
  useMemo,
} from 'react';

import { AppEnv } from '../../constants/common.constant';

const renderChildren = (children?: ReactNode[]) => {
  if (!children) {
    return null;
  }

  return children.length > 1
    ? createElement(Fragment, null, ...children)
    : Children.only(children); // verifies single child and returns, or throws error
};

interface SecureWrapperProps {
  requiredPermissions: Permission[];
  requiredRoles?: Role[];
  allowedEnvironments?: AppEnv[];
  excludedEnvironments?: AppEnv[];
  excludedRoles?: Role[];
  passThrough?: boolean;
  actingRole?: boolean;
}

const SecureWrapper = ({
  requiredPermissions,
  requiredRoles,
  allowedEnvironments,
  excludedEnvironments,
  excludedRoles,
  passThrough,
  actingRole = true,
  children,
}: PropsWithChildren<SecureWrapperProps>): JSX.Element => {
  const { hasPermission } = usePermissions();
  const { hasRole, roleExcluded } = useRole(actingRole);

  const environmentAllowed = useMemo(() => {
    const appEnv = globalThis.appConfig.env;
    return (
      (!allowedEnvironments ||
        (Array.isArray(allowedEnvironments) &&
          allowedEnvironments.includes(appEnv))) &&
      (!excludedEnvironments ||
        (Array.isArray(excludedEnvironments) &&
          !excludedEnvironments.includes(appEnv)))
    );
  }, [allowedEnvironments, excludedEnvironments]);

  const permsAllowed = useMemo(
    () => hasPermission(requiredPermissions),
    [hasPermission, requiredPermissions]
  );

  const roleAllowed = useMemo(
    () =>
      (!requiredRoles ||
        (Array.isArray(requiredRoles) && hasRole(requiredRoles))) &&
      (!excludedRoles ||
        (Array.isArray(excludedRoles) && !roleExcluded(excludedRoles))),
    [hasRole, roleExcluded, requiredRoles, excludedRoles]
  );

  const childElements = useMemo(
    () =>
      typeof children === 'function'
        ? children(permsAllowed && roleAllowed)
        : children,
    [children, permsAllowed, roleAllowed]
  );

  return (
    <>
      {passThrough || (permsAllowed && roleAllowed && environmentAllowed)
        ? renderChildren(childElements)
        : null}
    </>
  );
};

export default SecureWrapper;
