import { Box } from '@mui/material';
import { PropsWithChildren } from 'react';

import useStyles from './BDContainer.styles';

const BDContainer = ({
  id,
  children,
}: PropsWithChildren<{ id: string }>): JSX.Element => {
  const { classes } = useStyles();

  return (
    <Box
      key={`${id}-container`}
      className={classes.mainContainer}
      data-testid={`${id}-container`}
    >
      {children}
    </Box>
  );
};
export default BDContainer;
