import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme) => ({
  list: {
    borderRadius: '12px',
  },
  listWrapper: {
    borderRadius: '12px',
    backgroundColor: theme.palette.common.white,
  },

  popoverTabsContainer: {
    alignItems: 'center',
    backgroundColor: theme.new.color.common.white,
    borderBottom: `1px solid ${theme.new.color.line.hairline}`,
    display: 'flex',
    justifyContent: 'space-between',
    paddingLeft: '12px',
    position: 'absolute',
    width: '100%',
    zIndex: '1',
    button: {
      marginRight: '12px',
      minHeight: '35px',
    },
  },
  tabsContainer: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '20px',
  },
  titleWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    marginBottom: '15px',
    [theme.breakpoints.down('md')]: {
      '& > *': {
        width: '50%',
      },
      '& .MuiBox-root > div': {
        width: '100%',
      },
    },
  },
  unreadItem: {
    position: 'relative',
    '&::before': {
      content: 'none',
      position: 'absolute',
    },
  },
  readItem: {
    position: 'relative',
    '&::before': {
      top: 0,
      left: 0,
    },
  },
  iconContainer: {
    position: 'relative',
    backgroundColor: theme.new.color.grey[100],

    svg: {
      stroke: theme.new.color.textPrimary,
      fill: 'none',
    },
  },
  messageContainer: {
    backgroundColor: theme.new.color.common.white,
    borderRadius: '12px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'stretch',
    gap: '16px',
    height: '220px',
  },
  unReadIconContainer: {
    position: 'relative',
    backgroundColor: theme.new.color.brand['100'],
  },
  badge: {
    '& .MuiBadge-badge': {
      backgroundColor: theme.new.color.brand['600'], // Blue ellipse dot
      color: theme.new.color.brand['400'],
      width: '8px',
      height: '8px',
      borderRadius: '50%',
      left: 20,
      zIndex: 0,
    },
  },
  listItem: {
    minHeight: '120px',
    paddingBlock: '16px',
    paddingLeft: '16px',
    borderStyle: 'solid',
    borderWidth: '0px 0px 1px 0px',

    '.MuiListItemSecondaryAction-root': {
      right: '24px',
      top: '32px',
    },
  },
  listContainer: {
    backgroundColor: theme.new.color.common.white,
    borderRadius: '12px',
  },
  divider: {
    color: theme.new.color.common.white,
  },
  skeletonHeader: {
    color: theme.palette.grey[300],
    height: '20px',
    borderRadius: '12px',
    margin: '16px',
  },
  skeleton: {
    color: theme.palette.grey[100],
    animation: '$pulse 1.5s infinite ease-in-out',
  },
  '@keyframes pulse': {
    '0%': {
      opacity: 0.5,
    },
    '100%': {
      opacity: 1,
    },
  },
  skeleton1: {
    animationDelay: '0s',
  },
  skeleton2: {
    animationDelay: '0.5s',
  },
  skeleton3: {
    animationDelay: '1s',
  },
  skeleton4: {
    animationDelay: '1.5s',
  },
  skeleton5: {
    animationDelay: '2s',
  },
}));
