import { createSlice } from '@reduxjs/toolkit';

import { RouteParams } from '~/common/configs/route.config';
import {
  ListViewSession,
  OperationSession,
  SearchCriteria,
} from '~/common/models/common.model';
import { ScheduledDateRange } from '~/common/models/workplan.model';

import { RootState } from '../../../app/rootReducer';
import { JOBS_ACTIONS } from './state/jobsSlice.actions';
import { jobsAdapter } from './state/jobsSlice.adapters';
import { INITIAL_JOBS_STATE } from './state/jobsSlice.constants';
import {
  JOBS_EXTRA_REDUCER_BUILDERS,
  JOBS_REDUCERS,
} from './state/jobsSlice.reducers';

export type JobsRouteParams = Pick<
  RouteParams,
  'organizationsId' | 'hubsId' | 'fleetsId'
> & {
  sessionId: string;
};

export enum JobsSessionViewType {
  WORK_PLANS_UPLOAD_HISTORY = 'Work Plans Upload History',
}

export type UploadWorkPlanHistoryParams = JobsRouteParams & {
  size: number;
  page: number;
  createdBeforeDate?: string;
  createdAfterDate?: string;
  sortBy?: string;
  sortOrder?: string;
  status?: string;
  fileName?: string;
};

export interface JobsState {
  sessionConfigs: JobsSessionConfig;
}

export type JobsSessionConfig = {
  [JobsSessionViewType.WORK_PLANS_UPLOAD_HISTORY]?: {
    [key: string]: Partial<JobsSession>;
  };
};

export interface JobsSession extends ListViewSession, OperationSession {
  searchCriteria?: SearchCriteria;
  selectedScheduledDateRange?: ScheduledDateRange;
}

const { reduceSetJobsSessionConfig } = JOBS_REDUCERS;

export const jobsSlice = createSlice({
  name: 'jobs',
  initialState: INITIAL_JOBS_STATE,
  reducers: {
    setJobsSessionConfig: reduceSetJobsSessionConfig,
  },
  extraReducers: (builder) => {
    JOBS_EXTRA_REDUCER_BUILDERS.buildGetJobsUploadHistoryReducer(builder);
  },
});
export const { getUploadWorkPlanHistory } = JOBS_ACTIONS;
export const { setJobsSessionConfig } = jobsSlice.actions;

export const {
  selectAll: selectAllWorkPlansHistory,
  selectById: selectWorkPlansHistoryById,
  selectIds: selectWorkPlansHistoryIds,
} = jobsAdapter.getSelectors<RootState>((state: RootState) => state.jobs);

export const jobsReducer = jobsSlice.reducer;
