import { MsalProvider } from '@azure/msal-react';

import useCustomAuthentication from '../../common/hooks/useCustomAuthentication';
import { getMsalInstance } from './msalInstance';
import SessionTimeoutDialog from './timeout/SessionTimeoutDialog';

const AuthTokenWrapper = (): JSX.Element => {
  const { authenticationType } = useCustomAuthentication();
  const msalInstance = getMsalInstance(authenticationType);

  return (
    <>
      <MsalProvider instance={msalInstance}>
        <SessionTimeoutDialog />
      </MsalProvider>
    </>
  );
};

export default AuthTokenWrapper;
