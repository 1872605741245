import { Asset } from '~/common/models/asset.model';

/* Type encompassing all types relevant to global search results. */
export type GlobalSearchType = Asset;

/* Interface which specifies the exact search result type (from the GlobalSearchType list) and includes additional information */
export interface GlobalSearchResult<SearchType extends GlobalSearchType> {
  resultInfo: SearchType;
  isPreviousSearch: boolean;
}

/* Constants */
export const MAX_GLOBAL_SEARCH_RESULTS = 6;
