import { SearchType } from '~/common/constants/common.constant';
import { SearchCriteria } from '~/common/models/common.model';
import {
  CameraStatus,
  PeripheralLastCommunicatedStatus,
  PeripheralType,
} from '~/common/models/peripheral.model';
import { PeripheralFilterCriteria } from '~/features/peripherals/list/filter/PeripheralFilter.model';
import {
  DEFAULT_PERIPHERAL_TYPE,
  OLDEST_POSSIBLE_DATE,
  PeripheralParamKey,
} from '~/features/peripherals/peripherals.constants';

function computeLastCommunicatedRange(
  lastCommunicatedStatus?: PeripheralLastCommunicatedStatus[]
): { start?: string; end?: string } {
  if (!lastCommunicatedStatus || lastCommunicatedStatus.length === 0) return {};
  if (lastCommunicatedStatus.length > 1) return {};
  const status = lastCommunicatedStatus[0];
  const now = new Date().toISOString();
  const ONE_WEEK_MS = 7 * 24 * 60 * 60 * 1000;
  if (status === PeripheralLastCommunicatedStatus.LESS_THAN_WEEK) {
    const startDate = new Date(Date.now() - ONE_WEEK_MS).toISOString();
    return { start: startDate, end: now };
  } else if (status === PeripheralLastCommunicatedStatus.MORE_THAN_WEEK) {
    const endDate = new Date(Date.now() - ONE_WEEK_MS).toISOString();
    return { start: OLDEST_POSSIBLE_DATE, end: endDate };
  }
  return {};
}

export function buildPeripheralRequestParams(
  peripheralType: PeripheralType = DEFAULT_PERIPHERAL_TYPE,
  searchCriteria?: SearchCriteria,
  filterCriteria?: PeripheralFilterCriteria
): Record<string, string> {
  const requestParams: Record<string, string> = {
    [PeripheralParamKey.TYPE]: peripheralType,
  };

  if (filterCriteria?.assignmentStatus?.length === 1) {
    const status = filterCriteria.assignmentStatus[0];
    if (status === CameraStatus.ASSIGNED)
      requestParams[PeripheralParamKey.STATUS] = CameraStatus.ASSIGNED;
    else if (status === CameraStatus.UNASSIGNED)
      requestParams[PeripheralParamKey.STATUS] = CameraStatus.UNASSIGNED;
  }

  const { start, end } = computeLastCommunicatedRange(
    filterCriteria?.lastCommunicatedStatus
  );
  if (start && end) {
    requestParams[PeripheralParamKey.LAST_COMM_DATE_START] = start;
    requestParams[PeripheralParamKey.LAST_COMM_DATE_END] = end;
  }

  if (searchCriteria?.input && searchCriteria.searchType) {
    const input = searchCriteria.input.trim();
    if (input) {
      switch (searchCriteria.searchType) {
        case SearchType.VIN:
          requestParams[PeripheralParamKey.VIN] = input;
          break;
        case SearchType.IMEI:
          requestParams[PeripheralParamKey.IMEI] = input;
          break;
        case SearchType.LICENSE_PLATE:
          requestParams[PeripheralParamKey.LICENSE_PLATE] = input;
          break;
        case SearchType.ATTACHED_VEHICLE_NAME:
          requestParams[PeripheralParamKey.ATTACHED_VEHICLE_NAME] = input;
          break;
        default:
          break;
      }
    }
  }

  return requestParams;
}
