import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  dialogDivider: {
    margin: '0 20px',
  },
  closeWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  dialogClose: {
    padding: '0',
    '& svg': {
      width: '24px',
      height: '24px',
    },
  },
  '.MuiDialogTitle-root': {
    borderBottom: `1px solid ${theme.new.color.line.hairline}`,
  },
  dialogTitle: {
    marginTop: '8px',
  },
  dialogContent: {
    ' > form': {
      paddingTop: '4px',
    },
    '& form, & form > .MuiBox-root ': {
      '& > .MuiFormControl-root': {
        marginBottom: '16px',
      },
    },
    '& .actionButtons': {
      textAlign: 'right',
      paddingTop: '8px',
      '& button:last-of-type': {
        marginLeft: '8px',
      },
      [theme.breakpoints.up('lg')]: {
        '& button:last-of-type': {
          minWidth: '160px',
        },
      },
    },
    '& .panelContainer': {
      padding: '20px 16px',
      borderRadius: '12px',
      '&.primaryContainer': {
        backgroundColor: theme.palette.brand.lighter,
        border: `1px solid ${theme.palette.brand[300]}`,
      },
      '&.secondaryContainer': {
        backgroundColor: theme.palette.grey[300],
        border: `1px solid ${theme.palette.text.tertiary}`,
      },
      '& .formField': {
        width: '100%',
        '& .MuiInputBase-root': {
          backgroundColor: theme.palette.common.white,
          width: '100%',
        },
      },
      '& .radioForm': {
        '& .Mui-focused:not(.Mui-error)': {
          color: theme.palette.text.primary,
        },
        '& span svg.MuiSvgIcon-root': {
          fill: theme.palette.text.tertiary,
        },
        '& .Mui-checked span svg.MuiSvgIcon-root': {
          fill: theme.palette.brand.main,
        },
      },
    },
    '& .activityPanel': {
      padding: '15px 30px',
    },
    '& .MuiTableCell-body': {
      maxWidth: '200px',
    },
    /* created custom textfield for phone  */
    '& div.react-tel-input': {
      borderRadius: '8px',
      padding: '0px 16px 0 12px',
      height: '56px',
      border: `1px solid ${theme.palette.text.primary}33`,
      width: '100%',
      position: 'relative',
      backgroundColor: theme.palette.inputBackground,
      '& .MuiFormLabel-root': {
        fontSize: '0.75rem',
        color: theme.palette.text.secondary,
        top: '-10px',
      },
      '&:focus-within .special-label': {
        color: theme.palette.brand.main,
      },
      fontFamily: 'interRegular, "Open Sans", Arial, sans-serif',
      '&.error div.special-label': {
        color: theme.palette.error.main,
      },
      '& MuiFormLabel-root': {
        left: '58px',
        top: '4px',
        fontSize: '0.75rem',
      },
      ' & .PhoneInputCountry': {
        paddingRight: '8px',
      },
      '& .PhoneInput--focus > input': {},
      '& .PhoneInput': {
        padding: '28px 0 0 0',
        '& > input': {
          border: 'none',
          fontSize: '1rem',
          background: 'none',
        },
        '&.invalid-number, &.invalid-number:focus, &.invalid-number:hover': {
          boxShadow: 'none',
          border: `1px solid ${theme.palette.error.main}`,
        },
        '&:hover': {
          borderColor: `${theme.palette.text.primary}33`,
        },
        '&:focus': {
          boxShadow: 'none',
          border: `1px solid ${theme.palette.brand.main}`,
          '& + .flag-dropdown:not(.invalid-number)': {
            border: `1px solid ${theme.palette.brand.main}`,
          },
        },
      },
      '& .MuiFormHelperText-root': {
        paddingTop: '2px',
        marginLeft: '0',
      },
    },
  },
  dialogActions: {
    clear: 'both',
    margin: '16px',
    '& .actionButtons': {
      textAlign: 'right',
      paddingTop: '8px',
      '& button:last-of-type': {
        marginLeft: '8px',
      },
      [theme.breakpoints.up('lg')]: {
        '& button:last-child': {
          minWidth: '160px',
        },
      },
    },
  },
}));

export default useStyles;
