import {
  createEntityAdapter,
  createSlice,
  EntityState,
  PayloadAction,
} from '@reduxjs/toolkit';
import merge from 'deepmerge';
import { PURGE } from 'redux-persist';

import {
  API_VERSION_DEFAULTS,
  CONTINUATION_TOKEN_HEADER_NAME,
  DEFAULT_API_CONFIG,
} from '~/common/apis/api.constants';
import { RouteParams } from '~/common/configs/route.config';
import {
  ApiResponse,
  BDRequestStatus,
} from '~/common/models/apis/apiResponse.model';
import {
  ApiAssetMapItem,
  AssetConnectivityStatus,
  AssetExceptionStatus,
  AssetMapItem,
  AssetOperationalStatus,
  AssetProductModel,
  AssetServiceStatus,
} from '~/common/models/asset.model';
import {
  BatteryChargingStatus,
  BatterySocStatus,
} from '~/common/models/asset-report.model';
import {
  ListViewSession,
  OperationSession,
  PagedResult,
  PagedResultWithErrors,
  PaginationContinuationInfo,
  SearchCriteria,
} from '~/common/models/common.model';
import { BDAppErrorType, BDError } from '~/common/models/error.model';
import {
  addAcceptLanguageHeader,
  addHeader,
  hasApiResult,
} from '~/common/utils/apis/api.utils';
import {
  makeGetPayloadCreator,
  makeThunk,
} from '~/common/utils/store/thunk.helper';

import { RootState } from '../../app/rootReducer';
import { mapApiAssetMapItemToMapItem } from '../assets/mappers/asset.mappers';
import { mapItemsByExceptionsSorter } from './utils/AssetMapDetails.utils';

const mapAdapter = createEntityAdapter<MapStatus>({});

export interface MapStatus {
  zoom?: number;
  center?: { latitude: number; longitude: number };
  selectedMarkerIds?: string[];
  filteredMarkerIds?: string[];
  visibleMarkerIds?: string[];
  highlightedMarkerIds?: string[];
  highlightedPosition?: GeoJSON.Position;
}

export enum MapViewType {
  MAIN = 'MapView',
  TRIP_HISTORY = 'TripHistoryMap',
  DASHBOARD = 'DashboardMap',
  CURRENT_LOCATION = 'currentLocationMap',
  ROUTE_PLAN = 'RoutePlan',
}

export type MapFilterOperationalStatus =
  | AssetServiceStatus
  | Extract<
      AssetOperationalStatus,
      | AssetOperationalStatus.MOVING
      | AssetOperationalStatus.OFFLINE
      | AssetOperationalStatus.STOPPED
    >
  | Extract<
      AssetConnectivityStatus,
      | AssetConnectivityStatus.OFFLINE_24H
      | AssetConnectivityStatus.OFFLINE_3D
      | AssetConnectivityStatus.OFFLINE_1W
    >;

export type MapFilterBatteryStatus =
  | BatterySocStatus
  | BatteryChargingStatus.CHARGING;

export type MapFilterChargeStatus =
  | BatteryChargingStatus.CHARGING
  | BatteryChargingStatus.COMPLETED
  | BatteryChargingStatus.WAITING
  | BatteryChargingStatus.NOT_PLUGGED_IN
  | BatteryChargingStatus.INTERRUPTED;

export interface MapFilterSession {
  assetTypes: AssetProductModel[];
  exceptionStatus: AssetExceptionStatus[];
  operationalStatus: MapFilterOperationalStatus[];
  batteryStatus: MapFilterBatteryStatus[];
  chargeStatus: MapFilterChargeStatus[];
  searchCriteria: SearchCriteria;
}

export interface MapAlertsSession {
  dismissedAlerts: {
    noLocationFound?: boolean;
  };
}

/**
 * MapSessionConfigType represent an object where session is scoped by the view and entity id
 *
 * @interface MapSessionConfigType
 * @property {MapViewType} key The view that we are storing sessions.
 * @property {string} id The id of the entity you are viewing, E.g.
 */
export interface MapSessionConfigType {
  [MapViewType.MAIN]?: {
    [id: string]: Partial<
      MapStatus &
        MapFilterSession &
        MapAlertsSession &
        OperationSession &
        ListViewSession
    >;
  };
  [MapViewType.DASHBOARD]?: {
    [id: string]: Partial<
      MapStatus & MapFilterSession & OperationSession & ListViewSession
    >;
  };
  [MapViewType.TRIP_HISTORY]?: {
    [id: string]: Partial<MapStatus>;
  };
  [MapViewType.CURRENT_LOCATION]?: {
    [id: string]: Partial<MapStatus>;
  };
  [MapViewType.ROUTE_PLAN]?: {
    [id: string]: Partial<MapStatus>;
  };
}

export interface ViewConfig {
  bounds?: GeoJSON.BBox;
}

type MapViewConfigType = {
  [k in MapViewType]?: { [id: string]: ViewConfig };
};

export type AssetItemSessions = {
  [sessionId: string]: string[];
};

const assetItemsAdapter = createEntityAdapter<AssetMapItem>({
  selectId: (item: AssetMapItem) => item.id,
  sortComparer: mapItemsByExceptionsSorter,
});

interface MapState {
  sessionConfigs: {
    [k in MapViewType]?: MapSessionConfigType[k];
  };
  viewConfigs: MapViewConfigType;
  assetItems: EntityState<AssetMapItem>;
}

const initialState = mapAdapter.getInitialState<MapState>({
  sessionConfigs: {},
  viewConfigs: {},
  assetItems: assetItemsAdapter.getInitialState(),
});

export type AssetMapItemParams = RouteParams & {
  sessionId: string;
  viewType: MapViewType.DASHBOARD | MapViewType.MAIN;
};
export type AssetMapItemPaginatedListParams = AssetMapItemParams &
  PaginationContinuationInfo;

export const getAssetMapItems = makeThunk(
  'map/fetchAssetMapItems',
  makeGetPayloadCreator<
    ApiResponse<PagedResultWithErrors<AssetMapItem>>,
    AssetMapItemPaginatedListParams
  >({
    url: `${globalThis.appConfig.apiBaseUrl}/assets/${API_VERSION_DEFAULTS.default}/views/maplistitems`,
    axiosOptions: ({ continuationToken }, state) => {
      const commonHeaders = addAcceptLanguageHeader(
        DEFAULT_API_CONFIG,
        state.profile.currentLocale
      );
      return continuationToken
        ? addHeader(
            commonHeaders,
            CONTINUATION_TOKEN_HEADER_NAME,
            continuationToken
          )
        : commonHeaders;
    },
    argAdapter: ({
      rowsPerPage,
      organizationsId: organizationId,
      hubsId: hubId,
      fleetsId: fleetId,
    }) => {
      return {
        requestParams: {
          size: String(rowsPerPage),
          organizationId,
          fleetId,
          hubId,
        } as { [k: string]: string },
      };
    },
    responseAdapter: (response: unknown | ApiResponse<unknown>) => {
      if (
        !!response &&
        hasApiResult<PagedResult<Partial<ApiAssetMapItem>>>(response)
      ) {
        const errors = new Array<BDError>();
        const { items, total_items, continuation_token } = response.result;
        const result = {
          ...response,
          result: {
            total_items,
            continuation_token,
            items: items?.length
              ? items.reduce((mappedAssets, apiAsset) => {
                  try {
                    mappedAssets.push(mapApiAssetMapItemToMapItem(apiAsset));
                  } catch (e) {
                    // handle mapping errors without bombing entire list response
                    errors.push({
                      name: 'Get Asset Map Items',
                      type: BDAppErrorType.VALIDATION,
                      message:
                        e instanceof Error
                          ? e.message
                          : 'Failed to map asset API map item',

                      data: apiAsset,
                    });
                  }
                  return mappedAssets;
                }, new Array<AssetMapItem>())
              : [],
          },
        };
        return errors.length
          ? { ...result, errors: errors.map((e) => BDError.asJson(e)) }
          : result;
      }
      // bomb entire list response if response does not match expected format
      throw new BDError(
        'Unexpected asset map item response' + JSON.stringify(response),
        {
          data: response,
        }
      );
    },
  })
);

export const mapSlice = createSlice({
  name: 'map',
  initialState,
  reducers: {
    clearMapSessionConfig: (
      state,
      action: PayloadAction<{ viewType: MapViewType; id: string }>
    ) => {
      state.sessionConfigs = merge(
        state.sessionConfigs,
        {
          [action.payload.viewType]: {
            [action.payload.id]: {
              assetTypes: [],
              exceptionStatus: [],
              operationalStatus: [],
              batteryStatus: [],
              chargeStatus: [],
              searchCriteria: { input: '' },
            },
          },
        },
        {
          arrayMerge: (_, sourceArray) => sourceArray,
        }
      );
    },
    setAssetItems: (state, action: PayloadAction<AssetMapItem[]>) => {
      assetItemsAdapter.setAll(state.assetItems, action.payload);
    },
    setAssetItemsResult: (
      state,
      action: PayloadAction<{
        viewType: MapViewType;
        sessionId: string;
        items: AssetMapItem[];
        traceSuppressorEnabled?: boolean;
      }>
    ) => {
      const { viewType, sessionId, items, traceSuppressorEnabled } =
        action.payload;
      const filteredItems = traceSuppressorEnabled
        ? items.filter((item) => item.model == AssetProductModel.ZEVO)
        : items;
      state.sessionConfigs = {
        ...state.sessionConfigs,
        [viewType]: {
          ...state.sessionConfigs?.[viewType],
          [sessionId]: {
            ...state.sessionConfigs?.[viewType]?.[sessionId],
            operationStatus: {
              status: BDRequestStatus.SUCCEEDED,
            },
            count: filteredItems.length,
          },
        },
      };
      assetItemsAdapter.setAll(state.assetItems, filteredItems);
    },
    setMapSessionConfig: (
      state,
      action: PayloadAction<MapSessionConfigType>
    ) => {
      Object.keys(action.payload).forEach((key) => {
        const scope = key as MapViewType;
        const payload = action.payload[scope] || {};
        Object.keys(payload).forEach((id) => {
          state.sessionConfigs = merge(
            state.sessionConfigs,
            {
              [scope]: {
                [id]: payload[id] && {
                  ...payload[id],
                },
              },
            },
            {
              arrayMerge: (_, sourceArray) => sourceArray,
            }
          );
        });
      });
    },
    setMapViewConfig: (state, action: PayloadAction<MapViewConfigType>) => {
      Object.keys(action.payload).forEach((key) => {
        const scope = key as MapViewType;
        const payload = action.payload[scope] || {};
        Object.keys(payload).forEach((id) => {
          state.viewConfigs = merge(
            state.viewConfigs,
            {
              [scope]: {
                [id]: payload[id] && {
                  ...payload[id],
                },
              },
            },
            {
              arrayMerge: (_, sourceArray) => sourceArray,
            }
          );
        });
      });
    },
  },
  extraReducers: (builder) => {
    // map item list
    builder.addCase(getAssetMapItems.pending, (state, action) => {
      const { sessionId, viewType, continuationToken } = action.meta.arg;
      if (!continuationToken) {
        state.sessionConfigs = {
          ...state.sessionConfigs,
          [viewType]: {
            ...state.sessionConfigs?.[viewType],
            [sessionId]: {
              ...state.sessionConfigs?.[viewType]?.[sessionId],
              operationStatus: {
                status: BDRequestStatus.PENDING,
                errors: [],
              },
              count: 0,
            },
          },
        };
      }
    });
    builder.addCase(getAssetMapItems.fulfilled, (state, action) => {
      const response = action.payload;
      const { sessionId, viewType } = action.meta.arg;
      const status = response.result.continuation_token
        ? BDRequestStatus.PENDING
        : BDRequestStatus.SUCCEEDED;
      state.sessionConfigs = {
        ...state.sessionConfigs,
        [viewType]: {
          ...state.sessionConfigs?.[viewType],
          [sessionId]: {
            ...state.sessionConfigs?.[viewType]?.[sessionId],
            operationStatus: {
              status,
              errors: [
                ...[
                  state.sessionConfigs?.[viewType]?.[sessionId]?.operationStatus
                    ?.errors,
                ],
                ...(response.result.errors || []),
              ],
            },
            count:
              (state.sessionConfigs?.[viewType]?.[sessionId]?.count || 0) +
              response.result.total_items,
          },
        },
      };
      if (!response.result.continuation_token && !response.result.total_items) {
        assetItemsAdapter.setAll(state.assetItems, []);
      }
    });
    builder.addCase(getAssetMapItems.rejected, (state, action) => {
      const { sessionId, viewType } = action.meta.arg;
      state.sessionConfigs = {
        ...state.sessionConfigs,
        [viewType]: {
          ...state.sessionConfigs?.[viewType],
          [sessionId]: {
            ...state.sessionConfigs?.[viewType]?.[sessionId],
            operationStatus: {
              status: BDRequestStatus.FAILED,
              errors: [
                ...[
                  state.sessionConfigs?.[viewType]?.[sessionId]?.operationStatus
                    ?.errors,
                ],
                {
                  type: BDAppErrorType.API,
                  ...(action.payload || (action.error as BDError)),
                },
              ],
            },
          },
        },
      };
    });
    // reset state when persist store is purged on logout
    builder.addCase(PURGE, (state) => {
      state = initialState;
    });
  },
});

export const {
  setMapSessionConfig,
  clearMapSessionConfig,
  setMapViewConfig,
  setAssetItems,
  setAssetItemsResult,
} = mapSlice.actions;

export const {
  selectAll: selectAllMapAssetItems,
  selectById: selecMapAssetItemsById,
  selectIds: selectMapAssetItemsIds,
} = assetItemsAdapter.getSelectors<RootState>(
  (state: RootState) => state.map.assetItems
);

export const selectMapState = (state: RootState): RootState['map'] => state.map;

export const mapReducer = mapSlice.reducer;
