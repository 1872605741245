import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  API_VERSION_DEFAULTS,
  DEFAULT_API_CONFIG,
} from '~/common/apis/api.constants';
import {
  ApiResponse,
  BDRequestStatus,
  OperationStatus,
} from '~/common/models/apis/apiResponse.model';
import { BDAppErrorType, BDError } from '~/common/models/error.model';
import {
  addAcceptLanguageHeader,
  hasApiResult,
} from '~/common/utils/apis/api.utils';
import {
  makePostPayloadCreator,
  makeThunk,
} from '~/common/utils/store/thunk.helper';

import { RootState } from '../../app/rootReducer';

export type AssetEventParams = {
  organizationId: string;
  subscriptionIds: string[];
  cleanFromOthers?: boolean;
};

export enum PubSubEventType {
  ASSET,
}

export enum PubSubRequestType {
  GET_PUB_SUB_TOKEN = 'Get PubSub Token',
  SUBSCRIBE_EVENT = 'Subscribe Event',
  INIT_WEB_SOCKET = 'Initialize WebSocket',
}

export interface PubSubState {
  operations: { [k in PubSubRequestType]?: OperationStatus };
  subscriptions: {
    [scope: string]: {
      [event in PubSubEventType]: {
        ids?: string[];
      };
    };
  };
  effectiveScope?: string;
  webSocketScope?: string;
  token?: string;
  url?: string;
}

const CONFIG = DEFAULT_API_CONFIG;

const initialState: PubSubState = {
  operations: {},
  subscriptions: {},
};

export const getPubSubToken = makeThunk(
  'pubSub/getPubSubToken',
  makePostPayloadCreator<ApiResponse<{ token: string; url: string }>, string>({
    url: `${globalThis.appConfig.apiBaseUrl}/communications/${API_VERSION_DEFAULTS.default}/pubSubToken`,
    axiosOptions: (_, state) =>
      addAcceptLanguageHeader(CONFIG, state.profile.currentLocale),
    argAdapter: (organizationId) => {
      return {
        requestBody: { organizationId },
      };
    },
    responseAdapter: (response: unknown) => {
      if (
        hasApiResult<{ token: string; url: string }>(
          response,
          (result): result is { token: string; url: string } =>
            !!(result as { token: string; url: string }).token &&
            !!(result as { token: string; url: string }).url
        )
      ) {
        return {
          ...response,
          result: { token: response.result.token, url: response.result.url },
        };
      }
      throw new BDError('Failed to retrieve PubSub connection token', {
        data: response,
      });
    },
  })
);

export const subscribeAssetEvents = makeThunk(
  'pubSub/subscribeAssetEvents',
  makePostPayloadCreator<ApiResponse<unknown>, AssetEventParams>({
    url: `${globalThis.appConfig.apiBaseUrl}/assets/${API_VERSION_DEFAULTS.default}/eventsubscriptions`,
    axiosOptions: (_, state) =>
      addAcceptLanguageHeader(CONFIG, state.profile.currentLocale),
    argAdapter: ({
      organizationId,
      subscriptionIds,
      cleanFromOthers = true,
    }) => {
      return {
        requestBody: {
          subscriptionIds: subscriptionIds.filter((id) => !!id),
          organizationId,
          cleanFromOthers,
        },
      };
    },
    responseAdapter: (response) => {
      return {
        result: { data: response },
      } as ApiResponse<unknown>;
    },
  })
);

export const pubSubSlice = createSlice({
  name: 'pubSub',
  initialState,
  reducers: {
    setWebSocketScope: (state, action: PayloadAction<string | undefined>) => {
      state.webSocketScope = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getPubSubToken.pending, (state) => {
      state.effectiveScope = undefined;
      state.token = undefined;
      state.url = undefined;
      state.operations[PubSubRequestType.GET_PUB_SUB_TOKEN] = {
        status: BDRequestStatus.PENDING,
        errors: [],
      };
    });
    builder.addCase(getPubSubToken.fulfilled, (state, action) => {
      state.effectiveScope = action.meta.arg;
      state.token = action.payload.result.token;
      state.url = action.payload.result.url;
      state.operations[PubSubRequestType.GET_PUB_SUB_TOKEN] = {
        status: BDRequestStatus.SUCCEEDED,
      };
    });
    builder.addCase(getPubSubToken.rejected, (state, action) => {
      state.operations[PubSubRequestType.GET_PUB_SUB_TOKEN] = {
        status: BDRequestStatus.FAILED,
        errors: [
          {
            type: BDAppErrorType.API,
            ...(action.payload || (action.error as BDError)),
          },
        ],
      };
    });

    builder.addCase(subscribeAssetEvents.pending, (state, action) => {
      if (action.meta.arg.organizationId) {
        state.subscriptions = {
          ...state.subscriptions,
          [action.meta.arg.organizationId]: {
            ...state.subscriptions[action.meta.arg.organizationId],
            [PubSubEventType.ASSET]: {},
          },
        };
      }
      state.operations[PubSubRequestType.SUBSCRIBE_EVENT] = {
        status: BDRequestStatus.PENDING,
        errors: [],
      };
    });
    builder.addCase(subscribeAssetEvents.fulfilled, (state, action) => {
      if (action.meta.arg.organizationId) {
        state.subscriptions[action.meta.arg.organizationId][
          PubSubEventType.ASSET
        ] = { ids: action.meta.arg.subscriptionIds || [] };
      }
      state.operations[PubSubRequestType.SUBSCRIBE_EVENT] = {
        status: BDRequestStatus.SUCCEEDED,
      };
    });
    builder.addCase(subscribeAssetEvents.rejected, (state, action) => {
      state.operations[PubSubRequestType.SUBSCRIBE_EVENT] = {
        status: BDRequestStatus.FAILED,
        errors: [
          {
            type: BDAppErrorType.API,
            ...(action.payload || (action.error as BDError)),
          },
        ],
      };
    });
  },
});

export const selectPubSubState = (state: RootState): PubSubState =>
  state.pubSub;

export const { setWebSocketScope } = pubSubSlice.actions;

export const pubSubReducer = pubSubSlice.reducer;
