import i18next from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

import TranslationBundle from '../translations/index';

i18next
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: TranslationBundle,
    defaultNS: 'common',
    fallbackNS: 'common',
    fallbackLng: 'en',
    supportedLngs: ['en', 'fr', 'fr-CA', 'en-CA', 'es', 'es-US', 'es-MX'],
    debug: false,
    detection: {
      order: ['navigator'],
      caches: ['cookie'],
      cookieOptions: { secure: true, sameSite: 'strict' },
    },

    interpolation: {
      escapeValue: false,
      skipOnVariables: true,
    },
  });
export default i18next;
