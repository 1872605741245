import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import axiosRetry, { isSafeRequestError } from 'axios-retry';

import { AppEnv } from '../constants/common.constant';
import {
  bdAuthErrorResponseInterceptor,
  bdAuthRequestInterceptor,
} from './api.interceptors';

// TODO: add configs for retries, interceptors, etc.
export const instance = axios.create({
  baseURL: globalThis.appConfig.apiBaseUrl || '',
  timeout:
    globalThis.appConfig.env === AppEnv.DEV ||
    globalThis.appConfig.env === AppEnv.QA
      ? 15000
      : 5000,
});
instance.interceptors.request.use(bdAuthRequestInterceptor);
instance.interceptors.response.use(undefined, bdAuthErrorResponseInterceptor);

export const isTimeoutError = (error: AxiosError): boolean =>
  error && error.code === 'ECONNABORTED';

export const isBDRetryableError = (error: AxiosError): boolean =>
  isSafeRequestError(error) || isTimeoutError(error);

axiosRetry(instance, {
  retries: 0,
  shouldResetTimeout: true,
  retryCondition: isBDRetryableError,
});

export const doGet = <T>(
  url: string,
  config?: AxiosRequestConfig
): Promise<AxiosResponse<T>> => instance.get<T>(url, config);
export const doPost = <T>(
  url: string,
  data?: unknown,
  config?: AxiosRequestConfig
): Promise<AxiosResponse<T>> => instance.post<T>(url, data, config);
export const doPut = <T>(
  url: string,
  data?: unknown,
  config?: AxiosRequestConfig
): Promise<AxiosResponse<T>> => instance.put<T>(url, data, config);
export const doPatch = <T>(
  url: string,
  data?: unknown,
  config?: AxiosRequestConfig
): Promise<AxiosResponse<T>> => instance.patch<T>(url, data, config);
export const doDelete = <T>(
  url: string,
  config?: AxiosRequestConfig
): Promise<AxiosResponse<T>> => instance.delete<T>(url, config);
export const doPostForm = <T>(
  url: string,
  data?: unknown,
  config?: AxiosRequestConfig
): Promise<AxiosResponse<T>> => instance.postForm<T>(url, data, config);
