import { useTranslations } from '@brightdrop/localization-client';
import { Box, Container } from '@mui/material';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { refreshAuthToken } from '~/common/apis/api.interceptors';
import { AlertMessage } from '~/common/components';
import useCustomAuthentication from '~/common/hooks/useCustomAuthentication';
import { isMSALAuthError } from '~/common/utils/auth/auth.utils';

import Header from '../../features/header/Header';
import {
  selectAuthDomainHint,
  selectLoginAttempt,
} from '../../features/session/authSlice.selectors';
import { msalInstance } from '../../features/session/msalInstance';
import { useAppDispatch } from '../store';
import useStyles from './ErrorFallback.styles';

const MESSAGES = {
  'common:alertMsg.messageView.serviceUnavailable.header':
    'Temporarily Unavailable',
  'common:alertMsg.messageView.serviceUnavailable.body':
    'The BrightDrop application is not available at this time. Please try again later.',
  'common:alertMsg.messageView.serviceUnavailable.button': 'Try Again',
};

const ErrorFallback = ({ error }: { error: Error }): JSX.Element => {
  const { classes } = useStyles();
  const dispatch = useAppDispatch();
  const { translations } = useTranslations(MESSAGES);

  const [ready, setReady] = useState(false);

  const loginAttempts = useSelector(selectLoginAttempt);
  const domainHint = useSelector(selectAuthDomainHint);
  const { authenticationType } = useCustomAuthentication();

  useEffect(() => {
    // force login on unexpected auth error
    if (isMSALAuthError(error) && msalInstance) {
      refreshAuthToken({
        authenticationType,
        dispatch,
        forceRefresh: true,
        forceLoginOnSilentFailure: true,
        domainHint,
      });
    }
    console.error('BrightDrop Web - Unexpected App Error', error);
    setReady(true);
  }, [authenticationType, dispatch, domainHint, error, loginAttempts]);

  return (
    <>
      <Header />
      {ready && (
        <Container maxWidth="xl" className={classes.contentContainer}>
          <Box>
            <AlertMessage
              header={
                translations[
                  'common:alertMsg.messageView.serviceUnavailable.header'
                ]
              }
              body={
                translations[
                  'common:alertMsg.messageView.serviceUnavailable.body'
                ]
              }
              linkBtn={
                translations[
                  'common:alertMsg.messageView.serviceUnavailable.button'
                ]
              }
              topic="serviceUnavailable"
              id="service-unavailable"
            />
          </Box>
        </Container>
      )}
    </>
  );
};
export default ErrorFallback;
