import {
  createEntityAdapter,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';
import merge from 'deepmerge';
import { PURGE } from 'redux-persist';

import {
  API_VERSION_DEFAULTS,
  UTILIZATIONS_API_CONFIG,
} from '~/common/apis/api.constants';
import { RouteParams } from '~/common/configs/route.config';
import {
  ApiResponse,
  BDRequestStatus,
  BDRequestType,
  OperationStatus,
} from '~/common/models/apis/apiResponse.model';
import { ListViewSession, TimeUnit } from '~/common/models/common.model';
import { BDAppErrorType, BDError } from '~/common/models/error.model';
import {
  UtilizationSummaries,
  UtilizationSummary,
} from '~/common/models/utilization.model';
import {
  addAcceptLanguageHeader,
  hasApiResult,
} from '~/common/utils/apis/api.utils';
import {
  makePostPayloadCreator,
  makeThunk,
} from '~/common/utils/store/thunk.helper';

import { ContextItem } from '../profile/profileSlice';
import {
  ApiUtilizationSummaries,
  mapParamsToRequest,
  mapUtilizationApiResponse,
  mapUtilizationSummariesByAssetApiResponse,
} from './mappers/utilization.mapper';

const utilizationAdapter = createEntityAdapter<UtilizationSummary>({});

interface UtilizationCountDetails {
  filtered: number;
  total: number;
}

export type UtilizationRecordCount = {
  count?: UtilizationCountDetails;
};

interface UtilizationFilter {
  numberOfTimePeriods: number;
  timePeriod: TimeUnit;
  selectedAssetContext?: ContextItem;
}
interface UtilizationStatus {
  operations: {
    [key in BDRequestType]?: OperationStatus;
  };
}

export type UtilizationSummariesState = Partial<UtilizationSummaries> &
  Partial<UtilizationFilter> &
  UtilizationStatus &
  Partial<ListViewSession>;

interface UtilizationState {
  summaries: {
    [key: string]: UtilizationSummariesState;
  };
  summariesByAsset: {
    [key: string]: Partial<UtilizationSummaries> & UtilizationStatus;
  };
  summariesCount: {
    [key: string]: UtilizationRecordCount;
  };
}
export const DEFAULT_TIME_PERIODS = 30;
export const DEFAULT_TIME_UNIT = TimeUnit.DAY;
export const DEFAULT_BIN_SIZE = 10;

const initialState = utilizationAdapter.getInitialState<UtilizationState>({
  summaries: {},
  summariesByAsset: {},
  summariesCount: {},
});

export type UtilizationParams = Pick<
  RouteParams,
  'organizationsId' | 'hubsId' | 'fleetsId' | 'assetsId'
> &
  Partial<UtilizationFilter> & {
    sessionId: string;
    onlyUseMatchedDates?: boolean;
  };

const CONFIG = UTILIZATIONS_API_CONFIG;

export const getUtilizationSummaries = makeThunk(
  'utilization/getUtilizationSummaries',
  makePostPayloadCreator<ApiResponse<UtilizationSummaries>, UtilizationParams>({
    url: `${globalThis.appConfig.apiBaseUrl}/assets/${API_VERSION_DEFAULTS.utilizationAPI}/utilizations`,
    axiosOptions: (_, state) =>
      addAcceptLanguageHeader(CONFIG, state.profile.currentLocale),
    argAdapter: (params, state) => {
      const { numberOfTimePeriods, timePeriod } =
        state.utilization.summaries[params.sessionId];
      return {
        requestBody: mapParamsToRequest({
          ...params,
          numberOfTimePeriods: numberOfTimePeriods || DEFAULT_TIME_PERIODS,
          timePeriod: timePeriod || DEFAULT_TIME_UNIT,
        }),
      };
    },
    responseAdapter: (
      response: unknown | ApiResponse<unknown>,
      state,
      args
    ) => {
      const { numberOfTimePeriods } =
        state.utilization.summaries[args.sessionId];

      if (hasApiResult<Partial<ApiUtilizationSummaries>>(response)) {
        return {
          ...response,
          result: mapUtilizationApiResponse(
            response.result,
            numberOfTimePeriods,
            args.onlyUseMatchedDates
          ),
        };
      } else if (response && typeof response === 'object') {
        return {
          success: true,
          targetUrl: null,
          unauthorizedRequest: true,
          errors: null,
          __bd: true,
          result: mapUtilizationApiResponse(
            response as ApiUtilizationSummaries,
            numberOfTimePeriods,
            args.onlyUseMatchedDates
          ),
        };
      }
      throw new BDError('Unexpected summaries response', { data: response });
    },
  })
);

export const getUtilizationSummariesByAsset = makeThunk(
  '/utilizations/disaggregatedByAsset',
  makePostPayloadCreator<
    ApiResponse<Partial<UtilizationSummaries>>,
    UtilizationParams
  >({
    url: `${globalThis.appConfig.apiBaseUrl}/assets/${API_VERSION_DEFAULTS.utilizationAPI}/utilizations/disaggregatedByAsset`,
    axiosOptions: CONFIG,
    argAdapter: (params) => {
      return {
        requestBody: mapParamsToRequest({
          ...params,
          numberOfTimePeriods:
            params?.numberOfTimePeriods || DEFAULT_TIME_PERIODS,
          timePeriod: params?.timePeriod || DEFAULT_TIME_UNIT,
        }),
      };
    },
    responseAdapter: (response: unknown | ApiResponse<unknown>) => {
      if (hasApiResult<Partial<ApiUtilizationSummaries>>(response)) {
        return {
          ...response,
          result: mapUtilizationSummariesByAssetApiResponse(response.result),
        };
      } else if (response && typeof response === 'object') {
        return {
          success: true,
          targetUrl: null,
          unauthorizedRequest: true,
          errors: null,
          __bd: true,
          result: mapUtilizationSummariesByAssetApiResponse(
            response as ApiUtilizationSummaries
          ),
        };
      }
      throw new BDError('Unexpected summaries response', { data: response });
    },
  })
);

export const utilizationSlice = createSlice({
  name: 'utilization',
  initialState,
  reducers: {
    setUtilizationFilter: (
      state,
      action: PayloadAction<{
        [key: string]: Partial<UtilizationFilter>;
      }>
    ) => {
      Object.keys(action.payload).forEach((id) => {
        state.summaries = merge(
          state.summaries,
          {
            [id]: action.payload[id] && {
              ...action.payload[id],
            },
          },
          {
            arrayMerge: (_, sourceArray) => sourceArray,
          }
        );
      });
    },
    setUtilizationListSession: (
      state,
      action: PayloadAction<{
        [key: string]: Partial<ListViewSession>;
      }>
    ) => {
      Object.keys(action.payload).forEach((id) => {
        state.summaries = merge(
          state.summaries,
          {
            [id]: action.payload[id] && {
              ...action.payload[id],
            },
          },
          {
            arrayMerge: (_, sourceArray) => sourceArray,
          }
        );
      });
    },
    setUtilizationRecordCount: (
      state,
      action: PayloadAction<{
        [key: string]: UtilizationRecordCount;
      }>
    ) => {
      Object.keys(action.payload).forEach((id) => {
        state.summariesCount = merge(
          state.summariesCount,
          {
            [id]: action.payload[id] && {
              ...action.payload[id],
            },
          },
          {
            arrayMerge: (_, sourceArray) => sourceArray,
          }
        );
      });
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getUtilizationSummaries.pending, (state, action) => {
      const { sessionId, ...session } = action.meta.arg;
      state.summaries = merge(
        state.summaries,
        {
          [sessionId]: {
            ...session,
            operations: {
              [BDRequestType.GET_ALL]: {
                status: BDRequestStatus.PENDING,
                errors: [],
              },
            },
          },
        },
        {
          arrayMerge: (_, sourceArray) => sourceArray,
        }
      );
    });
    builder.addCase(getUtilizationSummaries.fulfilled, (state, action) => {
      state.summaries = merge(
        state.summaries,
        {
          [action.meta.arg.sessionId]: {
            ...action.payload.result,
            operations: {
              [BDRequestType.GET_ALL]: {
                status: BDRequestStatus.SUCCEEDED,
              },
            },
          },
        },
        {
          arrayMerge: (_, sourceArray) => sourceArray,
        }
      );
    });
    builder.addCase(getUtilizationSummaries.rejected, (state, action) => {
      state.summaries = merge(
        state.summaries,
        {
          [action.meta.arg.sessionId]: {
            operations: {
              [BDRequestType.GET_ALL]: {
                status: BDRequestStatus.FAILED,
                errors: [
                  {
                    type: BDAppErrorType.API,
                    ...(action.payload || (action.error as BDError)),
                  },
                ],
              },
            },
          },
        },
        {
          arrayMerge: (_, sourceArray) => sourceArray,
        }
      );
    });
    builder.addCase(getUtilizationSummariesByAsset.pending, (state, action) => {
      const { sessionId, ...session } = action.meta.arg;
      state.summariesByAsset = merge(
        state.summariesByAsset,
        {
          [sessionId]: {
            ...session,
            operations: {
              [BDRequestType.GET_ALL]: {
                status: BDRequestStatus.PENDING,
                errors: [],
              },
            },
          },
        },
        {
          arrayMerge: (_, sourceArray) => sourceArray,
        }
      );
    });
    builder.addCase(
      getUtilizationSummariesByAsset.fulfilled,
      (state, action) => {
        state.summariesByAsset = merge(
          state.summariesByAsset,
          {
            [action.meta.arg.sessionId]: {
              ...action.payload.result,
              operations: {
                [BDRequestType.GET_ALL]: {
                  status: BDRequestStatus.SUCCEEDED,
                },
              },
            },
          },
          {
            arrayMerge: (_, sourceArray) => sourceArray,
          }
        );
      }
    );
    builder.addCase(
      getUtilizationSummariesByAsset.rejected,
      (state, action) => {
        state.summariesByAsset = merge(
          state.summariesByAsset,
          {
            [action.meta.arg.sessionId]: {
              operations: {
                [BDRequestType.GET_ALL]: {
                  status: BDRequestStatus.FAILED,
                  errors: [
                    {
                      type: BDAppErrorType.API,
                      ...(action.payload || (action.error as BDError)),
                    },
                  ],
                },
              },
            },
          },
          {
            arrayMerge: (_, sourceArray) => sourceArray,
          }
        );
      }
    );
    // reset state when persist store is purged on logout
    builder.addCase(PURGE, (state) => {
      state = initialState;
    });
  },
});

export const {
  setUtilizationFilter,
  setUtilizationListSession,
  setUtilizationRecordCount,
} = utilizationSlice.actions;

export const utilizationReducer = utilizationSlice.reducer;
