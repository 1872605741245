import { User } from '@gm-commercial/profile-model';
import {
  createEntityAdapter,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';
import merge from 'deepmerge';

import {
  API_VERSION_DEFAULTS,
  DEFAULT_API_CONFIG,
} from '~/common/apis/api.constants';
import { RouteParams } from '~/common/configs/route.config';
import {
  ApiResponse,
  BDRequestStatus,
  BDRequestType,
  OperationStatus,
} from '~/common/models/apis/apiResponse.model';
import { Asset } from '~/common/models/asset.model';
import { AssetReportFieldType } from '~/common/models/asset-report.model';
import {
  AggregateCounts,
  ListViewSession,
  SeverityLevel,
} from '~/common/models/common.model';
import { BDAppErrorType, BDError } from '~/common/models/error.model';
import { Hub, ValidHubForm } from '~/common/models/hub.model';
import { BDQueryCriteriaType } from '~/common/models/query.model';
import {
  addAcceptLanguageHeader,
  hasApiResult,
} from '~/common/utils/apis/api.utils';
import {
  makeDeletePayloadCreator,
  makeGetPayloadCreator,
  makePostPayloadCreator,
  makePutPayloadCreator,
  makeThunk,
} from '~/common/utils/store/thunk.helper';

import { RootState } from '../../app/rootReducer';
import { createQuery } from '../assets/utils/assets.utils';
import {
  ApiHubFields,
  mapApiResponseToHub,
  mapHubFormToApiRequest,
} from './mappers/hub.mappers';

export type HubRouteParams = Pick<RouteParams, 'organizationsId' | 'hubsId'>;

const hubsAdapter = createEntityAdapter<Hub>({
  // redundant, but makes obvious the key used for fetching records
  selectId: (hub: Hub) => hub.id,
});

export enum HubViewType {
  DETAIL = 'Hub Detail',
  LIST = 'Hub List',
  SUBLIST = 'Hub Sublist',
}

export interface HubDetailSession {
  isDetailPropsOpen?: boolean;
}

export interface HubListSession
  extends Record<keyof Hub, string>,
    ListViewSession {}

/**
 * HubSessionConfigType represent an object where session is scoped by the view and entity id
 *
 * @interface HubSessionConfigType
 * @property {HubViewType} key The view that we are storing sessions.
 * @property {string} id The id of the entity you are viewing, E.g.
 */
export interface HubSessionConfigType {
  [HubViewType.DETAIL]?: {
    [id: string]: Partial<HubDetailSession>;
  };
  [HubViewType.LIST]?: {
    [id: string]: Partial<HubListSession> | undefined;
  };
  [HubViewType.SUBLIST]?: {
    [id: string]: Partial<HubListSession> | undefined;
  };
}

interface HubsState {
  operations: {
    [key in BDRequestType]?: OperationStatus;
  };
  sessionConfigs: {
    [k in HubViewType]?: HubSessionConfigType[k];
  };
}

const initialState = hubsAdapter.getInitialState<HubsState>({
  operations: {},
  sessionConfigs: {},
});

const CONFIG = DEFAULT_API_CONFIG;

export const getHubs = makeThunk(
  'hubs/fetchHubs',
  makeGetPayloadCreator<
    ApiResponse<Hub[]>,
    Required<Pick<RouteParams, 'organizationsId'>>
  >({
    url: `${globalThis.appConfig.apiBaseUrl}/organizations/${API_VERSION_DEFAULTS.default}/:organizationsId/hubs`,
    axiosOptions: (_, state) =>
      addAcceptLanguageHeader(CONFIG, state.profile.currentLocale),
    argAdapter: ({ organizationsId }) => ({
      requestParams: { organizationsId },
    }),
    responseAdapter: (response: any) => ({
      ...response,
      result:
        response && Array.isArray(response.result)
          ? response.result.map((data: ApiHubFields) =>
              mapApiResponseToHub(data)
            )
          : [],
    }),
  })
);

export const getHubDetails = makeThunk(
  'hubs/fetchHubDetails',
  makeGetPayloadCreator<ApiResponse<Hub>, Required<HubRouteParams>>({
    url: `${globalThis.appConfig.apiBaseUrl}/organizations/${API_VERSION_DEFAULTS.default}/:organizationsId/hubs/:hubsId`,
    axiosOptions: (_, state) =>
      addAcceptLanguageHeader(CONFIG, state.profile.currentLocale),
    argAdapter: ({ organizationsId, hubsId }) => ({
      requestParams: { organizationsId, hubsId },
    }),
    responseAdapter: (response: any) => ({
      ...response,
      result: mapApiResponseToHub(response?.result),
    }),
  })
);

//TODO: Replace once API creates a Hub Stats by ID(Phase 2)
export const getHubFleetStats = makeThunk(
  'hubs/fetchHubFleetStats',
  makeGetPayloadCreator<ApiResponse<number>, Required<HubRouteParams>>({
    url: `${globalThis.appConfig.apiBaseUrl}/organizations/${API_VERSION_DEFAULTS.default}/:organizationsId/hubs/:hubsId/fleets`,
    axiosOptions: (_, state) =>
      addAcceptLanguageHeader(CONFIG, state.profile.currentLocale),
    argAdapter: ({ organizationsId, hubsId }) => ({
      requestParams: { organizationsId, hubsId },
    }),
    responseAdapter: (response: unknown, _, params) => {
      if (!!response && hasApiResult(response)) {
        return {
          ...response,
          result: Array.isArray(response?.result) ? response?.result.length : 0,
        };
      }
      throw new BDError('Unable to parse fleets response for hub', {
        data: { response, params },
      });
    },
  })
);

export const getHubUserStats = makeThunk(
  'hubs/fetchHubUserStats',
  makeGetPayloadCreator<ApiResponse<number>, Required<HubRouteParams>>({
    url: `${globalThis.appConfig.apiBaseUrl}/users/${API_VERSION_DEFAULTS.default}`,
    axiosOptions: (_, state) =>
      addAcceptLanguageHeader(CONFIG, state.profile.currentLocale),
    argAdapter: (params) => ({
      requestParams: {
        organizationId: params.organizationsId || '',
        hubId: params.hubsId || '',
      },
    }),
    responseAdapter: (response: unknown, _, params) => {
      if (!!response && hasApiResult(response)) {
        return {
          ...response,
          result: Array.isArray(response?.result) ? response?.result.length : 0,
        };
      }
      throw new BDError('Unable to parse users response for hub', {
        data: { response, params },
      });
    },
  })
);

export const getHubAssetStats = makeThunk(
  'hubs/getHubAssetStats',
  makePostPayloadCreator<ApiResponse<number>, Required<HubRouteParams>>({
    url: `${globalThis.appConfig.apiBaseUrl}/assets/${API_VERSION_DEFAULTS.default}/views/readinessReport`,
    axiosOptions: (_, state) =>
      addAcceptLanguageHeader(CONFIG, state.profile.currentLocale),
    argAdapter: (params) => {
      const hubId = params.hubsId;
      const query = createQuery(
        BDQueryCriteriaType.IS_EQUAL,
        AssetReportFieldType.HUB_ID,
        [hubId]
      );
      return {
        requestBody: { query },
      };
    },
    responseAdapter: (response: unknown, _, params) => {
      if (!!response && hasApiResult(response)) {
        const { items, total_items } = response.result as any;
        return {
          ...response,
          result: items ? total_items : 0,
        };
      }
      throw new BDError('Unable to parse assets response for hub', {
        data: { response, params },
      });
    },
  })
);

//TODO: remove if summary counts are returned with hub list, or an endpoint is provided for this purpose (phase 2)
export const getHubListAssetCounts = makeThunk(
  'hubs/aggregateListAssetCounts',
  makeGetPayloadCreator<
    ApiResponse<AggregateCounts<'assets'>>,
    Required<Pick<RouteParams, 'organizationsId'>>
  >({
    url: `${globalThis.appConfig.apiBaseUrl}/assets/${API_VERSION_DEFAULTS.default}`,
    axiosOptions: (_, state) =>
      addAcceptLanguageHeader(CONFIG, state.profile.currentLocale),
    argAdapter: (routeParams) => {
      return {
        requestParams: {
          organizationId: routeParams.organizationsId || '',
        },
      };
    },
    responseAdapter: (response: unknown, _, params) => {
      if (!!response && hasApiResult(response)) {
        return {
          ...response,
          result: Array.isArray(response?.result)
            ? response?.result.reduce(
                (hubAssets: AggregateCounts<'assets'>, asset: Asset) => {
                  if (asset.hub?.id) {
                    hubAssets[asset.hub.id] = {
                      assets: (hubAssets[asset.hub.id]?.assets || 0) + 1,
                    };
                  }
                  return hubAssets;
                },
                {} as AggregateCounts<'assets'>
              )
            : {},
        };
      }
      throw new BDError('Unable to parse assets response for hub', {
        data: { response, params },
      });
    },
  })
);

//TODO: remove if summary counts are returned with hub list, or an endpoint is provided for this purpose (phase 2)
export const getHubListUserCounts = makeThunk(
  'hubs/aggregateListUserCounts',
  makeGetPayloadCreator<
    ApiResponse<AggregateCounts<'users'>>,
    Required<Pick<RouteParams, 'organizationsId'>>
  >({
    url: `${globalThis.appConfig.apiBaseUrl}/users/${API_VERSION_DEFAULTS.default}`,
    axiosOptions: (_, state) =>
      addAcceptLanguageHeader(CONFIG, state.profile.currentLocale),
    argAdapter: (params) => ({
      requestParams: { organizationId: params.organizationsId || '' },
    }),
    responseAdapter: (response: unknown, _, params) => {
      if (!!response && hasApiResult(response)) {
        return {
          ...response,
          result: Array.isArray(response?.result)
            ? response?.result.reduce(
                (hubUsers: AggregateCounts<'users'>, user: User) => {
                  if (user.hub?.id) {
                    hubUsers[user.hub.id] = {
                      users: (hubUsers[user.hub.id]?.users || 0) + 1,
                    };
                  }
                  return hubUsers;
                },
                {} as AggregateCounts<'users'>
              )
            : {},
        };
      }
      throw new BDError('Unable to parse users response for hub', {
        data: { response, params },
      });
    },
  })
);

export const addHub = makeThunk(
  'hubs/addNewHub',
  makePostPayloadCreator<
    ApiResponse<Hub>,
    ValidHubForm & Required<Pick<RouteParams, 'organizationsId'>>
  >({
    url: `${globalThis.appConfig.apiBaseUrl}/organizations/${API_VERSION_DEFAULTS.default}/:organizationsId/hubs`,
    axiosOptions: (_, state) =>
      addAcceptLanguageHeader(CONFIG, state.profile.currentLocale),
    argAdapter: (hubForm) => ({
      requestBody: mapHubFormToApiRequest(hubForm),
      requestParams: { organizationsId: hubForm.organizationsId },
    }),
  })
);

export const updateHub = makeThunk(
  'hubs/updateHub',
  makePutPayloadCreator<
    ApiResponse<Hub>,
    ValidHubForm & Required<HubRouteParams>
  >({
    url: `${globalThis.appConfig.apiBaseUrl}/organizations/${API_VERSION_DEFAULTS.default}/:organizationsId/hubs/:hubsId`,
    axiosOptions: (_, state) =>
      addAcceptLanguageHeader(CONFIG, state.profile.currentLocale),
    argAdapter: (hub) => ({
      requestParams: {
        hubsId: hub.hubsId,
        organizationsId: hub.organizationsId,
      },
      requestBody: mapHubFormToApiRequest(hub),
    }),
    responseAdapter: (response: any) => ({
      ...response,
      result: mapApiResponseToHub(response?.result),
    }),
  })
);

export const deleteHub = makeThunk(
  'hubs/deleteHub',
  makeDeletePayloadCreator<ApiResponse, Required<HubRouteParams>>({
    url: `${globalThis.appConfig.apiBaseUrl}/organizations/${API_VERSION_DEFAULTS.default}/:organizationsId/hubs/:hubsId`,
    argAdapter: ({ organizationsId, hubsId }) => ({
      requestParams: { organizationsId, hubsId },
    }),
  })
);

export const hubsSlice = createSlice({
  name: 'hubs',
  initialState,
  reducers: {
    setHubSessionConfig: (
      state,
      action: PayloadAction<HubSessionConfigType>
    ) => {
      Object.keys(action.payload).forEach((key) => {
        const scope = key as HubViewType;
        const payload = action.payload[scope] || {};
        Object.keys(payload).forEach((id) => {
          state.sessionConfigs = merge(
            state.sessionConfigs,
            {
              [scope]: {
                [id]: payload[id] && {
                  ...payload[id],
                },
              },
            },
            {
              arrayMerge: (_, sourceArray) => sourceArray,
            }
          );
        });
      });
    },
  },
  extraReducers: (builder) => {
    // hub list
    builder.addCase(getHubs.pending, (state) => {
      state.operations[BDRequestType.GET_ALL] = {
        status: BDRequestStatus.PENDING,
        errors: [],
      };
      hubsAdapter.removeMany(state, state.ids); // ensure no records when fetching
    });
    builder.addCase(getHubs.fulfilled, (state, action) => {
      state.operations[BDRequestType.GET_ALL] = {
        status: BDRequestStatus.SUCCEEDED,
      };
      hubsAdapter.upsertMany(state, action.payload.result);
    });
    builder.addCase(getHubs.rejected, (state, action) => {
      state.operations[BDRequestType.GET_ALL] = {
        status: BDRequestStatus.FAILED,
        errors: [
          {
            type: BDAppErrorType.API,
            ...(action.payload || (action.error as BDError)),
            requestType: BDRequestType.GET_ALL,
            severity: SeverityLevel.ERROR,
          },
        ],
      };
    });

    // hub details
    builder.addCase(getHubDetails.pending, (state) => {
      state.operations[BDRequestType.GET_BY_ID] = {
        status: BDRequestStatus.PENDING,
        errors: [],
      };
      state.operations[BDRequestType.GET_STATS] = {
        status: BDRequestStatus.IDLE,
      };
      state.operations[BDRequestType.GET_USER_SUMMARY] = {
        status: BDRequestStatus.IDLE,
      };
      state.operations[BDRequestType.GET_ASSET_SUMMARY] = {
        status: BDRequestStatus.IDLE,
      };
    });
    builder.addCase(getHubDetails.fulfilled, (state, action) => {
      state.operations[BDRequestType.GET_BY_ID] = {
        status: BDRequestStatus.SUCCEEDED,
      };
      // performs shallow merge update of existing records, _will overwrite nested object properties_
      hubsAdapter.upsertOne(state, action.payload.result);
    });
    builder.addCase(getHubDetails.rejected, (state, action) => {
      state.operations[BDRequestType.GET_BY_ID] = {
        status: BDRequestStatus.FAILED,
        errors: [
          {
            type: BDAppErrorType.API,
            ...(action.payload || (action.error as BDError)),
            requestType: BDRequestType.GET_BY_ID,
          },
        ],
      };
    });

    // hub fleet stats
    //TODO: Remove once API adds userCount to the hub stats by ID (Phase 2)
    builder.addCase(getHubFleetStats.pending, (state) => {
      state.operations[BDRequestType.GET_STATS] = {
        status: BDRequestStatus.PENDING,
        errors: [],
      };
    });
    builder.addCase(getHubFleetStats.fulfilled, (state, action) => {
      state.operations[BDRequestType.GET_STATS] = {
        status: BDRequestStatus.SUCCEEDED,
      };
      hubsAdapter.updateOne(state, {
        id: action?.meta.arg.hubsId,
        changes: {
          ...state.entities[action?.meta.arg.hubsId],
          summaryCounts: {
            ...state.entities[action?.meta.arg.hubsId]?.summaryCounts,
            fleets: action.payload.result,
          },
        },
      });
    });
    builder.addCase(getHubFleetStats.rejected, (state, action) => {
      state.operations[BDRequestType.GET_STATS] = {
        status: BDRequestStatus.FAILED,
        errors: [
          {
            type: BDAppErrorType.API,
            ...(action.payload || (action.error as BDError)),
            requestType: BDRequestType.GET_STATS,
          },
        ],
      };
    });

    // hub user stats
    //TODO: Remove once API adds userCount to the hub stats by ID (Phase 2)
    builder.addCase(getHubUserStats.pending, (state) => {
      state.operations[BDRequestType.GET_USER_SUMMARY] = {
        status: BDRequestStatus.PENDING,
        errors: [],
      };
    });
    builder.addCase(getHubUserStats.fulfilled, (state, action) => {
      state.operations[BDRequestType.GET_USER_SUMMARY] = {
        status: BDRequestStatus.SUCCEEDED,
      };
      hubsAdapter.updateOne(state, {
        id: action?.meta.arg.hubsId,
        changes: {
          ...state.entities[action?.meta.arg.hubsId],
          summaryCounts: {
            ...state.entities[action?.meta.arg.hubsId]?.summaryCounts,
            users: action.payload.result,
          },
        },
      });
    });
    builder.addCase(getHubUserStats.rejected, (state, action) => {
      state.operations[BDRequestType.GET_USER_SUMMARY] = {
        status: BDRequestStatus.FAILED,
        errors: [
          {
            type: BDAppErrorType.API,
            ...(action.payload || (action.error as BDError)),
            requestType: BDRequestType.GET_USER_SUMMARY,
          },
        ],
      };
    });

    // hub asset stats
    //TODO: Remove once API adds assetCount to the hub stats by ID (Phase 2)
    builder.addCase(getHubAssetStats.pending, (state) => {
      state.operations[BDRequestType.GET_ASSET_SUMMARY] = {
        status: BDRequestStatus.PENDING,
        errors: [],
      };
    });
    builder.addCase(getHubAssetStats.fulfilled, (state, action) => {
      state.operations[BDRequestType.GET_ASSET_SUMMARY] = {
        status: BDRequestStatus.SUCCEEDED,
      };
      hubsAdapter.updateOne(state, {
        id: action?.meta.arg.hubsId,
        changes: {
          ...state.entities[action?.meta.arg.hubsId],
          summaryCounts: {
            ...state.entities[action?.meta.arg.hubsId]?.summaryCounts,
            assets: action.payload.result,
          },
        },
      });
    });
    builder.addCase(getHubAssetStats.rejected, (state, action) => {
      state.operations[BDRequestType.GET_ASSET_SUMMARY] = {
        status: BDRequestStatus.FAILED,
        errors: [
          {
            type: BDAppErrorType.API,
            ...(action.payload || (action.error as BDError)),
            requestType: BDRequestType.GET_ASSET_SUMMARY,
          },
        ],
      };
    });

    // hub list user counts
    //TODO: Remove once API adds usersCount to the hub stats by ID (Phase 2)
    builder.addCase(getHubListUserCounts.pending, (state) => {
      state.operations[BDRequestType.GET_USER_COUNTS] = {
        status: BDRequestStatus.PENDING,
        errors: [],
      };
    });
    builder.addCase(getHubListUserCounts.fulfilled, (state, action) => {
      state.operations[BDRequestType.GET_USER_COUNTS] = {
        status: BDRequestStatus.SUCCEEDED,
      };
      const userCounts = Object.entries(action.payload.result);
      if (userCounts.length > 0) {
        hubsAdapter.updateMany(
          state,
          userCounts.map((userCount) => ({
            id: userCount[0],
            changes: {
              ...state.entities[userCount[0]],
              summaryCounts: {
                ...state.entities[userCount[0]]?.summaryCounts,
                users: userCount[1].users,
              },
            },
          }))
        );
      }
    });
    builder.addCase(getHubListUserCounts.rejected, (state, action) => {
      state.operations[BDRequestType.GET_USER_COUNTS] = {
        status: BDRequestStatus.FAILED,
        errors: [
          {
            type: BDAppErrorType.API,
            ...(action.payload || (action.error as BDError)),
            requestType: BDRequestType.GET_USER_COUNTS,
          },
        ],
      };
    });

    // hub list asset counts
    //TODO: Remove once API adds assetCount to the hub stats by ID (Phase 2)
    builder.addCase(getHubListAssetCounts.pending, (state) => {
      state.operations[BDRequestType.GET_ASSET_COUNTS] = {
        status: BDRequestStatus.PENDING,
        errors: [],
      };
    });
    builder.addCase(getHubListAssetCounts.fulfilled, (state, action) => {
      state.operations[BDRequestType.GET_ASSET_COUNTS] = {
        status: BDRequestStatus.SUCCEEDED,
      };
      const assetCounts = Object.entries(action.payload.result);
      if (assetCounts.length > 0) {
        hubsAdapter.updateMany(
          state,
          assetCounts.map((assetCount) => ({
            id: assetCount[0],
            changes: {
              ...state.entities[assetCount[0]],
              summaryCounts: {
                ...state.entities[assetCount[0]]?.summaryCounts,
                assets: assetCount[1].assets,
              },
            },
          }))
        );
      }
    });
    builder.addCase(getHubListAssetCounts.rejected, (state, action) => {
      state.operations[BDRequestType.GET_ASSET_COUNTS] = {
        status: BDRequestStatus.FAILED,
        errors: [
          {
            type: BDAppErrorType.API,
            ...(action.payload || (action.error as BDError)),
            requestType: BDRequestType.GET_ASSET_COUNTS,
          },
        ],
      };
    });

    // add hub
    builder.addCase(addHub.pending, (state) => {
      state.operations[BDRequestType.ADD] = {
        status: BDRequestStatus.PENDING,
        errors: [],
      };
    });
    builder.addCase(addHub.fulfilled, (state, action) => {
      state.operations[BDRequestType.ADD] = {
        status: BDRequestStatus.SUCCEEDED,
      };
    });
    builder.addCase(addHub.rejected, (state, action) => {
      state.operations[BDRequestType.ADD] = {
        status: BDRequestStatus.FAILED,
        errors: [
          {
            type: BDAppErrorType.API,
            ...(action.payload || (action.error as BDError)),
            requestType: BDRequestType.ADD,
          },
        ],
      };
    });

    // update hub
    builder.addCase(updateHub.pending, (state) => {
      state.operations[BDRequestType.UPDATE] = {
        status: BDRequestStatus.PENDING,
        errors: [],
      };
    });
    builder.addCase(updateHub.fulfilled, (state, action) => {
      state.operations[BDRequestType.UPDATE] = {
        status: BDRequestStatus.SUCCEEDED,
      };
      hubsAdapter.upsertOne(state, action.payload.result);
    });
    builder.addCase(updateHub.rejected, (state, action) => {
      state.operations[BDRequestType.UPDATE] = {
        status: BDRequestStatus.FAILED,
        errors: [
          {
            type: BDAppErrorType.API,
            ...(action.payload || (action.error as BDError)),
            requestType: BDRequestType.UPDATE,
          },
        ],
      };
    });

    // delete hub
    builder.addCase(deleteHub.pending, (state) => {
      state.operations[BDRequestType.DELETE] = {
        status: BDRequestStatus.PENDING,
        errors: [],
      };
    });
    builder.addCase(deleteHub.fulfilled, (state, action) => {
      state.operations[BDRequestType.DELETE] = {
        status: BDRequestStatus.SUCCEEDED,
      };
    });
    builder.addCase(deleteHub.rejected, (state, action) => {
      state.operations[BDRequestType.DELETE] = {
        status: BDRequestStatus.FAILED,
        errors: [
          {
            type: BDAppErrorType.API,
            ...(action.payload || (action.error as BDError)),
            requestType: BDRequestType.DELETE,
          },
        ],
      };
    });
  },
});

export const {
  selectAll: selectAllHubs,
  selectById: selectHubById,
  selectIds: selectHubIds,
} = hubsAdapter.getSelectors<RootState>((state: RootState) => state.hubs);

export const { setHubSessionConfig } = hubsSlice.actions;
export const hubsReducer = hubsSlice.reducer;
