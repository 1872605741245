import { Entity } from './common.model';

export enum InsightType {
  ELECTRICITY_COST = 'ElectricityCost',
  BATTERY_HEALTH = 'BatteryHealth',
  FUEL_COST_EMISSIONS = 'FuelCostAndEmissions',
}

export enum InsightChartType {
  ENERGY_COST = 'EnergyCost',
  DEMAND_COST = 'DemandCost',
  ENERGY_DEMAND_COST = 'EnergyAndDemandCost',
  DEMAND_CHARGE = 'DemandCharge',
  TIME_SPENT_ABOVE_SOC_THRESHOLD = 'TimeSpentAboveSocThreshold',
  FUEL_COST_SAVINGS = 'FuelCostSavings',
  EMISSIONS_SAVINGS = 'EmissionsSavings',
}

export enum InsightDetailType {
  REDUCE_ENERGY_COST = 'ReduceEnergyCost',
  REDUCE_DEMAND_COST = 'ReduceDemandCost',
  REDUCE_ENERGY_DEMAND_COST = 'ReduceEnergyAndDemandCost',
  REDUCE_SOC_TARGET = 'ReduceSocTarget',
  NO_RECOMMENDATION_ON_ELECTRICITY_COST = 'NoRecommendationOnElectricityCost',
  NO_RECOMMENDATION_ON_BATTERY_HEALTH = 'NoRecommendationOnBatteryHealth',
  FUEL_COST_EMISSIONS_SAVINGS = 'FuelCostAndEmissionsSavings',
}

export enum InsightTranslationBundleParams {
  SOC_THRESHOLD = 'socThreshold',
}

export enum InsightTranslationBundleFormatParams {
  CURRENCY = 'currency',
}

export enum InsightRecommendedSettingsType {
  TARGET_SOC = 'targetSoc',
}

export interface InsightTypeDetails {
  name: string;
  type: InsightType;
  status: boolean;
  savings?: InsightSavings;
  hasRecommendations?: boolean;
}

export interface InsightAssetTypeCount {
  numOfTraces: number;
  numOfZevos: number;
}

export interface InsightListDetails extends Entity {
  date: string;
  assetCount?: InsightAssetTypeCount;
  typeDetails?: Array<InsightTypeDetails>;
  organizationsId?: string;
  hubsId?: string;
}

export interface ApiInsight {
  id: string;
  organizationId: string;
  hubId: string;
  fleetId: string;
  assetId: string;
  insightType: InsightType;
  createdAt: string;
  insightPayload?: ApiInsightDetails;
}

export interface Insight extends Entity {
  organizationId: string;
  hubId: string;
  fleetId: string;
  type: InsightType;
  date: string;
  details?: InsightDetails;
}

export type TranslationBundleData =
  | number
  | string
  | { [k: string]: TranslationBundleData };

export interface InsightTranslationBundle {
  translationKey: string;
  parameters: {
    [key: string]: number;
  };
  formatParams: {
    [key: string]: TranslationBundleData;
  };
}

export interface InsightCostPatchTimePeriod {
  startAt: string;
  endAt: string;
}
export interface InsightCostPatch {
  timePeriods: Array<InsightCostPatchTimePeriod>;
  costTranslationBundle: InsightTranslationBundle;
}

export interface InsightMessageDetails {
  numberOfVehicles: number;
  estimatedFactualCostInLcuPerMonth: number;
  estimatedCounterfactualCostInLcuPerMonth: number;
  currency: string;
}

export interface InsightTimeSeriesData {
  counterfactualPowerInKw: {
    [date: string]: number;
  };
  factualPowerInKw: {
    [date: string]: number;
  };
}

export interface InsightStateOfChargeData {
  socLow: number;
  socHigh: number;
  hours: number;
}

export interface InsightChartDetails {
  timeSeriesData?: InsightTimeSeriesData;
  costPatches?: Array<InsightCostPatch>;
  bins?: Array<InsightStateOfChargeData>;
  timeZone?: string;
}

export interface InsightRecommedationDetails {
  type: InsightChartType;
  bodyText: InsightTranslationBundle;
  detailRecommendations: Array<InsightTranslationBundle>;
  chartDetails: InsightChartDetails;
}

export interface ApiInsightRecommedationDetails {
  recommendationInsightDetailType: InsightChartType;
  bodyText: InsightTranslationBundle;
  detailRecommendations: Array<InsightTranslationBundle>;
  plot: InsightChartDetails;
}

export interface ApiInsightDetails {
  id: string;
  createdAt: string;
  analysisStartAt: string;
  analysisEndAt: string;
  insightType: InsightType;
  insightName: InsightDetailType;
  fleetId: string;
  hubId: string;
  organizationId: string;
  mainMessage: InsightTranslationBundle;
  mainMessageInfo: InsightMessageDetails;
  recommendations: Array<InsightTranslationBundle>;
  recommendationInsightDetails: Array<ApiInsightRecommedationDetails>;
  timeZone: string;
  recommendedSettings?: Array<InsightRecommendedSetting>;
}

export interface InsightDetails {
  id: string;
  name: InsightDetailType;
  createdAt: string;
  analysisStartAt: string;
  analysisEndAt: string;
  type: InsightType;
  fleetId: string;
  hubId: string;
  organizationId: string;
  mainMessage: InsightTranslationBundle;
  mainMessageDetails: InsightMessageDetails;
  recommendations: Array<InsightTranslationBundle>;
  recommendationDetails: Array<InsightRecommedationDetails>;
  timeZone: string;
  recommendedSettings?: Array<InsightRecommendedSetting>;
}

export interface InsightPlotItem {
  [key: string]: string | number;
}

export interface InsightLinePlotData {
  recommendationLineData: Array<InsightPlotItem>;
  historicalLineData: Array<InsightPlotItem>;
  max: number;
}

export interface InsightCostPatchDetails {
  [key: string]: InsightPlotItem[];
}

export interface InsightSavings {
  value: number;
  displayValue?: string;
}

export interface InsightChartColors {
  line: string[];
  bar: string[];
  costPatch: string[];
  canvasBackground: string;
  title: string;
  subtitle: string;
}

export interface InsightChartTooltip {
  opacity: number;
  top: number;
  left: number;
  title: string;
  bodyLabels: Array<string>;
  bodyValues: Array<string>;
  colors: Array<string>;
  date?: string;
}

export type InsightRecommendedSetting = {
  [key in InsightRecommendedSettingsType]: number;
} & {
  _type: string;
};
