import { Flex } from '@brightdrop/bd-ui';
import { useTranslations } from '@brightdrop/localization-client';
import { Role } from '@gm-commercial/profile-model';
import { Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';

import {
  selectActingProfileEmail,
  selectActingProfileFirstName,
  selectActingProfileLastName,
  selectActingProfileName,
  selectActingRole,
} from '../../../profile/profileSlice.selectors';
import useStyles from './ProfileCard.styles';

export const USER_ROLE_MESSAGES = {
  roleList: {
    'common:roleList.allRoles': 'All Roles',
    [`common:roleList.${Role.ORG_MANAGER}`]: 'Account Admin',
    [`common:roleList.${Role.HUB_MANAGER}`]: 'Hub Manager',
    [`common:roleList.${Role.FLEET_MANAGER}`]: 'Fleet Manager',
    [`common:roleList.${Role.OPERATOR}`]: 'Operator',
    [`common:roleList.${Role.SYSTEM_MANAGER}`]: 'Advisor',
    [`common:roleList.${Role.SERVICE_TECHNICIAN}`]: 'Service Technician',
    [`common:roleList.${Role.SYSTEM_READER}`]: 'System Reader',
    [`common:roleList.${Role.POC_GROCERYMANAGER}`]: 'Grocery Manager',
    [`common:roleList.${Role.SERVICE_MANAGER}`]: 'Service Manager',
    [`common:roleList.${Role.VIEWER}`]: 'Viewer',
    [`common:roleList.${Role.POC_ROUTEMANAGER}`]: 'POC Route Manager',
  },
};
const ProfileCard = () => {
  const { translations } = useTranslations(USER_ROLE_MESSAGES);
  const { classes } = useStyles();
  const selectActingProfileParams = createSelector(
    selectActingProfileEmail,
    selectActingProfileFirstName,
    selectActingProfileLastName,
    selectActingProfileName,
    selectActingRole,
    (email, firstName, lastName, name, role) => {
      const roleTranslationKey =
        `common:roleList.${role}` as keyof typeof translations.roleList;
      const translatedRole = translations.roleList[roleTranslationKey] || role;

      return {
        email,
        firstName,
        lastName,
        name,
        role: translatedRole,
      };
    }
  );
  const actingProfileParams = useSelector(selectActingProfileParams);

  return (
    <Flex
      gap={12}
      style={{
        padding: '20px 16px',
      }}
    >
      <div className={classes.profileIconContainer}>
        <Typography variant="label_medium" className={classes.profileIcon}>
          {actingProfileParams?.firstName?.charAt(0)}
          {actingProfileParams?.lastName?.charAt(0)}
        </Typography>
      </div>
      <div>
        <Typography
          component="div"
          variant="small_body_medium"
          title={actingProfileParams.name}
          data-testid="profileInfoName"
          data-heap-redact-text
          data-heap-redact-attributes="title"
        >
          {actingProfileParams.name}
        </Typography>
        <Typography
          component="div"
          variant="label_regular"
          className={classes.profileEmail}
          title={actingProfileParams.email}
          data-testid="profileInfoEmail"
          data-heap-redact-text
          data-heap-redact-attributes="title"
        >
          {actingProfileParams.email}
        </Typography>
        <Typography
          component="div"
          variant="small_body_regular"
          className={classes.profileRole}
          title={actingProfileParams.role}
          data-testid="profileInfoRole"
        >
          {actingProfileParams.role}
        </Typography>
      </div>
    </Flex>
  );
};

export default ProfileCard;
