import { ASSET_LIST_REPORT_MESSAGES } from '../AssetListReportView.messages';
import {
  BRAKE_FLUID_COLUMN,
  getCommonReportColumns,
  OIL_LIFE_COLUMN,
  WASHER_FLUID_COLUMN,
} from './commonReportTable.config';

export const getFluidReportColumns = (
  columnLabels: typeof ASSET_LIST_REPORT_MESSAGES.columnLabels,
  valueLabels: typeof ASSET_LIST_REPORT_MESSAGES.valueLabels,
  isParamAvailable: boolean,
  locale?: string
) => [
  ...getCommonReportColumns(
    columnLabels,
    valueLabels,
    isParamAvailable,
    locale
  ),
  BRAKE_FLUID_COLUMN(columnLabels, valueLabels),
  WASHER_FLUID_COLUMN(columnLabels, valueLabels),
  OIL_LIFE_COLUMN(columnLabels, valueLabels),
];
