import { Permission, Role } from '@gm-commercial/profile-model';
import { RouteComponentProps, RouteProps } from 'react-router-dom';

import { RouteParams } from '../configs/route.config';

export enum RouteName {
  ORG_LIST,
  ORG_DETAILS,
  HUB_LIST,
  HUB_DETAILS,
  FLEET_LIST,
  FLEET_DETAILS,
  USER_LIST,
  USER_DETAILS,
  ASSET_LIST,
  ASSET_DETAILS,
  MAP,
  DASHBOARD,
  LOGIN,
  USER_TERMS_AND_CONDITIONS,
  ORGANIZATION_TERMS_AND_CONDITIONS,
  ASSETS_REPORT,
  SUPPORT,
  BUSINESS_ENROLLMENT,
  ALERTS,
  UTILIZATION_REPORT,

  INTEGRATION,
  ASSET_LIST_REPORT,
  MRT_ASSET_LIST_REPORT, // TODO: remove once MRT migration is complete
  ORG_ONBOARDING,
  PRIVACY,
  USER_TERMS,
  EDIT_FLEET_CONFIGURATION,
  STORE_VIEW,
  ORDERS_VIEW,
  INSIGHT_LIST,
  INSIGHT_DETAILS,
  ASSET_INSPECTIONS_LIST,
  ASSET_INSPECTIONS_DETAILS,
  DISPATCH,
  WORK_PLAN_DETAILS,

  CS_PLATFORM_ASSET_DETAILS,
  CS_PLATFORM_ORDER_LIST,
  CS_PLATFORM_ORDER_DETAILS,
  CS_PLATFORM_DASHBOARD,
  CS_PLATFORM_SETTINGS,
  CS_PLATFORM_NOTIFICATION_LIST,
  CS_PLATFORM_USERS_NOTIFICATIONS_SETTING,
  CS_PLATFORM_ACCOUNTS_NOTIFICATIONS_SETTING,
  ACCOUNT_PROFILE_PERSONAL,
  ACCOUNT_PREFERENCES_LANGUAGE_REGION,
  CS_PLATFORM_ACCOUNT_PREFERENCES,
  CS_PLATFORM_ACCOUNT_PROFILE,
  SUPPORT_CONTACT,
  CS_PLATFORM_SUPPORT_CONTACT,
  CS_PLATFORM_FLEET_PURCHASE_PROGRAMS,
  CS_PLATFORM_PERIPHERALS_LIST,
  CS_PLATFORM_USER_LIST,
  CS_PLATFORM_PENDING_VEHICLES,
}

export interface RouteConfig extends RouteProps {
  name: RouteName;
  authorizedPerms: Permission[];
  secure: boolean;
  component: React.FC<RouteComponentProps<RouteParams>>;
  featureFlagComponent?: React.FC<RouteComponentProps<RouteParams>>;
  authorizedRoles?: Role[];
  excludedRoles?: Role[];
  requiredFeatureFlags?: string[];
  requiresContextMatch?: boolean;
  requiredQueryParams?: string[];
}

export enum AppRoutePaths {
  PAGE_NOT_FOUND = '/page-not-found',
  UNAUTHORIZED = '/unauthorized',
  SERVICE_UNAVAILABLE = '/service-unavailable',
  SUPPORT = '/support',
  BUSINESS_ENROLLMENT = '/business-enrollment',
  LOGOUT = '/logout',
  LOGIN = '/login',
  MOBILE_APP_REQUIRED = '/mobile-app-required',
  INVITE_REQUIRED = '/invite-required',
  PRIVACY = '/privacy-statement',
  TERMS_CONDITIONS = '/user-terms',
  ASSET_LIST = '/assetReport',
  ORGANIZATION_LIST = '/organizations',
  DISPATCH = '/dispatch',
}
