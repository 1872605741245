import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  popoverContent: {
    boxShadow:
      '0px 4px 12px 0px var(--color-line-hairline, rgba(12, 17, 20, 0.10))',
    borderRadius: '12px',
    display: 'flex',
    position: 'absolute',
    right: '74px',
    left: 'unset !important',
    top: '56px !important',
    bottom: '32px',
    '&.MuiPopover-paper': {
      overflowY: 'hidden',
    },

    [theme.breakpoints.down('md')]: {
      bottom: '0',
    },
  },
  alertRedCircleIcon: {
    position: 'absolute',
    right: '6px',
    top: '9px',
  },
  notificationIconButton: {
    '&:active': {
      stroke: `${theme.new.color.textPrimary} !important`,
    },

    '&:active, &.selected': {
      backgroundColor: `${theme.new.color.brand[200]} !important`,

      circle: {
        stroke: theme.new.color.brand[200],
      },
    },
  },
}));

export default useStyles;
