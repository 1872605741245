export enum Role {
  SYSTEM_MANAGER = 'Advisor',
  SERVICE_MANAGER = 'Service Manager',
  SYSTEM_READER = 'System Reader',
  ORG_MANAGER = 'Account Admin',
  SERVICE_TECHNICIAN = 'Service Technician',
  POC_GROCERYMANAGER = 'POC Grocery Manager',
  HUB_MANAGER = 'Hub Manager',
  FLEET_MANAGER = 'Fleet Manager',
  OPERATOR = 'Operator',
  VIEWER = 'Viewer',
  POC_ROUTEMANAGER = 'POC Route Manager',
}

export const GLOBAL_ROLES = [
  Role.SYSTEM_MANAGER,
  Role.SERVICE_TECHNICIAN,
  Role.SYSTEM_READER,
  Role.SERVICE_MANAGER,
];
