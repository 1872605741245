import {
  API_VERSION_DEFAULTS,
  DEFAULT_API_CONFIG,
} from '~/common/apis/api.constants';
import { RouteParams } from '~/common/configs/route.config';
import { ApiResponse } from '~/common/models/apis/apiResponse.model';
import { BDAppErrorType, BDError } from '~/common/models/error.model';
import { ApiInsight, Insight } from '~/common/models/insight.model';
import {
  addAcceptLanguageHeader,
  addOrganizationIdHeader,
  hasApiResult,
  resultIsArray,
} from '~/common/utils/apis/api.utils';
import {
  makeGetPayloadCreator,
  makeThunk,
} from '~/common/utils/store/thunk.helper';

import { mapApiResponseToInsight } from '../mappers/insights.mappers';

enum InsightsActionType {
  GET_INSIGHTS = 'insigths/getInsights',
  GET_INSIGHT_DETAILS = 'insigths/getInsightDetails',
}

export type InsightsRouteParams = Required<
  Pick<RouteParams, 'organizationsId'>
> &
  Pick<RouteParams, 'hubsId' | 'fleetsId'>;

export type InsightsParams = InsightsRouteParams & {
  insightId?: string;
  sessionId?: string;
  pageSize?: number;
};

const CONFIG = {
  ...DEFAULT_API_CONFIG,
  axiosConfig: {
    ...DEFAULT_API_CONFIG.axiosConfig,
  },
};

export const getInsights = makeThunk(
  InsightsActionType.GET_INSIGHTS,
  makeGetPayloadCreator<ApiResponse<Insight[]>, InsightsParams>({
    url: `${globalThis.appConfig.apiBaseUrl}/insights/${API_VERSION_DEFAULTS.default}`,
    argAdapter: ({ organizationsId, hubsId, fleetsId }) => {
      return {
        requestParams: {
          organizationId: organizationsId,
          hubId: hubsId ? hubsId : '*',
          fleetId: fleetsId ? fleetsId : '*',
          pageSize: '-1', //Default until pagination is fully implemented in the backend
        },
      };
    },
    responseAdapter: (response: unknown | ApiResponse<unknown>) => {
      if (!!response && resultIsArray<ApiInsight>(response)) {
        const errors = new Array<BDError>();
        const result = {
          ...response,
          result: response.result?.length
            ? response.result.reduce((mappedInsights, apiInsight) => {
                try {
                  mappedInsights.push(mapApiResponseToInsight(apiInsight));
                } catch (e: unknown) {
                  errors.push({
                    name: 'Get Insights error',
                    type: BDAppErrorType.VALIDATION,
                    message:
                      e instanceof Error
                        ? e.message
                        : 'Failed to map API response to insight',
                    data: apiInsight,
                  });
                }
                return mappedInsights;
              }, new Array<Insight>())
            : [],
        };
        return errors.length
          ? { ...result, errors: errors.map((e) => BDError.asJson(e)) }
          : result;
      }
      throw new BDError('Unable to parse insights response', {
        data: { response },
      });
    },
  })
);

export const getInsightDetails = makeThunk(
  InsightsActionType.GET_INSIGHT_DETAILS,
  makeGetPayloadCreator<ApiResponse<Insight>, InsightsParams>({
    url: `${globalThis.appConfig.apiBaseUrl}/insights/${API_VERSION_DEFAULTS.insightsAPI}/:insightId`,
    axiosOptions: ({ organizationsId }, state) =>
      addAcceptLanguageHeader(
        addOrganizationIdHeader(CONFIG, organizationsId),
        state.profile.currentLocale
      ),
    argAdapter: ({ insightId }) => {
      return {
        requestParams: {
          ...(insightId ? { insightId: insightId } : null),
        },
      };
    },
    responseAdapter: (response: unknown | ApiResponse<unknown>) => {
      if (!!response && hasApiResult<ApiInsight>(response)) {
        return {
          ...response,
          result: mapApiResponseToInsight(response.result),
        };
      }
      throw new BDError('Unexpected insight details response', {
        data: response,
      });
    },
  })
);

export const INSIGHTS_ACTIONS = {
  getInsights,
  getInsightDetails,
};
