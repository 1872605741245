import MUIIconButton from '@mui/material/IconButton';
import { Theme,useTheme } from '@mui/material/styles';
import { type FunctionComponent, ReactNode, useState } from 'react';
import styled from 'styled-components';

import { IconButtonProps } from './IconButton.model';
import { generateButtonCss } from './IconButton.styles';

const StyledIconButton = styled(MUIIconButton).withConfig({
  shouldForwardProp: (prop) => !['hoverIcon', 'icon'].includes(prop)
})<IconButtonProps>`
  ${({ theme, ...props }) => generateButtonCss(theme, props)}
`;

/* BD Design System Button Component */
const IconButton: FunctionComponent<IconButtonProps> = 
({
  size = 'medium',
  variant = 'outline',
  ...props
}) => {
  const theme = useTheme<Theme>();

  /* State variable to handle icon changes during hover states. */
  const [icon, setIcon] = useState<ReactNode>(props.icon);

  /* Remove variant from props as MUI doesn't know what our variants are. */
  const convertedProps = { ...props, variant: undefined };

  /* Helper function to clean up style class name generation */
  const generateIconButtonClassName = (): string => {
    const sizeClass = size;
    const variantClass = variant;

    return `${props.className} ${variantClass} ${sizeClass}`;
  };

  return (
    <StyledIconButton
      {...convertedProps}
      className={generateIconButtonClassName()}
      onMouseEnter={() => {
        if (props.hoverIcon && !props.disabled) setIcon(props.hoverIcon);
      }}
      onMouseLeave={() => {
        if (props.hoverIcon && !props.disabled) setIcon(props.icon);
      }}
      theme={theme}
    >
      {icon}
    </StyledIconButton>
  );
};

export default IconButton;
