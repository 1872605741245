import {
  AppConfig,
  BDEnvironment,
  loadAppConfig,
} from '@brightdrop/app-config';

export {};

interface Heap {
  track: (event: string, properties?: any) => void;
  identify: (identity: string) => void;
  resetIdentity: () => void;
  addUserProperties: (properties: any) => void;
  addEventProperties: (properties: any) => void;
  removeEventProperty: (property: string) => void;
  clearEventProperties: () => void;
  getIdentity: () => string | null;
  appid: string;
  userId: string;
  config: any;
}

declare global {
  interface Window {
    bd_config?: BDEnvironment;
    local_config?: BDEnvironment;
    __REACT_DEVTOOLS_GLOBAL_HOOK__: any;
    heap: Heap;
  }

  // global augmentation requires use of var: https://github.com/Microsoft/TypeScript/pull/29332#issuecomment-785782937
  // eslint-disable-next-line no-var
  var appConfig: AppConfig;
}
globalThis.appConfig = loadAppConfig();

Object.freeze(globalThis.appConfig);
