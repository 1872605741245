import { createSlice } from '@reduxjs/toolkit';

import { RootState } from '../../app/rootReducer';
import { VEHICLE_ORDERS_ACTIONS } from './state/vehicleOrdersSlice.actions';
import { vehicleOrdersAdapter } from './state/vehicleOrdersSlice.adapters';
import { INITIAL_VEHICLE_ORDERS_STATE } from './state/vehicleOrdersSlice.constants';
import {
  VEHICLE_ORDERS_REDUCER_BUILDERS,
  VEHICLE_ORDERS_REDUCERS,
} from './state/vehicleOrdersSlice.reducers';

const {
  reducerSetVehicleOrdersSessionConfig,
  reduceResetVehicleOrdersSessionConfig,
  reducerSetSelectedVehicleOrdersSelected,
  reducerClearAllSelectedOrders,
} = VEHICLE_ORDERS_REDUCERS;

export const vehicleOrdersSlice = createSlice({
  name: 'vehicleOrders',
  initialState: INITIAL_VEHICLE_ORDERS_STATE,
  reducers: {
    setVehicleOrdersSessionConfig: reducerSetVehicleOrdersSessionConfig,
    resetVehicleOrderSessionConfig: reduceResetVehicleOrdersSessionConfig,
    setVehicleOrdersIndicies: reducerSetSelectedVehicleOrdersSelected,
    clearAllSelectedVehicleOrders: reducerClearAllSelectedOrders,
  },
  extraReducers: (builder) => {
    VEHICLE_ORDERS_REDUCER_BUILDERS.buildAddVehicleOrdersReducer(builder);
    VEHICLE_ORDERS_REDUCER_BUILDERS.buildGetVehicleOrdersReducer(builder);
    VEHICLE_ORDERS_REDUCER_BUILDERS.buildGetVehicleOrderDetailsReducer(builder);
    VEHICLE_ORDERS_REDUCER_BUILDERS.buildGetVehicleOrderListExportReducer(
      builder
    );
    VEHICLE_ORDERS_REDUCER_BUILDERS.buildGetVehicleOrdersSummaryReducer(
      builder
    );
    VEHICLE_ORDERS_REDUCER_BUILDERS.buildRecentVehicleOrdersReducer(builder);
    VEHICLE_ORDERS_REDUCER_BUILDERS.buildRemoveVehicleOrdersReducer(builder);
  },
});

export const {
  addVehicleOrders,
  getVehicleOrders,
  getVehicleOrderDetails,
  getVehicleOrderListExport,
  getVehicleOrdersSummary,
  getVehicleRecentOrder,
  removeVehicleOrders,
} = VEHICLE_ORDERS_ACTIONS;
export const {
  setVehicleOrdersSessionConfig,
  resetVehicleOrderSessionConfig,
  setVehicleOrdersIndicies,
  clearAllSelectedVehicleOrders,
} = vehicleOrdersSlice.actions;

export const {
  selectAll: selectAllVehicleOrders,
  selectById: selectVehicleOrderById,
  selectIds: selectVehicleOrderIds,
} = vehicleOrdersAdapter.getSelectors<RootState>(
  (state: RootState) => state.vehicleOrders
);

export const selectVehicleOrdersState = (
  state: RootState
): RootState['vehicleOrders'] => state.vehicleOrders;

export const vehicleOrdersReducer = vehicleOrdersSlice.reducer;
