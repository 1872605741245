import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  chartWrapper: {
    width: '100%',
    height: '350px',
    position: 'relative',
  },
  toolTip: {
    borderRadius: '12px',
    border: `1px solid ${theme.palette.border}`,
    boxShadow: '0px 1px 12px 0px rgba(26, 34, 51, 0.06)',
    padding: '0.625rem',
    backgroundColor: theme.palette.common.white,
    color: theme.palette.text.primary,
    display: 'flex',
    justifyContent: 'flex-start',
    minWidth: '160px',
    flexDirection: 'column',
    position: 'absolute',
    pointerEvents: 'none',
    transition: 'all .1s ease',
  },
  title: {
    '& .MuiTypography-root': {
      lineHeight: '22px',
      fontSize: '0.9rem',
      color: theme.palette.grey[900],
    },
    marginBottom: '0.5rem',
  },
  bodyWrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    color: theme.palette.grey[900],
    '& .MuiTypography-root': {
      fontSize: '0.75rem',
      lineHeight: '1.125rem',
    },
  },
  body: {
    marginRight: '40px',
  },
}));

export default useStyles;
