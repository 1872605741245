import { makeStyles } from 'tss-react/mui';

export const VIEW_AS_BANNER_HEIGHT = 50;

const useStyles = makeStyles()((theme) => ({
  viewAsContainer: {
    zIndex: '400',
    backgroundColor: theme.palette.warning.light,
    height: `${VIEW_AS_BANNER_HEIGHT}px`,
    position: 'fixed',
    borderBottom: `1px solid ${theme.new.color.line.hairline}`,
  },
  viewAsMessageContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
  },
  viewAslink: {
    marginLeft: '1rem',
  },
}));

export default useStyles;
