export type ChipLimitDetails = {
  maxLength: number;
  currentLength: number;
};

export type ChipInvalidFormatDetails = {
  invalidChips: string[];
};

export type ChipErrorDetails = ChipLimitDetails | ChipInvalidFormatDetails;

export enum ChipInputErrorType {
  INVALID_FORMAT = 'INVALID_FORMAT',
  CHIP_LIMIT = 'CHIP_LIMIT',
}

export type ChipErrorData = {
  type: ChipInputErrorType;
  details?: ChipErrorDetails;
};
