import { AppEnv } from '@brightdrop/app-config';

import {
  API_VERSION_DEFAULTS,
  CONTENT_TYPE_HEADER,
  CONTENT_TYPE_VALUE,
  DEFAULT_API_CONFIG,
} from '../../../common/apis/api.constants';
import { RouteParams } from '../../../common/configs/route.config';
import { ApiResponse } from '../../../common/models/apis/apiResponse.model';
import { BDError } from '../../../common/models/error.model';
import { Fleet } from '../../../common/models/fleet.model';
import {
  AddVehicleOrders,
  VehicleColumnNameSortForApi,
  VehicleOrderDetailsApiResponse,
  VehicleOrderListApiResponse,
  VehicleOrderSummary,
} from '../../../common/models/vehicleOrders.model';
import {
  addOrganizationIdHeader,
  hasApiResult,
} from '../../../common/utils/apis/api.utils';
import {
  makeGetPayloadCreator,
  makePostPayloadCreator,
  makeThunk,
  makeThunkCreatorConfig,
} from '../../../common/utils/store/thunk.helper';
import { ACCEPTED_EXPORT_FILE_TYPE } from '../vehicleOrders.constant';

export type VehicleOrderRouteParams = Required<
  Pick<RouteParams, 'organizationsId'>
>;

export interface VehicleOrderCodes {
  codes: string[];
}

export type RemoveVehicleOrdersParams = VehicleOrderRouteParams &
  VehicleOrderCodes;

export type AddVehicleOrdersParams = VehicleOrderRouteParams &
  VehicleOrderCodes & {
    fleet: Partial<Fleet>;
  };
export type VehicleOrderDetailsParams = VehicleOrderRouteParams & {
  code: string;
};
export type VehicleOrdersListPayload = VehicleOrderRouteParams & {
  sort?: Partial<VehicleColumnNameSortForApi>;
  filter?: {
    [key: string]: { oneOfValues: string[] } | undefined;
  };
  token?: string;
  itemsPerResponse?: number;
  //grab all orders for select all checkbox
  retrieveAllOrders?: boolean;
};
export type VehicleOrdersListExportPayload = VehicleOrderRouteParams & {
  sort: Partial<VehicleColumnNameSortForApi>;
  filter: {
    [key: string]: { oneOfValues: string[] } | undefined;
  };
};

export enum VehicleOrdersActionType {
  GET_ORDERS = 'orders/getVehicleOrders',
  ADD_ORDERS = 'orders/addVehicleOrders',
  GET_ORDER_DETAILS = 'orders/getVehicleOrderDetails',
  GET_ORDER_LIST_EXPORT = 'orders/getVehicleOrderListExport',
  GET_ORDER_SUMMARY = 'orders/getVehicleOrdersSummary',
  GET_RECENT_ORDER = 'orders/getVehicleRecentOrders',
  REMOVE_ORDERS = 'orders/removeVehicleOrders',
}

const API_EXPORT_CONFIG = makeThunkCreatorConfig({
  timeout:
    globalThis.appConfig.env === AppEnv.DEV ||
    globalThis.appConfig.env === AppEnv.QA
      ? 15000
      : 5000,
  retries: 2,
  delay: 100,
  customHeaders: {
    [CONTENT_TYPE_HEADER]: CONTENT_TYPE_VALUE,
    Accept: ACCEPTED_EXPORT_FILE_TYPE,
  },
});
const getVehicleOrders = makeThunk(
  VehicleOrdersActionType.GET_ORDERS,
  makePostPayloadCreator<
    ApiResponse<VehicleOrderListApiResponse> & VehicleOrdersListPayload,
    VehicleOrdersListPayload
  >({
    url: `${globalThis.appConfig.apiBaseUrl}/ordertracking/${API_VERSION_DEFAULTS.vehicleOrdersAPI}/orders/list`,
    axiosOptions: ({ organizationsId }) => {
      return addOrganizationIdHeader(DEFAULT_API_CONFIG, organizationsId);
    },
    argAdapter: (payload) => {
      return {
        requestBody: {
          sort: payload.sort || {},
          filter: payload.filter || {},
          token: payload.token || '',
          itemsPerResponse: payload.itemsPerResponse || 10,
        },
      };
    },
    responseAdapter: (response: any, _, args: VehicleOrdersListPayload) => {
      if (hasApiResult<any>(response)) {
        return { ...args, ...response };
      }
      throw new BDError(`Unexpected get Vehicle Orders response: `, {
        data: { ...args, ...response },
      });
    },
  })
);

const addVehicleOrders = makeThunk(
  VehicleOrdersActionType.ADD_ORDERS,
  makePostPayloadCreator<ApiResponse<AddVehicleOrders>, AddVehicleOrdersParams>(
    {
      url: `${globalThis.appConfig.apiBaseUrl}/ordertracking/${API_VERSION_DEFAULTS.vehicleOrdersAPI}/orders`,
      axiosOptions: ({ organizationsId }) =>
        addOrganizationIdHeader(DEFAULT_API_CONFIG, organizationsId),
      argAdapter: (payload) => ({
        requestBody: {
          fleet: payload.fleet,
          codes: payload.codes,
        },
      }),
      responseAdapter: (response: any) => {
        if (!!response && hasApiResult<any>(response)) {
          return {
            ...response,
            result: response.result,
          };
        }
        throw new BDError('Unexpected Add Vehicle Orders response', {
          data: response,
        });
      },
    }
  )
);

export const getVehicleOrderDetails = makeThunk(
  VehicleOrdersActionType.GET_ORDER_DETAILS,
  makeGetPayloadCreator<
    ApiResponse<VehicleOrderDetailsApiResponse>,
    VehicleOrderDetailsParams
  >({
    url: `${globalThis.appConfig.apiBaseUrl}/ordertracking/${API_VERSION_DEFAULTS.vehicleOrdersAPI}/orders/:code`,
    axiosOptions: ({ organizationsId }, state) =>
      addOrganizationIdHeader(DEFAULT_API_CONFIG, organizationsId),
    argAdapter: ({ code }) => {
      return {
        requestParams: {
          code,
        },
      };
    },
    responseAdapter: (response: unknown | ApiResponse<unknown>) => {
      if (
        !!response &&
        hasApiResult<VehicleOrderDetailsApiResponse>(response)
      ) {
        return {
          ...response,
          result: response.result,
        };
      }
      throw new BDError('Unexpected vehicle order details response', {
        data: response,
      });
    },
  })
);
const getVehicleOrdersSummary = makeThunk(
  VehicleOrdersActionType.GET_ORDER_SUMMARY,
  makeGetPayloadCreator<
    ApiResponse<VehicleOrderSummary>,
    VehicleOrderRouteParams
  >({
    url: `${globalThis.appConfig.apiBaseUrl}/ordertracking/${API_VERSION_DEFAULTS.vehicleOrdersAPI}/orders/summary`,
    axiosOptions: ({ organizationsId }, state) =>
      addOrganizationIdHeader(DEFAULT_API_CONFIG, organizationsId),

    responseAdapter: (response: unknown | ApiResponse<unknown>) => {
      if (!!response && hasApiResult<VehicleOrderSummary>(response)) {
        return {
          ...response,
          result: response.result,
        };
      }
      throw new BDError('Unexpected Inspection Summary response', {
        data: response,
      });
    },
  })
);

const getVehicleOrderListExport = makeThunk(
  VehicleOrdersActionType.GET_ORDER_LIST_EXPORT,
  makePostPayloadCreator<BlobPart, VehicleOrdersListExportPayload>({
    url: `${globalThis.appConfig.apiBaseUrl}/ordertracking/2024-06-01/orders/export`,

    axiosOptions: ({ organizationsId }, state) => {
      const thunkConfig = addOrganizationIdHeader(
        API_EXPORT_CONFIG,
        organizationsId
      );

      return {
        ...thunkConfig,
        axiosConfig: {
          ...thunkConfig.axiosConfig,
          responseType: 'blob',
        },
      };
    },
    argAdapter: (payload) => ({
      requestBody: {
        sort: payload.sort || {},
        filter: payload.filter || {},
      },
    }),
  })
);

export const removeVehicleOrders = makeThunk(
  VehicleOrdersActionType.REMOVE_ORDERS,
  makePostPayloadCreator<ApiResponse<string[]>, RemoveVehicleOrdersParams>({
    url: `${globalThis.appConfig.apiBaseUrl}/ordertracking/${API_VERSION_DEFAULTS.vehicleOrdersAPI}/orders/bulkremove`,
    axiosOptions: ({ organizationsId }) =>
      addOrganizationIdHeader(DEFAULT_API_CONFIG, organizationsId),
    argAdapter: (payload) => ({
      requestBody: {
        codes: payload.codes,
      },
    }),
    responseAdapter: (response: any) => {
      if (!!response && hasApiResult<any>(response)) {
        return {
          ...response,
          result: response.result,
        };
      }
      throw new BDError('Unexpected remove Vehicle Orders response', {
        data: response,
      });
    },
  })
);
const getVehicleRecentOrder = makeThunk(
  VehicleOrdersActionType.GET_RECENT_ORDER,
  makePostPayloadCreator<
    ApiResponse<VehicleOrderListApiResponse>,
    VehicleOrdersListPayload
  >({
    url: `${globalThis.appConfig.apiBaseUrl}/ordertracking/${API_VERSION_DEFAULTS.vehicleOrdersAPI}/orders/list`,
    axiosOptions: ({ organizationsId }) => {
      return addOrganizationIdHeader(DEFAULT_API_CONFIG, organizationsId);
    },
    argAdapter: (payload) => {
      return {
        requestBody: {
          sort: {
            updatedAt: 'desc',
          },
        },
      };
    },
    responseAdapter: (response: any) => {
      if (hasApiResult<any>(response)) {
        return response;
      }
      throw new BDError(`Unexpected get Vehicle Orders response: `, {
        data: response,
      });
    },
  })
);
export const VEHICLE_ORDERS_ACTIONS = {
  getVehicleOrders,
  addVehicleOrders,
  getVehicleOrderDetails,
  getVehicleOrderListExport,
  getVehicleOrdersSummary,
  getVehicleRecentOrder,
  removeVehicleOrders,
};
