import { FormState } from '../models/form.model';

export const getStateAsForm = <T extends { [field: string]: unknown }>(
  state: FormState<T>
): T => {
  return Object.keys(state).reduce((form, key) => {
    return {
      ...form,
      [key]: state[key as keyof FormState<T>].value,
    };
  }, {} as T);
};

export const getFormAsState = <T extends { [field: string]: unknown }>(
  formFields: T
): FormState<T> => {
  return Object.keys(formFields).reduce((state, key) => {
    return {
      ...state,
      [key]: { value: formFields[key], error: '' },
    };
  }, {} as FormState<T>);
};
