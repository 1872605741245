import { getFormAsState } from '@brightdrop/bd-form';

import {
  AlertCategory,
  AvailableSubscription,
  DiagnosticEvent,
} from '~/common/models/alert.model';
import {
  NotificationCardFormFields,
  NotificationFormState,
} from '~/common/models/notification.model';

import {
  PROFILE_SUBSCRIPTION_QUERY,
  VEHICLE_ACTIVITY_SUBSCRIPTION_QUERY,
  VEHICLE_DIAGNOSTICS_SUBSCRIPTION_QUERY,
} from './settings.constant';
import { NOTIFICATION_SETTINGS_MESSAGES } from './settings.messages';

export const getEditNotificationCardState = (
  notification?: Partial<AvailableSubscription>
): NotificationFormState => {
  return getFormAsState({
    name: notification?.preferredName || notification?.name || null,
    receiptPreferences: notification?.receiptPreferences || null,
    selectedEvents:
      notification?.eventOptions?.sort((a, b) =>
        a.displayName.localeCompare(b.displayName)
      ) || null,
    selectedAssets: notification?.selectedAssets || null,
    selectedVehicleSubscriberType:
      notification?.selectedVehicleSubscriberType || null,
  } as NotificationCardFormFields);
};

export const getSubscriptionsQuery = (category: string) => {
  switch (category) {
    case AlertCategory.ASSET:
      return VEHICLE_ACTIVITY_SUBSCRIPTION_QUERY;
    case AlertCategory.DIAGNOSTIC:
      return VEHICLE_DIAGNOSTICS_SUBSCRIPTION_QUERY;
    case AlertCategory.PROFILE: {
      return PROFILE_SUBSCRIPTION_QUERY;
    }
    default:
      return VEHICLE_DIAGNOSTICS_SUBSCRIPTION_QUERY;
  }
};

export const getDiagnosticEventName = (
  event: DiagnosticEvent,
  labels: typeof NOTIFICATION_SETTINGS_MESSAGES
) => {
  return (
    labels?.diagnostics?.[
      `settings:notification.card.diagnostics.type.${event}`
    ] || event
  );
};
