import './common/configs/app.config';
import './common/configs/i18n.config';

import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';

import App from './app/App';
import { persistor, store } from './app/store';

const disableReactDevTools = () => {
  // Check if the React Developer Tools global hook exists
  if (typeof window.__REACT_DEVTOOLS_GLOBAL_HOOK__ !== 'object') {
    return;
  }

  for (const prop in window.__REACT_DEVTOOLS_GLOBAL_HOOK__) {
    if (prop === 'renderers') {
      window.__REACT_DEVTOOLS_GLOBAL_HOOK__[prop] = new Map();
    } else {
      // Replace all of its properties with a no-op function or a null value
      // depending on their types

      window.__REACT_DEVTOOLS_GLOBAL_HOOK__[prop] =
        typeof window.__REACT_DEVTOOLS_GLOBAL_HOOK__[prop] === 'function'
          ? () => {
              //
            }
          : null;
    }
  }
};

// disable console logs in production
if (process.env.NODE_ENV === 'production') {
  disableReactDevTools();
}

// alert if appconfig is not loaded
if (!globalThis?.appConfig?.rawConfig) {
  console.error(
    `Failed to load appConfig: No config found for environment '${process.env.NODE_ENV}'`,
    globalThis?.appConfig
  );
}

/**
  When running in dev mode, force importing dependencies that are imported
  within a web worker. This is to address a current issue with vitejs (v4) where
  it will continueouly reload the web app every time a web worker loads a module
  that imports some external library.

  The root problem appears to be related to fsevents library and how vite
  handles import in different JS runtime contexts (main web app vs web worker
  context).

  There is a suggested solution [1] to upgrade fsevents from 2.3.2 to 2.3.3 but
  vite will still reload the web app at least once when a web worker loads
  its scripts and imports depedencies.

  [1] https://stackoverflow.com/questions/76811767/suddenly-vite-keeps-on-restarting-server
 */
if (process.env.NODE_ENV === 'development') {
  import('ky');
}

async function enableMocking() {
  if (process.env.NODE_ENV === 'development') {
    const { worker } = await import('./sw/browser');
    return worker.start({
      onUnhandledRequest: 'bypass',
    });
  }
}

// We intentionally want to wait for the service worker to loader first
enableMocking().then(() => {
  const app = document.getElementById('root');

  // create a root
  const root = createRoot(app as Element);

  //render app to root
  root.render(
    <StrictMode>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <App />
        </PersistGate>
      </Provider>
    </StrictMode>
  );
});
