import { ActionReducerMapBuilder } from '@reduxjs/toolkit';
import merge from 'deepmerge';

import {
  API_VERSION_DEFAULTS,
  BD_FLEET_HEADER_NAME,
  BD_HUB_HEADER_NAME,
  DEFAULT_API_CONFIG,
} from '~/common/apis/api.constants';
import { SessionConfigType } from '~/common/constants/common.constant';
import { ApiResponse } from '~/common/models/apis/apiResponse.model';
import { BDError } from '~/common/models/error.model';
import { RootWorkPlan } from '~/common/models/workplan.model';
import {
  addAcceptLanguageHeader,
  addHeader,
  addOrganizationIdHeader,
  hasApiResult,
} from '~/common/utils/apis/api.utils';
import {
  makePatchPayloadCreator,
  makeThunk,
} from '~/common/utils/store/thunk.helper';

import { WorkPlansState } from '../workPlansSlice.model';

interface UpdateWorkPlanAssignedOperatorParams {
  sessionId: string;
  workPlanId: string;
  operatorId: string | null;
  organizationsId: string;
  hubsId: string;
  fleetsId: string;
}

const CONFIG = DEFAULT_API_CONFIG;

export const updateWorkPlanAssignedOperator = makeThunk(
  'worksplans/updateWorkPlanAssignedOperator',
  makePatchPayloadCreator<
    ApiResponse<RootWorkPlan>,
    UpdateWorkPlanAssignedOperatorParams
  >({
    url: ({ workPlanId }) => {
      return `${globalThis.appConfig.apiBaseUrl}/dispatch/${API_VERSION_DEFAULTS.dispatchAPI}/workplans/${workPlanId}/operator`;
    },
    axiosOptions: ({ organizationsId, hubsId, fleetsId }, state) =>
      addAcceptLanguageHeader(
        addOrganizationIdHeader(
          addHeader(
            addHeader(CONFIG, BD_FLEET_HEADER_NAME, fleetsId),
            BD_HUB_HEADER_NAME,
            hubsId
          ),
          organizationsId
        ),
        state.profile.currentLocale
      ),
    argAdapter({ operatorId }) {
      return {
        requestBody: {
          operatorId,
        },
      };
    },
    responseAdapter: (response: unknown | ApiResponse<unknown>) => {
      if (!!response && hasApiResult<RootWorkPlan>(response)) {
        return response;
      }

      throw new BDError('Failed to update work plan assigned operator', {
        data: response,
      });
    },
  })
);

export function buildUpdateWorkPlanAssignedOperatorExtraReducers(
  builder: ActionReducerMapBuilder<WorkPlansState>
) {
  builder.addCase(updateWorkPlanAssignedOperator.fulfilled, (state, action) => {
    const { sessionId } = action.meta.arg;
    const updatedWorkPlan = action.payload.result;
    const session =
      state.workPlanEntitiesSessions[SessionConfigType.LIST_VIEW]?.[sessionId];

    if (!session) {
      console.error(
        `ERROR: Update work plan assigned operator, fulfilled. Invalid session ID ${sessionId}`
      );
      return;
    }

    const updatedEntities = session?.entities?.map((workPlan) => {
      return workPlan.id === updatedWorkPlan.id ? updatedWorkPlan : workPlan;
    });

    state.workPlanEntitiesSessions = merge(
      state.workPlanEntitiesSessions,
      {
        [SessionConfigType.LIST_VIEW]: {
          [sessionId]: {
            ...session,
            entities: updatedEntities,
          },
        },
      },
      {
        arrayMerge: (_, sourceArray) => sourceArray,
      }
    );
  });
}
