import {
  API_VERSION_DEFAULTS,
  DEFAULT_API_CONFIG,
} from '~/common/apis/api.constants';
import { ApiResponse } from '~/common/models/apis/apiResponse.model';
import { PagedResult } from '~/common/models/common.model';
import {
  ApiJobWorkPlanHistory,
  UploadWorkPlanHistory,
} from '~/common/models/dispatch.model';
import { BDAppErrorType, BDError } from '~/common/models/error.model';
import {
  addAcceptLanguageHeader,
  addOrganizationIdHeader,
  hasApiResult,
} from '~/common/utils/apis/api.utils';
import {
  makePostPayloadCreator,
  makeThunk,
} from '~/common/utils/store/thunk.helper';

import { JobsSessionViewType, UploadWorkPlanHistoryParams } from '../jobsSlice';
import { mapApiResponseToUploadWorkPlanHistory } from '../mappers/uploadWorkPlanHistory.mappers';
import {
  buildSortRequest,
  buildUploadWorkPlanHistoryQuery,
} from '../utils/uploadHistory.utils';

enum JobsActionType {
  GET_UPLOAD_WORK_PLAN_HISTORY = 'jobs/getUploadWorkPlanHistory',
}

const CONFIG = DEFAULT_API_CONFIG;

export const getUploadWorkPlanHistory = makeThunk(
  JobsActionType.GET_UPLOAD_WORK_PLAN_HISTORY,
  makePostPayloadCreator<
    ApiResponse<PagedResult<UploadWorkPlanHistory>>,
    UploadWorkPlanHistoryParams
  >({
    url: `${globalThis.appConfig.apiBaseUrl}/jobs/${API_VERSION_DEFAULTS.default}/searches`,
    axiosOptions: ({ organizationsId }, state) =>
      addAcceptLanguageHeader(
        addOrganizationIdHeader(CONFIG, organizationsId),
        state.profile.currentLocale
      ),
    argAdapter: (params, state) => {
      const sessionConfig =
        state.jobs.sessionConfigs[
          JobsSessionViewType.WORK_PLANS_UPLOAD_HISTORY
        ]?.[params.sessionId];
      const query = buildUploadWorkPlanHistoryQuery(sessionConfig, params);
      const sort = buildSortRequest(sessionConfig?.sortOrder);
      return {
        requestBody: {
          page: params.page,
          size: params.size,
          query,
          sort,
        },
      };
    },
    responseAdapter: (response: unknown | ApiResponse<unknown>) => {
      if (!!response && hasApiResult<ApiJobWorkPlanHistory>(response)) {
        const { content, totalElements } = response.result;
        const errors = new Array<BDError>();
        const result = {
          ...response,
          result: {
            items: content?.length
              ? content.reduce((mappedHistory, apiHistory) => {
                  try {
                    mappedHistory.push(
                      mapApiResponseToUploadWorkPlanHistory(apiHistory)
                    );
                  } catch (e) {
                    errors.push({
                      name: 'Get Upload Work Plan History error',
                      type: BDAppErrorType.VALIDATION,
                      message:
                        e instanceof Error
                          ? e.message
                          : 'Failed to map API response to upload work plan history',
                      data: apiHistory,
                    });
                  }
                  return mappedHistory;
                }, new Array<UploadWorkPlanHistory>())
              : [],
            total_items: totalElements,
          },
        };
        return errors.length
          ? { ...result, errors: errors.map((e) => BDError.asJson(e)) }
          : result;
      }
      throw new BDError('Unexpected job upload history item response', {
        data: response,
      });
    },
  })
);

export const JOBS_ACTIONS = {
  getUploadWorkPlanHistory,
};
