import {
  ActionReducerMapBuilder,
  CaseReducer,
  EntityState,
  PayloadAction,
} from '@reduxjs/toolkit';
import merge from 'deepmerge';

import { BDRequestStatus } from '~/common/models/apis/apiResponse.model';
import { UploadWorkPlanHistory } from '~/common/models/dispatch.model';
import { BDAppErrorType, BDError } from '~/common/models/error.model';

import {
  JobsSessionConfig,
  JobsSessionViewType,
  JobsState,
} from '../jobsSlice';
import { JOBS_ACTIONS } from './jobsSlice.actions';
import { jobsAdapter } from './jobsSlice.adapters';
import { INITIAL_JOBS_STATE } from './jobsSlice.constants';

const reduceSetJobsSessionConfig: CaseReducer<
  typeof INITIAL_JOBS_STATE,
  PayloadAction<Partial<JobsSessionConfig>>
> = (state, action) => {
  Object.keys(action.payload).forEach((key) => {
    const scope = key as JobsSessionViewType;
    const payload = action.payload[scope] || {};
    Object.keys(payload).forEach((id) => {
      state.sessionConfigs = merge(
        state.sessionConfigs,
        {
          [scope]: {
            [id]: payload[id] && {
              ...payload[id],
            },
          },
        },
        {
          arrayMerge: (_, sourceArray) => sourceArray,
        }
      );
    });
  });
};

export const buildGetJobsUploadHistoryReducer = (
  builder: ActionReducerMapBuilder<
    EntityState<UploadWorkPlanHistory> & JobsState
  >
) => {
  builder.addCase(
    JOBS_ACTIONS.getUploadWorkPlanHistory.pending,
    (state, action) => {
      const { sessionId } = action.meta.arg;
      if (sessionId) {
        state.sessionConfigs = merge(
          state.sessionConfigs,
          {
            [JobsSessionViewType.WORK_PLANS_UPLOAD_HISTORY]: {
              [sessionId]: {
                operationStatus: {
                  status: BDRequestStatus.PENDING,
                },
              },
            },
          },
          {
            arrayMerge: (_, sourceArray) => sourceArray,
          }
        );
      }
      jobsAdapter.removeMany(state, state.ids);
    }
  );
  builder.addCase(
    JOBS_ACTIONS.getUploadWorkPlanHistory.fulfilled,
    (state, action) => {
      const { sessionId, page, size } = action.meta.arg;
      const { items, total_items } = action.payload.result;
      jobsAdapter.upsertMany(state, items);
      if (sessionId) {
        state.sessionConfigs = merge(
          state.sessionConfigs,
          {
            [JobsSessionViewType.WORK_PLANS_UPLOAD_HISTORY]: {
              [sessionId]: {
                operationStatus: {
                  status: BDRequestStatus.SUCCEEDED,
                },
                count: total_items,
                page,
                rowsPerPage: size,
              },
            },
          },
          {
            arrayMerge: (_, sourceArray) => sourceArray,
          }
        );
      }
    }
  );
  builder.addCase(
    JOBS_ACTIONS.getUploadWorkPlanHistory.rejected,
    (state, action) => {
      const { sessionId } = action.meta.arg;
      if (sessionId) {
        state.sessionConfigs = merge(
          state.sessionConfigs,
          {
            [JobsSessionViewType.WORK_PLANS_UPLOAD_HISTORY]: {
              [sessionId]: {
                operationStatus: {
                  status: BDRequestStatus.FAILED,
                  errors: [
                    {
                      type: BDAppErrorType.API,
                      ...(action.payload || (action.error as BDError)),
                    },
                  ],
                },
              },
            },
          },
          {
            arrayMerge: (_, sourceArray) => sourceArray,
          }
        );
      }
    }
  );
};

export const JOBS_REDUCERS = {
  reduceSetJobsSessionConfig,
};
export const JOBS_EXTRA_REDUCER_BUILDERS = {
  buildGetJobsUploadHistoryReducer,
};
