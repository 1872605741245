import IconButton from '@mui/material/IconButton';
import Popover, { PopoverProps } from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import { useState } from 'react';

import useStyles from './BDPopover.styles';

export interface BDPopoverMessageProps {
  message?: string;
}
export interface BDPopoverProps {
  opener: React.ComponentType;
  label: string;
  message?: string;
  content?: React.ComponentType<BDPopoverMessageProps>;
  popoverProps?: Partial<PopoverProps>;
}

export const BDPopoverMessage = ({
  message,
}: BDPopoverMessageProps): JSX.Element => {
  const { classes } = useStyles();
  return <div className={classes.popoverMessage}>{message}</div>;
};

const BDPopover = ({
  label,
  message,
  opener: OpenerComponent,
  content: PopoverComponent,
  popoverProps,
}: BDPopoverProps): JSX.Element => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const handlePopover = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  return (
    <>
      <IconButton
        aria-label={label}
        color="inherit"
        onClick={handlePopover}
        size="large"
      >
        <OpenerComponent />
      </IconButton>
      {(PopoverComponent || message) && (
        <Popover
          id="popover"
          aria-label={label}
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          onClose={() => setAnchorEl(null)}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          {...popoverProps}
        >
          {PopoverComponent ? (
            <PopoverComponent message={message} />
          ) : (
            <Typography>{message}</Typography>
          )}
        </Popover>
      )}
    </>
  );
};

export default BDPopover;
