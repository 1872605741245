export enum OnboardingState {
  CREATE_HUB = 'create-hub',
  CREATE_FLEET = 'create-fleet',
  ASSIGN_ASSETS = 'assign-assets',
  CREATE_USER = 'invite-user',
  COMPLETED = 'completed',
  SKIPPED = 'skipped',
  UNKNOWN = 'unknown',
}
export interface ApiOnboardingState {
  onBoardingState?: OnboardingState;
}
