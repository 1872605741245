import { cx } from '@emotion/css';
import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem as MuiMenuItem,
  Select as MUISelect,
  SelectProps,
  Typography,
} from '@mui/material';

import DropdownArrow from '../../assets/dropdownArrow.svg?react';
import ErrorIcon from '../../assets/error.svg?react';
import useStyles from './FormSelect.styles';

export type FormSelectProps = SelectProps & {
  dataTestId?: string;
  helperText?: string;
  renderCustom?: () => React.ReactNode;
};

const FormSelect: React.FC<FormSelectProps> & {
  MenuItem: typeof MuiMenuItem; // Type declaration for MenuItem, providing access to MUI's MenuItem component
} = ({
  dataTestId,
  helperText,
  label,
  labelId,
  value,
  children,
  renderCustom,
  onChange,
  error,
  ...rest
}) => {
  const { classes } = useStyles();

  const renderedChildren = renderCustom ? renderCustom() : children;

  return (
    <FormControl
      variant="filled"
      className={cx(classes.formControl, { error })}
    >
      <InputLabel id={labelId} className={classes.label}>
        {label}
      </InputLabel>
      <MUISelect
        className={cx(classes.select, { error })}
        variant="filled"
        label={label}
        labelId={labelId}
        value={value}
        IconComponent={DropdownArrow}
        data-testid={dataTestId}
        onChange={onChange}
        {...rest}
      >
        {renderedChildren}
      </MUISelect>
      {helperText && (
        <FormHelperText className={classes.helperTextWrapper}>
          <span className={cx(classes.helperText, { error })}>
            {error && <ErrorIcon />}
            <Typography variant="small_body_medium">{helperText}</Typography>
          </span>
        </FormHelperText>
      )}
    </FormControl>
  );
};

// Attach the MUI MenuItem component to FormSelect for usage
FormSelect.MenuItem = MuiMenuItem;
export default FormSelect;
