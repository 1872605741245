import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';

import { SessionConfigType } from '~/common/constants/common.constant';
import {
  ListViewSession,
  OperationSession,
} from '~/common/models/common.model';
import { Insight } from '~/common/models/insight.model';

import { RootState } from '../../app/rootReducer';
import { INSIGHTS_ACTIONS } from './state/insightsSlice.actions';
import { INITIAL_INSIGHTS_STATE } from './state/insightsSlice.constants';
import {
  INSIGHTS_EXTRA_REDUCER_BUILDERS,
  INSIGHTS_REDUCERS,
} from './state/insightsSlice.reducers';

const insightsAdapter = createEntityAdapter<Insight>({
  selectId: (insight: Insight) => insight.id,
});

export type InsightsListSession = Partial<ListViewSession> &
  OperationSession & { selectedId?: string };
export interface InsightSessionConfig {
  [SessionConfigType.DETAIL_VIEW]?: {
    [id: string]: OperationSession;
  };
  [SessionConfigType.LIST_VIEW]?: {
    [key: string]: InsightsListSession;
  };
}

export interface InsightsState {
  sessionConfigs: {
    [k in SessionConfigType]?: InsightSessionConfig[k];
  };
}

const { reduceSetInsightsSession } = INSIGHTS_REDUCERS;

export const insightsSlice = createSlice({
  name: 'insights',
  initialState: INITIAL_INSIGHTS_STATE,
  reducers: {
    setInsightsSession: reduceSetInsightsSession,
  },
  extraReducers: (builder) => {
    INSIGHTS_EXTRA_REDUCER_BUILDERS.buildGetInsightsActionReducers(builder);
    INSIGHTS_EXTRA_REDUCER_BUILDERS.buildGetInsightDetailsActionReducer(
      builder
    );
  },
});

export const { getInsights, getInsightDetails } = INSIGHTS_ACTIONS;
export const { setInsightsSession } = insightsSlice.actions;

export const {
  selectAll: selectAllInsights,
  selectById: selectInsightsById,
  selectIds: selectInsightsIds,
} = insightsAdapter.getSelectors<RootState>(
  (state: RootState) => state.insights
);

export const insightsReducer = insightsSlice.reducer;
