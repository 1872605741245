import { Box, Grid, Paper } from '@mui/material';
import React from 'react';

import GMEnvolveLogo from './GMEnvolveLogo';
import useStyles from './LoginTemplate.style';

interface LoginTemplateProps {
  children: React.ReactNode;
}

const LoginTemplate: React.FC<LoginTemplateProps> = ({ children }) => {
  const { classes } = useStyles();
  return (
    <Box className={classes.wrapper}>
      <Paper elevation={2} className={classes.paper}>
        <Grid container style={{ height: '100%' }}>
          <Grid item lg={6} className={classes.image}></Grid>
          <Grid item xs={12} md={12} lg={6} p={3}>
            <div className={classes.rightContentContainer}>
              <div className={classes.companyLogo}>
                <GMEnvolveLogo />
              </div>
              <div className={classes.rightContent}>{children}</div>
            </div>
          </Grid>
        </Grid>
      </Paper>
    </Box>
  );
};

export default LoginTemplate;
