import { useTheme } from '@mui/material';
import Typography, {
  TypographyPropsVariantOverrides,
} from '@mui/material/Typography/Typography';
import { type OverridableStringUnion } from '@mui/types';
import { CSSProperties } from 'react';

import { splitBySearchTerm } from '../globalSearch.utils';
import useStyles from './HighlightableSearchText.styles';

interface HighlightableSearchTextProps {
  searchTerm: string;
  displayText: string;
  color?: CSSProperties['color'];
  typographyVariant: OverridableStringUnion<
    'inherit',
    TypographyPropsVariantOverrides
  >;
}

const HighlightableSearchText = (props: HighlightableSearchTextProps) => {
  const theme = useTheme();
  const {
    searchTerm,
    displayText,
    typographyVariant,
    color = theme.new.color.textPrimary,
  } = props;
  const { classes } = useStyles();

  const { before, match, after } = splitBySearchTerm(searchTerm, displayText);

  return (
    <Typography
      variant={typographyVariant}
      className={classes.resultLabel}
      color={color}
    >
      <span>{before}</span>
      <span className={before || after ? classes.highlightedLabel : undefined}>
        {match}
      </span>
      <span>{after}</span>
    </Typography>
  );
};

export default HighlightableSearchText;
