import { ConnectivityStatusType } from '~/common/models/common.model';
import {
  ApiConnectivityStatusSummary,
  ConnectivityStatusSummary,
} from '~/common/models/csDashboard.model';
import { mapConnectivityStatusType } from '~/features/assets/mappers/asset.mappers';

export const mapConnectivityStatusSummary = (
  data: ApiConnectivityStatusSummary
): ConnectivityStatusSummary => {
  let pendingOnboarding = 0;
  let onboarded = 0;
  let pendingConnectivity = 0;
  let connected = 0;

  data?.items?.forEach((item) => {
    const status = mapConnectivityStatusType(
      item.organizationPrimaryOwnershipStatus,
      item.provisioningState
    );
    switch (status) {
      case ConnectivityStatusType.PENDING_ONBOARDING:
        pendingOnboarding++;
        break;
      case ConnectivityStatusType.ONBOARDED:
        onboarded++;
        break;
      case ConnectivityStatusType.PENDING_CONNECTIVITY:
        pendingConnectivity++;
        break;
      case ConnectivityStatusType.CONNECTED:
        connected++;
        break;
    }
  });
  return {
    organizationId: data.organizationId,
    totalVehicleCount: data.totalVehicleCount,
    connectivityStatuses: {
      pendingOnboarding,
      onboarded,
      pendingConnectivity,
      connected,
    },
  };
};
