import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(() => ({
  popoverMessage: {
    borderRadius: '5px',
    padding: '10px',
    fontSize: '0.9375rem',
    maxWidth: '300px',
  },
}));

export default useStyles;
