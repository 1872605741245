import {
  FormSubmitFailure,
  FormSubmitSuccess,
} from '~/common/models/common.model';
import { ApiWorkPlanDetail } from '~/common/models/workplan.model';

export type WorkPlanFormSubmitSuccess = FormSubmitSuccess<ApiWorkPlanDetail>;

export type WorkPlanFormSubmitFailure = FormSubmitFailure<ApiWorkPlanDetail>;

export type WorkPlanFormSubmitResponse = Promise<
  WorkPlanFormSubmitSuccess | WorkPlanFormSubmitFailure
>;

export enum WorkPlanErrorCode {
  INVALID_VALUE = 'INVALID_VALUE',
  RESOURCE_ALREADY_EXISTS = 'RESOURCE_ALREADY_EXISTS',
}

export const WORK_PLAN_API_ERROR_FIELD_INDEX = 0;
export const WORK_PLAN_ERROR_LOCATION_FIELD = 'location';

export const WORK_PLAN_NAME_PREFIX = 'workplan_';
