import { cx } from '@emotion/css';
import { Box } from '@mui/material';

import { UnitsType } from '~/common/constants/common.constant';
import {
  AssetReportItem,
  BatteryChargingStatus,
} from '~/common/models/asset-report.model';
import { TableColumnConfig } from '~/common/models/table/table.model';
import { CHARGING_STATUS_ICONS } from '~/common/utils/icons/icons.utils';

import { ASSET_LIST_REPORT_MESSAGES } from '../AssetListReportView.messages';
import {
  ASSET_DETAILS_COLUMN,
  ASSET_REPORT_ADAPTERS,
  BATTERY_COLUMN,
  FLEET_COLUMN,
  getCommonReportColumns,
  HUB_COLUMN,
  ID_COLUMN,
  LAST_COMMUNICATED_COLUMN,
  LICENSE_PLATE_COLUMN,
  ORGANIZATION_COLUMN,
  TYPE_COLUMN,
  VEHICLE_TYPE_COLUMN,
} from './commonReportTable.config';

const CHARGE_STATUS_COLUMN_INDEX = 10;

export const getEnergyReportColumns = (
  columnLabels: typeof ASSET_LIST_REPORT_MESSAGES.columnLabels,
  valueLabels: typeof ASSET_LIST_REPORT_MESSAGES.valueLabels,
  units: UnitsType,
  isParamAvailable: boolean,
  locale?: string
) => [
  ...getCommonReportColumns(
    columnLabels,
    valueLabels,
    isParamAvailable,
    locale
  ),
  BATTERY_COLUMN(columnLabels),
  ESTIMATED_RANGE(columnLabels, units),
];

export const ESTIMATED_RANGE = (
  columnLabels: typeof ASSET_LIST_REPORT_MESSAGES.columnLabels,
  units: UnitsType
): TableColumnConfig<AssetReportItem> => ({
  name: 'estimatedRange',
  label: columnLabels['asset:estimatedRange'],
  options: {
    sort: false,
    customBodyRender: ASSET_REPORT_ADAPTERS.estimatedRange(units),
  },
});

export const STATE_OF_CHARGE_COLUMN = (
  columnLabels: typeof ASSET_LIST_REPORT_MESSAGES.columnLabels,
  units: UnitsType
): TableColumnConfig<AssetReportItem> => ({
  name: 'battery',
  label: columnLabels['asset:tableColumnLabels.charge'],
  options: {
    sort: false,
    customBodyRender: (battery) =>
      ASSET_REPORT_ADAPTERS.stateOfChargeWithIcon(units, battery),
  },
});

export const CHARGING_STATUS_COLUMN = (
  columnLabels: typeof ASSET_LIST_REPORT_MESSAGES.columnLabels,
  valueLabels: typeof ASSET_LIST_REPORT_MESSAGES.valueLabels
): TableColumnConfig<AssetReportItem> => ({
  name: 'chargingStatus',
  label: columnLabels['asset:tableColumnLabels.batteryChargingStatus'],
  options: {
    sort: false,
    customBodyRender: (status: BatteryChargingStatus) => {
      const icon = status ? CHARGING_STATUS_ICONS[status] : '';
      const display = (
        <Box className={cx('valueStatus', 'chargingStatus', `${status}Label`)}>
          {icon && <>{icon}</>}
          <span>
            {ASSET_REPORT_ADAPTERS.chargingStatus(valueLabels, status)}
          </span>
        </Box>
      );

      return display;
    },
  },
});

export const POWER_TYPE_COLUMN = (
  columnLabels: typeof ASSET_LIST_REPORT_MESSAGES.columnLabels,
  valueLabels: typeof ASSET_LIST_REPORT_MESSAGES.valueLabels
): TableColumnConfig<AssetReportItem> => ({
  name: 'powerType',
  label: columnLabels['asset:power.powerType'],
  options: {
    sort: false,
    customBodyRender: (powerType, tableMeta) => {
      const isNotPluggedIn =
        tableMeta.rowData[CHARGE_STATUS_COLUMN_INDEX] ===
        BatteryChargingStatus.NOT_PLUGGED_IN;
      return ASSET_REPORT_ADAPTERS.powerType(
        valueLabels,
        powerType,
        isNotPluggedIn
      );
    },
  },
});

export const TARGET_SOC_COLUMN = (
  columnLabels: typeof ASSET_LIST_REPORT_MESSAGES.columnLabels
): TableColumnConfig<AssetReportItem> => ({
  name: 'targetSOC',
  label: columnLabels['asset:targetStateOfCharge'],
  options: {
    sort: false,
    customBodyRender: (targetSOC, tableMeta) => {
      return ASSET_REPORT_ADAPTERS.targetSOC(
        tableMeta.rowData[CHARGE_STATUS_COLUMN_INDEX],
        targetSOC
      );
    },
  },
});

export const EST_TIME_COLUMN = (
  columnLabels: typeof ASSET_LIST_REPORT_MESSAGES.columnLabels,
  timeFormat: string,
  locale?: string,
  timeZone?: string
): TableColumnConfig<AssetReportItem> => ({
  name: 'minutesToTargetSOC',
  label: columnLabels['asset:detail.battery.estChargeComplete'],
  options: {
    sort: false,
    customBodyRender: (minutesToTargetSOC, tableMeta) => {
      const duration = minutesToTargetSOC?.value;
      const date = minutesToTargetSOC?.deviceTimestamp;
      return ASSET_REPORT_ADAPTERS.estimatedTimeSummary(
        timeFormat,
        duration,
        locale,
        timeZone,
        date,
        tableMeta.rowData[CHARGE_STATUS_COLUMN_INDEX]
      );
    },
  },
});

export const getChargeStatusReportColumns = (
  columnLabels: typeof ASSET_LIST_REPORT_MESSAGES.columnLabels,
  valueLabels: typeof ASSET_LIST_REPORT_MESSAGES.valueLabels,
  units: UnitsType,
  isParamAvailable: boolean,
  timeFormat: string,
  isChargeStatusFlagEnabled?: boolean,
  locale?: string,
  timeZone?: string
) => [
  ID_COLUMN,
  ORGANIZATION_COLUMN,
  HUB_COLUMN,
  FLEET_COLUMN,
  ASSET_DETAILS_COLUMN(columnLabels, isParamAvailable),
  TYPE_COLUMN(columnLabels, isChargeStatusFlagEnabled),
  LICENSE_PLATE_COLUMN,
  VEHICLE_TYPE_COLUMN,
  LAST_COMMUNICATED_COLUMN(
    columnLabels,
    valueLabels,
    locale,
    isChargeStatusFlagEnabled
  ),
  STATE_OF_CHARGE_COLUMN(columnLabels, units),
  CHARGING_STATUS_COLUMN(columnLabels, valueLabels),
  POWER_TYPE_COLUMN(columnLabels, valueLabels),
  TARGET_SOC_COLUMN(columnLabels),
  EST_TIME_COLUMN(columnLabels, timeFormat, locale, timeZone),
];
