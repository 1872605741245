import { ActionButtonConfig, ActionButtons } from '@brightdrop/bd-ui';
import { useTranslations } from '@brightdrop/localization-client';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { Box, useMediaQuery, useTheme } from '@mui/material';
import { DateTime, Duration } from 'luxon';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import { refreshAuthToken } from '~/common/apis/api.interceptors';
import BDDialog from '~/common/components/BDDialog/BDDialog';
import { TIMEOUT_MODAL_THRESHOLD } from '~/common/configs/auth.config';
import useCustomAuthentication from '~/common/hooks/useCustomAuthentication';
import useInterval from '~/common/hooks/useInterval';
import { AppRoutePaths } from '~/common/models/route.model';

import { useAppDispatch } from '../../../app/store';
import { selectPermissions } from '../../profile/profileSlice.selectors';
import {
  selectAuthDomainHint,
  selectAuthTokenExpiration,
  selectForceLogout,
  selectShortestExpiration,
} from '../authSlice.selectors';
import { buildRedirectPathQuery } from '../utils/location.utils';
import useStyles from './SessionTimeoutDialog.styles';

const MESSAGES = {
  'common:sessionTimeout.title': 'Session Timeout',
  'common:sessionTimeout.message': 'Your session is about to expire.',
  'common:continue': 'Continue',
  'common:sessionTimeout.continueButton': 'Continue Session',
  'common:header.logout': 'Logout',
};

const Countdown = ({
  onCloseModal,
}: {
  onCloseModal: (isSuccess: boolean) => void;
}): JSX.Element => {
  const expiration = useSelector(selectAuthTokenExpiration);
  const { classes } = useStyles();

  const [timeRemaining, setTimeRemaining] = useState<Duration>();

  const tick = useCallback(() => {
    if (expiration !== undefined) {
      const diff = DateTime.fromMillis(expiration).diffNow([
        'minutes',
        'seconds',
      ]);
      if (diff.valueOf() > 0) {
        setTimeRemaining(diff);
      } else {
        onCloseModal(true);
      }
    }
  }, [expiration, setTimeRemaining, onCloseModal]);

  useInterval(tick, 1000);

  return (
    <>
      {timeRemaining && timeRemaining?.valueOf() > 0 && (
        <Box className={classes.countDown}>
          {timeRemaining.toFormat('m:ss')}
        </Box>
      )}
    </>
  );
};

const SessionTimeoutDialogContent = ({
  onCloseModal,
  disableSubmit,
}: {
  onCloseModal: (isSuccess: boolean) => void;
  disableSubmit?: boolean;
}): JSX.Element => {
  const { translations } = useTranslations(MESSAGES);
  const { classes } = useStyles();
  const theme = useTheme();
  const isMobileView = useMediaQuery(theme.breakpoints.down('md'));
  const buttons = useMemo(
    () =>
      [
        {
          displayLabel: translations['common:header.logout'],
          accessibleLabel: translations['common:header.logout'],
          disabled: disableSubmit,
          onClick: () => onCloseModal(true),
        },
        {
          displayLabel: isMobileView
            ? translations['common:continue']
            : translations['common:sessionTimeout.continueButton'],
          accessibleLabel: isMobileView
            ? translations['common:continue']
            : translations['common:sessionTimeout.continueButton'],
          onClick: () => onCloseModal(false),
          disabled: disableSubmit,
          type: 'submit',
        },
      ] as ActionButtonConfig[],
    [translations, disableSubmit, isMobileView, onCloseModal]
  );

  return (
    <Box className={classes.sessionWrapper}>
      <Box display="flex" flexDirection="column" alignItems="center">
        <Box display="flex" flexDirection="row" alignItems="center">
          <Countdown onCloseModal={onCloseModal} />
          <AccessTimeIcon className={classes.timeOutIcon}></AccessTimeIcon>
        </Box>
        <Box mt={2} mb={{ xs: 2, md: 7 }}>
          <Box component="div" className={classes.subTitle}>
            {translations['common:sessionTimeout.message']}
          </Box>
        </Box>
      </Box>

      <ActionButtons buttons={buttons} customStyle={{ textAlign: 'center' }} />
    </Box>
  );
};

const SessionTimeoutDialog = (): JSX.Element => {
  const { translations } = useTranslations(MESSAGES);
  const history = useHistory();
  const dispatch = useAppDispatch();
  const { authenticationType, setToken } = useCustomAuthentication();

  const timeoutRef = useRef<NodeJS.Timeout>();
  const expiration = useSelector(selectShortestExpiration);
  const domainHint = useSelector(selectAuthDomainHint);
  const forceLogout = useSelector(selectForceLogout);

  const userPermissions = useSelector(selectPermissions);
  const [showDialog, setShowDialog] = useState(false);
  const [disableBtn, setDisableBtn] = useState(false);
  const location = useLocation<Location>();
  const [redirectQuery, setRedirectQuery] = useState<string>();

  const redirectToLogout = useCallback(() => {
    history.push({
      pathname: `/auth/${authenticationType}/logout`,
      search: redirectQuery,
    });
    history.go(0);
  }, [authenticationType, history, redirectQuery]);

  /**
   * Only start the timeout if user permissions have
   * been fetched to ensure timeout isn't set with an
   * already-expired token from a previous stale session.
   *
   * Permission refreshes triggering this effect are not
   * a problem, as the expiration will be unchanged and will
   * be used to re-start the timeout at the same place.
   */
  useEffect(() => {
    if (!!userPermissions && expiration) {
      if (timeoutRef.current !== undefined) {
        clearTimeout(timeoutRef.current);
      }
      const diff = DateTime.fromMillis(expiration).diff(DateTime.now());

      timeoutRef.current = setTimeout(() => {
        timeoutRef.current = undefined;
        refreshAuthToken({
          authenticationType,
          dispatch,
          forceLoginOnSilentFailure: false,
          domainHint,
        })
          .then((accessTokenResponse) => {
            if (
              accessTokenResponse?.accessToken &&
              accessTokenResponse?.expiresOn
            ) {
              setToken(
                accessTokenResponse.accessToken,
                accessTokenResponse.expiresOn
              );
            }
          })
          .catch((error) => {
            //Fail renew token silently, some systems in stand by mode are not able to logout because they are offline but the browser thread is executing
            console.error('BrightDrop Web - renew auth token failure', error);
          });
      }, Math.max(diff.minus({ seconds: TIMEOUT_MODAL_THRESHOLD }).toMillis(), 0));
    }
  }, [
    authenticationType,
    dispatch,
    domainHint,
    expiration,
    setToken,
    userPermissions,
  ]);

  useEffect(() => {
    if (forceLogout) {
      redirectToLogout();
    }
  }, [forceLogout, redirectToLogout]);

  useEffect(() => {
    if (location) {
      setRedirectQuery(buildRedirectPathQuery(location));
    }
  }, [location]);

  /*
  Cleanup only
   */
  useEffect(() => {
    return () => {
      if (timeoutRef.current !== undefined) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, []);

  const handleTimeout = useCallback(
    (didTimeOut: boolean) => {
      if (didTimeOut) {
        setShowDialog(false);
        history.push(AppRoutePaths.LOGOUT);
      } else {
        setDisableBtn(true);
        refreshAuthToken({ authenticationType, dispatch, domainHint })
          .catch((error) => {
            console.error('BrightDrop Web - Auth Refresh Failure', error);
            redirectToLogout();
          })
          .finally(() => {
            setDisableBtn(false);
            setShowDialog(false);
          });
      }
    },
    [authenticationType, dispatch, domainHint, history, redirectToLogout]
  );

  return (
    <BDDialog
      openModal={showDialog}
      title={translations['common:sessionTimeout.title']}
      onCloseModal={handleTimeout}
      component={SessionTimeoutDialogContent}
      disableSubmit={disableBtn}
      disableClose
    />
  );
};

export default SessionTimeoutDialog;
