import { NavBarConfig } from '@gm-commercial/navbar-model';
import { Profile } from '@gm-commercial/profile-model';

import HomeIcon from '~/assets/icons/homeIcon.svg?react';
import AssetsIcon from '~/assets/icons/new/assetsIcon.svg?react';
import CarIcon from '~/assets/icons/new/carIcon.svg?react';
import CoinsHand from '~/assets/icons/new/coinsHand.svg?react';
import { FeatureFlagValueType } from '~/common/models/featureFlags.model';
import {
  FLEET_HOME,
  FLEET_VEHICLE_LIST,
  FLEET_VEHICLE_ORDERS_LIST,
} from '~/common/models/pages/fleetPages.model';
import { RouteName } from '~/common/models/route.model';
import { findRoute, makeRoutePath } from '~/common/utils/route/route.utils';

import { NAV_DRAWER_MESSAGES } from '../GlobalNavDrawer.messages';

export const getFleetNavBarConfig = ({
  messages,
  profileParams,
  findFlagScope,
}: {
  messages: typeof NAV_DRAWER_MESSAGES.fleetMenu;
  profileParams: Profile['permissionsContextIds'];
  findFlagScope?: (flagName: string) => FeatureFlagValueType | undefined;
}): NavBarConfig => {
  const context = {
    fleetsId: profileParams?.fleetsId || undefined,
    hubsId: profileParams?.hubsId || undefined,
    organizationsId: profileParams?.organizationsId || undefined,
  };

  return {
    homePath: '/',
    items: [
      {
        label: messages['common:home'],
        icon: <HomeIcon className="noFill" />,
        testId: 'fleet-home',
        path: makeRoutePath(FLEET_HOME, context, true, true),
      },
      {
        label: messages['common:vehicles'],
        icon: <CarIcon className="noFill" />,
        testId: 'fleet-vehicles',
        path: makeRoutePath(FLEET_VEHICLE_LIST, context, true, true),
        children: [
          {
            label: messages['common:list'],
            testId: 'fleet-vehicle-list',
            path: makeRoutePath(FLEET_VEHICLE_LIST, context, true, true),
          },
          {
            label: messages['order:tableTitle'],
            testId: 'fleet-vehicle-orders-list',
            path: makeRoutePath(FLEET_VEHICLE_ORDERS_LIST, context, true, true),
          },
        ],
      },
      {
        label: messages['common:summary.assetReport_plural'],
        icon: <AssetsIcon className="noFill" />,
        testId: 'fleet-assets',
        path: makeRoutePath(
          findRoute(RouteName.CS_PLATFORM_PERIPHERALS_LIST),
          context,
          true,
          true,
          findFlagScope
        ),
        children: [
          {
            label: messages['peripheral:assetsList'],
            testId: 'fleet-assets-list',
            path: makeRoutePath(
              findRoute(RouteName.CS_PLATFORM_PERIPHERALS_LIST),
              context,
              true,
              true,
              findFlagScope
            ),
          },
        ],
      },
      {
        label: messages['purchasePrograms:purchasePrograms'],
        testId: 'purchase-programs',
        icon: <CoinsHand />,
        path: makeRoutePath(
          findRoute(RouteName.CS_PLATFORM_FLEET_PURCHASE_PROGRAMS),
          context,
          true,
          true,
          findFlagScope
        ),
      },
    ],
  };
};
