import { OperationStatus } from './apis/apiResponse.model';
import { OrganizationOwnershipStatus, ProvisioningStatus } from './asset.model';
import { ConnectivityStatusType, Entity } from './common.model';

export interface VehicleSummary {
  vin: string;
  make: string;
  model: string;
  year: string;
}

export interface ConnectivityStatusDetailItem extends Entity {
  summary: VehicleSummary;
  lastUpdated: string;
  actionRequired?: ConnectivityStatusActionRequiredType;
}

export interface ConnectivityStatusConfig {
  name: string;
  type: ConnectivityStatusType;
  count: number;
  description: string;
  color: string;
}

export interface ConnectivityChartConfig {
  labels: string[];
  data: number[];
  colors: string[];
  total: number;
}

export interface ConnectivityChartTooltip {
  opacity: number;
  top: number;
  left: number;
  title: string;
  values: Array<string>;
  colors: Array<string>;
}

export enum ConnectivityStatusActionRequiredType {
  TURN_ON_VEHICLE = 'turnOnVehicle',
  PURCHASE_ADAPTOR = 'purchaseAdaptor',
  PUSH_ONSTAR_BUTTON = 'pushOnstarButton',
}

export interface ApiConnectivityStatusSummary {
  organizationId: string;
  totalVehicleCount: number;
  items: {
    organizationPrimaryOwnershipStatus: OrganizationOwnershipStatus | null;
    provisioningState: ProvisioningStatus | null;
  }[];
}

export interface ConnectivityStatusSummary {
  organizationId: string;
  totalVehicleCount: number;
  connectivityStatuses: {
    pendingOnboarding: number;
    onboarded: number;
    pendingConnectivity: number;
    connected: number;
  };
}

export interface CSDashboardSummary {
  summaries: DashboardSessionConfig;
}

export interface DashboardSummarySession {
  connectivityStatusSummary?: ConnectivityStatusSummary;
  selectedConnectivityStatus?: ConnectivityStatusType;
  diagnosticsSummary?: DiagnosticSummary;
  recallSummary?: RecallSummary;
  oilLifeSummary?: OilLifeSummary;
  chargeLevelSummary?: ChargeLevelSummary;
}

export enum DashboardSessionViewType {
  MAIN = 'Main Dashboard View',
}

export type DashboardSessionConfig = {
  [key: string]: Partial<DashboardSummarySession>;
};

export enum DashboardRequestType {
  GET_DASHBOARD_CONNECTIVITY_STATUS_SUMMARY = 'Get Dashboard Connectivity Status Summary',
  GET_DASHBOARD_DIAGNOSTICS_SUMMARY = 'Get Dashboard Diagnostics Summary',
  GET_DASHBOARD_CHARGE_SUMMARY = 'Get Dashboard Charge Summary',
  GET_DASHBOARD_RECALL_SUMMARY = 'Get Dashboard Recall Summary',
  GET_DASHBOARD_OIL_LIFE_SUMMARY = 'Get Dashboard Oil Life Summary',
}

export interface CSDashboardState {
  sessionConfigs: DashboardSessionConfig;
  operations: {
    [key in DashboardRequestType]?: OperationStatus;
  };
}

export interface DiagnosticSummary {
  organizationId: string;
  totalVehicleCount: number;
  diagnostics: {
    criticalCount: number;
    warningCount: number;
    totalCount: number;
  };
}

export interface RecallSummary {
  organizationId: string;
  activeFieldActionVehicleCount: number | null;
}

export interface OilLifeSummary {
  organizationId: string;
  fleetId: string;
  totalIceCount: number;
  oilLifeStatusSummary: {
    criticalCount: number;
    lowCount: number;
    goodCount: number;
  };
}
export interface ChargeLevelSummary {
  organizationId: string;
  fleetId: string;
  totalEVCount: number;
  chargeLevelSummary: {
    charging: number;
    criticalCount: number;
    lowCount: number;
    goodCount: number;
    highCount: number;
  };
}
