import { Asset } from './asset.model';
import { Entity, NonNullablePick } from './common.model';

export enum ScheduledDateRange {
  TODAY = 'today',
  TOMORROW = 'tomorrow',
  YESTERDAY = 'yesterday',
  THIS_WEEK = 'this_week',
  LAST_WEEK = 'last_week',
}

export enum WorkPlanStatus {
  PENDING = 'pending',
  STARTED = 'started',
  ABORTED = 'aborted',
  FINISHED = 'finished',
}

export enum TaskType {
  STOP = 'STOP',
  DEPOT = 'DEPOT',
}

export enum InstructionType {
  NOTE = 'NOTE',
  BEWARE_OF_DOG = 'beware_of_dog',
  GATE_CODE = 'gate_code',
}

export enum ActionType {
  DROPOFF = 'DROPOFF',
  PICKUP = 'PICKUP',
}

export enum RootWorkPlanRequestPayload {
  STATUS = 'status',
  NAME = 'name',
  FILE_NAME = 'fileName',
  HUB_ID = 'hubId',
  FLEET_ID = 'fleetId',
  SCHEDULED_BEFORE_DATE = 'scheduledBeforeDate',
  SCHEDULED_AFTER_DATE = 'scheduledAfterDate',
  CREATED_AT = 'createdAt',
  ORG_ID = 'organizationId',
}

export interface Address {
  line1: string;
  line2?: string | null;
  city: string;
  state: string;
  country: string;
}

export interface LatLon {
  lat: string;
  lon: string;
}

export interface Operator {
  id: string;
  displayName: string;
}

export interface Contact {
  name: string;
  phone?: string | null;
  note?: string | null;
}

export interface Instruction {
  type: InstructionType;
  value?: string | number | boolean;
}

export interface Action {
  type: ActionType;
}

export interface DropOffAction extends Action {
  parcelCount: number;
}

export interface PickUpAction extends Action {
  parcelCount: number;
}

export interface Task {
  type: TaskType;
}

export interface StopTask extends Task {
  stopIndex: number;
  address: string;
  location: LatLon;
  contact: Contact;
  instructions: Array<Instruction>;
  actions?: Array<Action>;
}

export interface DepotTask extends Task {
  address: string;
  location: LatLon;
  name?: string | null;
  space?: string | null;
}

export interface RootWorkPlan extends Entity {
  status: WorkPlanStatus;
  organizationId: string;
  hubId: string;
  fleetId: string;
  scheduledDate: string;
  createdAt: string;
  tasks: Array<Task>;
  deleted: boolean;
  operator?: Operator | null;
  vehicle?: Asset | null;
  assetId?: string | null;
  fileName?: string | null;
  stopCount?: number;
}

export type ApiWorkPlanFormFields = {
  name: string;
  createdByJobId: string;
  scheduledDate: string;
  organizationId: string;
  hubId: string;
  fleetId: string;
  tasks: Partial<StopTask | DepotTask>[];
};

export interface ApiWorkPlanDetail extends Entity {
  organizationId: string;
  scheduledDate: string;
  status: WorkPlanStatus;
  deleted: boolean;
  createdAt: string;
  fleetId: string;
  hubId: string;
  tasks: Array<Task>;
  asset?: Asset & {
    hardwareId?: string;
  };
  createdByJobId?: string;
  deletedAt?: string | null;
  fileName?: string | null;
  fileUrl?: string;
  operator?: Operator | null;
  stopCount?: number;
  travelMode?: string;
}

export type WorkPlanSnackbar = 'create' | 'delete' | boolean;

export type WorkPlanFormFields = {
  name: string;
  scheduledDate: string;
  organizationsId: string;
  hubsId: string;
  fleetsId: string;
  tasks: Partial<StopTask>[];
  depotTask: Partial<DepotTask>;
};

export type ValidWorkPlanForm = NonNullablePick<
  WorkPlanFormFields,
  'name' | 'scheduledDate' | 'tasks'
> &
  Pick<
    WorkPlanFormFields,
    'organizationsId' | 'hubsId' | 'fleetsId' | 'depotTask'
  >;

export enum DeviceType {
  // vehicle logins
  VEHICLE = 'vehicle',
  // fleet key assignment
  FLEET_KEY = 'fleetKey',
}

export type ApiFleetKeyAssociation = {
  id: string;
  deviceId: string;
  deviceType: DeviceType;
  associationDateTime: string;
};
