import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import merge from 'deepmerge';
import { WritableDraft } from 'immer/dist/types/types-external';

import { SessionConfigType } from '~/common/constants/common.constant';

import { buildEditWorkPlanAssignedOperatorActivityExtraReducers } from './state/editWorkPlanAssignedOperatorActivity';
import { buildEditWorkPlanAssignedVehicleActivityExtraReducers } from './state/editWorkPlanAssignedVehicleActivity';
import { buildGetDriversExtraReducers } from './state/getOperators';
import { buildGetRootWorkPlansExtraReducers } from './state/getRootWorkPlans';
import { buildGetWorkPlansDetailsExtraReducers } from './state/getWorkPlansDetails';
import { buildUpdateWorkPlanAssignedOperatorExtraReducers } from './state/updateWorkPlanAssignedOperator';
import {
  SetPageNumberWorkPlanListSessionPayload,
  SetRowsPerPageWorkPlanListSessionPayload,
  SetScheduledDateRangeWorkPlanListSessionPayload,
  SetSearchCriteriaWorkPlanListSessionPayload,
  SetSnackbarWorkPlanListSessionPayload,
  UpdateWorkPlanListEntitiesSessionPayload,
  UpdateWorkPlanListFilterSessionPayload,
  WorkPlansState,
} from './workPlansSlice.model';

/**
  Core redux work plans initial state
*/
export const INITIAL_WORK_PLANS_STATE: WorkPlansState = {
  operatorEntitiesSessions: {
    [SessionConfigType.LIST_VIEW]: {},
  },
  workPlanEntitiesSessions: {
    [SessionConfigType.LIST_VIEW]: {},
  },
  workPlanEntitySession: {
    [SessionConfigType.DETAIL_VIEW]: {},
  },
  filterSessions: {
    [SessionConfigType.LIST_VIEW]: {},
  },
  editAssignedOperatorActivitySessions: {
    [SessionConfigType.LIST_VIEW]: {},
  },
  editAssignedVehicleActivitySessions: {
    [SessionConfigType.LIST_VIEW]: {},
  },
};

function applyPayloadToWorkPlanListFilterSession(
  state: WritableDraft<WorkPlansState>,
  action: PayloadAction<UpdateWorkPlanListFilterSessionPayload<object>>
) {
  const payload = action.payload || {};
  Object.keys(payload).forEach((sessionId) => {
    state.filterSessions = merge(
      state.filterSessions,
      {
        [SessionConfigType.LIST_VIEW]: {
          [sessionId]: payload[sessionId] && {
            ...payload[sessionId],
          },
        },
      },
      {
        arrayMerge: (_, sourceArray) => sourceArray,
      }
    );
  });
}

function setSearchCriteriaWorkPlansListSessionReducer(
  state: WritableDraft<WorkPlansState>,
  action: PayloadAction<SetSearchCriteriaWorkPlanListSessionPayload>
) {
  applyPayloadToWorkPlanListFilterSession(state, action);
}

function setScheduledDateRangeWorkPlansListSessionReducer(
  state: WritableDraft<WorkPlansState>,
  action: PayloadAction<SetScheduledDateRangeWorkPlanListSessionPayload>
) {
  applyPayloadToWorkPlanListFilterSession(state, action);
}

function setPageNumberWorkPlansListSessionReducer(
  state: WritableDraft<WorkPlansState>,
  action: PayloadAction<SetPageNumberWorkPlanListSessionPayload>
) {
  applyPayloadToWorkPlanListFilterSession(state, action);
}

function setRowsPerPageWorkPlansListSessionReducer(
  state: WritableDraft<WorkPlansState>,
  action: PayloadAction<SetRowsPerPageWorkPlanListSessionPayload>
) {
  applyPayloadToWorkPlanListFilterSession(state, action);
}

function applyPayloadToWorkPlanListEntitiesSessions(
  state: WritableDraft<WorkPlansState>,
  action: PayloadAction<UpdateWorkPlanListEntitiesSessionPayload<object>>
) {
  const payload = action.payload || {};
  Object.keys(payload).forEach((sessionId) => {
    state.workPlanEntitiesSessions = merge(
      state.workPlanEntitiesSessions,
      {
        [SessionConfigType.LIST_VIEW]: {
          [sessionId]: payload[sessionId] && {
            ...payload[sessionId],
          },
        },
      },
      {
        arrayMerge: (_, sourceArray) => sourceArray,
      }
    );
  });
}

function setSnackbarWorkPlansListSessionReducer(
  state: WritableDraft<WorkPlansState>,
  action: PayloadAction<SetSnackbarWorkPlanListSessionPayload>
) {
  applyPayloadToWorkPlanListEntitiesSessions(state, action);
}

export const workPlansSlice = createSlice({
  name: 'workPlans',
  initialState: INITIAL_WORK_PLANS_STATE,
  reducers: {
    setWorkPlansListSearchCriteria:
      setSearchCriteriaWorkPlansListSessionReducer,
    setWorkPlanListScheduledDateRange:
      setScheduledDateRangeWorkPlansListSessionReducer,
    setWorkPlansListPageNumber: setPageNumberWorkPlansListSessionReducer,
    setWorkPlansListRowsPerPage: setRowsPerPageWorkPlansListSessionReducer,
    setWorkPlansListSnackbar: setSnackbarWorkPlansListSessionReducer,
  },
  extraReducers: (builder) => {
    buildGetRootWorkPlansExtraReducers(builder);
    buildGetDriversExtraReducers(builder);
    buildGetWorkPlansDetailsExtraReducers(builder);
    buildUpdateWorkPlanAssignedOperatorExtraReducers(builder);
    buildEditWorkPlanAssignedOperatorActivityExtraReducers(builder);
    buildEditWorkPlanAssignedVehicleActivityExtraReducers(builder);
  },
});

export const {
  setWorkPlansListSearchCriteria,
  setWorkPlanListScheduledDateRange,
  setWorkPlansListPageNumber,
  setWorkPlansListRowsPerPage,
  setWorkPlansListSnackbar,
} = workPlansSlice.actions;

export const workPlansReducer = workPlansSlice.reducer;
