import Box from '@mui/material/Box';
import Button, { ButtonTypeMap } from '@mui/material/Button';
import { CSSProperties } from 'react';

export interface ActionButtonConfig {
  displayLabel: string | (() => string);
  accessibleLabel: string | (() => string);
  onClick: () => void;
  disabled?: boolean | (() => boolean);
  type?: string;
  variant?: ButtonTypeMap['props']['variant'];
  color?: ButtonTypeMap['props']['color'];
  startIcon?: React.ReactNode;
  endIcon?: React.ReactNode;
  id?: string;
}

interface ActionButtonsProps {
  buttons: ActionButtonConfig[];
  customStyle?: CSSProperties;
}

const ActionButtons = ({
  buttons,
  customStyle,
}: ActionButtonsProps): JSX.Element => {
  return (
    <Box className={'actionButtons'} style={customStyle}>
      {buttons.map((config, index) => {
        let { disabled, displayLabel, accessibleLabel } = config;
        if (disabled !== undefined) {
          disabled = typeof disabled === 'boolean' ? disabled : disabled();
        }
        displayLabel =
          typeof displayLabel === 'string' ? displayLabel : displayLabel();
        accessibleLabel =
          typeof accessibleLabel === 'string'
            ? accessibleLabel
            : accessibleLabel();
        return (
          <Button
            size="large"
            startIcon={config.startIcon}
            endIcon={config.endIcon}
            key={index}
            aria-label={accessibleLabel}
            variant={config.variant || 'contained'}
            color={config.color || 'primary'}
            onClick={config.onClick}
            disabled={disabled}
            id={config.id}
            data-testid={config.id}
          >
            {displayLabel}
          </Button>
        );
      })}
    </Box>
  );
};

export default ActionButtons;
