import { emptyApi as api } from './emptyApi';

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    updateSubscriptionByIdVersion2: build.mutation<
      UpdateSubscriptionByIdVersion2ApiResponse,
      UpdateSubscriptionByIdVersion2ApiArg
    >({
      query: (queryArg) => ({
        url: `/2022-07-01/subscriptions/me/${queryArg.subscriptionId}`,
        method: 'PUT',
        body: queryArg.subscriptionUpdateDto,
      }),
    }),
    getAlerts2ForaUser: build.mutation<
      GetAlerts2ForaUserApiResponse,
      GetAlerts2ForaUserApiArg
    >({
      query: (queryArg) => ({
        url: `/2023-12-01/me`,
        method: 'POST',
        body: queryArg.bdPageRequest,
      }),
    }),
    addSubscriptionsToUser: build.mutation<
      AddSubscriptionsToUserApiResponse,
      AddSubscriptionsToUserApiArg
    >({
      query: (queryArg) => ({
        url: `/2021-11-15/subscriptions`,
        method: 'POST',
        body: queryArg.subscriptionDto,
      }),
    }),
    getAllUserSubscriptions: build.query<
      GetAllUserSubscriptionsApiResponse,
      GetAllUserSubscriptionsApiArg
    >({
      query: (queryArg) => ({
        url: `/2021-11-15/subscriptions/me`,
        method: 'POST',
        body: queryArg.bdPageRequest,
      }),
    }),
    getRegistrationsForUser: build.query<
      GetRegistrationsForUserApiResponse,
      GetRegistrationsForUserApiArg
    >({
      query: (queryArg) => ({
        url: `/2021-11-15/registrations`,
        method: 'POST',
        body: queryArg.bdPageRequest,
      }),
    }),
    post20211115Me: build.mutation<
      Post20211115MeApiResponse,
      Post20211115MeApiArg
    >({
      query: (queryArg) => ({
        url: `/2021-11-15/me`,
        method: 'POST',
        body: queryArg.bdPageRequest,
      }),
    }),
    updateAlertToUnRead: build.mutation<
      UpdateAlertToUnReadApiResponse,
      UpdateAlertToUnReadApiArg
    >({
      query: (queryArg) => ({
        url: `/2021-11-15/me/${queryArg.id}/unread`,
        method: 'POST',
      }),
    }),
    updateAlertToRead: build.mutation<
      UpdateAlertToReadApiResponse,
      UpdateAlertToReadApiArg
    >({
      query: (queryArg) => ({
        url: `/2021-11-15/me/${queryArg.id}/read`,
        method: 'POST',
      }),
    }),
    updateAllAlertsToRead: build.mutation<
      UpdateAllAlertsToReadApiResponse,
      UpdateAllAlertsToReadApiArg
    >({
      query: () => ({ url: `/2021-11-15/me/read`, method: 'POST' }),
    }),
    getAlertById: build.query<GetAlertByIdApiResponse, GetAlertByIdApiArg>({
      query: (queryArg) => ({ url: `/2021-11-15/me/${queryArg.id}` }),
    }),
    deleteSubscription: build.mutation<
      DeleteSubscriptionApiResponse,
      DeleteSubscriptionApiArg
    >({
      query: (queryArg) => ({
        url: `/2021-11-15/subscriptions/me/${queryArg.subscriptionId}`,
        method: 'DELETE',
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as alertsApi };
export type UpdateSubscriptionByIdVersion2ApiResponse =
  /** status 200 OK */ BdResponseCommonSubscriptionDto;
export type UpdateSubscriptionByIdVersion2ApiArg = {
  /** Unique identifying subscription ID. */
  subscriptionId: string;
  subscriptionUpdateDto: SubscriptionUpdateDto;
};
export type GetAlerts2ForaUserApiResponse =
  /** status 200 OK */ BdResponseCommonBdPageAlertDto;
export type GetAlerts2ForaUserApiArg = {
  bdPageRequest: BdPageRequest;
};
export type AddSubscriptionsToUserApiResponse =
  /** status 200 OK */ BdResponseCommonSubscriptionDto;
export type AddSubscriptionsToUserApiArg = {
  subscriptionDto: SubscriptionDto;
};
export type GetAllUserSubscriptionsApiResponse =
  /** status 200 OK */ BdResponseCommonBdSubscriptionPage;
export type GetAllUserSubscriptionsApiArg = {
  bdPageRequest: BdPageRequest;
};
export type GetRegistrationsForUserApiResponse =
  /** status 200 OK */ BdResponseCommonBdRegistrationPage;
export type GetRegistrationsForUserApiArg = {
  bdPageRequest: BdPageRequest;
};
export type Post20211115MeApiResponse =
  /** status 200 OK */ BdResponseCommonBdAlertPage;
export type Post20211115MeApiArg = {
  bdPageRequest: BdPageRequest;
};
export type UpdateAlertToUnReadApiResponse =
  /** status 200 OK */ BdResponseCommonAlert;
export type UpdateAlertToUnReadApiArg = {
  /** Id of alert to update */
  id: string;
};
export type UpdateAlertToReadApiResponse =
  /** status 200 OK */ BdResponseCommonAlert;
export type UpdateAlertToReadApiArg = {
  /** Id of alert to update */
  id: string;
};
export type UpdateAllAlertsToReadApiResponse =
  /** status 200 Successfully marked all alerts as read. */ BdResponseCommon;
export type UpdateAllAlertsToReadApiArg = void;
export type GetAlertByIdApiResponse =
  /** status 200 OK */ BdResponseCommonAlert;
export type GetAlertByIdApiArg = {
  /** Unique generated identifying ID. */
  id: string;
};
export type DeleteSubscriptionApiResponse =
  /** status 200 Successfully deleted subscription. */ BdResponseCommon;
export type DeleteSubscriptionApiArg = {
  /** A unique identifying subscription ID. */
  subscriptionId: string;
};
export type RegistrationItem = {
  category: string;
  event: string;
};
export type SubscriptionDto = {
  /** Unique identifying subscription ID. */
  id?: string;
  organizationId: string;
  /** List of registrations for this subscription */
  registrations: RegistrationItem[];
  /** Whether subscription is active. */
  active: boolean;
  /** Threshold For Noisy Messages In Minutes */
  noiseThresholdInMinutes?: number;
  profileId?: string;
  /** The notification receipt preferences */
  receiptPreferences?: ('EMAIL' | 'FEED')[];
  /** The notification preferred name */
  preferredName?: string;
  /** List of assetIds for this subscription */
  assets?: string[];
};
export type BdResponseError = {
  errorType: 'GENERIC' | 'VALIDATION';
  message: string;
  errorCode: string;
};
export type BdResponseCommonSubscriptionDto = {
  result?: SubscriptionDto;
  targetUrl: string;
  __bd: boolean;
  success: boolean;
  unauthorizedRequest: boolean;
  errors: BdResponseError[];
};
export type BdResponseCommonObject = {
  __bd: boolean;
  targetUrl: string;
  result?: object;
  success: boolean;
  unauthorizedRequest: boolean;
  errors: BdResponseError[];
};
export type SubscriptionUpdateDto = {
  /** Whether subscription is active. */
  active: boolean;
  /** Threshold For Noisy Messages In Minutes */
  noiseThresholdInMinutes?: number;
  /** The notification receipt preferences */
  receiptPreferences?: ('EMAIL' | 'FEED')[];
  /** The notification preferred name */
  preferredName?: string;
  /** List of registrations for this subscription */
  registrations?: RegistrationItem[];
  /** List of assetIds for this subscription */
  assets?: string[];
};
export type Event = {
  sourceId: string;
  sourceType: string;
  sourceInstance?: object;
  applicationId: string;
  category: string;
  event: string;
  eventTimestamp: string;
  contentVariableMap?: {
    [key: string]: object;
  };
  returnUrl?: string;
};
export type AlertDto = {
  /** Unique generated identifying ID. */
  id?: string;
  profileId: string;
  /** ID of base subscription for alert. */
  subscriptionId: string;
  event: Event;
  organizationId: string;
  createDateTime: string;
};
export type BdPageAlertDto = {
  items: AlertDto[];
  total_items?: number;
  token?: string;
};
export type BdResponseCommonBdPageAlertDto = {
  result?: BdPageAlertDto;
  targetUrl: string;
  __bd: boolean;
  success: boolean;
  unauthorizedRequest: boolean;
  errors: BdResponseError[];
};
export type SortObject = {
  empty?: boolean;
  sorted?: boolean;
  unsorted?: boolean;
};
export type BdQuery = {
  qualifier:
    | 'ALL'
    | 'IS_EQUAL'
    | 'OR'
    | 'AND'
    | 'NOT'
    | 'BEFORE'
    | 'AFTER'
    | 'IN'
    | 'NOT_IN'
    | 'IS_NULL'
    | 'IS_NOT_NULL'
    | 'LESS_THAN'
    | 'LESS_THAN_EQUAL'
    | 'GREATER_THAN'
    | 'GREATER_THAN_EQUAL'
    | 'CONTAINING'
    | 'ENDS_WITH'
    | 'STARTS_WITH'
    | 'TRUE'
    | 'FALSE'
    | 'BETWEEN'
    | 'ARRAY_CONTAINS'
    | 'STRING_EQUALS'
    | 'NOT_CONTAINING';
  field: string;
  values:
    | string[]
    | {
        [key: string]: string;
      }[];
  leftQuery?: BdQuery;
  rightQuery?: BdQuery;
};
export type BdPageRequest = {
  /** Page index. Pages are 0-index based. */
  page: number;
  /** Number of elements per page. */
  size: number;
  direct?: boolean;
  /** Sort parameters. */
  sort?: SortObject[];
  token?: string;
  query?: BdQuery;
};
export type Subscription = {
  id?: string;
  organizationId?: string;
  profileId?: string;
  registrations: RegistrationItem[];
  active: boolean;
  noiseThresholdInMinutes?: number;
  createDateTime?: string;
  updateDateTime?: string;
  receiptPreferences?: ('EMAIL' | 'FEED')[];
  preferredName?: string;
  assets?: string[];
};
export type PageableObject = {
  offset?: number;
  sort?: SortObject;
  pageNumber?: number;
  pageSize?: number;
  paged?: boolean;
  unpaged?: boolean;
};
export type PageSubscription = {
  totalPages?: number;
  totalElements?: number;
  first?: boolean;
  last?: boolean;
  size?: number;
  content?: Subscription[];
  number?: number;
  sort?: SortObject;
  numberOfElements?: number;
  pageable?: PageableObject;
  empty?: boolean;
};
export type BdSubscriptionPage = {
  page?: PageSubscription;
  items: SubscriptionDto[];
  total_items?: number;
  token?: string;
};
export type BdResponseCommonBdSubscriptionPage = {
  result?: BdSubscriptionPage;
  targetUrl: string;
  __bd: boolean;
  success: boolean;
  unauthorizedRequest: boolean;
  errors: BdResponseError[];
};
export type EmailSettings = {
  templates?: {
    [key: string]: string;
  };
};
export type ContentSettings = {
  email?: EmailSettings;
};
export type Registration = {
  id?: string;
  applicationId?: string;
  sourceType: string;
  category: string;
  event: string;
  createDateTime?: string;
  timestamp?: string;
  contentSettings?: ContentSettings;
};
export type PageRegistration = {
  totalPages?: number;
  totalElements?: number;
  first?: boolean;
  last?: boolean;
  size?: number;
  content?: Registration[];
  number?: number;
  sort?: SortObject;
  numberOfElements?: number;
  pageable?: PageableObject;
  empty?: boolean;
};
export type RegistrationDto = {
  id?: string;
  applicationId?: string;
  /** Source type for the registration. */
  sourceType: string;
  /** Category type for the registration. */
  category: string;
  /** Event type for the registration. */
  event: string;
  contentSettings?: ContentSettings;
};
export type BdRegistrationPage = {
  page?: PageRegistration;
  items: RegistrationDto[];
  total_items?: number;
  token?: string;
};
export type BdResponseCommonBdRegistrationPage = {
  result?: BdRegistrationPage;
  targetUrl: string;
  __bd: boolean;
  success: boolean;
  unauthorizedRequest: boolean;
  errors: BdResponseError[];
};
export type Alert = {
  id?: string;
  profileId: string;
  subscriptionId: string;
  event: Event;
  organizationId: string;
  createDateTime: string;
  discriminator: string;
  receiptPreferences?: ('EMAIL' | 'FEED')[];
  read: boolean;
  sourceType: string;
  eventName: string;
  eventTimestamp: string;
  sourceId: string;
};
export type PageAlert = {
  totalPages?: number;
  totalElements?: number;
  first?: boolean;
  last?: boolean;
  size?: number;
  content?: Alert[];
  number?: number;
  sort?: SortObject;
  numberOfElements?: number;
  pageable?: PageableObject;
  empty?: boolean;
};
export type BdAlertPage = {
  page?: PageAlert;
  items: AlertDto[];
  total_items?: number;
  token?: string;
};
export type BdResponseCommonBdAlertPage = {
  result?: BdAlertPage;
  targetUrl: string;
  __bd: boolean;
  success: boolean;
  unauthorizedRequest: boolean;
  errors: BdResponseError[];
};
export type BdResponseCommonAlert = {
  result?: Alert;
  targetUrl: string;
  __bd: boolean;
  success: boolean;
  unauthorizedRequest: boolean;
  errors: BdResponseError[];
};
export type BdResponseCommon = {
  result?: object;
  targetUrl: string;
  __bd: boolean;
  success: boolean;
  unauthorizedRequest: boolean;
  errors: BdResponseError[];
};
export const {
  useUpdateSubscriptionByIdVersion2Mutation,
  useGetAlerts2ForaUserMutation,
  useAddSubscriptionsToUserMutation,
  useGetAllUserSubscriptionsQuery,
  useGetRegistrationsForUserQuery,
  usePost20211115MeMutation,
  useUpdateAlertToUnReadMutation,
  useUpdateAlertToReadMutation,
  useUpdateAllAlertsToReadMutation,
  useGetAlertByIdQuery,
  useDeleteSubscriptionMutation,
} = injectedRtkApi;
