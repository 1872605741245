import {
  AssetConnectivityStatus,
  AssetServiceStatus,
  BatteryRange,
  DiagnosticSeverity,
  DiagnosticState,
  DistanceUnit,
  OrganizationOwnershipStatus,
  ProvisioningStatus,
} from '~/common/models/asset.model';
import {
  ApiDiagnostic,
  AssetReportItem,
  BatteryChargingStatus,
  Diagnostic,
  DiagnosticDTCType,
  LastCommunicated,
  PowerType,
  ReadyStatus,
  ReportDiagnosticState,
  TiresStatus,
  VehicleDetailsType,
} from '~/common/models/asset-report.model';
import { Entity } from '~/common/models/common.model';
import { ApiRecallsItem } from '~/common/models/recalls.model';
import {
  MAX_VALID_SOC_VALUE,
  MIN_VALID_SOC_VALUE,
} from '~/common/utils/battery.utils';

import {
  mapApiResponseToType,
  mapConnectivityStatusType,
} from '../../mappers/asset.mappers';
import {
  MAX_VALID_FUEL_VALUE,
  MIN_VALID_FUEL_VALUE,
} from '../AssetReportView.constant';

export type ApiReadinessItem = Entity & {
  type: { model: string; version: string };
  organization?: Partial<Entity>;
  hub?: Partial<Entity>;
  fleet?: Partial<Entity>;
  ready?: ReadyStatus;
  faultCount?: number;
  battery?: Partial<{
    state: ReportDiagnosticState;
    soc: number;
    chargeState: BatteryChargingStatus;
    estimatedRangedInKM: number;
  }>;
  tires?: ReportDiagnosticState;
  brakeFluid?: ReportDiagnosticState;
  washerFluid?: ReportDiagnosticState;
  connectivity?: AssetConnectivityStatus;
  lastUpdated?: string;
  safetyCompliant?: boolean;
  milState?: ReportDiagnosticState;
  diagnosticState?: ReportDiagnosticState | null;
  availabilityState?: AssetServiceStatus;
  fuelLevel?: Partial<{
    state: ReportDiagnosticState;
    fuelLevel: number;
  }>;
  licensePlate?: string;
  oilLife?: Partial<{
    state: ReportDiagnosticState;
    oilLife: number;
  }>;
  brakePadFront?: Partial<{
    state: ReportDiagnosticState;
    brakePadLife: number;
  }>;
  brakePadRear?: Partial<{
    state: ReportDiagnosticState;
    brakePadLife: number;
  }>;
  engineAirFilter?: Partial<{
    state: ReportDiagnosticState;
    engineAirFilter: number;
  }>;
  vehicleType?: VehicleDetailsType;
  lastCommunicated?: Partial<{
    status: LastCommunicated;
    deviceTimestamp: string;
  }>;
  provisioning?: ProvisioningStatus;
  organizationPrimaryOwnershipStatus?: OrganizationOwnershipStatus;
  diagnostics?: ApiDiagnostic[];
};

export type ApiTiresReadinessItem = Entity & {
  type: { model: string; version: string };
  organization?: Partial<Entity>;
  hub?: Partial<Entity>;
  fleet?: Partial<Entity>;
  ready?: ReadyStatus;
  tires?: {
    leftFront: TiresStatus;
    leftRear: TiresStatus;
    rightFront: TiresStatus;
    rightRear: TiresStatus;
  };
  connectivity?: AssetConnectivityStatus;
  lastUpdated?: string;
  availabilityState?: AssetServiceStatus;
  licensePlate?: string;
  vehicleType?: VehicleDetailsType;
  lastCommunicated?: Partial<{
    status: LastCommunicated;
    deviceTimestamp: string;
  }>;
};

export type ApiBatteryReadinessItem = Entity & {
  type: { model: string; version: string };
  organization?: Partial<Entity>;
  hub?: Partial<Entity>;
  fleet?: Partial<Entity>;
  connectivity?: AssetConnectivityStatus;
  lastUpdated?: string;
  battery?: Partial<{
    state: ReportDiagnosticState;
    soc: number;
    targetSoc: number;
    chargeState: BatteryChargingStatus;
    estimatedRangedInKM: number;
    chargePowerType: PowerType;
    estimatedTimeRemainingToTargetSOC: number;
    lastUpdated: string;
    chargingEstimatedCompletionDateTime: string;
  }>;
  licensePlate?: string;
  vehicleType?: VehicleDetailsType;
  lastCommunicated?: Partial<{
    status: LastCommunicated;
    deviceTimestamp: string;
  }>;
};

export const BATTERY_RANGE_MAPPING: {
  [k in ReportDiagnosticState]?: BatteryRange;
} = {
  [ReportDiagnosticState.GOOD]: BatteryRange.HIGH,
  [ReportDiagnosticState.IMPAIRED]: BatteryRange.MEDIUM,
  [ReportDiagnosticState.CRITICAL]: BatteryRange.LOW,
};

export const DIAGNOSTIC_STATE_MAPPING: {
  [k in ReportDiagnosticState]?: DiagnosticState;
} = {
  [ReportDiagnosticState.GOOD]: DiagnosticState.CLEAR,
  [ReportDiagnosticState.IMPAIRED]: DiagnosticState.IMPAIRED,
  [ReportDiagnosticState.CRITICAL]: DiagnosticState.CRITICAL,
};

export const DIAGNOSTIC_SEVERITY_MAPPING: {
  [k in ReportDiagnosticState]?: DiagnosticSeverity;
} = {
  [ReportDiagnosticState.GOOD]: DiagnosticSeverity.INFO,
  [ReportDiagnosticState.IMPAIRED]: DiagnosticSeverity.IMPAIRED,
  [ReportDiagnosticState.CRITICAL]: DiagnosticSeverity.CRITICAL,
  [ReportDiagnosticState.NO_DATA]: DiagnosticSeverity.UNKNOWN,
};

export const mapApiAssetReadinessToReportItem = ({
  id,
  name,
  organization,
  hub,
  fleet,
  faultCount,
  vehicleType,
  ...apiResponse
}: Partial<ApiReadinessItem>): AssetReportItem => {
  const type = apiResponse?.type
    ? mapApiResponseToType(apiResponse?.type)
    : undefined;
  return {
    id,
    name,
    organization,
    hub,
    fleet,
    type,
    faultCount,
    ready: mapReadiness(apiResponse.ready),
    battery: {
      isCharging:
        apiResponse.battery?.chargeState === BatteryChargingStatus.CHARGING
          ? true
          : apiResponse.battery?.chargeState ===
            BatteryChargingStatus.NOT_CHARGING
          ? false
          : undefined,
      range: apiResponse.battery?.state
        ? BATTERY_RANGE_MAPPING[apiResponse.battery?.state] ||
          BatteryRange.UNKNOWN
        : undefined,
      value:
        apiResponse.battery?.soc &&
        apiResponse.battery?.soc >= MIN_VALID_SOC_VALUE &&
        apiResponse.battery?.soc <= MAX_VALID_SOC_VALUE
          ? apiResponse.battery?.soc
          : undefined,
      estimatedRange:
        typeof apiResponse.battery?.estimatedRangedInKM === 'number'
          ? {
              value: apiResponse.battery?.estimatedRangedInKM,
              unitOfMeasure: DistanceUnit.KM,
            }
          : undefined,
    },
    connectivity: apiResponse.connectivity,
    brakeFluid: apiResponse.brakeFluid,
    washerFluid: apiResponse.washerFluid,
    tires: apiResponse.tires,
    estimatedRange:
      typeof apiResponse.battery?.estimatedRangedInKM === 'number'
        ? {
            value: apiResponse.battery?.estimatedRangedInKM,
            unitOfMeasure: DistanceUnit.KM,
          }
        : undefined,
    safetyCompliant: apiResponse.safetyCompliant,
    lastUpdated: apiResponse.lastUpdated,
    milState: apiResponse.milState,
    diagnosticHealth: apiResponse.diagnosticState,
    availability: apiResponse.availabilityState,
    fuel: {
      state: apiResponse.fuelLevel?.state ?? undefined,
      value:
        apiResponse.fuelLevel?.fuelLevel &&
        apiResponse.fuelLevel.fuelLevel >= MIN_VALID_FUEL_VALUE &&
        apiResponse.fuelLevel.fuelLevel <= MAX_VALID_FUEL_VALUE
          ? apiResponse.fuelLevel.fuelLevel
          : undefined,
    },
    licensePlate: apiResponse.licensePlate ?? undefined,
    oilLife: {
      state: apiResponse.oilLife?.state ?? undefined,
      value: apiResponse.oilLife?.oilLife ?? undefined,
    },
    brakePadFront: {
      state: apiResponse.brakePadFront?.state ?? undefined,
      value: apiResponse.brakePadFront?.brakePadLife ?? undefined,
    },
    brakePadRear: {
      state: apiResponse.brakePadRear?.state ?? undefined,
      value: apiResponse.brakePadRear?.brakePadLife ?? undefined,
    },
    engineAirFilter: {
      state: apiResponse.engineAirFilter?.state ?? undefined,
      value: apiResponse.engineAirFilter?.engineAirFilter ?? undefined,
    },
    connectivityBoardingStatus: mapConnectivityStatusType(
      apiResponse?.organizationPrimaryOwnershipStatus,
      apiResponse?.provisioning
    ),
    vehicleType,
    lastCommunicated: {
      deviceTimestamp:
        apiResponse.lastCommunicated?.deviceTimestamp ?? undefined,
      status: apiResponse.lastCommunicated?.status ?? undefined,
    },
    diagnostics: mapDiagnostics(apiResponse.diagnostics),
  } as AssetReportItem;
};

export const mapApiAssetTireReadinessToReportItem = ({
  id,
  name,
  organization,
  hub,
  fleet,
  vehicleType,
  ...apiResponse
}: Partial<ApiTiresReadinessItem>): AssetReportItem => {
  const type = apiResponse?.type
    ? mapApiResponseToType(apiResponse?.type)
    : undefined;
  return {
    id,
    name,
    organization,
    hub,
    fleet,
    type,
    ready: mapReadiness(apiResponse.ready),
    leftRearTire: apiResponse?.tires?.leftRear,
    leftFrontTire: apiResponse?.tires?.leftFront,
    rightFrontTire: apiResponse?.tires?.rightFront,
    rightRearTire: apiResponse?.tires?.rightRear,
    connectivity: apiResponse.connectivity,
    lastUpdated: apiResponse.lastUpdated,
    availability: apiResponse.availabilityState,
    licensePlate: apiResponse.licensePlate ?? undefined,
    vehicleType,
    lastCommunicated: {
      deviceTimestamp:
        apiResponse.lastCommunicated?.deviceTimestamp ?? undefined,
      status: apiResponse.lastCommunicated?.status ?? undefined,
    },
  } as AssetReportItem;
};

export const mapApiAssetBatteryReadinessToReportItem = ({
  id,
  name,
  organization,
  hub,
  fleet,
  vehicleType,
  ...apiResponse
}: Partial<ApiBatteryReadinessItem>): AssetReportItem => {
  const type = apiResponse?.type
    ? mapApiResponseToType(apiResponse?.type)
    : undefined;
  return {
    id,
    name,
    organization,
    hub,
    fleet,
    type,
    connectivity: apiResponse.connectivity,
    lastUpdated: apiResponse.lastUpdated,
    battery: {
      isCharging:
        apiResponse.battery?.chargeState === BatteryChargingStatus.CHARGING
          ? true
          : apiResponse.battery?.chargeState ===
            BatteryChargingStatus.NOT_CHARGING
          ? false
          : undefined,
      range: apiResponse.battery?.state
        ? BATTERY_RANGE_MAPPING[apiResponse.battery?.state] ||
          BatteryRange.UNKNOWN
        : undefined,
      value:
        apiResponse.battery?.soc &&
        apiResponse.battery?.soc >= MIN_VALID_SOC_VALUE &&
        apiResponse.battery?.soc <= MAX_VALID_SOC_VALUE
          ? apiResponse.battery?.soc
          : undefined,
      estimatedRange:
        typeof apiResponse.battery?.estimatedRangedInKM === 'number'
          ? {
              value: apiResponse.battery?.estimatedRangedInKM,
              unitOfMeasure: DistanceUnit.KM,
            }
          : undefined,
      lastUpdated: apiResponse.battery?.lastUpdated,
    },
    targetSOC: {
      value: apiResponse.battery?.targetSoc
        ? apiResponse.battery.targetSoc
        : undefined,
      estimatedRange:
        typeof apiResponse.battery?.estimatedRangedInKM === 'number' &&
        apiResponse.battery?.targetSoc &&
        apiResponse.battery?.soc &&
        apiResponse.battery?.soc >= MIN_VALID_SOC_VALUE &&
        apiResponse.battery?.soc <= MAX_VALID_SOC_VALUE
          ? {
              value: calculateTargetSocEstRange(
                apiResponse.battery?.soc,
                apiResponse.battery?.estimatedRangedInKM,
                apiResponse.battery?.targetSoc
              ),
              unitOfMeasure: DistanceUnit.KM,
            }
          : undefined,
    },
    chargingStatus: apiResponse.battery?.chargeState,
    powerType: apiResponse.battery?.chargePowerType,
    minutesToTargetSOC: {
      value: apiResponse.battery?.estimatedTimeRemainingToTargetSOC,
      deviceTimestamp: apiResponse.battery?.chargingEstimatedCompletionDateTime,
    },
    licensePlate: apiResponse.licensePlate ?? undefined,
    vehicleType,
    lastCommunicated: {
      deviceTimestamp:
        apiResponse.lastCommunicated?.deviceTimestamp ?? undefined,
      status: apiResponse.lastCommunicated?.status ?? undefined,
    },
  } as AssetReportItem;
};
export const mapApiRecallsToReportItem = ({
  id,
  name,
  organization,
  hub,
  fleet,
  vehicleType,
  ...apiResponse
}: Partial<ApiRecallsItem>): AssetReportItem => {
  const type = apiResponse?.type
    ? mapApiResponseToType(apiResponse?.type)
    : undefined;
  return {
    id,
    name,
    organization,
    hub,
    fleet,
    type,
    availability: apiResponse.availabilityState,
    licensePlate: apiResponse.licensePlate ?? undefined,
    vehicleType,
    fieldActionSummary: apiResponse.fieldActionSummary,
  } as AssetReportItem;
};

export const calculateTargetSocEstRange = (
  currentSoc: number,
  currentSocEstRange: number,
  targetSoc: number
) => (currentSocEstRange / currentSoc) * targetSoc;

const mapReadiness = (ready?: ReadyStatus) =>
  ready === ReadyStatus.READY
    ? true
    : ready === ReadyStatus.NOT_READY
    ? false
    : undefined;
export const mapDiagnostics = (diagnostics?: ApiDiagnostic[]): Diagnostic[] => {
  const excludedDiagnostics = [
    DiagnosticDTCType.BATTERY,
    DiagnosticDTCType.FUEL_LEVEL,
  ];
  const updatedDiagnostics = diagnostics?.filter(
    (diagnostic) => !excludedDiagnostics.includes(diagnostic.type)
  );

  return updatedDiagnostics as Diagnostic[];
};
