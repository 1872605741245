import {
  ApiPeripheralMoveFleetSummary,
  PeripheralMoveFleetSummary,
  PeripheralOnboardStatusApiResponse,
  PeripheralOnboardStatusResponse,
  PeripheralsOnboardApiResponse,
  PeripheralsOnboardResponse,
} from '~/common/models/peripheral.model';

export const mapPeripheralsOnboardResponseToPeripherals = (
  apiOnboard: PeripheralsOnboardApiResponse
): PeripheralsOnboardResponse => ({
  batchId: apiOnboard.batchId,
  organizationId: apiOnboard.organizationId,
});

export const mapPeripheralsOnboardStatusResponseToPeripherals = (
  apiResponse: PeripheralOnboardStatusApiResponse
): PeripheralOnboardStatusResponse => {
  return {
    id: apiResponse.id,
    status: apiResponse.status,
    error: apiResponse.error,
    parameters: {
      organizationId: apiResponse.parameters?.organizationId,
      peripheralType: apiResponse.parameters?.peripheralType,
      make: apiResponse.parameters?.make,
      model: apiResponse.parameters?.model,
      version: apiResponse.parameters?.version,
      fileName: apiResponse.parameters?.fileName,
    },
    details: apiResponse.details,
  };
};

export const mapApiPeripheralMoveFleetSummary = (
  apiResponse: ApiPeripheralMoveFleetSummary
): PeripheralMoveFleetSummary => {
  const { successList, failedList } = apiResponse;
  return {
    assignedSuccessPeripherals: successList || [],
    assignedFailedPeripherals: failedList.map((peripheral) => ({
      id: peripheral.id,
      error: {
        id: peripheral.error.id,
        message: peripheral.error.message,
      },
    })),
  };
};
