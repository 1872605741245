import {
  ActionReducerMapBuilder,
  CaseReducer,
  EntityState,
  PayloadAction,
} from '@reduxjs/toolkit';
import merge from 'deepmerge';

import { BDRequestStatus } from '~/common/models/apis/apiResponse.model';
import {
  AssetInspectionDetail,
  AssetInspectionListItem,
} from '~/common/models/assetInspection.model';
import { BDAppErrorType, BDError } from '~/common/models/error.model';

import {
  InspectionsSessionConfig,
  InspectionsSessionViewType,
  InspectionsState,
} from '../inspectionsSlice.model';
import { INSPECTIONS_ACTIONS } from './inspectionsSlice.actions';
import { inspectionsAdapter } from './inspectionsSlice.adapters';
import { INITIAL_INSPECTIONS_STATE } from './inspectionsSlice.constants';

const reduceSetInspectionsSessionConfig: CaseReducer<
  typeof INITIAL_INSPECTIONS_STATE,
  PayloadAction<Partial<InspectionsSessionConfig>>
> = (state, action) => {
  Object.keys(action.payload).forEach((key) => {
    const scope = key as InspectionsSessionViewType;
    const payload = action.payload[scope] || {};
    Object.keys(payload).forEach((id) => {
      state.sessionConfigs = merge(
        state.sessionConfigs,
        {
          [scope]: {
            [id]: payload[id] && {
              ...payload[id],
            },
          },
        },
        {
          arrayMerge: (_, sourceArray) => sourceArray,
        }
      );
    });
  });
};

const reduceClearInspectionsFilterSession: CaseReducer<
  typeof INITIAL_INSPECTIONS_STATE,
  PayloadAction<{ viewType: InspectionsSessionViewType; sessionId: string }>
> = (state, action) => {
  state.sessionConfigs = merge(
    state.sessionConfigs,
    {
      [action.payload.viewType]: {
        [action.payload.sessionId]: {
          filterCriteria: {
            outcome: [],
            status: [],
            severity: [],
          },
        },
      },
    },
    {
      arrayMerge: (_, sourceArray) => sourceArray,
    }
  );
};

const buildGetInspectionsSummaryReducer = (
  builder: ActionReducerMapBuilder<
    EntityState<AssetInspectionListItem> & InspectionsState
  >
) => {
  builder.addCase(
    INSPECTIONS_ACTIONS.getInspectionSummary.pending,
    (state, action) => {
      const { sessionId } = action.meta.arg;

      if (sessionId) {
        state.sessionConfigs = merge(
          state.sessionConfigs,
          {
            [InspectionsSessionViewType.INSPECTION_SUMMARY]: {
              [sessionId]: {
                summaryOverview: {},
                operationStatus: {
                  status: BDRequestStatus.PENDING,
                },
              },
            },
          },
          {
            arrayMerge: (_, sourceArray) => sourceArray,
          }
        );
      }
    }
  );
  builder.addCase(
    INSPECTIONS_ACTIONS.getInspectionSummary.fulfilled,
    (state, action) => {
      const { sessionId } = action.meta.arg;

      if (sessionId) {
        state.sessionConfigs = merge(
          state.sessionConfigs,
          {
            [InspectionsSessionViewType.INSPECTION_SUMMARY]: {
              [sessionId]: {
                summaryOverview: action.payload.result,
                operationStatus: {
                  status: BDRequestStatus.SUCCEEDED,
                },
              },
            },
          },
          {
            arrayMerge: (_, sourceArray) => sourceArray,
          }
        );
      }
    }
  );
  builder.addCase(
    INSPECTIONS_ACTIONS.getInspectionSummary.rejected,
    (state, action) => {
      const { sessionId } = action.meta.arg;
      if (sessionId) {
        state.sessionConfigs = merge(
          state.sessionConfigs,
          {
            [InspectionsSessionViewType.INSPECTION_SUMMARY]: {
              [sessionId]: {
                operationStatus: {
                  status: BDRequestStatus.FAILED,
                  errors: [
                    {
                      type: BDAppErrorType.API,
                      ...(action.payload || (action.error as BDError)),
                    },
                  ],
                },
              },
            },
          },
          {
            arrayMerge: (_, sourceArray) => sourceArray,
          }
        );
      }
    }
  );
};

export const buildGetInspectionDetailsReducer = (
  builder: ActionReducerMapBuilder<
    EntityState<AssetInspectionDetail> & InspectionsState
  >
) => {
  builder.addCase(
    INSPECTIONS_ACTIONS.getInspectionDetails.pending,
    (state, action) => {
      const { sessionId } = action.meta.arg;
      if (sessionId) {
        state.sessionConfigs = merge(
          state.sessionConfigs,
          {
            [InspectionsSessionViewType.INSPECTION_DETAILS]: {
              [sessionId]: {
                inspectionDetails: {},
                operationStatus: {
                  status: BDRequestStatus.PENDING,
                },
              },
            },
          },
          {
            arrayMerge: (_, sourceArray) => sourceArray,
          }
        );
      }
    }
  );
  builder.addCase(
    INSPECTIONS_ACTIONS.getInspectionDetails.fulfilled,
    (state, action) => {
      const { sessionId } = action.meta.arg;
      if (sessionId) {
        state.sessionConfigs = merge(
          state.sessionConfigs,
          {
            [InspectionsSessionViewType.INSPECTION_DETAILS]: {
              [sessionId]: {
                report: action.payload.result,
                operationStatus: {
                  status: BDRequestStatus.SUCCEEDED,
                },
              },
            },
          },
          {
            arrayMerge: (_, sourceArray) => sourceArray,
          }
        );
      }
    }
  );
  builder.addCase(
    INSPECTIONS_ACTIONS.getInspectionDetails.rejected,
    (state, action) => {
      const { sessionId } = action.meta.arg;
      if (sessionId) {
        state.sessionConfigs = merge(
          state.sessionConfigs,
          {
            [InspectionsSessionViewType.INSPECTION_DETAILS]: {
              [sessionId]: {
                operationStatus: {
                  status: BDRequestStatus.FAILED,
                  errors: [
                    {
                      type: BDAppErrorType.API,
                      ...(action.payload || (action.error as BDError)),
                    },
                  ],
                },
              },
            },
          },
          {
            arrayMerge: (_, sourceArray) => sourceArray,
          }
        );
      }
    }
  );
};
export const buildGetInspectionLongTermReportReducer = (
  builder: ActionReducerMapBuilder<
    EntityState<AssetInspectionListItem> & InspectionsState
  >
) => {
  builder.addCase(
    INSPECTIONS_ACTIONS.getInspectionLongTermReport.pending,
    (state, action) => {
      const { sessionId } = action.meta.arg;
      if (sessionId) {
        state.sessionConfigs = merge(
          state.sessionConfigs,
          {
            [InspectionsSessionViewType.INSPECTION_LONG_TERM_REPORTING]: {
              [sessionId]: {
                operationStatus: {
                  status: BDRequestStatus.PENDING,
                },
              },
            },
          },
          {
            arrayMerge: (_, sourceArray) => sourceArray,
          }
        );
      }
    }
  );
  builder.addCase(
    INSPECTIONS_ACTIONS.getInspectionLongTermReport.fulfilled,
    (state, action) => {
      const { sessionId } = action.meta.arg;
      if (sessionId) {
        state.sessionConfigs = merge(
          state.sessionConfigs,
          {
            [InspectionsSessionViewType.INSPECTION_LONG_TERM_REPORTING]: {
              [sessionId]: {
                operationStatus: {
                  status: BDRequestStatus.SUCCEEDED,
                },
              },
            },
          },
          {
            arrayMerge: (_, sourceArray) => sourceArray,
          }
        );
      }
    }
  );
  builder.addCase(
    INSPECTIONS_ACTIONS.getInspectionLongTermReport.rejected,
    (state, action) => {
      const { sessionId } = action.meta.arg;
      if (sessionId) {
        state.sessionConfigs = merge(
          state.sessionConfigs,
          {
            [InspectionsSessionViewType.INSPECTION_LONG_TERM_REPORTING]: {
              [sessionId]: {
                operationStatus: {
                  status: BDRequestStatus.FAILED,
                  errors: [
                    {
                      type: BDAppErrorType.API,
                      ...(action.payload || (action.error as BDError)),
                    },
                  ],
                },
              },
            },
          },
          {
            arrayMerge: (_, sourceArray) => sourceArray,
          }
        );
      }
    }
  );
};
export const buildGetInspectionListReducer = (
  builder: ActionReducerMapBuilder<
    EntityState<AssetInspectionListItem> & InspectionsState
  >
) => {
  builder.addCase(
    INSPECTIONS_ACTIONS.getInspectionList.pending,
    (state, action) => {
      const { sessionId, skipStateUpdate } = action.meta.arg;
      if (skipStateUpdate) {
        return;
      }
      inspectionsAdapter.removeMany(state, state.ids);
      if (sessionId) {
        state.sessionConfigs = merge(
          state.sessionConfigs,
          {
            [InspectionsSessionViewType.INSPECTION_LIST]: {
              [sessionId]: {
                operationStatus: {
                  status: BDRequestStatus.PENDING,
                },
              },
            },
          },
          {
            arrayMerge: (_, sourceArray) => sourceArray,
          }
        );
      }
    }
  );
  builder.addCase(
    INSPECTIONS_ACTIONS.getInspectionList.fulfilled,
    (state, action) => {
      const { sessionId, page, pageSize, skipStateUpdate } = action.meta.arg;
      if (skipStateUpdate) {
        return;
      }
      const { items, total_items } = action.payload.result;
      inspectionsAdapter.upsertMany(state, items);
      if (sessionId) {
        state.sessionConfigs = merge(
          state.sessionConfigs,
          {
            [InspectionsSessionViewType.INSPECTION_LIST]: {
              [sessionId]: {
                operationStatus: {
                  status: BDRequestStatus.SUCCEEDED,
                },
                count: total_items,
                page,
                rowsPerPage: pageSize,
              },
            },
          },
          {
            arrayMerge: (_, sourceArray) => sourceArray,
          }
        );
      }
    }
  );
  builder.addCase(
    INSPECTIONS_ACTIONS.getInspectionList.rejected,
    (state, action) => {
      const { skipStateUpdate, sessionId } = action.meta.arg;
      if (skipStateUpdate) {
        return;
      }
      if (sessionId) {
        state.sessionConfigs = merge(
          state.sessionConfigs,
          {
            [InspectionsSessionViewType.INSPECTION_LIST]: {
              [sessionId]: {
                operationStatus: {
                  status: BDRequestStatus.FAILED,
                  errors: [
                    {
                      type: BDAppErrorType.API,
                      ...(action.payload || (action.error as BDError)),
                    },
                  ],
                },
              },
            },
          },
          {
            arrayMerge: (_, sourceArray) => sourceArray,
          }
        );
      }
    }
  );
};

export const INSPECTIONS_REDUCERS = {
  reduceSetInspectionsSessionConfig,
  reduceClearInspectionsFilterSession,
};
export const INSPECTIONS_EXTRA_REDUCER_BUILDERS = {
  buildGetInspectionsSummaryReducer,
  buildGetInspectionDetailsReducer,
  buildGetInspectionListReducer,
  buildGetInspectionLongTermReportReducer,
};
