import {
  Box,
  BoxProps,
  Skeleton,
  SkeletonProps,
  Typography,
} from '@mui/material';
import { useEffect, useMemo, useRef, useState } from 'react';

import VehicleImg from '~/assets/icons/new/vehicleDefault.svg';

import useStyles from './ImageWithFallback.styles';

export interface ImageWithFallbackProps extends BoxProps {
  src: string;
  alt: string;
  fallbackSrc?: string;
  onResult?: ({
    isLoaded,
    isError,
  }: {
    isLoaded: boolean;
    isError: boolean;
  }) => void;
  skeletonProps?: SkeletonProps;
  errorImageClass?: string;
  imageContainerProps?: BoxProps;
  disclaimerText?: string;
}

const ImageWithFallback = ({
  src,
  alt,
  fallbackSrc = VehicleImg,
  onResult,
  skeletonProps = {},
  errorImageClass,
  imageContainerProps = {},
  disclaimerText = '',
  ...props
}: ImageWithFallbackProps): JSX.Element => {
  const { classes } = useStyles();
  const [imgSrc, setImgSrc] = useState<string>(src);
  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);
  const [size, setSize] = useState<{ width: number; height: number }>({
    width: 0,
    height: 0,
  });
  const containerRef = useRef<HTMLDivElement>(null);

  const resizeObserver = useMemo(
    () =>
      new ResizeObserver((entries) => {
        for (const entry of entries) {
          setSize({
            width: entry.contentRect.width,
            height: entry.contentRect.height,
          });
        }
      }),
    []
  );

  useEffect(() => {
    const parentElement = containerRef.current;

    if (parentElement) {
      resizeObserver.observe(parentElement);
    }

    return () => {
      if (parentElement) {
        resizeObserver.unobserve(parentElement);
      }
    };
  }, [resizeObserver]);

  useEffect(() => {
    /*
    creating a new instance of Image and defining handler instead of using onError or 
    onLoad on img tag to avoid broken image flashing before fallback image is loaded
    */
    const img = new Image();

    img.onload = () => {
      setIsLoaded(true);
      setImgSrc(src);
      onResult?.({ isLoaded: true, isError: false });
    };

    img.onerror = () => {
      setIsLoaded(true);
      setIsError(true);
      setImgSrc(fallbackSrc);
      onResult?.({ isLoaded: true, isError: true });
    };

    img.src = src;

    // Cleanup
    return () => {
      setIsLoaded(false);
      setIsError(false);
      img.onload = null;
      img.onerror = null;
    };
  }, [fallbackSrc, onResult, src]);

  return (
    <Box ref={containerRef} className={classes.fullSize} {...props}>
      {!isLoaded ? (
        <Skeleton
          variant="rounded"
          data-testid="image-skeleton"
          height={size.height}
          width={size.width}
          {...skeletonProps}
        />
      ) : (
        <Box
          className={`${classes.fullSize} ${classes.imageContainer}`}
          {...imageContainerProps}
        >
          <img
            src={imgSrc}
            alt={alt}
            className={`${classes.fullSize} ${classes.image} ${
              isError ? `${errorImageClass}` : ''
            }`}
          />
          {disclaimerText && !isError && (
            <Typography variant="caption_regular">{disclaimerText}</Typography>
          )}
        </Box>
      )}
    </Box>
  );
};

export default ImageWithFallback;
