import {
  ApiAddressFields,
  mapAddressToApiResponse,
  mapApiResponseToAddress,
} from '~/common/mappers/common.mappers';
import { PartialEntity } from '~/common/models/common.model';
import { Hub, ValidHubForm } from '~/common/models/hub.model';
import { Organization } from '~/common/models/organization.model';

export type ApiHubFields = {
  name: string;
  id?: string;
  description?: string;
  address?: ApiAddressFields;
  creationDate?: string;
  organization?: PartialEntity<Organization>;
};

export const mapApiResponseToHub = (apiHub: ApiHubFields): Hub => ({
  id: apiHub.id || '',
  name: apiHub.name || '',
  description: apiHub.description || '',
  creationDate: apiHub.creationDate,
  address: apiHub.address ? mapApiResponseToAddress(apiHub.address) : undefined,
  organization: apiHub.organization,
});

export const mapHubFormToApiRequest = (
  hubForm: ValidHubForm
): ApiHubFields => ({
  name: hubForm.name.trim(),
  description: hubForm.description || '',
  address: mapAddressToApiResponse(hubForm.address),
});
