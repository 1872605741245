import {
  createEntityAdapter,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';

import { BDRequestStatus } from '~/common/models/apis/apiResponse.model';
import {
  FeatureFlag,
  FeatureFlagsRequestType,
  FeatureFlagsState,
} from '~/common/models/featureFlags.model';

import { RootState } from '../../app/rootReducer';

const featureFlagsAdapter = createEntityAdapter<FeatureFlag>({
  selectId: (featureFlag) => featureFlag.name,
});

const initialState = featureFlagsAdapter.getInitialState<FeatureFlagsState>({
  operations: {},
});

const featureFlagsSlice = createSlice({
  name: 'featureFlagsSlice',
  initialState,
  reducers: {
    setEffectiveFeatures: (state, action: PayloadAction<FeatureFlag[]>) => {
      featureFlagsAdapter.setAll(state, action.payload);
      state.operations[FeatureFlagsRequestType.GET_FEATURE_FLAGS] = {
        status: BDRequestStatus.SUCCEEDED,
      };
    },
  },
});

export const {
  selectAll: selectAllFeatureFlags,
  selectById: selectFeatureFlagById,
} = featureFlagsAdapter.getSelectors<RootState>(
  (state: RootState) => state.featureFlags
);

export const { setEffectiveFeatures } = featureFlagsSlice.actions;
export const featureFlagsReducer = featureFlagsSlice.reducer;
