import { Entity } from './common.model';

type AssetType = {
  model: string;
  version?: string;
};

type AssetInspectionCommonAttributes = {
  outcome?: ReportOutcome;
  generatedAt?: string;
  submittedAt?: string;
  majorFailCount?: number;
  minorFailCount?: number;
  asset?: Partial<{
    id: string;
    name: string;
    vin: string;
    type: AssetType;
  }>;
  createdBy?: Partial<{
    id: string;
    displayName: string;
  }>;
};

type InspectionCategory = {
  id: string;
  label?: string;
};

type InspectionSubCategories = InspectionCategory & {
  questions?: AssetInspectionQuestion[];
};

type InspectionDetailAttributes = {
  id: string;
  assetId?: string;
  fleetId?: string;
  checklistId?: string;
  categories?: Partial<
    InspectionCategory & {
      subcategories: InspectionSubCategories[];
    }
  >[];
};

export enum InspectionsDateRange {
  THIS_DATE = 'this_date',
  THIS_WEEK = 'this_week',
  THIS_MONTH = 'this_month',
  THIS_YEAR = 'this_year',
}

export enum InspectionLongTermReportPeriod {
  DAY = 'DAY',
  WEEK = 'WEEK',
  MONTH = 'MONTH',
  YEAR = 'YEAR',
}

export enum ReportSeverity {
  MAJOR = 'MAJOR',
  MINOR = 'MINOR',
}

export enum InspectionLongTermReportTargetType {
  FLEET = 'FLEET',
}
export enum ReportOutcome {
  PASS = 'PASS',
  FAIL = 'FAIL',
}
export enum InspectionLongTermReportIssueType {
  MAJOR = 'TOP3_MAJOR_FAIL_BY_SUBCATEGORY',
  MINOR = 'TOP3_MINOR_FAIL_BY_SUBCATEGORY',
  FAIL = 'FAIL_BY_CATEGORY',
}
export enum AssetInspectionStatus {
  COMPLETED = 'completed',
  INCOMPLETED = 'incompleted',
}

export const InspectionLongTermReportPeriodMap: {
  [k in InspectionsDateRange]: InspectionLongTermReportPeriod;
} = {
  [InspectionsDateRange.THIS_DATE]: InspectionLongTermReportPeriod.DAY,
  [InspectionsDateRange.THIS_WEEK]: InspectionLongTermReportPeriod.WEEK,
  [InspectionsDateRange.THIS_MONTH]: InspectionLongTermReportPeriod.MONTH,
  [InspectionsDateRange.THIS_YEAR]: InspectionLongTermReportPeriod.YEAR,
};

export interface AssetInspectionsSummary {
  total?: number;
  failed?: number;
  passed?: number;
  majorIssues?: number;
  minorIssues?: number;
}

export type AssetInspectionQuestion = {
  id: string;
  label?: string;
  isFailed?: boolean;
  severity?: ReportSeverity;
  isAuto?: boolean;
  notes?: string;
  photos?: string[];
};

export type ApiInspectionDetail = InspectionDetailAttributes &
  AssetInspectionCommonAttributes;

export type AssetInspectionDetail = InspectionDetailAttributes &
  AssetInspectionCommonAttributes;

export type AssetInspectionListItem = AssetInspectionCommonAttributes & Entity;

export type AssetInspectionListApi = {
  totalCount: number;
  items: AssetInspectionListItem[];
};

export type AssetInspectionsOverviewResultType =
  | ReportOutcome
  | AssetInspectionStatus
  | ReportSeverity;
