import { PublicClientApplication } from '@azure/msal-browser';

import { msalCdiConfig, msalConfig } from '~/common/configs/auth.config';

import { AuthenticationType } from './model/authentication.model';

export const msalInstance = new PublicClientApplication(msalConfig);
export const msalCdiInstance = new PublicClientApplication(msalCdiConfig);

export const getMsalInstance = (authType: AuthenticationType) => {
  switch (authType) {
    case AuthenticationType.SSO:
      return msalInstance;
    case AuthenticationType.CDI:
      return msalCdiInstance;
  }
};
