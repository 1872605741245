import { createSlice } from '@reduxjs/toolkit';

import { RootState } from '~/app/rootReducer';

import { PERIPHERALS_ACTIONS } from './state/peripheralsSlice.actions';
import { peripheralsAdapter } from './state/peripheralsSlice.adapters';
import { INITIAL_PERIPHERALS_STATE } from './state/peripheralsSlice.constants';
import {
  PERIPHERALS_EXTRA_REDUCER_BUILDERS,
  PERIPHERALS_REDUCERS,
} from './state/peripheralsSlice.reducers';

export enum PeripheralRequestType {
  POST_MOVE_FLEET = 'Post Move Fleet',
}

const {
  reduceSetPeripheralsSessionConfig,
  reduceResetPeripheralsSessionConfig,
} = PERIPHERALS_REDUCERS;

export const peripheralsSlice = createSlice({
  name: 'peripherals',
  initialState: INITIAL_PERIPHERALS_STATE,
  reducers: {
    setPeripheralsSessionConfig: reduceSetPeripheralsSessionConfig,
    resetPeripheralsSessionConfig: reduceResetPeripheralsSessionConfig,
  },
  extraReducers: (builder) => {
    PERIPHERALS_EXTRA_REDUCER_BUILDERS.buildGetPeripheralsListReducer(builder);
    PERIPHERALS_EXTRA_REDUCER_BUILDERS.buildPostLinkVehicleReducer(builder);
    PERIPHERALS_EXTRA_REDUCER_BUILDERS.buildPostUnlinkVehicleReducer(builder);
    PERIPHERALS_EXTRA_REDUCER_BUILDERS.buildOnboardPeripheralsReducer(builder);
    PERIPHERALS_EXTRA_REDUCER_BUILDERS.buildOnboardPeripheralsStatusReducer(
      builder
    );
    PERIPHERALS_EXTRA_REDUCER_BUILDERS.buildPostMoveFleetReducer(builder);
  },
});

export const {
  getPeripherals,
  postLinkVehicle,
  postUnlinkVehicle,
  postPeripheralsOnboard,
  postMoveFleet,
  getPeripheralsOnboardStatus,
} = PERIPHERALS_ACTIONS;
export const { setPeripheralsSessionConfig, resetPeripheralsSessionConfig } =
  peripheralsSlice.actions;

export const {
  selectAll: selectAllPeripherals,
  selectById: selectPeripheralsById,
  selectIds: selectPeripheralsIds,
  selectEntities: selectPeripheralsEntities,
} = peripheralsAdapter.getSelectors<RootState>(
  (state: RootState) => state.peripherals.items
);

export const peripheralsReducer = peripheralsSlice.reducer;
