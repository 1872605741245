import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { useHistory } from 'react-router-dom';

import ProfileCard from '../ProfileCard/ProfileCard';
import { MenuOnClick, ProfileMenuItem } from './ProfileMenu.model';
import useStyles from './ProfileMenu.styles';

export interface MenuProps {
  label: string;
  anchorEl: null | HTMLElement;
  setAnchorEl: React.Dispatch<React.SetStateAction<HTMLElement | null>>;
  items: ProfileMenuItem[];
}

const ProfileMenu = (props: MenuProps): JSX.Element => {
  const { classes } = useStyles();
  const { items, setAnchorEl, anchorEl } = props;
  const history = useHistory();
  const handleClick = ({ redirect, callback }: MenuOnClick) => {
    if (callback) {
      callback();
    }
    if (redirect) {
      history.push(redirect);
    }
    setAnchorEl(null);
  };
  const topMenuItems = items.filter((item) => item.section !== 'bottom');
  const bottomMenuItems = items.filter((item) => item.section === 'bottom');

  return (
    <div>
      <Menu
        id="menu"
        aria-label={props.label}
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
        slotProps={{
          paper: {
            elevation: 1,
            sx: {
              width: 305,
              right: '24px',
              top: '56px !important',
              left: 'unset !important',
            },
          },
        }}
      >
        <div className="menuUserSection">
          <ProfileCard />
        </div>
        <div className={classes.menuTopSection} data-testid={'menuTopSection'}>
          {topMenuItems.map((item, index) => (
            <MenuItem
              aria-label="items"
              key={index}
              onClick={() => handleClick(item.onClick || {})}
              data-testid={item.testId}
              className={classes.menuItem}
            >
              {item.label}
            </MenuItem>
          ))}
        </div>
        <div
          className={classes.menuBottomSection}
          data-testid={'menuBottomSection'}
        >
          {bottomMenuItems.map((item, index) => (
            <MenuItem
              aria-label="items"
              key={index}
              onClick={() => handleClick(item.onClick || {})}
              data-testid={item.testId}
              className={classes.menuItem}
            >
              {item.label}
            </MenuItem>
          ))}
        </div>
      </Menu>
    </div>
  );
};

export default ProfileMenu;
