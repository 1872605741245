import {
  DEFAULT_DISTANCE_THRESHOLD_KM,
  DEFAULT_DISTANCE_THRESHOLD_MI,
} from '../../../../features/utilization/mappers/utilization.mapper';
import {
  UtilizationReportType,
  UtilizationSummary,
  UtilizationViewType,
} from '../../../models/utilization.model';
import { getRoundedValue } from '../../../utils/common.utils';
import { format, fromISODate, HOUR } from '../../../utils/date-time.utils';
import { numberSortCompare } from '../../table/utils/table.utils';

const INITIAL_STATE = {
  labels: [] as string[],
  datapoints: [] as string[],
  dates: [] as string[],
};

export const getDistanceDrivenBarChartData = (
  data: {
    [date: string]: UtilizationSummary;
  },
  dayFormat: string,
  backgroundColor: string
) => {
  const { labels, datapoints, dates } = Object.entries(data)
    .map((entry) => {
      return { date: entry[0], value: Math.round(entry[1].distanceDriven) };
    })
    .sort(barChartDatumSorter)
    .reduce(
      (accumulator, entry) =>
        ({
          labels: [
            ...accumulator.labels,
            format(fromISODate(entry.date), dayFormat),
          ],
          datapoints: [...accumulator.datapoints, entry.value],
          dates: [...accumulator.dates, entry.date],
        } as typeof INITIAL_STATE),
      INITIAL_STATE
    );
  return {
    labels,
    datasets: [
      {
        backgroundColor,
        data: datapoints,
        dates,
      },
    ],
  };
};

export const getVehicleOnTimeBarChartData = (
  data: {
    [date: string]: UtilizationSummary;
  },
  dayFormat: string,
  backgroundColor: string
) => {
  const { labels, datapoints, dates } = Object.entries(data)
    .map((entry) => {
      return { date: entry[0], value: entry[1].timeDriven / HOUR };
    })
    .sort(barChartDatumSorter)
    .reduce(
      (accumulator, entry) =>
        ({
          labels: [
            ...accumulator.labels,
            format(fromISODate(entry.date), dayFormat),
          ],
          datapoints: [...accumulator.datapoints, entry.value],
          dates: [...accumulator.dates, entry.date],
        } as typeof INITIAL_STATE),
      INITIAL_STATE
    );
  return {
    labels,
    datasets: [
      {
        backgroundColor,
        data: datapoints,
        dates,
      },
    ],
  };
};

export const getEnergyConsumptionByDayChartData = (
  data: {
    [date: string]: UtilizationSummary;
  },
  dayFormat: string,
  backgroundColor: string
) => {
  const { labels, datapoints, dates } = Object.entries(data)
    .map((entry) => {
      return {
        date: entry[0],
        value: (entry[1]?.energyUsed && Math.round(entry[1].energyUsed)) || 0,
      };
    })
    .sort(barChartDatumSorter)
    .reduce(
      (accumulator, entry) =>
        ({
          labels: [
            ...accumulator.labels,
            format(fromISODate(entry.date), dayFormat),
          ],
          datapoints: [...accumulator.datapoints, entry.value],
          dates: [...accumulator.dates, entry.date],
        } as typeof INITIAL_STATE),
      INITIAL_STATE
    );
  return {
    labels,
    datasets: [
      {
        backgroundColor,
        data: datapoints,
        dates,
      },
    ],
  };
};

export const getEnergyConsumptionRateByDayChartData = (
  data: {
    [date: string]: UtilizationSummary;
  },
  dayFormat: string,
  backgroundColor: string
) => {
  const { labels, datapoints, dates } = Object.entries(data)
    .map((entry) => {
      return {
        date: entry[0],
        value:
          (entry[1]?.daysDriven &&
            entry[1]?.energyUsedRate &&
            Math.round(entry[1].energyUsedRate * 100) / 100) ||
          0,
        distance: entry[1]?.distanceDriven || 0,
        energyUsed: entry[1]?.energyUsed || 0,
      };
    })
    .sort(barChartDatumSorter)
    .reduce(
      (accumulator, entry) =>
        ({
          labels: [
            ...accumulator.labels,
            format(fromISODate(entry.date), dayFormat),
          ],
          datapoints: [...accumulator.datapoints, entry.value],
          dates: [...accumulator.dates, entry.date],
        } as typeof INITIAL_STATE),
      INITIAL_STATE
    );
  return {
    labels,
    datasets: [
      {
        backgroundColor,
        data: datapoints,
        dates,
      },
    ],
  };
};

export const getDataForBarUtil = (
  data: {
    [date: string]: UtilizationSummary;
  },
  dayFormat: string,
  utilizationType: UtilizationReportType
) =>
  Object.entries(data)
    .map((entry) => {
      switch (utilizationType) {
        case UtilizationReportType.DISTANCE_DRIVEN:
          return {
            date: entry[0],
            value: entry[1].distanceDriven,
            distance: entry[1]?.distanceDriven || 0,
            energyUsed: entry[1]?.energyUsed || 0,
          };
        case UtilizationReportType.VEHICLE_ON_TIME:
          return {
            date: entry[0],
            value: entry[1].timeDriven / HOUR,
            distance: entry[1]?.distanceDriven || 0,
            energyUsed: entry[1]?.energyUsed || 0,
          };
        case UtilizationReportType.ENERGY_CONSUMPTION:
          return {
            date: entry[0],
            value: entry[1]?.energyUsed || 0,
            distance: entry[1]?.distanceDriven || 0,
            energyUsed: entry[1]?.energyUsed || 0,
          };
        case UtilizationReportType.ENERGY_CONSUMPTION_RATE:
          return {
            date: entry[0],
            value:
              (entry[1]?.daysDriven &&
                entry[1]?.energyUsedRate &&
                Math.round(entry[1].energyUsedRate * 100) / 100) ||
              0,
            distance: entry[1]?.distanceDriven || 0,
            energyUsed: entry[1]?.energyUsed || 0,
          };
      }
    })
    .sort(barChartDatumSorter)
    .map((entry) => ({
      ...entry,
      date: format(fromISODate(entry.date), dayFormat),
      fullDate: entry.date,
    }));

// This is to determine max Y axis value which would also set the top end grid
// e.g. maxDataValue = 737; normalizingFactor = 1000; rangeFactor = 0.737; maxChartValue = 800; return 800
// e.g. maxDataValue = 112; normalizingFactor = 1000; rangeFactor = 0.112; maxCChartValue = 200; return 120
export const getYAxisUpperBound = (maxDataValue: number) => {
  // determine next power of 10 larger then maxValue. eg. 737 would be 1000; 23 would be 100
  const normalizingFactor = Math.pow(
    10,
    // number of digi of the maxValue. eg 737 would be 3 which is use to calculate 10 to the power 3
    Math.ceil(Math.log10(Math.abs(maxDataValue + 1)))
  );
  // e.g. 737 / 1000 = 0.737 || 13 / 100 = 0.13
  const rangeFactor = maxDataValue / normalizingFactor;
  // determine maxChartValue (Y axis max) (Math.ceil(0.737 / 10) * 10) * 1000 = 800
  const maxChartValue = (Math.ceil(rangeFactor * 10) / 10) * normalizingFactor;

  // To minimize the larger gap from maxDataValue and maxChartValue on the lower values (112 vs 200)
  // if maxChartValue is 30% larger than maxDataValue return with following caculation instead
  // (Math.ceil(0.112 * 100) / 100) * 1000 = 120
  return maxDataValue / maxChartValue > 0.7
    ? maxChartValue
    : (Math.ceil(rangeFactor * 100) / 100) * normalizingFactor;
};

export const calculateAverage = (
  data: {
    [date: string]: UtilizationSummary;
  },
  dayFormat: string,
  utilizationType: UtilizationReportType,
  daysOfOperation: number
) => {
  const chartData = getDataForBarUtil(data, dayFormat, utilizationType);
  let totalNonZeroValue = 0;
  let maxDataValue = 0;
  let average = 0;
  let totalDistance = 0;
  let totalEnergy = 0;
  chartData.map((data) => {
    maxDataValue = Math.max(maxDataValue, +data.value);
    if (!isNaN(+data.value) && data.value > 0) {
      totalNonZeroValue += +data.value;
    }

    if (
      utilizationType === UtilizationReportType.ENERGY_CONSUMPTION_RATE &&
      data?.distance &&
      typeof data.distance === 'number' &&
      daysOfOperation
    ) {
      totalDistance += data?.distance;
    }

    if (
      utilizationType === UtilizationReportType.ENERGY_CONSUMPTION_RATE &&
      data?.energyUsed &&
      typeof data.energyUsed === 'number'
    ) {
      totalEnergy += data?.energyUsed;
    }
  });

  switch (utilizationType) {
    case UtilizationReportType.DISTANCE_DRIVEN:
    case UtilizationReportType.ENERGY_CONSUMPTION:
      average =
        daysOfOperation !== 0
          ? Math.round(totalNonZeroValue / daysOfOperation)
          : 0;
      break;
    case UtilizationReportType.VEHICLE_ON_TIME:
      average = daysOfOperation !== 0 ? totalNonZeroValue / daysOfOperation : 0;
      break;
    case UtilizationReportType.ENERGY_CONSUMPTION_RATE:
      average =
        totalDistance !== 0
          ? parseFloat(getRoundedValue(totalEnergy / totalDistance, 2))
          : 0;
      break;
  }

  if (average > maxDataValue) {
    maxDataValue = average;
  }

  return average;
};

const barChartDatumSorter = (a: { date: string }, b: { date: string }) =>
  numberSortCompare(
    'asc',
    new Date(a.date as string).getTime(),
    new Date(b.date as string).getTime()
  );

export const getDistanceThresholdValues = (
  viewType?: UtilizationViewType,
  numberOfUtilizedAssets?: number
) => {
  const thresholdValues = {
    mi: DEFAULT_DISTANCE_THRESHOLD_MI,
    km: DEFAULT_DISTANCE_THRESHOLD_KM,
  };
  const numberOfAssets = numberOfUtilizedAssets || 0;
  switch (viewType) {
    case UtilizationViewType.ASSET_DETAILS: {
      return thresholdValues;
    }
    case UtilizationViewType.DASHBOARD: {
      // threshold per Zevo (multiplied by a constant utilization factor of 20%)
      thresholdValues.mi = DEFAULT_DISTANCE_THRESHOLD_MI * numberOfAssets * 0.2;
      thresholdValues.km = DEFAULT_DISTANCE_THRESHOLD_KM * numberOfAssets * 0.2;
      return thresholdValues;
    }
  }
  return thresholdValues;
};
