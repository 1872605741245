import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  highlightedLabel: {
    color: theme.new.color.brandPrimary,
  },
  resultLabel: {
    flex: '1 1 100%',
  },
}));

export default useStyles;
