export type Permission = `${keyof typeof PermissionType}.${
  | keyof typeof BaseAction
  | keyof typeof ProfileAction
  | keyof typeof OperationAction
  | keyof typeof AssetAction
  | keyof typeof WorkPlanOperationAction
  | keyof typeof PeripheralOperationAction
  | keyof typeof PeripheralBoardingOperationAction}`;

export enum PermissionType {
  Organization,
  Profile,
  Hub,
  Fleet,
  Asset,
  Alert,
  Integration,
  Job,
  'Pilot.PDR.Jobs',
  Order,
  Insight,
  Inspection,
  WorkPlan,
  Peripheral,
  'Boarding.Peripheral',
}

export enum BaseAction {
  Create,
  Read,
  Update,
  Delete,
  Invite,
  List,
}

export enum ProfileAction {
  AssignProfile,
  UnassignProfile,
}

export enum OperationAction {
  PerformOperation,
  ModifyProtectedValues,
}

export enum AssetAction {
  ModifyHardwareID,
  ManageHardware,
  SetHub,
  SetFleet,
  'Update.State.Availability',
}

export enum WorkPlanOperationAction {
  AssignOperator,
  SetStatus,
}

export enum PeripheralBoardingOperationAction {
  Offboard,
  BatchOnboardStatus,
  BatchOnboard,
}

export enum PeripheralOperationAction {
  LinkToVehicle,
  UnlinkFromVIN,
  SetFleet,
}

export enum PermissionScope {
  SELF = 'Self',
  ALL = 'All',
  NONE = 'None',
  TECHNICIAN = 'Technician',
  SERVICEMANAGER = 'ServiceManager',
}
