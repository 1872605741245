import { Theme } from '@mui/material/styles';
import { CSSObject } from 'styled-components';

import { IconButtonProps } from './IconButton.model';

export const generateButtonCss = (
  theme: Theme,
  props: IconButtonProps
): CSSObject => {
  return {
    borderRadius: '50px',
    cursor: 'pointer',
    margin: props.margin,
    fill: theme.new.color.textPrimary,
    stroke: theme.new.color.textPrimary,

    '&.medium': {
      height: '40px',
      width: '40px',
    },

    '&.small': {
      height: '32px',
      width: '32px',
    },

    '&.outline': {
      'svg, path': {
        stroke: 'inherit',
        fill: 'none',
      },
    },

    '&.fill': {
      'svg, path': {
        fill: 'inherit',
        stroke: 'none',
      },
    },

    '&:focus, &:hover': {
      backgroundColor: theme.new.color.brand[100],
    },

    '&:active': {
      fill: theme.new.color.brandPrimary,
      stroke: theme.new.color.brandPrimary,
    },

    '&.Mui-disabled': {
      fill: theme.new.color.text.tertiary,
      stroke: theme.new.color.text.tertiary,
    },
  };
};
