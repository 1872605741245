import { useTranslations } from '@brightdrop/localization-client';
import {
  Dispatch,
  ReactElement,
  SetStateAction,
  useCallback,
  useState,
} from 'react';

import {
  FormState,
  ValidationSchema,
  ValidationSchemaAdapter,
} from '../models/form.model';

const useForm = <
  FormFields extends { [field: string | number]: unknown },
  ValidFormFields extends FormFields
>(
  initialState: FormState<FormFields>,
  validationSchema: ValidationSchema<FormState<FormFields>>,
  options = {} as {
    stateAdapter: <P extends keyof FormFields>(
      currentState: FormState<FormFields>,
      formField: P,
      formValue: FormFields[P]
    ) => FormState<FormFields>;
  }
): {
  isDirty: boolean;
  formState: FormState<FormFields>;
  setFormState: Dispatch<SetStateAction<FormState<FormFields>>>;
  formStateIsValid: (formState: FormState<FormFields>) => formState is {
    [k in keyof ValidFormFields]: {
      value: ValidFormFields[k];
      error: '';
      isDirty: false;
    };
  };
  handleChange: <P extends keyof FormFields>(
    formField: P,
    formValue: FormFields[P]
  ) => void;
  validateFormValue: <P extends keyof FormFields>(
    formField: P,
    formValue: FormFields[P]
  ) => void;
  updateErrors: () => void;
} => {
  const [formState, setFormState] = useState(initialState);
  const [isDirty, setIsDirty] = useState(false);
  const { translations } = useTranslations({
    'common:errors.requiredField': 'Required field',
  });

  const fieldIsRequired = useCallback(
    <P extends keyof FormFields>(
      formField: P,
      formValue: FormFields[P],
      formState: FormState<FormFields>
    ) => {
      const fieldSchema =
        validationSchema[
          formField as keyof ValidationSchema<FormState<FormFields>>
        ];
      const required = fieldSchema.required as
        | boolean
        | ValidationSchemaAdapter<boolean, FormState<FormFields>, P>;
      return (
        (typeof required === 'boolean'
          ? required
          : required(formValue, formState)) &&
        !(formValue || typeof formValue === 'boolean')
      );
    },
    [validationSchema]
  );

  const getRequiredMessage = useCallback(
    <P extends keyof FormFields>(
      formField: P,
      formValue: FormFields[P],
      formState: FormState<FormFields>
    ) => {
      const fieldSchema =
        validationSchema[
          formField as keyof ValidationSchema<FormState<FormFields>>
        ];
      const requiredMessage = fieldSchema.requiredMessage as
        | string
        | ValidationSchemaAdapter<string, FormState<FormFields>, P>;
      return requiredMessage
        ? typeof requiredMessage === 'string'
          ? requiredMessage
          : requiredMessage(formValue, formState)
        : translations['common:errors.requiredField'];
    },
    [validationSchema, translations]
  );

  /**
   * Check validity of formState without triggering side-effects
   */
  const formStateIsValid = useCallback(
    (
      formState: FormState<FormFields>
    ): formState is {
      [k in keyof ValidFormFields]: {
        value: ValidFormFields[k];
        error: '';
        isDirty: false;
      };
    } => {
      const hasError = Object.keys(validationSchema).some((field) => {
        const formValue = formState[field as keyof FormState<FormFields>].value;
        const formError = formState[field as keyof FormState<FormFields>].error;
        return fieldIsRequired(field, formValue, formState) || !!formError;
      });
      return !hasError;
    },
    [validationSchema, fieldIsRequired]
  );

  /**
   * Validate provided form field using current validationSchema
   */
  const validateFormValue = useCallback(
    <P extends keyof FormFields>(formField: P, formValue: FormFields[P]) => {
      setFormState((previousState) => {
        const newState = {
          ...previousState,
          [formField]: { value: formValue, errors: '', isDirty: false },
        };

        let error: string | ReactElement = '';

        if (fieldIsRequired(formField, formValue, newState)) {
          error = getRequiredMessage(formField, formValue, newState);
        }

        if (
          Array.isArray(validationSchema[formField].validators) &&
          formValue
        ) {
          const validators = (validationSchema[formField].validators ||
            []) as Array<{
            validator: ValidationSchemaAdapter<
              boolean,
              FormState<FormFields>,
              P
            >;
            error:
              | string
              | ReactElement
              | ValidationSchemaAdapter<string, FormState<FormFields>, P>;
          }>;
          const fieldError = validators.find(
            (v) => !v.validator(formValue, previousState)
          )?.error;

          if (fieldError) {
            error =
              typeof fieldError !== 'function'
                ? fieldError
                : fieldError(formValue, previousState);
          }
        }

        return {
          ...previousState,
          [formField]: {
            value: formValue,
            error,
            isDirty: initialState[formField].value !== formValue,
          },
        };
      });
    },

    [fieldIsRequired, validationSchema, initialState, getRequiredMessage]
  );

  /**
   * Apply any additional logic to the state after validation
   */
  const adaptState = useCallback(
    (formField, formValue) => {
      if (options.stateAdapter) {
        setFormState((currentState) =>
          options.stateAdapter(currentState, formField, formValue)
        );
      }
    },
    [options, setFormState]
  );

  /**
   * Dirty the form, validate change, and apply post-validation state adapter logic
   */
  const handleChange = useCallback(
    <P extends keyof FormFields>(
      formField: P,
      formValue: FormFields[P]
    ): void => {
      setIsDirty(true);
      validateFormValue(formField, formValue);
      adaptState(formField, formValue);
    },
    [setIsDirty, validateFormValue, adaptState]
  );

  /**
   * Trigger validation on all form fields, updating form state
   */
  const updateErrors = useCallback(() => {
    Object.keys(formState).forEach((key) => {
      const field = key as keyof typeof formState;
      validateFormValue(field, formState[field].value);
    });
  }, [formState, validateFormValue]);

  return {
    isDirty,
    formState,
    setFormState,
    formStateIsValid,
    handleChange,
    validateFormValue,
    updateErrors,
  };
};

export default useForm;
