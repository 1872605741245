import LinearProgress from '@mui/material/LinearProgress';

import useStyles from './BDSpinner.styles';

const BDSpinner = (): JSX.Element => {
  const { classes } = useStyles();
  return <LinearProgress className={classes.spinner} data-testid="spinner" />;
};

export default BDSpinner;
