import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  resultContainer: {
    borderRadius: '8px',
    cursor: 'pointer',
    display: 'flex',
    flexFlow: 'column',
    padding: '8px 12px',
    width: '100%',

    '&:hover': {
      background: theme.new.color.brand[100],
    },
  },

  selectedResult: {
    background: theme.new.color.brand[200],

    '&:hover': {
      background: theme.new.color.brand[200],
    },
  },
}));

export default useStyles;
