import { cx } from '@emotion/css';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import Paper from '@mui/material/Paper';
import Select from '@mui/material/Select';
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';
import isNumber from 'lodash/isNumber';
import {
  PropsWithChildren,
  ReactNode,
  useCallback,
  useMemo,
  useState,
} from 'react';

import useStyles from './WidgetCard.styles';

export enum CardType {
  SUCCESS = 'success',
  ERROR = 'error',
  WARNING = 'warning',
  NONE = 'none',
  DEFAULT = 'default',
}
export interface WidgetCardProps {
  title?: string;
  tools?: ReactNode;
  icon?: ReactNode;
  type?: CardType;
  collapsible?: boolean;
  loading?: boolean;
  headerPosition?: { bottom?: boolean; left?: boolean };
  onClick?: () => void;
  open?: boolean;
  count?: number;
  sortOptions?: Array<string>;
  saveExpandedState?: boolean;
  onExpandClick?: (expanded: boolean) => void;
  elevation?: number;
  widgetContentSkeletonHeight?: number;
}

const WidgetCard = ({
  title,
  tools,
  icon,
  type = CardType.DEFAULT,
  collapsible,
  loading,
  headerPosition,
  onClick,
  children,
  open,
  count,
  sortOptions,
  saveExpandedState,
  onExpandClick,
  elevation = 1,
  widgetContentSkeletonHeight,
}: PropsWithChildren<WidgetCardProps>): JSX.Element => {
  const { classes } = useStyles({ widgetContentSkeletonHeight });
  const [expanded, setExpanded] = useState(open);

  const handleAccordionChange = useCallback(() => {
    setExpanded((currentValue) => !currentValue);
    if (saveExpandedState && onExpandClick) {
      onExpandClick(!expanded);
    }
  }, [expanded, saveExpandedState, onExpandClick]);
  const handleSortChange = useCallback((sortType: string) => {
    //TODO:  US16223 - [web] Alerts Feed - Search, Sort, Filter
    return sortType
  }, []);

  const header = useMemo(
    () =>
      loading ? (
        <Skeleton className={classes.headerSkeleton} variant="rectangular" />
      ) : (
        <>
          {title && (
            <Box className={classes.title}>
              {icon && (
                <div className={cx(classes.icon, { [type as string]: true })}>
                  {icon}
                </div>
              )}
              <Typography variant='h4_deprecated'>{title}</Typography>
              {isNumber(count) && (
                <>
                  <Typography className={classes.count} variant='h4_deprecated'>
                    ({count})
                  </Typography>
                </>
              )}
            </Box>
          )}

          {sortOptions && (
            <>
              <Box
                className={classes.sortContainer}
                onClick={() => {
                  setExpanded((currentValue) => !currentValue);
                }}
              >
                <Select
                  value={'latest'}
                  onChange={(e) => {
                    handleSortChange(e.target.value as string);
                  }}
                >
                  <MenuItem value={'latest'}>
                    {sortOptions && sortOptions[0]}
                  </MenuItem>
                </Select>
              </Box>
            </>
          )}
          {tools}
        </>
      ),
    [
      loading,
      classes.headerSkeleton,
      classes.title,
      classes.icon,
      classes.count,
      classes.sortContainer,
      icon,
      type,
      title,
      count,
      sortOptions,
      tools,
      handleSortChange,
    ]
  );

  const body = useMemo(
    () =>
      loading ? (
        <Skeleton className={classes.contentSkeleton} variant="rectangular" />
      ) : (
        <Box className={classes.content}>{children}</Box>
      ),
    [children, classes.content, classes.contentSkeleton, loading]
  );

  return (
    <Paper
      className={cx(classes.paper, {
        clickable: !!onClick,
      })}
      elevation={elevation}
      onClick={onClick}
    >
      {collapsible ? (
        <Accordion
          expanded={expanded}
          elevation={0}
          onChange={handleAccordionChange}
        >
          <AccordionSummary
            className={classes.accordionSummary}
            expandIcon={<KeyboardArrowDownIcon />}
          >
            {header}
          </AccordionSummary>
          <AccordionDetails className={classes.accordionDetails}>
            {body}
          </AccordionDetails>
        </Accordion>
      ) : (
        <Box
          className={cx(classes.cardContainer, {
            bottomHeader: headerPosition?.bottom,
            leftHeader: headerPosition?.left,
          })}
        >
          {header}
          {body}
        </Box>
      )}
    </Paper>
  );
};

export default WidgetCard;
