import { RumInitConfiguration } from '@datadog/browser-rum';

// Defines common Data Dog configuration settings
const DATADOG_CONFIG = {
  site: 'us3.datadoghq.com',
  service: 'bd-web-app',
  sessionSampleRate: 100,
};

/* RUM configs */
export const DATADOG_RUM_DEFAULT_CONFIG: RumInitConfiguration = {
  applicationId: globalThis.appConfig.instrumentationAppId,
  clientToken: globalThis.appConfig.instrumentationClientToken,
  site: DATADOG_CONFIG.site,
  service: DATADOG_CONFIG.service,
  env: globalThis.appConfig.instrumentationEnv,
  // Specify a version number to identify the deployed version of your application in Datadog
  version: globalThis.appConfig.version,
  sessionSampleRate: DATADOG_CONFIG.sessionSampleRate,
  sessionReplaySampleRate: 0,
  trackUserInteractions: true,
  trackResources: true,
  trackLongTasks: true,
  defaultPrivacyLevel: 'allow',
  excludedActivityUrls: [
    //Do not track App Insights Activity and Errors
    'https://dc.services.visualstudio.com/v2/track',
  ],
  allowedTracingUrls: [
    {
      match: (url: string) =>
        globalThis.appConfig.apiBaseUrl
          ? url.startsWith(globalThis.appConfig.apiBaseUrl)
          : false,
      propagatorTypes: ['tracecontext'],
    },
  ],
  beforeSend: (log) => {
    if (
      (log.resource as { url: string })?.['url'] ===
      'https://dc.services.visualstudio.com/v2/track'
    ) {
      return false;
    }
    return true;
  },
};

/* log configs */
export const DATADOG_LOGS_DEFAULT_CONFIG = {
  clientToken: globalThis.appConfig.instrumentationClientToken,
  site: DATADOG_CONFIG.site,
  service: DATADOG_CONFIG.service,
  env: globalThis.appConfig.instrumentationEnv,
  // Specify a version number to identify the deployed version of your application in Datadog
  version: globalThis.appConfig.version,
  forwardErrorsToLogs: true,
  sessionSampleRate: DATADOG_CONFIG.sessionSampleRate,
};
