import { ChartType } from 'chart.js';
import isEmpty from 'lodash/isEmpty';
import round from 'lodash/round';
import { DateTime } from 'luxon';
import { ReactNode } from 'react';
import { Trans } from 'react-i18next';

import {
  numberSortCompare,
  stringSortCompare,
} from '~/common/components/table/utils/table.utils';
import {
  DEFAULT_EMPTY_VALUE,
  LanguageLocale,
} from '~/common/constants/common.constant';
import {
  InsightChartColors,
  InsightChartDetails,
  InsightChartTooltip,
  InsightChartType,
  InsightCostPatchDetails,
  InsightDetails,
  InsightDetailType,
  InsightLinePlotData,
  InsightListDetails,
  InsightRecommendedSetting,
  InsightRecommendedSettingsType,
  InsightSavings,
  InsightStateOfChargeData,
  InsightTranslationBundle,
  InsightType,
  InsightTypeDetails,
} from '~/common/models/insight.model';
import { format } from '~/common/utils/date-time.utils';

import { INSIGHT_CHART_MESSAGES } from '../card/chart/InsightChart';
import { INSIGHT_CARD_MESSAGES } from '../card/InsightCard';
import BatteryHealthChartToolTip from '../card/tooltip/BatteryHealthChartToolTip';
import ElectricityCostChartToolTip from '../card/tooltip/ElectricityCostChartToolTip';
import { INSIGHT_TRANSLATION_PREFIX } from '../insights.constant';
import {
  mapCostPatchChartData,
  mapLineChartData,
} from '../mappers/insights.mappers';

export const getInsightTypeName = (
  type: InsightType,
  labels: { [index: string]: string }
): string => {
  let name = '';
  switch (type) {
    case InsightType.ELECTRICITY_COST:
      name = labels['insight:type.electricityCost'];
      break;
    case InsightType.BATTERY_HEALTH:
      name = labels['insight:type.batteryHealth'];
      break;
    case InsightType.FUEL_COST_EMISSIONS:
      name = labels['insight:type.fuelCostAndEmissions'];
      break;
  }
  return name;
};

export const getInsightChartName = (
  type: InsightChartType,
  labels: { [index: string]: string }
): string => {
  let name = '';
  switch (type) {
    case InsightChartType.ENERGY_COST:
      name = labels['insight:card.chart.tabs.energyCost'];
      break;
    case InsightChartType.DEMAND_COST:
    case InsightChartType.DEMAND_CHARGE:
      name = labels['insight:card.chart.tabs.demandCharge'];
      break;
    case InsightChartType.TIME_SPENT_ABOVE_SOC_THRESHOLD:
      name = labels['insight:card.chart.tabs.averageTimeSpentInSocBin'];
      break;
  }
  return name;
};

export const buildElectricityCostLinePlotData = (
  data: InsightLinePlotData,
  labels: typeof INSIGHT_CHART_MESSAGES,
  colors: Array<string>
) => {
  const { historicalLineData, recommendationLineData } = data;
  return [
    {
      type: 'line' as const,
      label: labels.legend['insight:card.chart.legend.recommendation'],
      data: recommendationLineData,
      borderColor: colors?.[0],
      backgroundColor: colors?.[0],
      borderWidth: 3,
      order: 1,
      stepped: 'before',
      elements: {
        point: {
          poinHoverBorderColor: colors?.[0],
        },
      },
    },
    {
      type: 'line' as const,
      label: labels.legend['insight:card.chart.legend.historical'],
      data: historicalLineData,
      borderColor: colors?.[1],
      backgroundColor: colors?.[1],
      borderWidth: 3,
      order: 2,
      stepped: 'before',
      elements: {
        point: {
          poinHoverBorderColor: colors?.[1],
        },
      },
    },
  ];
};

export const getInsightTitle = (
  type: InsightDetailType,
  labels: { [index: string]: string }
): string => {
  let name = '';
  switch (type) {
    case InsightDetailType.REDUCE_ENERGY_COST:
    case InsightDetailType.REDUCE_ENERGY_DEMAND_COST:
    case InsightDetailType.REDUCE_DEMAND_COST:
    case InsightDetailType.NO_RECOMMENDATION_ON_ELECTRICITY_COST:
      name = labels['insight:card.title.reduceElectricityCost'];
      break;
    case InsightDetailType.REDUCE_SOC_TARGET:
    case InsightDetailType.NO_RECOMMENDATION_ON_BATTERY_HEALTH:
      name = labels['insight:card.title.increaseBatteryLifespan'];
      break;
    case InsightDetailType.FUEL_COST_EMISSIONS_SAVINGS:
      name = labels['insight:card.title.lifetimeFuelAndEmissionSavings'];
      break;
  }
  return name;
};

export const getInsightMessage = (
  key: string,
  labels: { [index: string]: string }
): string => {
  return labels[`${INSIGHT_TRANSLATION_PREFIX}${key}`] || key;
};

export const formatCurrency = (
  value: number,
  locale: string,
  currency = 'USD',
  maximumFractionDigits = 0
): string => {
  return value.toLocaleString(locale, {
    style: 'currency',
    currency,
    maximumFractionDigits,
  });
};

export const convertTimestampToAxisValue = (
  value: string,
  locale: string,
  timeZone?: string
): number => {
  const startAtDate = DateTime.fromJSDate(new Date(value))
    .setLocale(locale)
    .setZone(timeZone);
  const startAtHoursValue = startAtDate
    .setLocale(locale)
    .setZone(timeZone)
    .get('hour');
  const startAtMinutesValue = startAtDate
    .setLocale(locale)
    .setZone(timeZone)
    .get('minute');
  return startAtHoursValue + startAtMinutesValue / 60;
};

export const buildElectricityCostPatchData = (
  data: InsightCostPatchDetails,
  chartType: InsightChartType,
  labels: typeof INSIGHT_CHART_MESSAGES,
  costPatchColors: Array<string>
) => {
  const datasets: any[] = [];
  Object.keys(data).forEach((_item, index, items) => {
    datasets.push({
      type: 'line' as const,
      label:
        items.length > 1
          ? `${getInsightChartName(chartType, labels.tooltipLabels)} ${
              index + 1
            }`
          : `${getInsightChartName(chartType, labels.tooltipLabels)}`,
      data: data[index],
      borderColor: costPatchColors?.[index],
      backgroundColor: costPatchColors?.[index],
      order: 3 + index,
      fill: true,
      stepped: true,
      showLine: false,
      pointStyle: 'false',
      radius: 0,
      pointHitRadius: 0,
      pointRadius: 0,
      hoverRadius: 0,
      tooltip: {
        callbacks: {
          label: () => {
            return '';
          },
        },
      },
    });
  });
  return [...datasets];
};

export const getInsightDescription = (
  type: InsightType,
  labels: typeof INSIGHT_CARD_MESSAGES.descriptions
): string => {
  let name = '';
  switch (type) {
    case InsightType.ELECTRICITY_COST:
      name = labels['insight:messages.insights.electricityCost.description'];
      break;
    case InsightType.BATTERY_HEALTH:
      name = labels['insight:messages.insights.batteryHealth.description'];
      break;
  }
  return name;
};

export const getInsightDateRange = ({
  startDateFormat,
  endDateFormat,
  locale,
  details,
}: {
  startDateFormat?: string;
  endDateFormat?: string;
  locale?: string;
  details?: InsightDetails;
}): string => {
  let name = '';
  if (details) {
    name = `${format(
      new Date(details.analysisStartAt),
      startDateFormat,
      locale,
      details.timeZone
    )} - ${format(
      new Date(details.analysisEndAt),
      endDateFormat,
      locale,
      details.timeZone
    )}`;
  }
  return name;
};

export const getInsightChartDate = (
  details: InsightChartDetails,
  dateFormat?: string,
  locale?: string
): string => {
  const { timeZone, timeSeriesData } = details;
  const dates = timeSeriesData?.factualPowerInKw
    ? Object.keys(timeSeriesData.factualPowerInKw)
    : false;
  return dates
    ? format(new Date(dates[0]), dateFormat, locale, timeZone)
    : DEFAULT_EMPTY_VALUE;
};

export const getInsightChartDateDescription = (
  chartType: InsightChartType,
  labels: { [index: string]: string }
): string => {
  let description = '';
  switch (chartType) {
    case InsightChartType.DEMAND_CHARGE:
    case InsightChartType.DEMAND_COST:
      description = labels['insight:card.chart.description.demandCost'];
      break;
    case InsightChartType.ENERGY_COST:
      description = labels['insight:card.chart.description.energyCost'];
      break;
  }
  return description;
};

export const getInsightItem = (
  data: InsightTranslationBundle,
  labels: { [index: string]: string }
): string | ReactNode => {
  if (isEmpty(data.parameters)) {
    return getInsightMessage(data.translationKey, labels);
  } else {
    let item = <></>;
    Object.keys(data.parameters).forEach((key) => {
      const value =
        typeof data?.parameters?.[key] === 'number' ? data.parameters[key] : 0;
      item = (
        <Trans
          data-testid={`insight-item-${key}`}
          i18nKey={getInsightMessage(data.translationKey, labels)}
          values={{
            [key]: value,
          }}
        />
      );
    });
    return item;
  }
};

export const getInsightSavingDetails = ({
  details,
  customSavings,
  locale = LanguageLocale.EN,
}: {
  details: InsightDetails;
  locale?: string;
  customSavings?: number;
}): InsightSavings => {
  const value = customSavings
    ? customSavings
    : typeof details?.mainMessage?.parameters?.['savingsInLcuPerMonth'] ===
      'number'
    ? details.mainMessage.parameters['savingsInLcuPerMonth']
    : 0;
  const currency =
    typeof details?.mainMessage?.formatParams?.['savingsInLcuPerMonth'] ===
    'object'
      ? typeof details?.mainMessage?.formatParams?.['savingsInLcuPerMonth'][
          'currency'
        ] === 'string'
        ? details.mainMessage.formatParams['savingsInLcuPerMonth']['currency']
        : undefined
      : undefined;
  return { value, displayValue: formatCurrency(value, locale, currency) };
};

export const insightListDefaultSortCompare = (
  a: InsightListDetails,
  b: InsightListDetails
) => {
  const dateA = format(new Date(a.date));
  const dateB = format(new Date(b.date));
  if (dateA !== dateB) {
    return stringSortCompare('desc', a.date, b.date);
  }
  const savingsA = a?.typeDetails?.map(
    (item: InsightTypeDetails) => item?.savings?.value || 0
  ) || [0];
  const savingsB = b?.typeDetails?.map(
    (item: InsightTypeDetails) => item?.savings?.value || 0
  ) || [0];
  return numberSortCompare(
    'desc',
    Math.max(...savingsA),
    Math.max(...savingsB)
  );
};

export const buildChartDataset = ({
  details,
  labels,
  locale = LanguageLocale.EN,
  timeFormat,
  chartType,
  chartColors,
}: {
  details: InsightChartDetails;
  chartType: InsightChartType;
  chartColors: InsightChartColors;
  labels: typeof INSIGHT_CHART_MESSAGES;
  locale?: string;
  timeFormat?: string;
}) => {
  const defaultDataset = { datasets: [] };
  switch (chartType) {
    case InsightChartType.DEMAND_COST:
    case InsightChartType.ENERGY_COST:
      if (details?.timeSeriesData && details?.costPatches) {
        const linePlotData = mapLineChartData({
          data: details.timeSeriesData,
          locale,
          timeZone: details.timeZone,
        });
        const costPatchData = mapCostPatchChartData({
          data: details.costPatches,
          max: linePlotData.max,
          locale,
          timeZone: details.timeZone,
          timeFormat,
          chartType,
        });
        return {
          datasets: [
            ...buildElectricityCostLinePlotData(
              linePlotData,
              labels,
              chartColors.line
            ),
            ...buildElectricityCostPatchData(
              costPatchData,
              chartType,
              labels,
              chartColors.costPatch
            ),
          ],
        };
      }
      break;
    case InsightChartType.TIME_SPENT_ABOVE_SOC_THRESHOLD: {
      if (details?.bins) {
        const data = details.bins.map((item: InsightStateOfChargeData) =>
          round(item.hours, 1)
        );
        const labels = getBatteryHealthChartLabels(details.bins);
        return {
          labels,
          datasets: [
            {
              data,
              backgroundColor: [...chartColors.bar],
              borderColor: [...chartColors.bar],
              barPercentage: 0.5,
              categoryPercentage: 0.5,
              borderRadius: 5,
              align: 'center' as const,
              type: 'bar' as ChartType,
            },
          ],
        };
      }
    }
  }
  return defaultDataset;
};

export const getInsightChartType = (type: InsightChartType): ChartType => {
  let chartType = '';
  switch (type) {
    case InsightChartType.ENERGY_COST:
    case InsightChartType.DEMAND_COST:
    case InsightChartType.DEMAND_CHARGE:
      chartType = 'scatter';
      break;
    case InsightChartType.TIME_SPENT_ABOVE_SOC_THRESHOLD:
      chartType = 'bar';
      break;
    case InsightChartType.FUEL_COST_SAVINGS:
      chartType = 'bar';
      break;
  }
  return chartType as ChartType;
};

export const getInsightChartCustomTooltip = (
  type: InsightChartType,
  tooltipData: InsightChartTooltip
): ReactNode | undefined => {
  switch (type) {
    case InsightChartType.DEMAND_COST:
    case InsightChartType.ENERGY_COST:
      return <ElectricityCostChartToolTip tooltip={tooltipData} />;
    case InsightChartType.TIME_SPENT_ABOVE_SOC_THRESHOLD:
      return <BatteryHealthChartToolTip tooltip={tooltipData} />;
  }
};

export const getBatteryHealthChartLabels = (
  bins: Array<InsightStateOfChargeData>
): Array<string> => {
  return bins.map((item: InsightStateOfChargeData) => {
    return `${Math.floor(item.socLow)}-${Math.floor(item.socHigh)}%`;
  });
};

export const getInsightCardRecommedationTitle = (
  labels: { [index: string]: string },
  data?: Array<InsightTranslationBundle>,
  hasRecommendations?: boolean
): string => {
  return hasRecommendations
    ? data?.length && data?.length > 1
      ? labels['insight:card.ourRecommendations']
      : labels['insight:card.ourRecommendation']
    : labels['insight:card.noRecommendations'];
};

export const hasInsightRecommendations = (type: InsightDetailType): boolean => {
  switch (type) {
    case InsightDetailType.NO_RECOMMENDATION_ON_ELECTRICITY_COST:
    case InsightDetailType.NO_RECOMMENDATION_ON_BATTERY_HEALTH:
      return false;
    default:
      return true;
  }
};

export const getRecommendedSettings = (
  type: InsightRecommendedSettingsType,
  settings?: Array<InsightRecommendedSetting>
): InsightRecommendedSetting | undefined => {
  return settings?.find((item: InsightRecommendedSetting) => type in item);
};
