import { DEFAULT_EMPTY_VALUE } from '~/common/constants/common.constant';
import {
  AlertCardAssetAlerts,
  AlertCategory,
  AlertContentVariableMapKeys,
  AlertDetailsMap,
  AlertEvent,
  AlertLink,
  AlertType,
  AlertUnread,
  ApiAlertDetail,
  ApiAlertEntity,
  ApiAlertUnread,
  AssetAlert,
  AvailableSubscription,
  DiagnosticEvent,
  SubscriptionDetails,
  SubscriptionType,
} from '~/common/models/alert.model';
import { BDAppErrorType, BDError } from '~/common/models/error.model';
import { VehicleSubscriberType } from '~/common/models/notification.model';
import { RouteName } from '~/common/models/route.model';
import { toDateStringWithTimezone } from '~/common/utils/date-time.utils';
import { findRoute, makeRoutePath } from '~/common/utils/route/route.utils';
import { NOTIFICATION_SETTINGS_MESSAGES } from '~/features/settings/settings.messages';
import { getDiagnosticEventName } from '~/features/settings/settings.utils';

import { mapApiResponseToType } from '../../assets/mappers/asset.mappers';
import { AllowedContexts } from '../../profile/profileSlice';
import { AlertParams } from '../alertsSlice';

export const mapAlertLink = (
  organizationsId?: string,
  hubsId?: string,
  fleetsId?: string,
  sourceId?: string,
  sourceName?: string,
  findAssetExist?: boolean
): AlertLink => {
  if (sourceName && sourceId) {
    if (findAssetExist) {
      return {
        id: sourceId,
        name: sourceName,
        url: makeRoutePath(findRoute(RouteName.ASSET_DETAILS), {
          assetsId: sourceId,
          organizationsId,
          hubsId,
          fleetsId,
        }),
      };
    } else
      return {
        id: sourceId,
        name: sourceName,
      };
  }
  return {
    id: DEFAULT_EMPTY_VALUE,
    name: DEFAULT_EMPTY_VALUE,
    url: DEFAULT_EMPTY_VALUE,
  };
};

export const mapAlertApiEntityToAlertHistoryDetail = (
  {
    sourceId: assetId,
    sourceType,
    lastContentVariableMap,
    ...apiAlertDetail
  }: Partial<ApiAlertEntity>,
  { organizationsId = '' }: AlertParams,
  allowedContexts?: AllowedContexts
): AlertCardAssetAlerts => {
  if (assetId && sourceType === 'asset') {
    const hubId = lastContentVariableMap?.[AlertContentVariableMapKeys.HUB_ID];
    const fleetId =
      lastContentVariableMap?.[AlertContentVariableMapKeys.FLEET_ID];
    const model = lastContentVariableMap?.[
      AlertContentVariableMapKeys.ASSET_TYPE
    ] as string;
    const assetName =
      lastContentVariableMap?.[AlertContentVariableMapKeys.ALERT_OBJECT_ALIAS];
    return {
      assetId,
      assetName,
      type: model ? mapApiResponseToType({ model }) : undefined,
      hub: hubId
        ? {
            id: hubId,
            name: allowedContexts?.[organizationsId]?.hubs?.[hubId]?.name,
          }
        : undefined,
      fleet: fleetId
        ? {
            id: fleetId,
            name: hubId
              ? allowedContexts?.[organizationsId]?.hubs?.[hubId]?.fleets?.[
                  fleetId
                ]?.name
              : undefined,
          }
        : undefined,
    } as AlertCardAssetAlerts;
  }

  throw new BDError('Unexpected alert response', {
    type: BDAppErrorType.VALIDATION,
    data: apiAlertDetail,
  });
};

export const mapAlertApiToAlertDetail = (
  {
    id,
    event,
    read,
    organizationId,
    subscriptionId,
    ...apiAlertDetail
  }: Partial<ApiAlertDetail>,
  allowedContexts?: AllowedContexts
): AssetAlert => {
  if (id && event?.eventTimestamp) {
    const hubId =
      event.contentVariableMap?.[AlertContentVariableMapKeys.HUB_ID];
    const fleetId =
      event.contentVariableMap?.[AlertContentVariableMapKeys.FLEET_ID];
    const model = event.contentVariableMap?.[
      AlertContentVariableMapKeys.ASSET_TYPE
    ] as string;
    return {
      id,
      assetName:
        event.contentVariableMap?.[
          AlertContentVariableMapKeys.ALERT_OBJECT_ALIAS
        ],
      type: model ? mapApiResponseToType({ model }) : undefined,
      hub: hubId
        ? {
            id: hubId,
            name: organizationId
              ? allowedContexts?.[organizationId]?.hubs?.[hubId]?.name
              : undefined,
          }
        : undefined,
      fleet: fleetId
        ? {
            id: fleetId,
            name:
              organizationId && hubId
                ? allowedContexts?.[organizationId]?.hubs?.[hubId]?.fleets?.[
                    fleetId
                  ]?.name
                : undefined,
          }
        : undefined,
      eventType: event.event,
      eventTimestamp: event?.eventTimestamp
        ? toDateStringWithTimezone(event?.eventTimestamp)
        : undefined,
      contentVariableMap: event?.contentVariableMap
        ? event.contentVariableMap
        : undefined,
      read,
      subscriptionId,
    } as AssetAlert;
  }

  throw new BDError('Unexpected alert response', {
    type: BDAppErrorType.VALIDATION,
    data: apiAlertDetail,
  });
};

export const mapAlertUnreadApiResponseToAlertUnread = (
  apiAlertUnread: Partial<ApiAlertUnread>
): AlertUnread => {
  return {
    ...apiAlertUnread,
    hasUnread: apiAlertUnread.hasUnread,
    approximateCount: apiAlertUnread.approximateCount,
  };
};

export const mapAlertMessage = (
  alertType: string,
  labels: {
    [index: string]: string;
  }
): string => {
  switch (alertType) {
    case AlertType.LOW_BATTERY:
    case AlertType.LOW_BRAKE_FLUID:
    case AlertType.LOW_WASHER_FLUID:
      return labels['alerts:feed.detail.isLowOn'];
    case AlertType.LOW_TIRE_PRESSURE:
      return labels['alerts:feed.detail.zevoHasLowTirePressure'];
    case AlertType.FLAT_TIRE:
      return labels['alerts:feed.detail.zevoHasFlatTire'];
    default:
      return DEFAULT_EMPTY_VALUE;
  }
};

export const mapAlertStatus = (
  alertType: string,
  variableMap: AlertDetailsMap,
  labels: {
    [index: string]: string;
  }
): string => {
  switch (alertType) {
    case AlertType.LOW_BATTERY:
      return variableMap?.battery_soc || variableMap?.battery_soc === 0
        ? `${labels['alerts:feed.detail.charge']} (${variableMap.battery_soc}%)`
        : '';
    case AlertType.LOW_BRAKE_FLUID:
      return labels['alerts:feed.detail.brakeFluid'];
    case AlertType.LOW_WASHER_FLUID:
      return labels['alerts:feed.detail.washerFluid'];
    default:
      return DEFAULT_EMPTY_VALUE;
  }
};

export const mapAlertEventName = (
  apiEvent: string,
  labels: typeof NOTIFICATION_SETTINGS_MESSAGES
): string => {
  switch (apiEvent) {
    case AlertEvent.ACCOUNT_UPDATE:
      return labels['settings:notification.card.name.profileUpdates'];
    case AlertEvent.REMOVE_USER:
      return labels['settings:notification.card.name.removeUser'];
    case AlertEvent.ADD_USER:
      return labels['settings:notification.card.name.addUser'];
    case AlertEvent.ONBOARDING_VEHICLE:
      return labels['settings:notification.card.name.addVehicle'];
    case AlertEvent.DEFAULT_DIAGNOSTICS:
      return labels['settings:notification.card.name.defaultDiagnostics'];
    default:
      return DEFAULT_EMPTY_VALUE;
  }
};

export const mapSubscriptionTypesToAvailableSubscriptions = (
  type: SubscriptionType,
  labels: typeof NOTIFICATION_SETTINGS_MESSAGES
): AvailableSubscription => {
  return {
    id: '',
    name: mapAlertEventName(type.event, labels),
    category: type.category as AlertCategory,
    event: type.event as AlertEvent,
    selectId: `${type.category}*${type.event}`,
    receiptPreferences: [],
    eventOptions: [],
  };
};

export const mapSubscriptionsToAvailableSubscription = (
  sub: SubscriptionDetails,
  labels: typeof NOTIFICATION_SETTINGS_MESSAGES
): AvailableSubscription => {
  const {
    id,
    organizationId,
    profileId,
    active,
    registrations,
    receiptPreferences,
    preferredName,
    assets,
  } = sub;
  const event = registrations[0].event as AlertEvent;
  const category = registrations[0].category;
  const name =
    category === AlertCategory.DIAGNOSTIC
      ? preferredName
        ? preferredName
        : labels['settings:notification.card.name.defaultDiagnostics']
      : mapAlertEventName(event, labels);

  return {
    id: id || '',
    name,
    subscriptionId: id,
    organizationId,
    profileId,
    active,
    category,
    event,
    selectId: `${category}*${event}`,
    preferredName,
    receiptPreferences: receiptPreferences || [],
    eventOptions:
      category === AlertCategory.DIAGNOSTIC
        ? registrations.map((item) => {
            return {
              event: item.event,
              category: item.category,
              selectId: `${item.category}*${item.event}`,
              active: true,
              id: id || '',
              displayName: getDiagnosticEventName(
                item.event as DiagnosticEvent,
                labels
              ),
            };
          })
        : [],
    ...(assets?.length && {
      selectedAssets: assets,
    }),
    selectedVehicleSubscriberType: assets?.length
      ? VehicleSubscriberType.SELECTED_VEHICLES
      : VehicleSubscriberType.ALL_VEHICLES,
  };
};
