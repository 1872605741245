import { BDRequestType } from './apis/apiResponse.model';
import { SeverityLevel } from './common.model';

export enum BDAppErrorType {
  API,
  VALIDATION,
}

export interface BDError<T = unknown> extends Error {
  status?: number;
  type?: BDAppErrorType;
  code?: string;
  messageKey?: string;
  requestId?: string;
  requestType?: BDRequestType;
  response?: T;
  severity?: SeverityLevel;
  data?: unknown;
}

// TODO: Add this type back when we rebuild errors
// export interface ApiError extends BDError {
//   errorCode: string;
//   errorType: BDAppErrorType.API;
//   message: string;
// }

export interface ValidationError<T = unknown> extends BDError<T> {
  errorCode: string;
  errorType: BDAppErrorType.VALIDATION;
  message: string;
  field: string;
}

// eslint-disable-next-line @typescript-eslint/no-unsafe-declaration-merging
export class BDError extends Error {
  constructor(message?: string, options?: Partial<BDError>) {
    super(message);
    this.name = 'BrightDrop App Error';
    if (options) {
      for (const o of Object.entries(options)) {
        const key = o[0] as keyof this;
        const value = o[1] as this[typeof key];
        this[key] = value;
      }
    }
  }

  // convert class-based error into object for use with redux state
  static asJson = <T>(error: BDError<T>): BDError<T> => {
    return { ...error, message: error.message };
  };
}
