import { Asset, AssetMapItem } from '../../../models/asset.model';
import { Location } from '../../../models/common.model';

const MAX_LATITUDE = 90;
const MAX_LONGITUDE = 180;

export const isValidLocation = (location?: Partial<Location>): boolean =>
  !!location &&
  typeof location.latitude === 'number' &&
  typeof location.longitude === 'number' &&
  !isNaN(location.latitude) &&
  !isNaN(location.longitude) &&
  location.latitude >= -MAX_LATITUDE &&
  location.latitude <= MAX_LATITUDE &&
  location.longitude >= -MAX_LONGITUDE &&
  location.longitude <= MAX_LONGITUDE;

export const isValidPosition = ([longitude, latitude]: GeoJSON.Position) =>
  isValidLocation({ longitude, latitude } as Location);

export const isAssetCritical = (asset?: Asset): boolean =>
  typeof asset?.flags?.ready === 'boolean' ? !asset.flags.ready : false;

export const isItemCritical = (item?: AssetMapItem): boolean =>
  typeof item?.ready === 'boolean' ? !item.ready : false;
