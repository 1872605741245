import { Permission, Profile, Role } from '@gm-commercial/profile-model';

import {
  ApiAddressFields,
  mapApiResponseToAddress,
} from '~/common/mappers/common.mappers';
import { ActivationStatus, PartialEntity } from '~/common/models/common.model';
import { BDAppErrorType, BDError } from '~/common/models/error.model';
import { Fleet } from '~/common/models/fleet.model';
import { Hub } from '~/common/models/hub.model';
import { OnboardingState } from '~/common/models/onboarding.model';
import { Organization } from '~/common/models/organization.model';
import { lowercaseLiteral } from '~/common/utils/common.utils';

export interface Result {
  grantedPermissions: GrantedPermission[];
}
export interface GrantedPermission {
  scope: string;
  grants: { [key: string]: boolean };
}
export interface PermissionsContextIDS {
  fleetsId: string | null;
  hubsId: string | null;
  organizationsId: string | null;
}
export interface Permissions {
  permissions: Permission[];
  permissionsContextIds: PermissionsContextIDS;
}
export const mapApiResponseToProfile = <
  Result extends Omit<Profile, 'name' | 'address' | 'status'> & {
    displayName: string | null;
    address: ApiAddressFields;
    status?: Uppercase<ActivationStatus> | null;
    organization?: PartialEntity<Organization> | null;
    hub?: PartialEntity<Hub> | null;
    fleet?: PartialEntity<Fleet> | null;
  } & Record<string, unknown>
>(
  rawResult: Result
): Profile => {
  if (rawResult.status) {
    const mapped: Profile = {
      ...rawResult,
      address: rawResult.address
        ? mapApiResponseToAddress(rawResult.address)
        : undefined,
      name:
        rawResult.displayName !== 'null null'
          ? rawResult.displayName === null
            ? rawResult.firstName + ' ' + rawResult.lastName
            : rawResult.displayName
          : '',
      role: Role[rawResult.role as unknown as keyof typeof Role],
      status: lowercaseLiteral(rawResult.status),
      organization: rawResult?.organization,
      hub: rawResult?.hub,
      fleet: rawResult?.fleet,
    };
    return mapped;
  }

  throw new BDError('Unexpected profile response', {
    type: BDAppErrorType.VALIDATION,
    data: rawResult,
  });
};

export const mapApiResponseToOnboardingState = (result?: OnboardingState) => {
  return result &&
    Object.values(OnboardingState).includes(result as OnboardingState)
    ? (result as OnboardingState)
    : OnboardingState.UNKNOWN;
};

export const mapOnboardingStateToApiResponse = (
  onBoardingState: OnboardingState
) => {
  return { onBoardingState };
};

export const mapPermissionResponse = (
  apiPermissionContext: Result
): Permissions => {
  const consolidatedGrants: Record<string, boolean> = {};
  apiPermissionContext?.grantedPermissions?.forEach(
    (permission: GrantedPermission) => {
      Object.entries(permission.grants).forEach(([action, isGranted]) => {
        consolidatedGrants[action] = consolidatedGrants[action] || isGranted;
      });
    }
  );
  const permissionsContextIds: PermissionsContextIDS = {
    organizationsId: null,
    fleetsId: null,
    hubsId: null,
  };
  const arrayOfIds = apiPermissionContext.grantedPermissions?.[0]?.scope
    .split('/')
    .filter(Boolean);
  for (let i = 0; i < arrayOfIds.length; i += 2) {
    const key = arrayOfIds[i];
    const value = arrayOfIds[i + 1];
    if (key === 'organization') {
      permissionsContextIds.organizationsId = value;
    } else if (key === 'fleet') {
      permissionsContextIds.fleetsId = value;
    } else if (key === 'hub') {
      permissionsContextIds.hubsId = value;
    }
  }
  return {
    permissions: Object.keys(consolidatedGrants).filter(
      (action) => consolidatedGrants[action]
    ) as Permission[],
    permissionsContextIds,
  };
};
