export const COMMON_FORM_MESSAGES = {
  errors: {
    'common:form.errors.name.duplicated':
      'Name is already in use. Please enter a different name.',
    'common:form.errors.email.duplicated':
      'Email is already in use. Please enter a different email.',
    'common:form.errors.update':
      'Unexpected error while saving {{name}}, please try again later.',
    'common:form.errors.add':
      'Unexpected error while adding {{name}}, please try again later.',
    'common:form.errors.apply':
      'Unexpected error while applying {{name}}, please try again later.',
  },
};
