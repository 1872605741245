import { Box } from '@mui/material';
import { type CSSProperties, type ReactNode, useMemo } from 'react';

export interface FlexProps {
  children?: ReactNode;
  col?: boolean;
  center?: boolean;
  gap?: number;
  style?: CSSProperties;
  className?: string;
  dataTestId?: string;
  onClick?: () => void;
}

const Flex = ({
  children,
  col,
  center,
  style,
  className,
  gap,
  dataTestId,
  onClick,
}: FlexProps) => {
  const calculatedStyles = useMemo(() => {
    const defaultStyle: CSSProperties = {
      display: 'flex',
      flexDirection: col ? 'column' : undefined,
      gap,
    };

    if (center) {
      defaultStyle.justifyContent = 'center';
      defaultStyle.alignItems = 'center';
    }

    return {
      ...defaultStyle,
      ...style,
    };
  }, [style, col, center, gap]);

  return (
    <Box
      data-testid={dataTestId}
      className={className}
      style={calculatedStyles}
      onClick={onClick}
    >
      {children}
    </Box>
  );
};

export default Flex;
