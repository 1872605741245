import { Button } from '@brightdrop/bd-ui';
import { useTranslations } from '@brightdrop/localization-client';
import { cx } from '@emotion/css';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import { ReactNode, useMemo } from 'react';
import { useHistory } from 'react-router-dom';

import usePageTitle from '../../hooks/usePageTitle';
import useStyles from './AlertMessage.styles';
import { getMobileAppUrlConfig } from './config/getAlertMessage.config';

const MESSAGES = {
  'common:alertMsg.bdLogo': 'BrightDrop logo',
  'common:alertMsg.alertMsg': 'Alert message',
};
export interface AlertMessageProps {
  header?: string;
  body?: string;
  topic?: string;
  hideLogo?: boolean;
  imageContent?: ReactNode;
  showGraphic?: boolean;
  linkBtn?: string;
  onClick?: () => void;
  id?: string;
}

const AlertMessage = ({
  header,
  body,
  topic,
  hideLogo = true,
  imageContent,
  showGraphic = false,
  linkBtn,
  onClick,
  id,
}: AlertMessageProps): JSX.Element => {
  usePageTitle(header);
  const { classes } = useStyles();
  const history = useHistory();
  const { translations } = useTranslations(MESSAGES);
  const handleClicked = () => history.push('/');
  const getDownloadItems = useMemo(() => getMobileAppUrlConfig(), []);

  return (
    <div
      className={cx(
        classes.alertMessageWrapper,
        `topic_${topic ? topic : 'default'} ${
          showGraphic ? 'showGraphic' : 'noGraphic'
        }`
      )}
    >
      <div className={classes.alertMessageContainer}>
        <div className={cx(classes.alertMessageLeftCol, 'leftColumn')}>
          <Box className="alertWrapper">
            {!hideLogo && (
              <div
                data-testid={'alert-message-bd-logo'}
                aria-label={translations['common:alertMsg.bdLogo']}
                className={classes.logo}
              ></div>
            )}
            <Box
              display="flex"
              alignSelf="center"
              alignItems="center"
              justifyContent="center"
              flexDirection="column"
            >
              {imageContent && (
                <div
                  className={classes.imageContentContainer}
                  data-testid={'alert-message-imageContent-container'}
                >
                  {imageContent}
                </div>
              )}
              {header && (
                <Box
                  data-testid={`${id}-alert-title`}
                  component="div"
                  className={cx(classes.alertTitle, 'alertTitleCopy')}
                >
                  <Typography variant="display1_regular">{header}</Typography>
                </Box>
              )}
              {body && (
                <Box
                  aria-label={translations['common:alertMsg.alertMsg']}
                  component="div"
                  data-testid={`${id}-alert-message`}
                >
                  <Typography
                    variant="body_regular"
                    className={cx(classes.body, 'alertBody')}
                    gutterBottom
                  >
                    {body}
                  </Typography>
                </Box>
              )}
              {!!topic && topic == 'mobileAppRequired' && (
                <Box
                  component="div"
                  className="appStoreLogos"
                  data-testid="app-store-link-container"
                >
                  {getDownloadItems.map((items, index) => (
                    <Box key={index}>
                      <Link
                        href={items.url}
                        data-testid={items.id}
                        target="_blank"
                      >
                        <Box>{items.img}</Box>
                      </Link>
                    </Box>
                  ))}
                </Box>
              )}
              {!!linkBtn && (
                <Box component="div">
                  <Button
                    id="alert-btn"
                    size="medium"
                    margin="16px 0 0"
                    onClick={onClick || handleClicked}
                  >
                    {linkBtn}
                  </Button>
                </Box>
              )}
            </Box>
          </Box>
        </div>
        {!!showGraphic && (
          <div className={classes.alertMessageRightCol}>
            <div
              className="alertMessageGraphic"
              data-testid="alert-message-graphic"
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default AlertMessage;
