import { Box, Typography } from '@mui/material';

import { InsightChartTooltip } from '~/common/models/insight.model';

import useStyles from './ElectricityCostChartToolTip.styles';

export interface ElectricityCostChartToolTipProps {
  tooltip: InsightChartTooltip;
}

const ElectricityCostChartToolTip = ({
  tooltip,
}: ElectricityCostChartToolTipProps): JSX.Element => {
  const { classes } = useStyles();

  return (
    <>
      <Box
        className={classes.toolTip}
        style={{
          top: tooltip.top,
          left: tooltip.left + 60,
          opacity: tooltip.opacity,
        }}
      >
        <Box className={classes.titleWrapper}>
          <Typography
            variant="h5_deprecated"
            data-testid="electricity-cost-chart-tooltip-title"
            className={classes.title}
          >
            {tooltip.title}
          </Typography>
          <span className={classes.date}>
            <Typography
              variant="subtitle1_deprecated"
              data-testid="electricity-cost-chart-tooltip-date"
            >
              {tooltip?.date && tooltip.date}
            </Typography>
          </span>
        </Box>
        <Box className={classes.bodyWrapper}>
          <Box display="flex" alignItems="center">
            <Box
              className={classes.body}
              style={{
                backgroundColor: tooltip.colors[1],
              }}
            ></Box>
            <Typography
              variant="subtitle1_deprecated"
              data-testid="electricity-cost-chart-tooltip-historical-label"
            >
              {tooltip.bodyLabels[1]}
            </Typography>
          </Box>
          <Typography
            variant="h5_deprecated"
            data-testid="electricity-cost-chart-tooltip-historical-value"
          >
            {tooltip.bodyValues[1]}
          </Typography>
        </Box>
        <Box className={classes.bodyWrapper}>
          <Box display="flex" alignItems="center">
            <Box
              className={classes.body}
              style={{
                backgroundColor: tooltip.colors[0],
              }}
            ></Box>
            <Typography
              variant="subtitle1_deprecated"
              data-testid="electricity-cost-chart-tooltip-recommendation-label"
            >
              {tooltip.bodyLabels[0]}
            </Typography>
          </Box>
          <Typography
            variant="h5_deprecated"
            data-testid="electricity-cost-chart-tooltip-recommendation-value"
          >
            {tooltip.bodyValues[0]}
          </Typography>
        </Box>
      </Box>
    </>
  );
};

export default ElectricityCostChartToolTip;
