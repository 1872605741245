export type MaxSizeDetails = {
  maxSize: number;
  currentSize: number;
};

export type FileTypeDetails = {
  providedType: string[];
  acceptedType: string[];
};

export type FileUploadErrorDetails = MaxSizeDetails | FileTypeDetails;

export enum FileUploadErrorType {
  MAX_SIZE = 'MAX_SIZE',
  FILE_TYPE = 'FILE_TYPE',
  CONTENT_VALIDATION = 'CONTENT_VALIDATION',
}

export type FileUploadErrorData = {
  type: FileUploadErrorType;
  details?: FileUploadErrorDetails;
};
