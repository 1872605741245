import { Profile } from '@gm-commercial/profile-model';

import {
  permissionsOperationSelector,
  profileOperationSelector,
  selectPermissions,
  selectProfile,
} from '../../features/profile/profileSlice.selectors';
import useDelayedResult from './useDelayedResult';

const useProfileWithPermissions = (): {
  isLoading: boolean;
  isUpdating: boolean;
  profile?: Profile;
} => {
  const {
    result: profile,
    isLoading: isProfileLoading,
    isUpdating: isProfileUpdating,
  } = useDelayedResult(selectProfile, profileOperationSelector);

  const {
    isLoading: arePermissionsLoading,
    isUpdating: arePermissionsUpdating,
  } = useDelayedResult(selectPermissions, permissionsOperationSelector);

  const isLoading = isProfileLoading || arePermissionsLoading;

  const isUpdating = isProfileUpdating || arePermissionsUpdating;

  return { isLoading, isUpdating, profile };
};

export default useProfileWithPermissions;
