import { useTranslations } from '@brightdrop/localization-client';
import {
  Alert,
  AlertTitle,
  Button,
  CircularProgress,
  Snackbar,
} from '@mui/material';
import ky from 'ky';
import { useCallback, useEffect, useState } from 'react';
import { matchPath, useHistory, useLocation } from 'react-router-dom';
import { useRegisterSW } from 'virtual:pwa-register/react';

import useEvent from '~/common/hooks/useEvent';
import { AppRoutePaths } from '~/common/models/route.model';

import useStyles from './VersionRefresher.styles';

export const EVERY_HOUR = 60 * 60 * 1000;

const MESSAGES = {
  'common:newVersionMessage': 'There is a new version of the application.',
  'common:newVersionConfirmation': 'Do you wish to use it now?',
  'common:update': 'Update',
};
function VersionRefresher() {
  const { classes } = useStyles();
  const [inProgress, setInProgress] = useState(false);
  const [allowNotification, setAllowNotification] = useState(false);
  const history = useHistory();
  const { translations } = useTranslations(MESSAGES);
  const location = useLocation();
  const matchUnsupportedRoute = matchPath(location.pathname, {
    path: [AppRoutePaths.LOGIN, AppRoutePaths.LOGOUT],
  });
  const [intervalId, setIntervalId] = useState<NodeJS.Timer>();

  const {
    needRefresh: [newVersionAvailable],
    updateServiceWorker,
  } = useRegisterSW({
    onRegisteredSW: (serviceWorkerUrl, registration) => {
      if (!registration) {
        return;
      }
      const id = setInterval(async () => {
        if (!(!registration.installing && window.navigator)) {
          //Currently installing a new version
          return;
        }
        if ('connection' in window.navigator && !window.navigator.onLine) {
          //App working offline
          return;
        }
        await ky
          .get(serviceWorkerUrl, {
            headers: {
              cache: 'no-store',
              'cache-control': 'no-cache',
            },
          })
          .then(async () => {
            await registration.update();
            setAllowNotification(true);
          });
      }, EVERY_HOUR);
      setIntervalId(id);
    },
  });

  const installNewVersion = useCallback(() => {
    if (!inProgress && newVersionAvailable) {
      setInProgress(true);
      updateServiceWorker(!matchUnsupportedRoute).then(() => {
        if (!matchUnsupportedRoute) {
          history.go(0);
        }
      });
    }
  }, [
    inProgress,
    newVersionAvailable,
    updateServiceWorker,
    matchUnsupportedRoute,
    history,
  ]);

  const closeNotification = useCallback(() => {
    setAllowNotification(false);
  }, []);

  const action = (
    <Button color="primary" onClick={installNewVersion} disabled={inProgress}>
      <span>{translations['common:update']}</span>
      {inProgress && <CircularProgress size={15} />}
    </Button>
  );

  useEffect(() => {
    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [intervalId]);

  //This is to make users get the most recent version on their next visit/reload in case they have not explicitly accepted it yet
  useEvent('beforeunload', installNewVersion);

  return (
    <Snackbar
      open={allowNotification && newVersionAvailable && !matchUnsupportedRoute}
      autoHideDuration={60000}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      onClose={closeNotification}
    >
      <Alert
        data-testid="update-version-container"
        severity="info"
        variant="filled"
        sx={{ width: '100%' }}
        action={action}
        className={classes.newVersionAlert}
      >
        <AlertTitle>
          {translations['common:newVersionMessage']}{' '}
          {translations['common:newVersionConfirmation']}
        </AlertTitle>
      </Alert>
    </Snackbar>
  );
}

export default VersionRefresher;
