import { useTranslations } from '@brightdrop/localization-client';
import { useMemo } from 'react';
import { Trans } from 'react-i18next';

import { DEFAULT_EMPTY_VALUE } from '~/common/constants/common.constant';
import { formatNotificationDate } from '~/common/utils/date-time.formatter.utils';
import {
  DAY,
  HOUR,
  isAfter,
  isBefore,
  isSameDate,
  MINUTE,
  toRelative,
  toRelativeCalendar,
  toTimeFormatWithoutTimeZone,
} from '~/common/utils/date-time.utils';

import i18n from '../../configs/i18n.config';

interface CSPlatformDateTimeProps {
  date?: string;
}

const MESSAGES = {
  'common:just_now': 'Just Now',
  'common:cs_platform_datetime': '{{capitalizedDate}} at {{absoluteTime}}',
};

const CSPlatformDateTime = ({ date }: CSPlatformDateTimeProps): JSX.Element => {
  const { translations } = useTranslations(MESSAGES);
  const formattedDate = useMemo(() => {
    if (!date) {
      return DEFAULT_EMPTY_VALUE;
    }

    const toDate = new Date(date);
    const relativeDate = toRelative(toDate, i18n.language);
    //Specifying units so that last month/year/week isn't shown
    const relativeCalendarDate = toRelativeCalendar(toDate, i18n.language, {
      unit: 'days',
    });
    const absoluteTime = toTimeFormatWithoutTimeZone(toDate);

    //If event was < 1 min ago, show Just Now
    if (isAfter(toDate, new Date(Date.now() - MINUTE))) {
      return translations['common:just_now'];
    }

    //If the event was between 1 minute and 4 hours ago, show the amount of time that has passed in minutes or hours
    if (
      isBefore(toDate, new Date(Date.now() - MINUTE)) &&
      isAfter(toDate, new Date(Date.now() - HOUR * 4))
    ) {
      return relativeDate || DEFAULT_EMPTY_VALUE;
    }

    //If event was more than 4 hours ago and on the same day, or if it was yesterday, show Today or Yesterday
    if (
      (isBefore(toDate, new Date(Date.now() - HOUR * 4)) &&
        isSameDate(toDate.toISOString(), new Date(Date.now()).toISOString())) ||
      isSameDate(toDate.toISOString(), new Date(Date.now() - DAY).toISOString())
    ) {
      const capitalizedDate = relativeCalendarDate
        ?.substring(0, 1)
        ?.toLocaleUpperCase()
        ?.concat(relativeCalendarDate?.substring(1));
      //Utilizing translation string here so that Trans component isn't returned in the function when it's not needed
      const transEl = (
        <Trans
          i18nKey="common:notification_timestamp"
          values={{ capitalizedDate, absoluteTime }}
        >
          {{ capitalizedDate }} at {{ absoluteTime }}
        </Trans>
      );
      return transEl || DEFAULT_EMPTY_VALUE;
    }

    //If the time that has passed is more than 1 day, show the actual timestamp like now.
    return formatNotificationDate(date) || DEFAULT_EMPTY_VALUE;
  }, [date, translations]);

  return <>{formattedDate}</>;
};

export default CSPlatformDateTime;
