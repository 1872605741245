import { makeStyles } from 'tss-react/mui';

export const HEADER_HEIGHT = 64;

const useStyles = makeStyles()((theme) => ({
  headerWrapper: {
    borderBottom: `1px solid ${theme.new.color.line.hairline}`,
    boxShadow: 'none',
    maxHeight: `${HEADER_HEIGHT}px`,
    zIndex: 2,
    '&.hideBar': { display: 'none' },
  },
  container: {
    display: 'flex',
    justifyContent: 'right',
    alignItems: 'flex-start',
    flex: '1 0 0',
    padding: '12px 16px',
    minHeight: `${HEADER_HEIGHT}px`,
    [theme.breakpoints.up('md')]: {
      padding: '12px 32px',
    },
  },
  header: {
    display: 'flex',
    justifyContent: 'flex-end',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '24px',
    width: '100%',
  },
  profileContainerButton: {
    borderRadius: '50%',
    height: '32px',
    minWidth: '32px',
    padding: 0,
    width: '32px',

    '&:hover, &:focus': {
      outline: `5px solid ${theme.new.color.brand[100]}`,
      outlineOffset: '0px',
    },

    '&:active': {
      outline: `5px solid ${theme.new.color.brand[200]}`,
      outlineOffset: '0px',
    },
  },
  profileContainer: {
    borderRadius: '50%',
    width: '32px',
    height: '32px',
    padding: 0,
    backgroundColor: theme.palette.grey[100],
    border: `1px solid ${theme.new.color.line.hairline}`,
    color: theme.palette.text.secondary,
    fontSize: '0.9rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '&.isOpen': {
      outline: `5px solid ${theme.new.color.brand[200]}`,
      outlineOffset: '0px',
    },
  },
}));

export default useStyles;
