import { IconButton } from '@brightdrop/bd-ui';
import { ListItemIcon, ListItemText, Typography } from '@mui/material';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import SvgIcon from '@mui/material/SvgIcon';
import React, { MouseEvent, ReactNode, useState } from 'react';

import Overflow from '~/assets/icons/new/overflowh.svg?react';

/**
 * Settings Menu Component for list / row / menu item
 * @param options[] array of options parameters for display:
 *  accepts array of:
 *    - text: string;
 *    - icon?: React.ReactNode / null
 *    - onClick?: function return type void [onClick: () => void]
 */

export interface Option {
  text: string | JSX.Element;
  icon?: ReactNode;
  onClick?: (e: React.MouseEvent<HTMLElement>, handleClose: () => void) => void;
}

interface SettingsMenuProps {
  options: Option[];
}

const CSPlatformSettingsMenu = ({ options }: SettingsMenuProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClick = (event: MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  return (
    <>
      <IconButton
        edge={'end'}
        aria-label={'settings'}
        data-testid={'settings-icon-button'}
        onClick={handleClick}
        sx={{ padding: '2px' }}
        icon={<SvgIcon component={Overflow} viewBox="0 0 16 16" />}
        size="medium"
        variant="fill"
      />
      <Menu
        anchorEl={anchorEl}
        onClick={(e: MouseEvent<HTMLElement>) => e.stopPropagation()}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        slotProps={{
          paper: {
            style: {
              transform: 'translateX(-7%) translateY(21%)',
            },
          },
        }}
      >
        {options?.map((option, index) => (
          <MenuItem
            key={index}
            onClick={(e) => {
              handleClose();
              //Call OnClick handler if one exists
              option.onClick && option.onClick(e, handleClose);
            }}
            sx={{ width: '220px' }}
          >
            <ListItemIcon
              data-testid={'CSPlatformMenuItemIcon' + index}
              style={{ minWidth: '28px' }}
            >
              {option?.icon}
            </ListItemIcon>
            <ListItemText
              data-testid={'CSPlatformMenuItemText' + index}
              primary={
                <Typography variant="body_regular">{option?.text}</Typography>
              }
            />
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default CSPlatformSettingsMenu;
