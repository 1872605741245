import { combineReducers } from '@reduxjs/toolkit';
import { DateTime } from 'luxon';
import { createTransform, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import { peripheralsReducer } from '~/features/peripherals/peripheralsSlice';

import { alertsApi } from '../alertsApi/alertsApi';
import { alertsReducer } from '../features/alerts/alertsSlice';
import { assetsReducer } from '../features/assets/assetsSlice';
import { csDashboardReducer } from '../features/dashboard/csPlatform/csDashboardSlice';
import { dashboardReducer } from '../features/dashboard/dashboardSlice';
import { dispatchReducer } from '../features/dispatch/dispatchSlice';
import { jobsReducer } from '../features/dispatch/UploadHistory/jobsSlice';
import { workPlansReducer } from '../features/dispatch/workplans/workPlansSlice';
import { featureFlagsReducer } from '../features/featureFlags/featureFlagsSlice';
import { fleetsReducer } from '../features/fleets/fleetsSlice';
import { hubsReducer } from '../features/hubs/hubsSlice';
import { insightsReducer } from '../features/insights/insightsSlice';
import { inspectionsReducer } from '../features/inspections/inspectionsSlice';
import { mapReducer } from '../features/map/mapSlice';
import { vehicleOrdersReducer } from '../features/orders/vehicleOrdersSlice';
import { organizationsReducer } from '../features/organizations/organizationsSlice';
import { profileReducer } from '../features/profile/profileSlice';
import { pubSubReducer } from '../features/pubSub/pubSubSlice';
import {
  authReducer,
  AuthState,
  INITIAL_AUTH_STATE,
} from '../features/session/authSlice';
import { usersReducer } from '../features/users/usersSlice';
import { utilizationReducer } from '../features/utilization/utilizationSlice';

const isTokenExpired = (authToken: AuthState['authToken']) => {
  const expiration = authToken?.expiration;
  return (
    expiration &&
    DateTime.now().diff(DateTime.fromMillis(expiration)).toMillis() > 0
  );
};

/**
 * Check in-bound and out-bound auth state to avoid persisting expired tokens
 */
const authTokenTransform = createTransform<
  AuthState,
  AuthState,
  AuthState,
  AuthState
>(
  (inboundState: AuthState): AuthState =>
    isTokenExpired(inboundState.authToken)
      ? { ...inboundState }
      : INITIAL_AUTH_STATE,
  (outboundState: AuthState): AuthState =>
    isTokenExpired(outboundState.authToken)
      ? { ...outboundState }
      : INITIAL_AUTH_STATE,
  { whitelist: ['auth'] }
);

const authPersistConfig = {
  key: 'auth',
  storage,
  whitelist: ['authToken', 'domainHint'],
  transforms: [authTokenTransform],
};

const dashboardPersistConfig = {
  key: 'dashboard',
  storage,
  whitelist: ['summaryConfigs'],
};

const mapPersistConfig = {
  key: 'map',
  storage,
  whitelist: ['viewConfigs'],
};
const profilePersistConfig = {
  key: 'profile',
  storage,
  whitelist: ['rowsPerPage'],
};

export const rootReducer = combineReducers({
  auth: persistReducer(authPersistConfig, authReducer),
  profile: persistReducer(profilePersistConfig, profileReducer),
  organizations: organizationsReducer,
  hubs: hubsReducer,
  fleets: fleetsReducer,
  assets: assetsReducer,
  users: usersReducer,
  map: persistReducer(mapPersistConfig, mapReducer),
  dashboard: persistReducer(dashboardPersistConfig, dashboardReducer),
  alerts: alertsReducer,
  [alertsApi.reducerPath]: alertsApi.reducer,
  utilization: utilizationReducer,
  pubSub: pubSubReducer,
  dispatch: dispatchReducer,
  featureFlags: featureFlagsReducer,
  insights: insightsReducer,
  inspections: inspectionsReducer,
  jobs: jobsReducer,
  workPlans: workPlansReducer,
  vehicleOrders: vehicleOrdersReducer,
  csDashboard: csDashboardReducer,
  peripherals: peripheralsReducer,
});

export type RootState = ReturnType<typeof rootReducer>;
