import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  mainContainer: {
    display: 'flex',
    [theme.breakpoints.up('lg')]: {
      padding: 0,
    },
    '& .contentContainer': {
      display: 'flex',
      padding: '0',
      minHeight: 'calc(100vh - 240px)',
      [theme.breakpoints.up('md')]: {
        minHeight: 'calc(100vh - 150px)',
        padding: '0',
      },
    },
    '& .contentWrapper': {
      padding: '32px',
      maxWidth: '100%',
      [theme.breakpoints.between('lg', 'xl')]: {
        maxWidth: 'calc(100vw - 60px)',
      },
      [theme.breakpoints.down('md')]: {
        padding: '24px',
      },
      '&.noPagePadding': {
        padding: '0px',
      },
      '& > .contentInner': {
        margin: '0 auto',
        [theme.breakpoints.down('md')]: {
          '& .subTitleBar.MuiAppBar-positionSticky': {
            position: 'relative',
          },
        },
      },
      '& header.subTitleBar': {
        background: theme.palette.grey[100],
        zIndex: '3',
        borderBottom: 'none',
        boxShadow: 'none',
        '& .MuiContainer-root': {
          padding: '0',
        },
        '& .MuiToolbar-root, & .MuiTabs-root': {
          padding: '0',
        },
        [theme.breakpoints.down('md')]: {
          '& .MuiTabs-root': {
            display: 'none',
          },
        },
      },
      '& .pageTitleContainer': {
        '& .pageTitleSection': {
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          [theme.breakpoints.down('md')]: {
            '& > *': {
              width: '50%',
            },
            '& > .MuiBox-root > div': {
              width: '100%',
            },
            '& .MuiFormControl-root': {
              width: '100%',
            },
            '&.asset-details-title': {
              flexDirection: 'column',
              '& > *': {
                width: '100%',
              },
            },
          },
        },
        [theme.breakpoints.down('md')]: {
          '& .MuiTabs-root': { padding: '12px 0 0' },
        },
      },
    },
  },
}));

export default useStyles;
