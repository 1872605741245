import { RootState } from '../../../app/rootReducer';
import {
  BDRequestStatus,
  OperationStatus,
} from '../../models/apis/apiResponse.model';

export const IDLE_OPERATION_STATUS: OperationStatus = {
  status: BDRequestStatus.IDLE,
  errors: [],
};

export const getOperationStatus = <T extends Record<string, string>>(
  {
    operations,
  }: {
    operations: { [key in keyof T]?: OperationStatus };
  },
  expected: keyof T
): OperationStatus =>
  operations
    ? (operations[expected] as OperationStatus) || IDLE_OPERATION_STATUS
    : IDLE_OPERATION_STATUS;

export const selectSessionConfigById = <T, K extends keyof T>(
  {
    sessionConfigs,
  }: {
    sessionConfigs: { [k in K]: T[k] };
  },
  key: K
): T[typeof key] => sessionConfigs[key];

export const makeSelectState =
  <K extends keyof RootState>(name: K): ((state: RootState) => RootState[K]) =>
  (state: RootState): RootState[K] =>
    state[name];

// TODO: Remove if unuse
// type HasOperations<S, T> = S & {
//   operations: { [key in keyof T]?: OperationStatus };
// };
// export const makeSelectOperationStatus = <S, T extends Record<string, string>>(
//   operation: keyof T
// ): ((state: HasOperations<S, T>) => OperationStatus | undefined) =>
//   createSelector(
//     (state: HasOperations<S, T>) => state.operations?.[operation],
//     (operationStatus) => operationStatus || IDLE_OPERATION_STATUS
//   );
