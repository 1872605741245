import { Box, Button, Typography } from '@mui/material';
import Skeleton from '@mui/material/Skeleton';
import { useEffect, useRef, useState } from 'react';
import { RouteComponentProps, useHistory } from 'react-router-dom';

import CloseIcon from '~/assets/icons/new/close.svg?react';
import CSPlatformNotificationListViewWrapper from '~/common/components/list/CSPlatformNotificationListViewWrapper';
import useSessionId from '~/common/hooks/useSessionId';
import { FLEET_NOTIFICATIONS } from '~/common/models/pages/fleetPages.model';

import { useTranslations } from '../../../../../../../packages/localization-client';
import useStyles from './Notification.style';

interface NotificationListProps {
  handleCloseNotification: () => void;
  insidePopover?: boolean;
}

const MESSAGES = {
  'settings:notifications.title': 'Notifications',
  'settings:notifications.seeAllTab': 'See all',
};
const initialRouteProps: RouteComponentProps = {
  history: {
    length: 2,
    action: 'POP',
    location: { pathname: '/', search: '', state: undefined, hash: '' },
    push: () => {},
    replace: () => {},
    go: () => {},
    goBack: () => {},
    goForward: () => {},
    block: () => () => {},
    listen: () => Function,
    createHref: (location) => `${location.pathname}`,
  },
  location: { pathname: '/', search: '', state: undefined, hash: '' },
  match: { path: '/', url: '/', isExact: true, params: {} },
};

const NotificationList = ({
  handleCloseNotification,
  insidePopover,
}: NotificationListProps) => {
  const { classes } = useStyles();
  const history = useHistory();
  const { translations } = useTranslations(MESSAGES);
  const sessionId = useSessionId(true, true);
  const [childLoading, setChildLoading] = useState(true);
  const listRef = useRef(true);

  useEffect(() => {
    const interval = setInterval(() => {
      // @ts-expect-error - IDE not seeing this item as optional
      if (listRef?.current.isLoaded) {
        setChildLoading(false);
        clearInterval(interval);
      }
    }, 500);
    return () => clearInterval(interval);
  }, []);

  const navigateSeeAllBtn = () => {
    history.push(FLEET_NOTIFICATIONS, { insidePopover: true });
    handleCloseNotification();
  };

  return (
    <Box className={classes.notificationList}>
      <Box className={classes.notificationTitle} display="flex">
        {childLoading ? (
          <Skeleton width={'140px'} height={'40px'} />
        ) : (
          <Typography variant="header1_medium">
            {translations['settings:notifications.title']}
          </Typography>
        )}
        <CloseIcon
          data-testid="popover-close-icon"
          className={classes.closeIcon}
          onClick={handleCloseNotification}
        />
      </Box>
      <Box className={classes.wrapperListItem}>
        <CSPlatformNotificationListViewWrapper
          ref={listRef as any}
          insidePopover={insidePopover}
          handleCloseNotification={handleCloseNotification}
          {...initialRouteProps}
        />
      </Box>
      {childLoading ? (
        <Skeleton sx={{ height: '60px', margin: '12px' }} />
      ) : (
        <Box className={classes.seeAllButton}>
          <Button onClick={navigateSeeAllBtn}>
            {translations['settings:notifications.seeAllTab']}
          </Button>
        </Box>
      )}
    </Box>
  );
};

export default NotificationList;
