import isEqual from 'lodash/isEqual';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import { RootState } from '../../app/rootReducer';
import {
  BDRequestStatus,
  OperationStatus,
} from '../models/apis/apiResponse.model';
import { BDError } from '../models/error.model';

const useDelayedResult = <T>(
  valueSelector: (state: RootState) => T,
  statusSelector: (state: RootState) => OperationStatus
): OperationStatus & {
  result: T;
  isFailed: boolean;
  isLoading: boolean;
  isUpdating: boolean;
  returnedErrors: BDError[] | undefined;
  returnedResult: T | undefined;
} => {
  const updatedValue = useSelector(valueSelector);
  const { status: updatedStatus, errors } = useSelector(statusSelector);
  const [status, setStatus] = useState<BDRequestStatus>(BDRequestStatus.IDLE);
  const [returnedErrors, setReturnedErrors] = useState<BDError[]>();
  const [returnedResult, setReturnedResult] = useState<T>();
  const [result, setResult] = useState<T>(updatedValue);
  const isNotReady = useCallback(
    (status: BDRequestStatus) =>
      status &&
      status !== BDRequestStatus.SUCCEEDED &&
      status !== BDRequestStatus.FAILED,
    []
  );
  const isFailed = useMemo(
    () => updatedStatus === BDRequestStatus.FAILED,
    [updatedStatus]
  );
  const isLoading = useMemo(() => isNotReady(status), [isNotReady, status]);
  const isUpdating = useMemo(
    () => isNotReady(updatedStatus),
    [isNotReady, updatedStatus]
  );

  useEffect(() => {
    if (
      status !== BDRequestStatus.SUCCEEDED ||
      updatedStatus === BDRequestStatus.SUCCEEDED
    ) {
      setResult(updatedValue);
    }
  }, [updatedValue, status, updatedStatus]);
  useEffect(() => {
    if (!isLoading && !isUpdating) {
      if (!isEqual(returnedErrors, errors)) {
        setReturnedErrors(errors);
      }
    }
  }, [errors, isLoading, isUpdating, returnedErrors]);
  useEffect(() => {
    setStatus(
      (previousStatus) =>
        (previousStatus !== BDRequestStatus.SUCCEEDED &&
          previousStatus !== BDRequestStatus.FAILED &&
          updatedStatus) ||
        previousStatus
    );
  }, [updatedStatus]);
  useEffect(() => {
    if (!isLoading && !isUpdating) {
      if (!isEqual(returnedResult, updatedValue)) {
        setReturnedResult(updatedValue);
      }
    }
  }, [isLoading, isUpdating, returnedResult, updatedValue]);
  return {
    result,
    status,
    errors,
    returnedErrors,
    isFailed,
    isLoading,
    isUpdating,
    returnedResult,
  };
};

export default useDelayedResult;
