import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  formControl: {
    width: '100%',
  },
  chip: {
    borderRadius: 4,
    fontSize: 12,
    height: 20,
    lineHeight: 1.4,
    margin: 4,
    padding: '1.5px 8px',
    textTransform: 'uppercase',
  },
  invalidChip: {
    backgroundColor: theme.new.color.danger[100],
    borderColor: theme.new.color.danger[600],
    color: theme.new.color.danger[600],
  },
  helperTextWrapper: {
    margin: '4px 0 0',
  },
  helperText: {
    alignItems: 'center',
    display: 'flex',
    gap: 6,
    '&.error': {
      color: theme.new.color.errorSecondary,
    },
  },
}));

export default useStyles;
