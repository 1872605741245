import { makeStyles } from 'tss-react/mui';

import loginBackground from '../../../assets/images/login/bgImageV3.jpg';

const useStyles = makeStyles()((theme) => ({
  wrapper: {
    display: 'flex',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  paper: {
    display: 'flex',
    flexDirection: 'column',
    margin: '20px',
    height: '100%',
    width: '100%',
    '& h1': {
      paddingTop: '0',
    },
    borderRadius: '5px',
  },
  image: {
    background: `url("${loginBackground}") 50% 50% no-repeat`,
    backgroundSize: 'cover',
  },
  companyLogo: {
    padding: '0 0 4px',
    '& svg': {
      height: '50px',
    },
  },
  smLogo: {
    padding: '20px',
    '& svg': {
      height: '50px',
    },
  },
  rightContentContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    width: '100%',
    margin: 'auto',
    [theme.breakpoints.up('lg')]: {
      margin: 'auto',
    },
    [theme.breakpoints.up('xl')]: {
      marginLeft: 'auto',
      marginRight: 'auto',
      width: '65%',
    },
  },
  rightContent: {
    [theme.breakpoints.up('lg')]: {
      marginTop: '200px',
    },
  },
}));

export default useStyles;
