import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  container: {
    paddingLeft: '8px',
    '&:first-of-type': {
      paddingLeft: '0',
    },
  },
  button: {
    textTransform: 'none',
    fontFamily: 'interRegular, "Open Sans", Arial, sans-serif',
    fontWeight: '500',
    lineHeight: 'normal',
    fontSize: '0.875rem',
    color: theme.palette.text.primary,
    border: `1px solid ${theme.palette.border}`,
    backgroundColor: theme.palette.inputBackground,
    borderRadius: '8px',
    width: '100%',
    padding: '8px 10px',
    height: '38px',
    [theme.breakpoints.up('md')]: {
      width: 'auto',
    },
    [theme.breakpoints.between('lg', 'xl')]: {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    [theme.breakpoints.up('lg')]: {
      padding: '8px 10px',
      height: '38px',
    },
    '&:hover': {
      borderColor: theme.palette.text.secondary,
      backgroundColor: theme.palette.brand.light,
    },
    '&.selected': {
      backgroundColor: theme.palette.brand.light,
      borderColor: theme.palette.grey[400],
    },
  },
  options: {
    display: 'flex',
    flexDirection: 'column',
    padding: '5px 10px',
    minWidth: '145px',
  },
}));

export default useStyles;
